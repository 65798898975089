import React from "react";
import BigNumber from "bignumber.js";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { beautifyTokensAmount, poolsLogos } from "../../helpers";
import { Skeleton } from "../../Shared";
import AssetImage from "./AssetImage";
import { ReactComponent as ArrowBack } from "../../assets/arrow-back-dark.svg";
import { breakPoints } from "../../theme";
import { borrowOperations } from "../../redux/borrowSlice";

const BorrowAssetDescription = styled.div`
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid ${(props) => props.theme.border2};
  flex-wrap: wrap;
  @media (max-width: ${breakPoints.md}px) {
  }
  .borrow_asset_main_info {
    display: flex;
    align-items: flex-end;
    @media (max-width: ${breakPoints.md}px) {
      width: 100%;
    }
  }
`;

const BorrowAssetWidget = styled.div`
  margin-right: 2.5rem;
  margin-bottom: 1rem;
  @media (max-width: ${breakPoints.sm}px) {
    ${(props) =>
      props.bigText &&
      css`
        margin-right: 1rem;
      `}
  }

  h6 {
    font-size: 12px;
    font-weight: 500;
    color: ${(props) => props.theme.grayTitleBorrow};
    margin: 0 0 0.25rem;
  }
  > div {
    display: flex;
    align-items: center;
    font-size: ${(props) => (props.bigText ? "20px" : "16px")};
    ${(props) =>
      (props.variableAPY || props.stableAPY) &&
      css`
        color: ${props.stableAPY
          ? props.theme.primaryButtonHover
          : props.theme.uniswapPink};
      `}
    .borrow_asset_description_image, img {
      height: ${(props) => (props.bigImage ? "32px" : "20px")};
      width: ${(props) => (props.bigImage ? "32px" : "20px")};
      margin-right: 6px;
      border-radius: 50%;
    }
    .borrow_asset_widget_ball {
      height: 14px;
      width: 14px;
      background: ${(props) =>
        props.stableAPY
          ? props.theme.primaryButtonHover
          : props.theme.uniswapPink};
      border-radius: 50%;
      margin-right: 0.25rem;
    }
  }
  .borrow_asset_disable {
    color: ${(props) => props.theme.disabled};
    .borrow_asset_widget_ball {
      background: ${(props) => props.theme.disabled};
    }
  }
`;

function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}

const BorrowAssetHeader = ({ user, params, borrowAssetData }) => {
  const { t } = useTranslation();
  const { platform, version, asset } = params;

  const dispatch = useDispatch();

  const utilisationRate = BigNumber(borrowAssetData.totalDebt)
    .dividedBy(
      BigNumber(borrowAssetData.totalDebt).plus(
        borrowAssetData.availableLiquidity
      )
    )
    .times(100);

  return (
    <>
      <Link to="/borrow">
        <button className="borrow_asset_back">
          <ArrowBack /> {t("addition.back")}
        </button>
      </Link>
      <h1>{t("borrow.title")}</h1>
      <BorrowAssetDescription>
        <div className="borrow_asset_main_info">
          <BorrowAssetWidget bigText bigImage>
            <h6>{t("borrow.asset")}</h6>
            <div>
              <AssetImage
                className="borrow_asset_description_image"
                src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${borrowAssetData.address}/logo.png`}
                alt={borrowAssetData.symbol}
              />
              {asset}
            </div>
          </BorrowAssetWidget>
          <BorrowAssetWidget>
            <h6>{t("borrow.platform")}</h6>
            <div>
              {poolsLogos[capitalizeFirstLetter(platform)]}{" "}
              {capitalizeFirstLetter(platform)}{" "}
              {dispatch(borrowOperations.doesPlatformHaveVersions(platform)) &&
                version}
            </div>
          </BorrowAssetWidget>
        </div>

        <BorrowAssetWidget stableAPY>
          <h6>{t("borrow.stableAPY")}</h6>
          <div
            className={
              borrowAssetData.stableAPY === "0" ||
              borrowAssetData.totalStableDebt === "0"
                ? "borrow_asset_disable"
                : ""
            }
          >
            <span className="borrow_asset_widget_ball" />{" "}
            {borrowAssetData.stableBorrowRateEnabled
              ? beautifyTokensAmount(borrowAssetData.stableAPY * 100, {
                  suffix: "%",
                  percent: true,
                })
              : "-"}
          </div>
        </BorrowAssetWidget>
        <BorrowAssetWidget variableAPY>
          <h6>{t("borrow.variableAPY")}</h6>
          <div>
            <span className="borrow_asset_widget_ball" />
            {beautifyTokensAmount(borrowAssetData.variableAPY * 100, {
              suffix: "%",
              percent: true,
            })}
          </div>
        </BorrowAssetWidget>
        <BorrowAssetWidget>
          <h6>{t("borrow.assetPrice")}</h6>
          <div>
            {user.usdPrices ? (
              beautifyTokensAmount(
                borrowAssetData.priceETH * user.usdPrices.ETH,
                { prefix: "$", suffix: " USD" }
              )
            ) : (
              <Skeleton white height="20" />
            )}
          </div>
        </BorrowAssetWidget>
        <BorrowAssetWidget>
          <h6>{t("borrow.utilisationRate")}</h6>
          <div>
            {beautifyTokensAmount(utilisationRate, {
              suffix: "%",
              percent: true,
            })}
          </div>
        </BorrowAssetWidget>
        <BorrowAssetWidget>
          <h6>{t("borrow.availableLiquidity")}</h6>
          <div>
            {beautifyTokensAmount(borrowAssetData.availableLiquidity, {
              suffix: ` ${asset}`,
            })}
          </div>
        </BorrowAssetWidget>
      </BorrowAssetDescription>
    </>
  );
};

export default BorrowAssetHeader;
