import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import timestamp from "time-stamp";
import { integrations } from "@keyfi/keyfi-common";
import { useLocation } from "react-router-dom";

// Redux
import { connect, useDispatch } from "react-redux";
import { walletOperations } from "../../../../redux/walletSlice";
import {
  addTx,
  removeTx,
  showTransactionManager,
} from "../../../../redux/transactionManager/actions";

// Assets and Helpers
import InputRange from "../../SharedLiquidity/InputRange";
import { beautifyTokensAmount, tokensLogos } from "../../../../helpers";
import { Storage } from "../../../../helpers/Storage";
import { Skeleton } from "../../../../Shared";
import { getTxHistory } from "../../../TransactionManager";
import { RemoveLiquidityBody } from "../../SharedLiquidity";

const V1 = ({ showTransactionManager, addTx, removeTx, liquidityData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [percent, setPercent] = useState(0);

  // Gets both token symbols
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [input1, input2] = [query.get("input1"), query.get("input2")];

  const input1Return = useMemo(
    () =>
      liquidityData &&
      beautifyTokensAmount(liquidityData[input1] * (percent / 100)),
    [input1, liquidityData, percent]
  );

  const input2Return = useMemo(
    () =>
      liquidityData &&
      beautifyTokensAmount(liquidityData[input2] * (percent / 100)),
    [input2, liquidityData, percent]
  );

  const handleInputChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    if (value > 100) {
      return setPercent(100);
    }
    return setPercent(value);
  };

  const removeLiquidity = async () => {
    // Check if the pair exists
    const pairExists = await integrations.pancakeswap.isPairAvailable(
      input1,
      input2
    );

    if (pairExists) {
      let txHash = [];
      let isFirstTrxFired = false;
      try {
        const res = await integrations.pancakeswap.removeLiquidity(
          input1,
          input2,
          percent / 100,
          {
            pendingCallback: (trx) => {
              if (!isFirstTrxFired) {
                showTransactionManager();
                addTx({
                  platform: "PancakeSwap",
                  txType: "Remove liquidity",
                  txAmount: input1Return,
                  txAmount2: input2Return,
                  txToken: input1,
                  txToken2: input2,
                  txHash: trx.hash,
                  timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
                });
                isFirstTrxFired = true;
              }

              txHash.push(trx.hash);
            },
          }
        );

        if (res) {
          dispatch(walletOperations.loadUserData());
          const historyArray = getTxHistory();
          historyArray.unshift({
            platform: "PancakeSwap",
            txType: "Remove Liquidity",
            txAmount: input1Return,
            txAmount2: input2Return,
            txToken: input1,
            txToken2: input2,
            txHash: res.transactionHash,
            timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
          });
          Storage.setItem("transactions", JSON.stringify(historyArray));
          removeTx(txHash);
        }
      } catch (err) {
        showTransactionManager();
        const HistoryArray = getTxHistory();
        HistoryArray.unshift({
          platform: "PancakeSwap",
          txType: "Remove Liquidity",
          txAmount: input1Return,
          txAmount2: input2Return,
          txToken: input1,
          txToken2: input2,
          txHash: txHash[txHash.length - 1],
          timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
          status: "failed",
        });
        Storage.setItem("transactions", JSON.stringify(HistoryArray));
        removeTx(txHash);
      }
    }
  };

  return (
    <>
      <div className="remove-liquidity-tokens">
        <div className="remove-liquidity-tokens-logos">
          {tokensLogos[input1]}
          {tokensLogos[input2]}
          {input1}:{input2}
        </div>
        {liquidityData ? (
          <span className="remove-liquidity-token-liquidity">
            {beautifyTokensAmount(liquidityData.liquidity)}
          </span>
        ) : (
          <Skeleton width="60" white />
        )}
      </div>
      <RemoveLiquidityBody>
        <div className="remove-liquidity-input-container">
          <div className="remove-liquidity-input-label">
            {t("addition.amountToRemove")}
          </div>
          <div className="remove-liquidity-input">
            <input
              value={percent}
              onChange={handleInputChange}
              max="100"
              min="0"
            />{" "}
            %
          </div>
        </div>
        <div className="remove-liquidity-slider">
          <InputRange
            type="range"
            min="0"
            max="100"
            value={percent}
            onChange={(e) => setPercent(parseFloat(e.target.value))}
            step="1"
          />
        </div>
        <div className="remove-liquidity-options">
          <button
            className={percent === 25 && "remove-liquidity-option-selected"}
            onClick={() => setPercent(25)}
          >
            25%
          </button>
          <button
            className={percent === 50 && "remove-liquidity-option-selected"}
            onClick={() => setPercent(50)}
          >
            50%
          </button>
          <button
            className={percent === 75 && "remove-liquidity-option-selected"}
            onClick={() => setPercent(75)}
          >
            75%
          </button>
          <button
            className={percent === 100 && "remove-liquidity-option-selected"}
            onClick={() => setPercent(100)}
          >
            {t("swap.max")}
          </button>
        </div>
        {liquidityData && (
          <div className="remove-liquidity-data">
            <div className="remove-liquidity-data-item">
              <div>
                {tokensLogos[input1]}{" "}
                {t("addition.tokenYouWillReceive", { token: input1 })}
              </div>
              <div>{input1Return}</div>
            </div>
            <div className="remove-liquidity-data-item">
              <div>
                {tokensLogos[input2]}{" "}
                {t("addition.tokenYouWillReceive", { token: input2 })}
              </div>
              <div>{input2Return}</div>
            </div>
          </div>
        )}

        <button
          onClick={removeLiquidity}
          className="remove-liquidity-button"
          disabled={!percent || !liquidityData}
        >
          {t("addition.removeLiquidity")}
        </button>
      </RemoveLiquidityBody>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showTransactionManager: () => dispatch(showTransactionManager()),
  addTx: (tx) => dispatch(addTx(tx)),
  removeTx: (txHashs) => dispatch(removeTx(txHashs)),
});

export default connect(null, mapDispatchToProps)(V1);
