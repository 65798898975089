import React from "react";
import styled from "styled-components";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import { ReactComponent as CloseIcon } from "../assets/closeModal.svg";
import { breakPoints } from "../theme";

const ModalWrapper = styled(Dialog)`
  position: relative !important;
  width: ${(props) => (props.width ? props.width : "400px")};
  border-radius: 6px;
  margin: 5vh auto;
  padding: ${(props) => (props.padding ? props.padding : "2rem")};
  background: ${(props) => (props.bg ? props.bg : props.theme.superLightBlue)};
  max-width: ${(props) => (props.maxwidth ? props.maxwidth : "400px")};
  @media (max-width: ${breakPoints.lg}px) {
    width: calc(100% - 32px);
    max-width: 640px;
  }
  @media (max-width: ${breakPoints.md}px) {
    padding: 1rem;
  }
`;

const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 16px;
  border-bottom: ${(props) =>
    props.removeHeaderLine ? "none" : `1px solid ${props.theme.brandBlue}`};
  align-items: center;
  .close-icon {
    margin-left: auto;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: ${(props) => props.theme.lightBlue3};
    }
  }

  .title {
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    color: ${(props) => props.theme.brandBlue};

    @media (max-width: ${breakPoints.sm}px) {
      font-size: 1rem;
      line-height: 20px;
    }
  }

  @media (max-width: ${breakPoints.sm}px) {
    padding-bottom: 8px;
  }
`;
const ModalContent = styled.div`
  max-height: 75vh;
  overflow-y: auto;
`;
export default function Modal(props) {
  const {
    children,
    title,
    open,
    handleCloseModal,
    txModal,
    width,
    padding,
    tokensModal,
    maxwidth,
    removeHeaderLine,
  } = props;
  return (
    <>
      <ModalWrapper
        padding={padding}
        width={width}
        isOpen={open}
        onDismiss={handleCloseModal}
        aria-label="modal"
        maxwidth={maxwidth}
      >
        {txModal ? (
          children
        ) : (
          <>
            <ModalHeader removeHeaderLine={removeHeaderLine}>
              <span className="title">{title}</span>
              <span className="close-icon" onClick={handleCloseModal}>
                <CloseIcon />
              </span>
            </ModalHeader>
            <ModalContent tokensModal={tokensModal}>{children}</ModalContent>
          </>
        )}
      </ModalWrapper>
    </>
  );
}
