import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as TokensIcon } from "../../../../assets/sidebar/tokens.svg";
import { ReactComponent as ArrowDown } from "../../../../assets/arrowDown.svg";
import { AccordionList, AccordionWrapper } from "../../shared";

// Components
import KeyFiStaking from "./KeyFiStaking";
import KeyFiEthStaking from "./KeyFiEthStaking";
import USDCStaking from "./USDCStaking";
import WETHStaking from "./WETHStaking";
import WBTCStaking from "./WBTCStaking";

const V2Staking = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(true);

  return (
    <AccordionWrapper open={open}>
      <div className="accordion_header" onClick={() => setOpen(!open)}>
        <div className="accordion_header_title">
          <TokensIcon />
          <span>v2 {t("earn.staking")}</span>
        </div>
        <ArrowDown className="accordion_arrow" />
      </div>
      {open && (
        <AccordionList padding>
          <KeyFiStaking />
          <KeyFiEthStaking />
          <USDCStaking />
          <WETHStaking />
          <WBTCStaking />
        </AccordionList>
      )}
    </AccordionWrapper>
  );
};

export default V2Staking;
