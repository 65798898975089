import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { integrations } from "@keyfi/keyfi-common";

// components
import Compound from "../components/Deposit-WithdrawPools/Compound";
import Aave from "../components/Deposit-WithdrawPools/Aave";
import Dydx from "../components/Deposit-WithdrawPools/Dydx";

// utils
import { poolsLogos, switchToETH } from "../helpers";

const platforms = [
  { value: "compound", icon: poolsLogos.Compound, text: "Compound" },
  { value: "aave", icon: poolsLogos.Aave, text: "Aave" },
  { value: "dydx", icon: poolsLogos.dydx, text: "dydx" },
];

const Wrapper = styled.div``;

function Deposit() {
  const history = useHistory();
  const { platformCode } = useParams();
  const { search } = useLocation();

  const selectedSupply = new URLSearchParams(search).get("asset") ?? "ETH";
  const getPlatformFromUrl = platforms.find((p) => p.value === platformCode);

  const [selectedPlatform, setPlatform] = useState(
    getPlatformFromUrl || platforms[0]
  );

  useEffect(() => {
    setPlatform(getPlatformFromUrl || platforms[0]);
    switchToETH();
    integrations.resetNetwork();
  }, [platformCode, getPlatformFromUrl]);

  const handleSwitch = (value) => {
    history.push(`/deposit/${value}`);
  };
  return (
    <Wrapper>
      {selectedPlatform.value === "compound" && (
        <Compound
          setOption={handleSwitch}
          selectedSupply={selectedSupply}
          selectedOption={selectedPlatform}
          options={platforms}
        />
      )}
      {selectedPlatform.value === "aave" && (
        <Aave
          setOption={handleSwitch}
          selectedSupply={selectedSupply}
          selectedOption={selectedPlatform}
          options={platforms}
        />
      )}
      {selectedPlatform.value === "dydx" && (
        <Dydx
          setOption={handleSwitch}
          selectedSupply={selectedSupply}
          selectedOption={selectedPlatform}
          options={platforms}
        />
      )}
    </Wrapper>
  );
}

export default Deposit;
