import styled from "styled-components";
import { poolsLogos } from "../../helpers";
import { breakPoints } from "../../theme";

export const handlePriceImpactStyle = (priceImpact) => {
  if (priceImpact < 3) {
    return "swap-price-impact-green";
  }
  if (priceImpact >= 3 && priceImpact < 5) {
    return "swap-price-impact-orange";
  }
  if (priceImpact >= 5) {
    return "swap-price-impact-red";
  }
};

export const swapPlatforms = [
  { value: "uniswap", icon: poolsLogos["Uniswap"], text: "Uniswap", network: "mainnet" },
  { value: "1inch", icon: poolsLogos["1Inch"], text: "1inch", network: "mainnet" },
  { value: "apeswap", icon: poolsLogos["ApeSwap"], text: "ApeSwap", network: "bsc-mainnet" },
  {
    value: "pancakeswap",
    icon: poolsLogos["Pancakeswap"],
    text: "PancakeSwap",
    network: "bsc-mainnet",
  },
];

export const SwapPlatformBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  .swap-input-wrapper {
    .swap-input-labels {
      margin-bottom: 0.5rem;
      display: flex;
      justify-content: space-between;
      color: ${(props) => props.theme.mainDarkText};
      font-size: 12px;
      font-weight: 500;
    }
  }
  .swap-icon {
    cursor: pointer;
    height: 34px;
    width: 34px;
    margin: 1rem auto 0.5rem;
    border-radius: 50%;
    position: relative;
    :hover {
      background: ${(props) => props.theme.lightBlue3};
    }
    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 24px;
      width: 24px;
      path {
        fill: ${(props) => props.theme.brandBlue};
      }
    }
  }
  .swap-input-error {
    margin-top: 0.5rem;
    font-size: 12px;
    font-weight: 500;
    color: ${(props) => props.theme.error};
  }
  .swap-estimation-container {
    margin: 2rem 0 0;
    h3 {
      font-size: 12px;
      font-weight: 500;
      margin: 0 0 0.5rem;
    }
    .swap-estimation-datalist {
      display: flex;
      justify-content: space-between;
      padding: 0.75rem 1rem;
      border: 1px solid ${(props) => props.theme.border2};
      border-radius: 6px;
      color: ${(props) => props.theme.mainDarkText};
      .swap-estimation-data {
        * {
          margin: 0;
          font-weight: 500;
        }
        h4 {
          font-size: 12px;
          text-align: center;
        }
        div {
          font-size: 12px;
          opacity: 0.6;
        }
        .swap-price-impact-green {
          color: green;
        }
        .swap-price-impact-orange {
          color: orange;
        }
        .swap-price-impact-red {
          color: red;
        }
        :first-of-type {
          * {
            text-align: left;
          }
        }
        :last-of-type {
          * {
            text-align: right;
          }
        }
      }
      @media (max-width: ${breakPoints.md}px) {
        flex-direction: column;
        .swap-estimation-data {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          :not(:last-of-type) {
            margin-bottom: 0.25rem;
          }
        }
      }
    }
  }
  .swap-button {
    margin-top: 1.5rem;
    cursor: pointer;
    width: 100%;
    border: 0;
    border-radius: 6px;
    background: ${(props) => props.theme.brandBlue};
    color: white;
    padding: 0.75rem;
    font-size: 20px;
    :hover {
      background: ${(props) => props.theme.primaryButtonHover};
    }
    :disabled {
      pointer-events: none;
      background: ${(props) => props.theme.disabled};
    }
  }
`;
