import styled from "styled-components";
import { breakPoints } from "../../../theme";

export const StakingChart = styled.div`
  display: flex;
  margin: 3rem 0 4rem;
  max-height: 218px;
  * {
    font-weight: 500;
  }
  .flash {
    animation: fade 300ms linear;
  }
  @keyframes fade {
    0% {
      opacity: 1;
    }
    20% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media (max-width: ${breakPoints.lg}px) {
    flex-direction: column;
    max-height: none;
    margin-bottom: 1rem;
  }

  .tokens_chart_wrapper {
    height: 218px;
    width: 218px;
    margin-right: 4.5rem;
    position: relative;
    @media (max-width: ${breakPoints.lg}px) {
      display: flex;
      margin-right: 0;

      margin: 0 auto;
    }

    .tokens_chart_total {
      position: absolute;
      top: 38%;
      left: 0;
      width: 100%;
      text-align: center;
      z-index: -1;
      .tokens_chart_total_title {
        font-size: 14px;
        color: ${(props) => props.theme.mainDarkText};
        margin-bottom: 0.3rem;
      }
      .tokens_chart_total_keyfi {
        color: ${(props) => props.theme.brandBlue};
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 2px;
      }
      .tokens_chart_total_usd {
        color: #005ad380;
      }
    }
  }
  .tokens_chart_details {
    @media (max-width: ${breakPoints.lg}px) {
      margin-top: 1rem;
    }
    .tokens_chart_title {
      font-size: 12px;
      color: ${(props) => props.theme.mainDarkText};
      margin-bottom: 1.35rem;
    }
    .tokens_chart_labels {
      max-height: 218px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      @media (max-width: ${breakPoints.lg}px) {
        flex-direction: row;

        .tokens_chart_label {
          flex: 1 1 160px;
        }
      }
      .tokens_chart_label {
        margin-bottom: 1rem;
        margin-right: 2rem;
        width: max-content;
        display: flex;
        .tokens_chart_label_color {
          height: 20px;
          width: 20px;
          border-radius: 10px;
          margin-right: 0.5rem;
          margin-top: 5px;
        }
        .tokens_chart_label_title {
          color: ${(props) => props.theme.mainDarkText};
        }
        .tokens_chart_label_value {
          color: ${(props) => props.theme.lightGray};
        }
      }
    }
  }
`;

export const listOfColors = [
  "#1655AA",
  "#FE7F0A",
  "#03D06E",
  "#97DFFF",
  "#F3BA2F",
  "#DA2595",
  "#FF1117",
  "#7603D0",
  "#3C916B",
  "#121212",
  "#705E78",
  "#812F33",
];
