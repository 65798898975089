import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "./redux/store";
import { StyledThemeProvider } from "./theme";

import "./index.css";

import "./i18n";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div>loading..</div>}>
      <Provider store={store}>
        <BrowserRouter>
          <StyledThemeProvider>
            <App />
          </StyledThemeProvider>
        </BrowserRouter>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
