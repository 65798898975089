import React from "react";

import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  height: calc(100% - 8px);
  max-width: 250px;
  min-width: 250px;
  width: 100%;
  display: none;
  align-items: center;
  ${(props) => props.arrowTop && `margin-top: 2.5em;`}
  text-align: left;
  .tooltip_arrow_left {
    position: relative;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid ${(props) => props.theme.border2};
    z-index: 13;
    ${(props) => props.arrowTop && `margin-bottom: 4.5em;`}
  }
  .tooltip_box {
    position: relative;
    width: 247px;
    font-size: 12px;
    color: ${(props) => props.theme.brandBlue} !important;
    background: ${(props) => props.theme.border2};
    padding: 0.5rem;
    font-weight: 500;
    border-radius: 6px;
  }
  :hover {
    display: none;
  }
`;

const Tooltip = ({ text, arrowTop }) => {
  return (
    <Wrapper className="tooltip" arrowTop={arrowTop}>
      <div className="tooltip_arrow_left" />
      <div className="tooltip_box">{text}</div>
    </Wrapper>
  );
};

export default Tooltip;
