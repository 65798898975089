import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import PlatformsDropdown from "../../PlatformsDropdown";
import { SwapPlatformBody, swapPlatforms } from "../shared";

// Platform Versions
import V2 from "./v2";
import V1 from "./v1";

const SwapVersionButtons = styled.div`
  margin-top: 1rem;
  button {
    cursor: pointer;
    color: ${(props) => props.theme.mainDarkText};
    background: none;
    border: 1px solid ${(props) => props.theme.brandBlue};
    padding: 9px 0;
    max-width: 80px;
    width: 100%;
    border-right: 0;
    :first-of-type {
      border-radius: 6px 0 0 6px;
    }
    :last-of-type {
      border-radius: 0 6px 6px 0;
      border-right: 1px solid ${(props) => props.theme.brandBlue};
    }
  }
  .swap-version-selected {
    background: ${(props) => props.theme.brandBlue};
    color: white;
  }
`;

const handlePancakeSwapVersion = (version) => {
  switch (version) {
    case "v1":
      return <V1 />;
    case "v2":
      return <V2 />;
    default:
      break;
  }
};

const PancakeSwapSwap = ({ handlePlatformSwitch, selectedPlatform }) => {
  const { t } = useTranslation();

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const [version, setVersion] = useState(query.get("v") || "v2");

  return (
    <div>
      <div className="swap-platform-container">
        <div className="swap-platform-label">{t("swap.choosePlatform")}:</div>
        <PlatformsDropdown
          setOption={handlePlatformSwitch}
          selectedOption={selectedPlatform}
          options={swapPlatforms}
        />
        <SwapVersionButtons>
          <button
            className={version === "v2" && "swap-version-selected"}
            onClick={() => setVersion("v2")}
          >
            V2
          </button>
          <button
            className={version === "v1" && "swap-version-selected"}
            onClick={() => setVersion("v1")}
          >
            V1
          </button>
        </SwapVersionButtons>
      </div>
      <SwapPlatformBody>{handlePancakeSwapVersion(version)}</SwapPlatformBody>
    </div>
  );
};

export default PancakeSwapSwap;
