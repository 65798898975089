import React from "react";
import { useTranslation } from "react-i18next";
import PlatformsDropdown from "../../PlatformsDropdown";
import { SwapPlatformBody, swapPlatforms } from "../shared";

// Platform Versions
import V1 from "./v1";

const ApeSwapSwap = ({ handlePlatformSwitch, selectedPlatform }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="swap-platform-container">
        <div className="swap-platform-label">{t("swap.choosePlatform")}:</div>
        <PlatformsDropdown
          setOption={handlePlatformSwitch}
          selectedOption={selectedPlatform}
          options={swapPlatforms}
        />
      </div>
      <SwapPlatformBody>
        <V1 />
      </SwapPlatformBody>
    </div>
  );
};

export default ApeSwapSwap;
