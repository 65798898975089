import React, { useCallback, useEffect, useState } from "react";
import { integrations } from "@keyfi/keyfi-common";
import timestamp from "time-stamp";
import { useTranslation } from "react-i18next";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import {
  addTx,
  removeTx,
  showTransactionManager,
} from "../../../redux/transactionManager/actions";
import { walletOperations } from "../../../redux/walletSlice";

// Helpers and Assets
import { beautifyTokensAmount } from "../../../helpers";
import InputGroupv2 from "../../InputGroupv2";
import { ReactComponent as SwapIcon } from "../../../assets/sidebar/swap.svg";
import { getTxHistory } from "../../TransactionManager";
import { Storage } from "../../../helpers/Storage";
import { handlePriceImpactStyle } from "../shared";

const V1 = ({ showTransactionManager, addTx, removeTx }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [inputData, setInputData] = useState({
    input1: "",
    selectinput1: "BUSD",
    input2: "",
    selectinput2: "KEYFI",
    whichInputChanged: null,
    route: null,
    estimation: null,
    priceImpact: "",
    isFetching: false,
    tokenPrice1: "",
    tokenPrice2: "",
  });

  const [errorData, setErrorData] = useState({
    pairExists: true,
    enoughInput1: true,
    emptyInputs: true,
  });

  const {
    input1,
    selectinput1,
    input2,
    selectinput2,
    whichInputChanged,
    route,
    priceImpact,
    estimation,
    isFetching,
  } = inputData;
  const { pairExists, enoughInput1, emptyInputs } = errorData;

  useEffect(() => {
    if (
      input1 &&
      selectinput1 &&
      selectinput2 &&
      whichInputChanged !== "input2"
    ) {
      setInputData((prev) => ({ ...prev, isFetching: true }));
      integrations.pancakeswap
        .estimateSwap(selectinput1, input1, selectinput2)
        .then((estimation) => {
          setInputData((prevState) => {
            return {
              ...prevState,
              input2: +estimation.returnAmountHuman || "",
              route: estimation.route,
              priceImpact: estimation.priceImpact,
              tokenPrice1: String(
                Number(estimation.fromAmountHuman) /
                  Number(estimation.returnAmountHuman)
              ),
              tokenPrice2: String(
                Number(estimation.returnAmountHuman) /
                  Number(estimation.fromAmountHuman)
              ),
              estimation,
              isFetching: false,
            };
          });
        })
        .catch((err) => console.log(err));
    } else if (
      input2 &&
      selectinput1 &&
      selectinput2 &&
      whichInputChanged !== "input1"
    ) {
      setInputData((prev) => ({ ...prev, isFetching: true }));
      integrations.pancakeswap
        .estimateSwap(selectinput1, input2, selectinput2, true)
        .then((estimation) => {
          setInputData((prevState) => {
            return {
              ...prevState,
              input1: +estimation.fromAmountHuman || "",
              route: estimation.route,
              tokenPrice1: String(
                Number(estimation.fromAmountHuman) /
                  Number(estimation.returnAmountHuman)
              ),
              tokenPrice2: String(
                Number(estimation.returnAmountHuman) /
                  Number(estimation.fromAmountHuman)
              ),
              priceImpact: estimation.priceImpact,
              estimation,
              isFetching: false,
            };
          });
        })
        .catch((err) => console.log(err));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inputData.input1,
    inputData.input2,
    inputData.selectinput1,
    inputData.selectinput2,
  ]);

  const handleSelectInputSwitch = () => {
    setInputData({
      ...inputData,
      selectinput1: inputData.selectinput2,
      input1: inputData.input2,
      input2: inputData.input1,
      selectinput2: inputData.selectinput1,
    });
  };

  const getInputValues = (name, value) => {
    if (value === "") {
      setInputData((prevState) => ({
        ...prevState,
        whichInputChanged: name,
        input1: "",
        input2: "",
      }));
    } else {
      setInputData((prevState) => ({
        ...prevState,
        [name]: value,
        whichInputChanged: name,
      }));
    }
  };

  const handleMaxInput = (name) => {
    if (name && user.tokens) {
      setInputData((prevState) => ({
        ...prevState,
        [name]: user.tokens[inputData[`select${name}`]] ?? 0,
        whichInputChanged: name,
      }));
    }
  };

  const handleValidation = useCallback(() => {
    const isPairValid =
      selectinput1 !== selectinput2 || !selectinput1 || !selectinput2;
    const maxInput1Balance = user.tokens[selectinput1] ?? 0;

    setErrorData({
      pairExists: isPairValid,
      enoughInput1: +input1 <= +maxInput1Balance,
      emptyInputs: !+input1 || !+input2,
    });
  }, [input1, input2, selectinput2, selectinput1, user.tokens]);

  useEffect(() => {
    handleValidation();
  }, [inputData, handleValidation]);

  const handleSwap = async () => {
    if (+input1 && selectinput1 && +input2 && selectinput2) {
      let txHash = [];
      let isFirstTrxFired = false;

      // SET ON V2, HAS TO BE CHANGED TO V1 ONCE KEYFI-COMMON UPDATED
      try {
        const response = await integrations.pancakeswap.v2.swap(
          selectinput1,
          input1,
          selectinput2,
          estimation,
          {
            pendingCallback: (trx) => {
              showTransactionManager();
              if (!isFirstTrxFired) {
                addTx({
                  platform: "PancakeSwap",
                  txType: "Swap",
                  txAmount: input1,
                  txAmount2: input2,
                  txToken: selectinput1,
                  txHash: trx.hash,
                  timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
                });
                isFirstTrxFired = true;
              }
              txHash.push(trx.hash);
            },
          }
        );

        if (response) {
          dispatch(walletOperations.loadUserData());
          const HistoryArray = getTxHistory();
          const txObject = {
            platform: "PancakeSwap",
            txType: "Swap",
            txAmount: input1,
            txAmount2: input2,
            txToken: selectinput1,
            txHash: response.transactionHash,
            timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
          };
          HistoryArray.unshift(txObject);
          Storage.setItem("transactions", JSON.stringify(HistoryArray));
          removeTx(txHash);
        }
      } catch (err) {
        showTransactionManager();
        const HistoryArray = getTxHistory();
        const txObject = {
          platform: "PancakeSwap",
          txType: "Swap",
          txAmount: input1,
          txAmount2: input2,
          txToken: selectinput1,
          txHash: txHash[txHash.length - 1],
          timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
          status: "failed",
        };
        HistoryArray.unshift(txObject);
        Storage.setItem("transactions", JSON.stringify(HistoryArray));
        removeTx(txHash);
      }
    }

    setInputData((prevState) => {
      return {
        ...prevState,
        input1: "",
        input2: "",
      };
    });
  };

  return (
    <>
      <div className="swap-input-wrapper">
        <div className="swap-input-labels">
          <span>{t("swap.input")}</span>
          <span>
            {t("swap.balance")}:{" "}
            {beautifyTokensAmount(user.tokens[inputData.selectinput1])}{" "}
            {inputData.selectinput1}
          </span>
        </div>

        <InputGroupv2
          name="input1"
          updateParent={getInputValues}
          value={inputData.input1}
          defaultSelected={inputData.selectinput1}
          removeToken={inputData.selectinput2}
          platform="pancakeswap"
          invokeMaxInput={handleMaxInput}
          filterZeroBalance
          error={!enoughInput1 || !pairExists}
        />
        {!enoughInput1 && (
          <div className="swap-input-error">
            {t("swap.notEnough")} {selectinput1}
          </div>
        )}
      </div>
      <div className="swap-icon" onClick={handleSelectInputSwitch}>
        <SwapIcon />
      </div>

      <div className="swap-input-wrapper">
        <div className="swap-input-labels">
          <span>{t("swap.input")}</span>
          <span>
            {t("swap.balance")}:{" "}
            {beautifyTokensAmount(user.tokens[inputData.selectinput2])}{" "}
            {inputData.selectinput2}
          </span>
        </div>

        <InputGroupv2
          name="input2"
          updateParent={getInputValues}
          value={inputData.input2}
          defaultSelected={inputData.selectinput2}
          removeToken={inputData.selectinput1}
          platform="pancakeswap"
          filterZeroBalance
          error={!pairExists}
        />
      </div>
      {pairExists && !emptyInputs && (
        <div className="swap-estimation-container">
          <h3>{t("swap.estimatedSwapPrice")}</h3>
          <div className="swap-estimation-datalist">
            <div className="swap-estimation-data">
              <h4>{inputData.tokenPrice1.slice(0, 9)}</h4>
              <div>
                {selectinput1} {t("addition.per")} {selectinput2}
              </div>
            </div>
            <div className="swap-estimation-data">
              <h4>{inputData.tokenPrice2.slice(0, 9)}</h4>
              <div>
                {selectinput2} {t("addition.per")} {selectinput1}
              </div>
            </div>
            <div className="swap-estimation-data">
              <h4 className={handlePriceImpactStyle(priceImpact)}>
                {priceImpact >= 0.01
                  ? beautifyTokensAmount(priceImpact, { suffix: "%" })
                  : "<0.01%"}
              </h4>
              <div>{t("swap.priceImpact")}</div>
            </div>
            <div className="swap-estimation-data">
              <h4>{route && route.join(" > ")}</h4>
              <div>{t("swap.route")}</div>
            </div>
          </div>
        </div>
      )}
      <button
        className="swap-button"
        disabled={emptyInputs || !pairExists || !enoughInput1 || isFetching}
        onClick={handleSwap}
      >
        {t("swap.swapbtn")}
      </button>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showTransactionManager: () => dispatch(showTransactionManager()),
  addTx: (tx) => dispatch(addTx(tx)),
  removeTx: (txHash) => dispatch(removeTx(txHash)),
});
export default connect(null, mapDispatchToProps)(V1);
