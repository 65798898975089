import React from "react";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  beautifyTokensAmount,
  poolsLogos,
  tokensLogos,
} from "../../../../helpers";
import {
  PlatformRemoveLiquidityWrapper,
  RemoveLiquidityData,
} from "../../SharedLiquidity";

// Assets
import { ReactComponent as ArrowBack } from "../../../../assets/arrow-back-dark.svg";

// Versions
import V2 from "./v2";
import V3 from "./v3";

const handleVersion = (version, data) => {
  switch (version) {
    case "v2":
      return <V2 liquidityData={data} />;
    case "v3":
      return <V3 liquidityData={data} />;
    default:
      break;
  }
};

const UniswapRemoveLiquidity = () => {
  const { t } = useTranslation();

  const liquidity = useSelector((state) => state.liquidity);
  const { mainnet } = liquidity;

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const [input1, input2, version] = [
    query.get("input1"),
    query.get("input2"),
    query.get("v"),
  ];
  const liquidityData = mainnet.uniswap[version].find(
    (item) => item.assetA === input1 && item.assetB === input2
  );

  return (
    <>
      <PlatformRemoveLiquidityWrapper>
        <Link
          to="/pools/my-liquidity/uniswap"
          className="remove-liquidity-back"
        >
          <button>
            <ArrowBack /> {t("addition.back")}
          </button>
        </Link>
        <h2>{t("addition.removeLiquidity")}</h2>
        <div className="remove-liquidity-description">
          {t("addition.removeLiquidityDescription")}
        </div>
        <div className="remove-liquidity-platform-container">
          <div className="remove-liquidity-platform-label">
            {t("platforms.title")}
          </div>
          <div className="remove-liquidity-platform">
            {poolsLogos["Uniswap"]}
            <h4>Uniswap</h4>
            <div className="remove-liquidity-platform-network">ETH</div>
          </div>
        </div>
        {handleVersion(query.get("v"), liquidityData)}
      </PlatformRemoveLiquidityWrapper>
      <RemoveLiquidityData>
        <div className="remove-liquidity-data-line">
          <div>
            {tokensLogos[input1]}
            {t("addition.pooledToken", { token: input1 })}
          </div>
          <div>{beautifyTokensAmount(liquidityData[input1])}</div>
        </div>
        <div className="remove-liquidity-data-line">
          <div>
            {tokensLogos[input2]}
            {t("addition.pooledToken", { token: input2 })}
          </div>
          <div>{beautifyTokensAmount(liquidityData[input2])}</div>
        </div>
        <div className="remove-liquidity-data-line">
          <div>{t("addition.yourPoolShare")}</div>
          <div>
            {parseFloat(liquidityData.liquidityPercent) * 100 > 0.01
              ? beautifyTokensAmount(
                  parseFloat(liquidityData.liquidityPercent) * 100
                )
              : "<0.01"}
            %
          </div>
        </div>
      </RemoveLiquidityData>
    </>
  );
};

export default UniswapRemoveLiquidity;
