import React from "react";
import styled from "styled-components";

import { ReactComponent as Arrow } from "../assets/arrow-blue.svg";
import { ReactComponent as LastArrow } from "../assets/last-arrow.svg";

const Wrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  > button,
  div {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    border: 0;
    outline: 0;
  }
  > div,
  button:hover:not(:disabled) {
    border: 1px solid ${(props) => props.theme.brandBlue};
    background: ${(props) => props.theme.lightBlue2};
    color: ${(props) => props.theme.brandBlue};
  }
  > button {
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.lightBlue3};
    background: 0;
    :disabled {
      svg {
        path {
          fill: ${(props) => props.theme.lightBlue2};
        }
        rect {
          fill: ${(props) => props.theme.lightBlue2};
        }
      }
    }
  }
`;

const Pagination = ({ page, setPage, lastPage, className }) => (
  <Wrapper className={className}>
    <button disabled={page === 1} onClick={() => setPage(1)}>
      <LastArrow style={{ transform: "rotate(180deg)" }} />
    </button>
    <button disabled={page === 1} onClick={() => setPage(page - 1)}>
      <Arrow style={{ transform: "rotate(180deg)" }} />
    </button>
    <div>{page}</div>
    <button disabled={page === lastPage} onClick={() => setPage(page + 1)}>
      <Arrow />
    </button>
    <button disabled={page === lastPage} onClick={() => setPage(lastPage)}>
      <LastArrow />
    </button>
  </Wrapper>
);

export default Pagination;
