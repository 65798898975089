import React, { useState } from "react";
import styled, { css } from "styled-components";
import DrwopDown from "./DropDown";
import { breakPoints } from "../theme";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  background: white;
  width: 100%;
  padding: 9px 0.75rem;
  display: flex;
  align-items: center;
  border: 1px solid
    ${(props) => (props.error ? props.theme.error : props.theme.border2)};
  border-radius: 6px;

  ${(props) =>
    props.error &&
    css`
      input {
        color: ${(props) => props.theme.error};
      }
    `}
`;

const StyledInput = styled.input`
  flex: 1;
  font-family: Montserrat;
  font-size: 16px;
  border: 0;
  font-weight: 500;
  :focus {
    outline: 0;
  }
  @media (max-width: ${breakPoints.md}px) {
    font-size: 16px;

    width: 50%;
  }
`;

const StyledMax = styled.span`
  background: ${(props) => props.theme.mainDarkText};
  color: white;
  font-size: 1rem;
  border-radius: 6px;
  padding: 6px 10px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;

  :hover {
    background: ${(props) => props.theme.brandBlack};
  }

  @media (max-width: ${breakPoints.md}px) {
    font-size: 12px;
    flex: 2;
  }

  ${(props) =>
    props.readOnly &&
    css`
      background: ${props.theme.disabled};
      pointer-events: none;
    `}
`;

const SelectionWrap = styled.div`
  display: flex;
  align-items: center;
  img {
    height: 24px;
    width: 24px;
  }
`;

const InputGroupv2 = (props) => {
  const { t } = useTranslation();
  const [showMax, setShowMax] = useState(false);
  const {
    placeholder,
    updateParent,
    name,
    value,
    defaultSelected,
    platform,
    noOptions,
    disableOptions,
    invokeMaxInput,
    filterZeroBalance,
    filterZeroPlatformBalance,
    readOnly,
    removeToken,
    showAll,
  } = props;

  const onChange = (e) => {
    // filter non-numric input values
    let str = "";
    if (e.target.value.match(/\d|\./g)) {
      str = e.target.value.match(/\d|\./g).join("");
    }

    updateParent(e.target.name, str);
  };

  return (
    <Wrapper {...props}>
      <StyledInput
        onChange={onChange}
        autoComplete="off"
        placeholder={placeholder || "0.0"}
        name={name}
        value={value}
        readOnly={readOnly}
      />
      <SelectionWrap>
        {invokeMaxInput && (
          <StyledMax readOnly={readOnly} onClick={() => invokeMaxInput(name)}>
            {t("pool.addLiquidity.max")}
          </StyledMax>
        )}
        {!disableOptions && (
          <DrwopDown
            updateParent={(name, value) => {
              updateParent(name, value);
              value && setShowMax(true);
            }}
            removeToken={removeToken}
            name={`select${name}`}
            defaultSelected={defaultSelected}
            platform={platform}
            noOptions={noOptions}
            filterZeroBalance={filterZeroBalance}
            filterZeroPlatformBalance={filterZeroPlatformBalance}
          />
        )}
      </SelectionWrap>
    </Wrapper>
  );
};

export default InputGroupv2;
