import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ReactComponent as TokensIcon } from "../../../../assets/sidebar/tokens.svg";
import { ReactComponent as ArrowDown } from "../../../../assets/arrowDown.svg";
import { AccordionList, AccordionWrapper } from "../../shared";

// Components
import KeyStaking from "./KeyStaking";
import KeyfiUsdcStaking from "./KeyfiUsdcStaking";
import Modal from "../../../Modal";
import Credential from "../../../TokensV1/Credential";

const V1Staking = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const user = useSelector((state) => state.user);

  useEffect(() => {
    user.isWhitelisted && setOpen(true);
  }, []);

  const handleOpen = () => {
    if (user.isWhitelisted) {
      setOpen(!open);
    } else {
      setShowModal(true);
    }
  };

  return (
    <AccordionWrapper open={open}>
      <div className="accordion_header" onClick={handleOpen}>
        <div className="accordion_header_title">
          <TokensIcon />
          <span>v1 {t("earn.staking")}</span>
          <span className="accordion_subtitle">
            {t("earn.v1Staking.credentialRequired")}
          </span>
          {showModal && (
            <Modal
              maxwidth="600px"
              width="100%"
              title={t("earn.v1Staking.credential")}
              handleCloseModal={() => setShowModal(false)}
            >
              <Credential />
            </Modal>
          )}
        </div>
        <ArrowDown className="accordion_arrow" />
      </div>
      {open && (
        <AccordionList padding>
          <KeyStaking />
          <KeyfiUsdcStaking />
        </AccordionList>
      )}
    </AccordionWrapper>
  );
};

export default V1Staking;
