import React from "react";
import styled from "styled-components";
import Modal from "./Modal";

import ChinaIcon from "../assets/chinaFlag-big.svg";
import EnIcon from "../assets/enFlag-big.svg";
import JapanIcon from "../assets/japanFlag-big.svg";
import KoreaIcon from "../assets/koreaFlag-big.svg";
import RussianIcon from "../assets/russianFlag-big.svg";
import TurkishIcon from "../assets/turkishFlag-big.svg";
import ArabicIcon from "../assets/arabicFlag-big.svg";

import { useTranslation } from "react-i18next";

const LanguageOption = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.25rem;
  font-weight: 500;
  border-bottom: 1px solid #d4e7ff;

  img {
    margin-right: 0.5rem;
  }
  :hover {
    background: ${(props) => props.theme.lightBlue2};
  }
  :last-of-type {
    border: none;
  }
`;

const LanguageSelector = ({ close, changeLanguage }) => {
  const { t } = useTranslation();
  return (
    <Modal
      open
      title={t("dashboard.menu.changeLanguages")}
      handleCloseModal={close}
    >
      <LanguageOption onClick={() => changeLanguage("zh")}>
        <img src={ChinaIcon} alt="china flag" />
        中文
      </LanguageOption>
      <LanguageOption onClick={() => changeLanguage("en")}>
        <img src={EnIcon} alt="uk flag" />
        English
      </LanguageOption>
      <LanguageOption onClick={() => changeLanguage("ar")}>
        <img src={ArabicIcon} alt="arabic flag" />
        عربي
      </LanguageOption>
      <LanguageOption onClick={() => changeLanguage("jp")}>
        <img src={JapanIcon} alt="japan flag" />
        日本語
      </LanguageOption>
      <LanguageOption onClick={() => changeLanguage("kr")}>
        <img src={KoreaIcon} alt="korea flag" />
        한국인
      </LanguageOption>
      <LanguageOption onClick={() => changeLanguage("ru")}>
        <img src={RussianIcon} alt="russian flag" />
        русский
      </LanguageOption>
      <LanguageOption onClick={() => changeLanguage("tr")}>
        <img src={TurkishIcon} alt="turkish flag" />
        Türkçe
      </LanguageOption>
    </Modal>
  );
};

export default LanguageSelector;
