import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { isMobile, integrations, WalletProviderId } from "@keyfi/keyfi-common";

import { Storage, StorageKey } from "../helpers/Storage";
import { resetUser, setCurrentUser } from "./user/actions";
import { erc20BalanceTokens } from "../constants";
import { appActions } from "./appSlice";

import { liquidityOperations } from "./liquiditySlice";
import { stakingOperations } from "./stakingSlice";
import { merge } from "../helpers";

const { PubSub, Web3Events } = integrations;
const FETCH_URL = process.env.REACT_APP_BALANCE_API_URL;
const supportedNetworks = ["mainnet", "bsc-mainnet", "polygon"];

const initialState = {
  web3: false,
  isLoading: false,
  selectedAddress: null,
  // WalletProviderId
  network: {
    name: "",
    chainId: null,
  },
  providerId: null,
};

const wallet = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setWeb3(state, action) {
      state.web3 = action.payload;
    },
    setSelectedAddress(state, action) {
      return { ...state, selectedAddress: action.payload };
    },
    setNetwork(state, action) {
      state.network = action.payload;
    },
    setProviderId(state, action) {
      state.providerId = action.payload;
    },
  },
});

export const walletActions = wallet.actions;

const getRoot = (state) => state.wallet;

export const walletSelectors = {
  getLoading: (state) => getRoot(state).isLoading,
  getWeb3: (state) => getRoot(state).web3,
  getSelectedAddress: (state) => getRoot(state).selectedAddress,
  getNetwork: (state) => getRoot(state).network,
  getProviderId: (state) => getRoot(state).providerId,
};

export const walletOperations = {
  /**
   * Subscribe to web3 provider events such as selectedAddress change
   */
  subscribeEvents: () => async (dispatch, getState) => {
    PubSub.subscribe(Web3Events.accountsChanged, (_, accounts) => {
      dispatch(walletActions.setSelectedAddress(accounts[0]));
      // Legacy action
      dispatch(
        setCurrentUser({
          id: accounts[0],
        })
      );
    });
  },

  setProviderId: (providerId, addressId) => async (dispatch, getState) => {
    dispatch(walletActions.setProviderId(providerId));
    if (!isMobile() && providerId === WalletProviderId.SelfKey) {
      Storage.setItem(StorageKey.selectedProvider, null);
    } else if (providerId === "Read-only mode") {
      Storage.setItem(StorageKey.selectedProvider, providerId);
      Storage.setItem(StorageKey.selectedAddress, addressId);
    } else {
      Storage.setItem(StorageKey.selectedProvider, providerId);
    }
    // set transaction in storage
    Storage.getItem("transactions") || Storage.setItem("transactions", "[]");
  },
  // loadProvider

  /**
   * Load wallet using stored preferences
   */
  loadLocalProvider: () => async (dispatch, getState) => {
    const providerId = Storage.getItem(StorageKey.selectedProvider);

    if (!providerId) {
      return;
    }

    if (providerId === "Read-only mode") {
      const addressId = Storage.getItem(StorageKey.selectedAddress);
      return await dispatch(
        walletOperations.setProviderId(providerId, addressId)
      );
    }

    await dispatch(walletOperations.setProviderId(providerId));
  },

  initialize: () => async (dispatch, getState) => {
    let providerId = walletSelectors.getProviderId(getState());

    if (!providerId) {
      await dispatch(walletOperations.loadLocalProvider());
    }

    providerId = walletSelectors.getProviderId(getState());

    if (!providerId) {
      return;
    }

    try {
      if (providerId === "Read-only mode") {
        const addressId = Storage.getItem(StorageKey.selectedAddress);
        if (addressId) {
          return dispatch(walletOperations.connectReadOnly(addressId));
        }
      }
      dispatch(setCurrentUser({ readOnly: false }));
      return dispatch(walletOperations.connect(providerId, true));
    } catch (err) {
      console.log(err);
    }
  },
  connect: (providerId, init) => async (dispatch, getState) => {
    const state = getState();

    if (providerId) {
      await dispatch(walletOperations.setProviderId(providerId));
    } else {
      providerId = walletSelectors.getProviderId(state);
    }

    if (
      providerId === WalletProviderId.Metamask &&
      typeof window.ethereum === "undefined"
    ) {
      alert("Please install Metamask!");
      return;
    }

    const web3 = await integrations.getWeb3(providerId, init);
    if (!web3) {
      return;
    }
    const selectedAddress = integrations.getCurrentAccountAddress(web3);

    // LEGACY Function, we might want to replace it later
    dispatch(
      setCurrentUser({
        // ...user
        id: selectedAddress,
      })
    );

    try {
      dispatch(resetUser());
      dispatch(walletActions.setWeb3(web3));
      dispatch(walletActions.setSelectedAddress(selectedAddress));
      const network = await integrations.getNetwork(web3);
      dispatch(walletActions.setNetwork(network));
      dispatch(walletOperations.loadUserData());
      dispatch(appActions.setLoading(true));
    } catch (err) {
      console.log(err);
    }
  },

  connectReadOnly: (address) => async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      dispatch(resetUser());
      dispatch(setCurrentUser({ readOnly: true, id: address }));
      dispatch(walletOperations.setProviderId("Read-only mode", address));
      dispatch(walletActions.setSelectedAddress(address));
      dispatch(walletActions.setNetwork({ chainId: 1, name: "mainnet" }));
      dispatch(walletOperations.loadUserData());
    } catch (err) {
      console.log(err);
    }
  },

  disconnect: () => async (dispatch, getState) => {
    try {
      dispatch(resetUser());
      const state = getState();
      const providerId = walletSelectors.getProviderId(state);
      const web3 = await integrations.getWeb3(providerId);
      dispatch(walletActions.setSelectedAddress(null));
      localStorage.setItem("walletconnect", null);
      await dispatch(walletOperations.setProviderId(null));
      if (web3.currentProvider.disconnect) {
        web3.currentProvider.disconnect();
      }
      integrations.resetWeb3();
    } catch (error) {
      console.error(error);
    }
  },

  loadUserDataReadOnly: () => async (dispatch, getState) => {
    const state = getState();
    const address = state.user.id;
    try {
      const newUsdPrice = {};

      const { data } = await axios.get(
        `${FETCH_URL}/tokens?address=${address}&network=mainnet`
      );
      const alkemiData = await integrations.alkemi.getSupply(address);

      const tokens = {};
      const usdPrices = {};

      for (const item of data) {
        if (item.amount && item.amount !== "0") {
          tokens[item.symbol] = item.amount;
        }
        usdPrices[item.symbol] = item.price || 0;
      }

      const merge = (...objects) => {
        const merged = objects.reduce((a, obj) => {
          Object.entries(obj).forEach(([key, val]) => {
            a[key] = ((parseFloat(a[key]) || 0) + parseFloat(val)).toString();
          });
          return a;
        }, {});
        return Object.fromEntries(
          Object.entries(merged).sort((a, b) => b[1] - a[1])
        );
      };

      const totalTokens = merge(tokens, alkemiData);

      dispatch(
        setCurrentUser({
          tokens,
          totalTokens,
          alkemi: alkemiData,
          usdPrices,
          readOnly: true,
        })
      );
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
    }
  },

  // TODO: Move to userSlice
  loadUserData: () => async (dispatch, getState) => {
    const { user } = getState();

    let selectedAddress;
    let network;

    try {
      if (user.readOnly) {
        selectedAddress = user.id;
      } else {
        selectedAddress = walletSelectors.getSelectedAddress(getState());
      }

      if (!selectedAddress) {
        return;
      }

      if (!user.readOnly) {
        const isWhitelisted =
          (await integrations.whitelist.isSupportedNetwork())
            ? await integrations.whitelist.isWhitelisted(selectedAddress)
            : undefined;

        // Legacy action, will need refator later
        dispatch(
          setCurrentUser({
            id: selectedAddress,
            isWhitelisted,
          })
        );
        network = walletSelectors.getNetwork(getState());
      } else {
        network = { name: "mainnet", chainId: 1 };
      }

      if (!supportedNetworks.some((item) => item === network.name)) {
        throw new Error(
          `Network chainId=${network.chainId},` +
            ` name=${network.name} is not supported!`
        );
      }

      const newUsdPrice = {};

      const fetchTokens = async () => {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BALANCE_API_URL}/tokens?address=${selectedAddress}&network=${network.name}&ignoreCache=true`
          );
          const tokens = {};

          data.forEach((item) => {
            if (item.symbol) {
              tokens[item.symbol] = item.amount;
              newUsdPrice[item.symbol] = item.price || 0;
            }
          });

          return tokens;
        } catch (err) {
          return {};
        }
      };

      // Fetch balances in parallel the most we can
      const [tokens, alkemiBalance, staking, liquidityPairs] =
        await Promise.all([
          fetchTokens(),
          integrations.alkemi.getSupply(selectedAddress),
          dispatch(stakingOperations.loadStaking(selectedAddress, network)),
          dispatch(
            liquidityOperations.getAccountLiquidity(selectedAddress, network)
          ),
        ]);

      const totalTokens = merge(tokens, staking, liquidityPairs, alkemiBalance);

      const { data: usdPrices } = await axios.get(
        `${process.env.REACT_APP_BALANCE_API_URL}/prices?symbols=${[
          ...Object.keys(merge(staking, liquidityPairs, alkemiBalance)),
          "MATIC",
          "ETH",
          "BNB",
          "KEYFI",
        ].join()}`
      );

      const newPrices = { ...usdPrices, ...newUsdPrice };

      dispatch(
        setCurrentUser({
          id: selectedAddress,
          tokens,
          totalTokens,
          alkemi: alkemiBalance,
          usdPrices: newPrices,
        })
      );
      dispatch(appActions.setLoading(false));
    } catch (err) {
      // setTimeout(() => dispatch(walletOperations.loadUserData()), 15000);
      console.log("User state fetching error", err);
    }
  },
};

export const walletReducer = wallet.reducer;
