import React from "react";
import { useTranslation } from "react-i18next";
import TotalCard from "./TotalCard";
import StakingIcon from "../../assets/staking-blue.svg";

function StakingCard({ total }) {
  const { t } = useTranslation();

  const cardProps = {
    name: t("dashboard.accountOverview.staking"),
    icon: <img src={StakingIcon} alt="Staking Icon" />,
  };

  return (
    <div>
      <TotalCard value={total} {...cardProps} />
    </div>
  );
}

export default StakingCard;
