import React, { useEffect, useState } from "react";
import { integrations } from "@keyfi/keyfi-common";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import timestamp from "time-stamp";

import {
  addTx,
  removeTx,
  showTransactionManager,
} from "../../../../redux/transactionManager/actions";
import { getTxHistory } from "../../../TransactionManager";

// Helpers and assets
import InputGroupv2 from "../../../InputGroupv2";
import { beautifyTokensAmount } from "../../../../helpers";
import { Storage } from "../../../../helpers/Storage";
import { walletOperations } from "../../../../redux/walletSlice";
import { AddLiquidityBody, ErrorMessageLiquidity } from "../../SharedLiquidity";
import { ReactComponent as PlusSign } from "../../../../assets/plus.svg";

const V1 = ({ showTransactionManager, addTx, removeTx }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [inputData, setInputData] = useState({
    input1: "",
    selectinput1: "KEYFI",
    input2: "",
    selectinput2: "BNB",
    whichInputChanged: null,
    isFetching: false,
    shareOfPool: 0,
    tokenPrice1: 0,
    tokenPrice2: 0,
  });

  const [errorData, setErrorData] = useState({
    pairExists: true,
    insufficientInput1: false,
    insufficientInput2: false,
    emptyInputs: true,
  });
  const {
    input1,
    selectinput1,
    input2,
    selectinput2,
    whichInputChanged,
    shareOfPool,
    tokenPrice1,
    tokenPrice2,
  } = inputData;
  const { pairExists, insufficientInput1, insufficientInput2, emptyInputs } =
    errorData;

  useEffect(() => {
    if (
      +input1 &&
      selectinput1 &&
      selectinput2 &&
      whichInputChanged !== "input2"
    ) {
      setInputData((prev) => ({ ...prev, isFetching: true }));
      integrations.apeswap
        .getPrice(selectinput1, selectinput2, input1)
        .then((data) => {
          setInputData((prevState) => {
            return {
              ...prevState,
              input2: +prevState.input1 ? +data.price * +prevState.input1 : "",
              shareOfPool: data.shareOfPool,
              tokenPrice1: String(
                Number(prevState.input1) / Number(data.price * prevState.input1)
              ).slice(0, 9),
              tokenPrice2: String(
                Number(data.price * prevState.input1) / Number(prevState.input1)
              ).slice(0, 9),
            };
          });
          setInputData((prev) => ({ ...prev, isFetching: false }));
        })
        .catch((err) => console.log(err));
    } else if (
      +input2 &&
      selectinput1 &&
      selectinput2 &&
      whichInputChanged !== "input1"
    ) {
      setInputData((prev) => ({ ...prev, isFetching: true }));
      integrations.apeswap
        .getPrice(selectinput2, selectinput1, input2)
        .then((data) => {
          setInputData((prevState) => {
            return {
              ...prevState,
              input1: Number(input2) ? Number(data.price) * Number(input2) : "",
              shareOfPool: data.shareOfPool,
              tokenPrice1: String(
                Number(data.price * prevState.input2) / Number(prevState.input2)
              ).slice(0, 9),
              tokenPrice2: String(
                Number(prevState.input2) / Number(data.price * prevState.input2)
              ).slice(0, 9),
            };
          });
          setInputData((prev) => ({ ...prev, isFetching: false }));
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inputData.input1,
    inputData.input2,
    inputData.selectinput1,
    inputData.selectinput2,
  ]);

  const getInputValues = (name, value) => {
    if (value === "") {
      setInputData((prevState) => ({
        ...prevState,
        whichInputChanged: name,
        input1: "",
        input2: "",
      }));
    } else {
      setInputData((prevState) => ({
        ...prevState,
        [name]: value,
        whichInputChanged: name,
      }));
    }
  };

  const handleMaxInput = (name) => {
    if (name && user.tokens) {
      setInputData((prevState) => ({
        ...prevState,
        [name]: user.tokens[inputData[`select${name}`]] ?? 0,
        whichInputChanged: name,
      }));
    }
  };

  const handleValidation = async () => {
    try {
      // check if the pair exist
      const pairExists =
        (await integrations.apeswap.isPairAvailable(
          selectinput1,
          selectinput2
        )) && selectinput1 !== selectinput2;
      const maxInput1Balance = user.tokens[selectinput1] ?? 0;
      const maxInput2Balance = user.tokens[selectinput2] ?? 0;

      setErrorData({
        pairExists,
        insufficientInput1: +input1 > +maxInput1Balance,
        insufficientInput2: +input2 > +maxInput2Balance,
        emptyInputs: !+input1 || !+input2,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleValidation();
  }, [inputData]);

  const handleAddLiquidity = async () => {
    // Check if the pair exist
    const doesPairExist = await integrations.apeswap.isPairAvailable(
      selectinput1,
      selectinput2
    );

    if (Number(input1) && Number(input2) ** doesPairExist) {
      let txHash = [];
      let isFirstTrxFired = false;

      try {
        const response = await integrations.apeswap.addLiquidity(
          selectinput1,
          input1,
          selectinput2,
          input2,
          {
            pendingCallback: (trx) => {
              if (!isFirstTrxFired) {
                showTransactionManager();
                addTx({
                  platform: "ApeSwap",
                  txType: "Add liquidity",
                  txAmount: input1,
                  txAmount2: input2,
                  txToken: selectinput1,
                  txToken2: selectinput2,
                  txHash: trx.hash,
                  timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
                });
                isFirstTrxFired = true;
              }

              txHash.push(trx.hash);
            },
          }
        );

        if (response) {
          dispatch(walletOperations.loadUserData());

          const HistoryArray = getTxHistory();
          HistoryArray.unshift({
            platform: "ApeSwap",
            txType: "Add liquidity",
            txAmount: input1,
            txAmount2: input2,
            txToken: selectinput1,
            txToken2: selectinput2,
            txHash: response.transactionHash,
            timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
          });
          Storage.setItem("transactions", JSON.stringify(HistoryArray));
          removeTx(txHash);
        }
      } catch (err) {
        showTransactionManager();
        const HistoryArray = getTxHistory();
        HistoryArray.unshift({
          platform: "ApeSwap",
          txType: "Add liquidity",
          txAmount: input1,
          txAmount2: input2,
          txToken: selectinput1,
          txToken2: selectinput2,
          txHash: txHash[txHash.length - 1],
          timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
          status: "failed",
        });
        Storage.setItem("transactions", JSON.stringify(HistoryArray));
        removeTx(txHash);
      }
    }

    setInputData((prevState) => {
      return {
        ...prevState,
        input1: "",
        input2: "",
      };
    });
  };

  return (
    <AddLiquidityBody>
      <div className="add-liquidity-input-wrapper">
        <div className="add-liquidity-input-labels">
          <span>{t("pool.addLiquidity.input")}</span>
          <span>
            {t("pool.addLiquidity.balance")}:{" "}
            {beautifyTokensAmount(user.tokens[inputData.selectinput1])}{" "}
            {inputData.selectinput1}
          </span>
        </div>

        <InputGroupv2
          name="input1"
          updateParent={getInputValues}
          value={inputData.input1}
          defaultSelected={inputData.selectinput1}
          platform="apeswap"
          removeToken={inputData.selectinput2}
          invokeMaxInput={handleMaxInput}
          filterZeroBalance
          error={insufficientInput1 || !pairExists}
        />
        {insufficientInput1 && (
          <ErrorMessageLiquidity>
            {t("pool.addLiquidity.notEnough")} {selectinput1}
          </ErrorMessageLiquidity>
        )}
        {!pairExists && (
          <ErrorMessageLiquidity>
            {t("pool.addLiquidity.invalidPair")}
          </ErrorMessageLiquidity>
        )}
      </div>
      <div className="add-liquidity-plus">
        <PlusSign />
      </div>

      <div
        className="add-liquidity-input-wrapper"
        style={{ marginBottom: "0" }}
      >
        <div className="add-liquidity-input-labels">
          <span>{t("pool.addLiquidity.input")}</span>
          <span>
            {t("pool.addLiquidity.balance")}:{" "}
            {beautifyTokensAmount(user.tokens[inputData.selectinput2])}{" "}
            {inputData.selectinput2}
          </span>
        </div>

        <InputGroupv2
          name="input2"
          updateParent={getInputValues}
          value={inputData.input2}
          defaultSelected={inputData.selectinput2}
          platform="apeswap"
          removeToken={inputData.selectinput1}
          invokeMaxInput={handleMaxInput}
          filterZeroBalance
          error={insufficientInput2 || !pairExists}
        />
        {insufficientInput2 && (
          <ErrorMessageLiquidity>
            {t("pool.addLiquidity.notEnough")} {selectinput2}
          </ErrorMessageLiquidity>
        )}
      </div>
      {pairExists && !emptyInputs && (
        <div className="add-liquidity-data">
          <h2>{t("pool.addLiquidity.priceAndPoolShare")}</h2>
          <div className="add-liquidity-data-box">
            <div className="add-liquidity-data-snippet">
              <h4>{beautifyTokensAmount(tokenPrice1)}</h4>
              <h6>
                {selectinput1} {t("addition.per")} {selectinput2}
              </h6>
            </div>
            <div className="add-liquidity-data-snippet">
              <h4>{beautifyTokensAmount(tokenPrice2)}</h4>
              <h6>
                {selectinput2} {t("addition.per")} {selectinput1}
              </h6>
            </div>
            <div className="add-liquidity-data-snippet">
              <h4>
                {shareOfPool * 100 < 0.01
                  ? "<0.01"
                  : (shareOfPool * 100).toFixed(2)}
                %
              </h4>
              <h6>{t("pool.addLiquidity.shareOfPool")}</h6>
            </div>
          </div>
        </div>
      )}

      <button
        onClick={handleAddLiquidity}
        className="add-liquidity-button"
        disabled={
          !pairExists || insufficientInput1 || insufficientInput2 || emptyInputs
        }
      >
        {t("pool.addLiquidity.addLiquiditybtn")}
      </button>
    </AddLiquidityBody>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showTransactionManager: () => dispatch(showTransactionManager()),
  addTx: (tx) => dispatch(addTx(tx)),
  removeTx: (txHashs) => dispatch(removeTx(txHashs)),
});

export default connect(null, mapDispatchToProps)(V1);
