import React, { useEffect } from "react";
import { integrations } from "@keyfi/keyfi-common";
import V1Staking from "./ETH/V1";
import V2Staking from "./ETH/V2";
import { TabWrapper } from "./shared";
import { switchToETH } from "../../helpers";

const ETHStaking = () => {
  useEffect(() => {
    switchToETH();
    integrations.resetNetwork();
  }, []);

  return (
    <>
      <TabWrapper>
        <V2Staking />
        <V1Staking />
      </TabWrapper>
    </>
  );
};

export default ETHStaking;
