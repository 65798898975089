const INITIAL_STATE = {
  gasPrices: {},
  isFetching: false,
  error: null,
};

function gasReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "FETCH_GAS_REQUEST":
      return Object.assign({}, state, {
        isFetching: true,
      });
    case "FETCH_GAS_SUCCESS":
      return Object.assign({}, state, {
        isFetching: false,
        gasPrices: action.gasPrices,
      });
    case "FETCH_GAS_FAILURE":
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
      });
    default:
      return state;
  }
}

export default gasReducer;
