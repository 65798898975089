export const showTransactionManager = () => ({
  type: "SHOW_TRANSACTION_MANAGER",
});

export const closeTransactionManager = () => ({
  type: "CLOSE_TRANSACTION_MANAGER",
});

export const addTx = (tx) => ({
  type: "ADD_TX",
  payload: tx,
});

export const removeTx = (txHashs) => ({
  type: "REMOVE_TX",
  payload: txHashs,
});

export const addTxNotification = (tx, status) => ({
  type: "ADD_TX_NOTIFICATION",
  payload: { ...tx, status },
});

export const removeTxNotification = (hash) => ({
  type: "REMOVE_TX_NOTIFICATION",
  payload: hash,
});
