import React, { useState } from "react";
import styled from "styled-components";
import { WalletProviderId } from "@keyfi/keyfi-common";
import { walletsLogos } from "../helpers";
import Button from "./Button";
import { breakPoints } from "../theme";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  h3 {
    font-weight: 500;
    margin: 1.5rem 0 1rem 0;
    color: ${(props) => props.theme.brandBlue};
  }
  .walletlist_divider {
    height: 1px;
    width: 100%;
    background: ${(props) => props.theme.walletModalBorder};
  }
  .walletlist_track {
    padding-bottom: 2rem;
    .walletlist_track_label {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
    .walletlist_track_container {
      display: flex;
      input {
        font-family: Montserrat;
        width: 100%;
        margin-right: 1rem;
        padding: 0.75rem 10px;
        font-size: 16px;
        border-radius: 6px;
        outline: none;
        border: 1px solid ${(props) => props.theme.walletModalBorder};
        ::placeholder {
          font-family: Montserrat;
          color: ${(props) => props.theme.placeholder2};
        }
      }
      @media (max-width: ${breakPoints.lg}px) {
        flex-direction: column;
        input {
          margin-bottom: 1rem;
          font-size: 12px;
        }
      }
    }
  }
`;

export const ListItemsWrapper = styled.div`
  margin: ${(props) => (props.noMarginTop ? `0` : `1rem`)} 0 1rem 0;

  .listItem {
    color: ${(props) => props.theme.mainDarkText};
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    height: 72px;
    padding: 1rem;
    display: flex;
    align-items: center;
    border-radius: 4px;
    svg,
    img {
      margin-right: 8px;
    }
    cursor: pointer;
  }
  .listItem:hover {
    color: ${(props) => props.theme.brandBlue};
    background: ${(props) => props.theme.border2};
  }
`;

const StyledButton = styled(Button)`
  font-size: 16px;
  padding: 0 3rem;
  text-transform: capitalize;
`;

export default function WalletsList({ handleWalletSelect, handleReadOnly }) {
  const [input, setInput] = useState("");
  const { t } = useTranslation();
  return (
    <Wrapper>
      <h3>{t("addition.wallet")}</h3>
      <ListItemsWrapper noMarginTop>
        <div
          className="listItem"
          onClick={() => handleWalletSelect(WalletProviderId.Metamask)}
        >
          {walletsLogos.Metamask}
          MetaMask
        </div>

        <div
          className="listItem"
          onClick={() => handleWalletSelect(WalletProviderId.WalletConnect)}
        >
          {walletsLogos.WalletConnect}
          WalletConnect
        </div>

        <div
          className="listItem"
          onClick={() => handleWalletSelect(WalletProviderId.SelfKey)}
        >
          {walletsLogos.SelfKey}
          SelfKey
        </div>
      </ListItemsWrapper>
      <div className="walletlist_divider" />
      <h3>{t("addition.trackPortfolio")}</h3>
      <div className="walletlist_track">
        <div className="walletlist_track_label">
          {t("addition.readOnlyPortfolioChecking")}
        </div>
        <div className="walletlist_track_container">
          <input
            placeholder="eg. 0x640Fdf0FF52Ee61E8bebcaED718572B1385f4052"
            maxLength="42"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            autoCorrect="false"
            spellCheck="false"
          />
          <StyledButton primary onClick={() => handleReadOnly(input)}>
            {t("addition.connect")}
          </StyledButton>
        </div>
      </div>
    </Wrapper>
  );
}
