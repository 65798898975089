import React from "react";
import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import styled from "styled-components";
import Tippy from "@tippyjs/react/headless";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getAddressSum,
  getContractPlatform,
} from "@keyfi/keyfi-common/src/integrations/common";

// Styles
import {
  DirectionSign,
  StyledTableCell,
  TransactionTable,
  TXTooltipWrapper,
} from "./transaction.styles";

// Helpers
import { beautifyTokensAmount, trimAddress } from "../../helpers";
import AssetImage from "../Borrow/AssetImage";
import { Skeleton } from "../../Shared";

// Icons
import { ReactComponent as ArrowIcon } from "../../assets/arrow-out.svg";
import { ReactComponent as TXSuccess } from "../../assets/transactions/txSuccess.svg";
import { ReactComponent as TXFailed } from "../../assets/transactions/txFailed.svg";
import { ReactComponent as InfoIcon } from "../../assets/infoIcon.svg";

export const directionSignCheck = (item, id, t) => {
  if (item.from === item.to) {
    return <DirectionSign self>{t("transactions.Self")}</DirectionSign>;
  }
  if (item.from === id.toLowerCase()) {
    return <DirectionSign out>{t("transactions.out")}</DirectionSign>;
  }
  return <DirectionSign in>{t("transactions.in")}</DirectionSign>;
};

export const TXTooltip = ({ attrs, network }) => {
  const { t } = useTranslation();
  return (
    <TXTooltipWrapper {...attrs}>
      {t("addition.TXfeeBaseOn")}{" "}
      {network && network.name === "bsc-mainnet" ? "BNB" : "ETH"}{" "}
      {t("dashboard.accountOverview.price")}
    </TXTooltipWrapper>
  );
};

const DesktopTransactionTable = ({
  tableHeadItems,
  tokenPrice,
  tokenSymbol,
  network,
  apiURL,
  loading,
  filter,
  tx,
}) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const { id } = user;

  return (
    <>
      <TransactionTable>
        <thead>
          <tr>
            {tableHeadItems.map((item) => (
              <th key={item.id}>{item.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              {[...Array(8).keys()].map((_, i) => (
                <td key={Math.random()}>
                  <Skeleton
                    white
                    style={{ marginLeft: i === 7 ? "auto" : "0" }}
                  />
                </td>
              ))}
            </tr>
          )}
          {/* If filters selected AND tx array length === 0 */}
          {tx.length === 0 && !loading && (filter.status || filter.direction) && (
            <tr>
              <td colSpan="6" style={{ textAlign: "left" }}>
                {t("addition.doNotMeetCriteria")}
              </td>
            </tr>
          )}
          {/* If tx array length === 0 AND no filters selected */}
          {tx.length === 0 && !loading && !filter.status && !filter.direction && (
            <tr>
              <td colSpan="6" style={{ textAlign: "left" }}>
                {t("addition.noTranscation")}
              </td>
            </tr>
          )}
          {!loading &&
            tx.length !== 0 &&
            tokenPrice &&
            id &&
            tx.map((item, i) => (
              <tr
                style={{
                  border: tx[i + 1] && tx[i + 1].hash === item.hash && "0",
                }}
              >
                <StyledTableCell>
                  <div>
                    {((tx[i - 1] && tx[i - 1].hash !== item.hash) ||
                      i === 0) && (
                      <a
                        href={`https://${apiURL}/tx/${item.hash}`}
                        target="_blank"
                        rel="noreferrer"
                        className="transaction_txhash tx_blue"
                      >
                        {trimAddress(item.hash)}
                        <ArrowIcon />
                      </a>
                    )}
                  </div>
                </StyledTableCell>
                <StyledTableCell txHash>
                  <div>
                    {item.tokenName ? (
                      <div className="tx_token">
                        <AssetImage
                          className="tx_token_image"
                          src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
                            network.name === "bsc-mainnet"
                              ? "smartchain"
                              : "ethereum"
                          }/assets/${getAddressSum(
                            item.contractAddress
                          )}/logo.png`}
                          alt={item.tokenSymbol}
                        />
                        <div>
                          <div className="tx_token_name">{item.tokenName}</div>
                          <div className="tx_token_symbol">
                            {item.tokenSymbol}
                          </div>
                        </div>
                      </div>
                    ) : (
                      "-"
                    )}

                    {directionSignCheck(item, id, t)}
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div>
                    <a
                      href={`https://${apiURL}/address/${item.from}`}
                      target="_blank"
                      rel="noreferrer"
                      className="transaction_txhash"
                    >
                      {trimAddress(item.from)}
                      <ArrowIcon />
                    </a>
                  </div>
                  <div>
                    <a
                      href={`https://${apiURL}/address/${item.to}`}
                      target="_blank"
                      rel="noreferrer"
                      className={`transaction_txhash ${
                        getContractPlatform(item.to) ? "tx_blue" : ""
                      }`}
                    >
                      {getContractPlatform(item.to) ?? trimAddress(item.to)}

                      <ArrowIcon />
                    </a>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div>
                    {item.isError === "0" || !item.isError ? (
                      <TXSuccess />
                    ) : (
                      <TXFailed />
                    )}
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  {((tx[i - 1] && tx[i - 1].hash !== item.hash) || i === 0) && (
                    <>
                      <div>
                        {BigNumber(item.gasPrice)
                          .times(item.gasUsed)
                          .shiftedBy(-18)
                          .toFixed(9)}{" "}
                        {tokenSymbol}
                      </div>
                      <div className="tx_blue">
                        {beautifyTokensAmount(
                          BigNumber(item.gasPrice)
                            .times(item.gasUsed)
                            .shiftedBy(-18)
                            .times(tokenPrice)
                            .toFixed(),
                          { prefix: "$", suffix: " USD" }
                        )}
                        <Tippy
                          offset={[60, 5]}
                          render={(attrs) => (
                            <TXTooltip attrs={attrs} network={network} />
                          )}
                        >
                          <InfoIcon className="tx_info" />
                        </Tippy>
                      </div>
                    </>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {((tx[i - 1] && tx[i - 1].hash !== item.hash) || i === 0) && (
                    <>
                      <div>
                        {BigNumber(item.gasPrice).shiftedBy(-18).toFixed(9)}{" "}
                        {tokenSymbol}
                      </div>
                      <div className="tx_blue">
                        {beautifyTokensAmount(
                          BigNumber(item.gasPrice).shiftedBy(-9).toFixed(),
                          { suffix: " Gwei" }
                        )}
                      </div>
                    </>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  <div>
                    {beautifyTokensAmount(
                      BigNumber(item.value)
                        .shiftedBy(item.tokenDecimal ? -item.tokenDecimal : -18)
                        .toFixed(),
                      { suffix: ` ${item.tokenSymbol ?? tokenSymbol}` }
                    )}
                  </div>
                </StyledTableCell>
                <td>
                  {((tx[i - 1] && tx[i - 1].hash !== item.hash) || i === 0) && (
                    <>
                      <div>
                        {dayjs(item.timeStamp * 1000).format("YYYY/MM/DD")}
                      </div>
                      <div>
                        {dayjs(item.timeStamp * 1000).format("HH:mm:ss")}
                      </div>
                    </>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </TransactionTable>
    </>
  );
};

export default DesktopTransactionTable;
