import React from "react";
import styled from "styled-components";

const InputRangeWrapper = styled.input`
  height: 33px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  background: none;

  :focus {
    outline: none;
  }
  ::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: ${(props) => props.theme.brandBlue};
    border-radius: 5px;
  }
  ::-webkit-slider-thumb {
    height: 24px;
    width: 23px;
    border-radius: 50%;
    background: ${(props) => props.theme.brandBlue};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -10px;
  }
  :focus::-webkit-slider-runnable-track {
    background: ${(props) => props.theme.brandBlue};
  }
  ::-moz-range-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    background: #3071a9;
    border-radius: 5px;
  }
  ::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000;
    border: 1px solid #000000;
    height: 25px;
    width: 25px;
    border-radius: 25px;
    background: #ffffff;
    cursor: pointer;
  }
  ::-ms-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  ::-ms-fill-lower {
    background: #3071a9;
    border: 1px solid #000000;
    border-radius: 10px;
    box-shadow: 1px 1px 1px #000000;
  }
  ::-ms-fill-upper {
    background: #3071a9;
    border: 1px solid #000000;
    border-radius: 10px;
    box-shadow: 1px 1px 1px #000000;
  }
  ::-ms-thumb {
    margin-top: 1px;
    box-shadow: 1px 1px 1px #000000;
    border: 1px solid #000000;
    height: 25px;
    width: 25px;
    border-radius: 25px;
    background: #ffffff;
    cursor: pointer;
  }
`;

const InputRange = ({ ...props }) => {
  return <InputRangeWrapper type="range" {...props} />;
};

export default InputRange;
