import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { ReactComponent as ArrowDown } from "../../../../assets/arrowDown.svg";
import { ReactComponent as TokensIcon } from "../../../../assets/sidebar/tokens.svg";
import { AccordionList, AccordionWrapper } from "../../shared";
import KeyFiStaking from "./KeyFiStaking";

const Staking = () => {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();
  return (
    <AccordionWrapper open={open}>
      <div className="accordion_header" onClick={() => setOpen(!open)}>
        <div className="accordion_header_title">
          <TokensIcon />
          <span>{t("earn.staking")}</span>
        </div>
        <ArrowDown className="accordion_arrow" />
      </div>
      {open && (
        <AccordionList>
          <KeyFiStaking />
        </AccordionList>
      )}
    </AccordionWrapper>
  );
};

export default Staking;
