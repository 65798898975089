import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import WalletIcon from "../../assets/wallet.svg";
import TotalCard from "./TotalCard";
import WalletTable from "./WalletTable";
import { beautifyTokensAmount } from "../../helpers";
import Modal from "../Modal";
import CustomCheckbox from "../CustomCheckbox";
import { breakPoints } from "../../theme";

const WalletCardTitle = styled.span`
  display: flex;
  align-items: center;
  @media (max-width: ${breakPoints.md}px) {
    flex-wrap: wrap;
    > span {
      margin-bottom: 1rem;
    }
    > label {
      flex-basis: 100%;
    }
  }
`;

const ModalSecondTitle = styled.span`
  display: inline-block;
  background: ${(props) => props.theme.lightBlue3};
  color: ${(props) => props.theme.brandBlue};
  font-weight: 500;
  border-radius: 4px;
  padding: 8px 1rem;
  font-size: 16px;
  line-height: 20px;
  margin: 0 1rem;
  vertical-align: middle;
`;

function WalletCard({ total }) {
  const { t } = useTranslation();

  const [hideZeroBalances, setHideZeroBalances] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const cardProps = {
    name: t("dashboard.accountOverview.wallet"),
    icon: <img src={WalletIcon} alt="Wallet Icon" />,
  };
  return (
    <div onClick={() => setShowModal(true)}>
      <TotalCard value={total} {...cardProps} />
      <Modal
        width="920px"
        maxwidth="950px"
        title={
          <WalletCardTitle>
            <span> {cardProps.name} </span>
            <ModalSecondTitle>
              {beautifyTokensAmount(total, {
                suffix: " USD",
                prefix: "$",
              })}
            </ModalSecondTitle>
            <CustomCheckbox
              onClick={() => setHideZeroBalances((prev) => !prev)}
              checked={hideZeroBalances}
              title={
                t("dashboard.accountOverview.hide") +
                " " +
                0 +
                " " +
                t("dashboard.accountOverview.balance")
              }
            />
          </WalletCardTitle>
        }
        open={showModal}
        handleCloseModal={() => setShowModal(false)}
        padding="2.5rem"
        removeHeaderLine
      >
        <WalletTable hideZeroBalances={hideZeroBalances} />
      </Modal>
    </div>
  );
}

export default WalletCard;
