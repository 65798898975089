import React from "react";
import styled from "styled-components";
import { beautifyTokensAmount } from "../../helpers";
import { breakPoints } from "../../theme";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Skeleton } from "../../Shared";

const TotalWrapper = styled.div`
  .netAPY {
    color: ${(props) => props.theme.brandBlue};
    display: flex;
    font-size: 12px;
    font-weight: 500;

    span {
      background: ${(props) => props.theme.lightBlue3};
      border: 1px solid ${(props) => props.theme.lightBlue3};
      border-radius: 6px 6px 0 0;
      padding: 8px;
    }
  }

  .platformTotal {
    border-radius: 0px 6px 6px 6px;
  }

  .defaultTotal {
    border-radius: 6px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 70px;
  padding: 16px;
  font-family: Montserrat;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  background: ${(props) => props.theme.superLightBlue};
  color: ${(props) => props.theme.mainDarkText};
  border: 1px solid ${(props) => props.theme.border};
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${breakPoints.lg}px) and (max-width: ${breakPoints.xl}px) {
    width: 100%;
  }

  .total_title {
    display: flex;
    align-items: center;
    line-height: 20px;
    font-size: 16px;

    .title__icon {
      border-radius: 50%;
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${(props) => props.theme.lightBlue2};
      margin-right: 9px;
      img,
      svg {
        max-width: 32px;
        max-height: 32px;
      }
    }
  }

  :hover {
    background: ${(props) => props.theme.lightBlue3};
    cursor: pointer;

    .title__icon {
      background: ${(props) => props.theme.superLightBlue};
    }
  }

  &:hover ${Skeleton} {
    background-color: #b9d6fc;
    ::before {
      background: linear-gradient(
        to right,
        rgba(185, 214, 252, 0.4) 0%,
        #a5ccff 100%
      );
    }
  }

  .total_value {
    max-width: 160px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 18px;
    line-height: 22px;
  }
`;

function Total({ name, icon, value, netAPYtab, netAPY }) {
  const { t } = useTranslation();
  const isFetching = useSelector((state) => state.app.isLoading);
  const totalClassName = netAPYtab ? "platformTotal" : "defaultTotal";

  return (
    <TotalWrapper>
      {netAPYtab && (
        <div className="netAPY">
          <span>
            {t("rebalancer.netAPY")}:{" "}
            {beautifyTokensAmount(netAPY, { suffix: "%" })}
          </span>
        </div>
      )}
      <Wrapper className={totalClassName}>
        <div className="total_title">
          <span className="title__icon">{icon}</span>
          <span className="title__text">{name}</span>
        </div>

        <div className="total_value">
          {isFetching ? (
            <Skeleton width="75" white="true" />
          ) : (
            beautifyTokensAmount(value ?? 0, { suffix: " USD", prefix: "$" })
          )}
        </div>
      </Wrapper>
    </TotalWrapper>
  );
}

export default Total;
