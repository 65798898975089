const INITIAL_STATE = {
  isTransactionManagerOpen: false,
  txNotifications: [
    // {
    //   platform: "Uniswap",
    //   txType: "Swap",
    //   txAmount: 100,
    //   txAmount2: 20,
    //   txToken: "ETH",
    //   txHash: "123123",
    //   timeStamp: "2021/05/07 12:12:12",
    // },
    // {
    //   platform: "1Inch",
    //   txType: "Swap",
    //   txAmount: 200,
    //   txAmount2: 20,
    //   txToken: "DAI",
    //   txHash: "12312334",
    //   timeStamp: "2021/05/07 12:12:12",
    //   status: "failed",
    // },
  ],
  txData: [],
};

const transactionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SHOW_TRANSACTION_MANAGER":
      return {
        ...state,
        isTransactionManagerOpen: true,
      };
    case "CLOSE_TRANSACTION_MANAGER":
      return {
        ...state,
        isTransactionManagerOpen: false,
      };

    case "ADD_TX":
      return {
        ...state,
        txNotifications: [...state.txNotifications, action.payload],
        txData: [...state.txData, action.payload],
      };
    case "ADD_TX_NOTIFICATION":
      return {
        ...state,
        txNotifications: [...state.txNotifications, action.payload],
      };
    case "REMOVE_TX_NOTIFICATION":
      let newArrNotifications = [...state.txNotifications];
      newArrNotifications = newArrNotifications.filter(
        (tx) => tx.txHash !== action.payload
      );
      return {
        ...state,
        txNotifications: newArrNotifications,
      };
    case "REMOVE_TX":
      let newArr = [...state.txData];
      action.payload.forEach((txHash) => {
        newArr = newArr.filter((tx) => tx.txHash !== txHash);
        console.log(newArr, "newArr reducer");
      });
      return {
        ...state,
        txData: newArr,
      };

    default:
      return state;
  }
};

export default transactionReducer;
