import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import Tippy from "@tippyjs/react";

// icons
import zhIcon from "../assets/zhFlag-small.svg";
import enIcon from "../assets/enFlag-small.svg";
import jpIcon from "../assets/jpFlag-small.svg";
import krIcon from "../assets/krFlag-small.svg";
import ruIcon from "../assets/ruFlag-small.svg";
import trIcon from "../assets/trFlag-small.svg";
import arIcon from "../assets/arFlag-small.svg";
import { ReactComponent as ArrowDown } from "../assets/arrowDown.svg";
import Logo from "../assets/Blue-only-logo.svg";
import LogoText from "../assets/Blue-only-text.svg";
import { ReactComponent as DashboardIcon } from "../assets/sidebar/dashboard.svg";
import { ReactComponent as PoolsIcon } from "../assets/sidebar/pools.svg";
import { ReactComponent as RebalancerIcon } from "../assets/sidebar/rebalancer.svg";
import { ReactComponent as TokensIcon } from "../assets/sidebar/tokens.svg";
import { ReactComponent as SwapIcon } from "../assets/sidebar/swap.svg";
import { ReactComponent as BlockchainIcon } from "../assets/header/blockchain.svg";
import { ReactComponent as GasStationIcon } from "../assets/header/gasStation.svg";
import { ReactComponent as BorrowIcon } from "../assets/sidebar/borrow.svg";
import { ReactComponent as TransactionsIcon } from "../assets/sidebar/transactions.svg";
import OpenCloseSidebarIcon from "../assets/sidebar/close.svg";
import CloseIcon from "../assets/sidebar/closeIcon.svg";
import { ReactComponent as DepositIcon } from "../assets/sidebar/deposit.svg";
import { ReactComponent as VoteIcon } from "../assets/sidebar/vote.svg";
import { ReactComponent as HackenIcon } from "../assets/hacken.svg";
import { ReactComponent as MetaMaskIcon } from "../assets/wallets/metaMask.svg";
import GrayKeyFiIcon from "../assets/Gray-only-logo.svg";

// components
import GasCard from "./GasCard";
import Button from "./Button";
import Tooltip from "./Tooltip";

// redux
import { getGasPrices } from "../redux/gasPrices/actions";

// helpers
import { trimAddress } from "../helpers";
import { useWallet } from "../helpers/useWallet";
import { breakPoints } from "../theme";

const ConnectWalletButton = styled(Button)`
  height: initial;
  margin-bottom: 1em;
  padding: 0.5em;
  width: 100%;
`;

export const StyledListItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 200px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 8px;
  padding: 15px 16px 15px 19px;
  cursor: pointer;
  color: ${(props) => props.theme.mainDarkText};

  &:hover {
    div {
      color: ${(props) => props.theme.brandBlue};
    }
    svg {
      path {
        fill: ${(props) => props.theme.brandBlue};
      }
    }
    .sidebar_icon_nofill {
      path {
        fill: transparent;
        stroke: ${(props) => props.theme.brandBlue};
      }
    }
  }

  @media (max-width: ${breakPoints.md}px) {
    padding: 16px;
  }
`;

export const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 24px;
  font-size: 16px;

  svg,
  img {
    margin-right: ${(props) => (props.sidebarMinimized ? "0" : "16px")};
  }
  img {
    max-width: 24px;
    max-height: 24px;
  }
`;

const SidebarBackground = styled.div`
  background-color: #555c68;
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 1;
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 200px;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: ${(props) => (props.sidebarMinimized ? 60 : 200)}px;
  background: ${(props) => props.theme.superLightBlue};
  padding: 16px 20px;
  z-index: ${(props) => (props.isMobile ? 2 : 0)};
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: #ff0000;
  }
  @media (max-width: ${breakPoints.md}px) {
    background: ${(props) => props.theme.white};
    height: 100vh;
    overflow: auto;
    width: 300px;
    align-items: flex-start;
    padding: 16px;
    right: 0;
    left: auto;
  }

  ${(props) =>
    props.sidebarMinimized &&
    css`
      ${LogoWrapper} {
        width: initial;
        margin-top: 25px;
        margin-bottom: 18px;
        justify-content: center;

        img {
          margin-right: 0;
        }
      }
      ${InnerWrapper} {
        svg {
          margin-right: 0;
        }
      }

      ${StyledListItem} {
        width: 60px;
        justify-content: center;
        padding: 0px 0px 0px 4px;
      }

      ${TransilationLogos} {
        flex-direction: column;
        align-self: center;
      }

      .close-icon {
        transform: scale(-1);
        cursor: pointer;
      }
    `}

  a {
    .tooltip {
      display: none;
    }
  }

  .active {
    div {
      color: ${(props) => props.theme.brandBlue};
    }

    svg {
      path {
        fill: ${(props) => props.theme.brandBlue};
      }
    }
    .sidebar_icon_nofill {
      path {
        fill: transparent;
        stroke: ${(props) => props.theme.brandBlue};
      }
    }
  }

  .link_disabled {
    position: relative;
    cursor: default;
    * {
      cursor: default;
    }
    div {
      color: ${(props) => props.theme.placeholder2};
    }
    svg {
      path {
        fill: ${(props) => props.theme.placeholder2};
      }
    }
    &:hover {
      .tooltip {
        display: flex;
      }
    }
  }

  .active::before {
    background-color: ${(props) => props.theme.lightBlue2};
    border-left: 8px solid ${(props) => props.theme.brandBlue};
    content: "";
    position: absolute;
    height: 40px;
    width: calc(100% - 8px);
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  width: 170px;
  margin-bottom: 32px;

  img {
    margin-right: 8px;
  }
  .close-icon {
    cursor: pointer;
    margin-right: 0;
  }

  @media (max-width: ${breakPoints.md}px) {
    margin-bottom: 1em;
    width: 100%;
  }
`;

const TransilationLogos = styled.div`
  margin-top: auto;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  img {
    cursor: pointer;
  }
`;

const HackenAudit = styled.div`
  align-self: flex-start;
  font-weight: 500;
  display: flex;
  align-items: center;
  span {
    margin-right: 0.75rem;
  }
  svg {
    height: 24px;
    width: 22px;
  }
`;

export const StyledConnectedButton = styled.button`
  align-items: center;
  background: ${(props) => props.theme.superLightBlue};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.lightBlue2};
  color: ${(props) => props.theme.brandBlue};
  cursor: pointer;
  display: flex;
  height: 54px;
  margin-bottom: 8px;
  outline: 0;
  padding: 12px 16px;
  width: 100%;
  span {
    font-size: 16px;
    font-weight: 500;
  }

  img {
    margin-right: 8px;
  }
`;

const AddKeyfitoButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  background: linear-gradient(256.02deg, #0ca7eb 2.19%, #005ad3 188.15%);
  color: #ffffff;
  padding: 5px 10px 3px 10px;
  border-radius: 6px;
  margin-bottom: 1rem;
  &:hover {
    background: #1a6bd7;
  }
  @media (max-width: 767px) {
    margin: 1rem 0 1.5rem;
  }
`;

const LanguageDropdown = styled.div`
  margin: auto 0 1.5rem;
  width: 100%;
  h6 {
    margin: 0 0 0.5rem;
    font-weight: 500;
    font-size: 12px;
  }
  .language_container {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    border: 1px solid #465b6f;
    padding: 0.5rem;
    font-weight: 500;
    .language_current {
      display: flex;
      align-items: center;
      img {
        margin-right: 0.6rem;
        max-height: 30px;
        max-width: 30px;
      }
    }
    svg {
      max-width: 18px;
      path {
        fill: #3b474e;
      }
    }
  }
`;

function SideBar({
  showSidebar,
  isMobile,
  handleToggleSidebar,
  sidebarMinimized,
  handleMinimize,
  handleShowModal,
  setShowLang,
  gas,
  readOnly,
}) {
  const { i18n, t } = useTranslation();
  const { ethereum } = window;
  const wallet = useWallet();

  const handleAddKeyfiToMetamask = async () => {
    const tokenAddressETH = "0xb8647e90c0645152fccf4d9abb6b59eb4aa99052";
    const tokenAddressBSC = "0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d";
    const tokenAddressBSCTestnet = "0x2bA8E0794B99B9Fe7236FD25aE943563F1F1f50a";
    const tokenSymbol = "KEYFI";
    const tokenDecimals = 18;
    const tokenImage =
      "https://app.keyfi.com/static/media/Blue-filled-logo.dd2b87f5.svg";

    const getAddress = () => {
      if (ethereum.networkVersion === "97") return tokenAddressBSCTestnet;
      if (ethereum.networkVersion === "56") return tokenAddressBSC;
      return tokenAddressETH;
    };

    try {
      await ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: getAddress(),
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const checkReadOnly = (e) => {
    if (readOnly) return e.preventDefault();
    if (isMobile) return handleToggleSidebar;
    return null;
  };

  const languageFlag = {
    zh: zhIcon,
    en: enIcon,
    jp: jpIcon,
    kr: krIcon,
    ru: ruIcon,
    tr: trIcon,
    ar: arIcon,
  };

  return showSidebar ? (
    <>
      <Wrapper sidebarMinimized={sidebarMinimized} isMobile={isMobile}>
        {!isMobile && sidebarMinimized && (
          <img
            src={OpenCloseSidebarIcon}
            alt="open close icon"
            onClick={handleMinimize}
            className="close-icon"
          />
        )}

        <LogoWrapper>
          {isMobile && (
            <>
              <Link to="/" style={{ display: "flex", alignItems: "center" }}>
                <img src={Logo} alt="logo" width="45px" height="45px" />
                <img
                  src={LogoText}
                  alt="logotext"
                  width="100px"
                  height="32px"
                />
              </Link>
              <img
                src={CloseIcon}
                alt="open close icon"
                className="close-icon"
                onClick={handleToggleSidebar}
              />
            </>
          )}
          {!isMobile &&
            (sidebarMinimized ? (
              <Link to="/">
                {/* logo when sidebar Minimized  */}
                <img src={Logo} alt="logo" width="35px" height="35px" />
              </Link>
            ) : (
              <>
                {/* logos desktop version  */}
                <Link to="/" style={{ display: "flex", alignItems: "center" }}>
                  <img src={Logo} alt="logo" width="35px" height="35px" />
                  <img
                    src={LogoText}
                    alt="logotext"
                    width="62px"
                    height="35px"
                  />
                </Link>
                <img
                  src={OpenCloseSidebarIcon}
                  alt="open close icon"
                  className="close-icon"
                  onClick={handleMinimize}
                />
              </>
            ))}
        </LogoWrapper>

        {isMobile &&
          (wallet.address ? (
            <div style={{ width: "100%" }}>
              <StyledConnectedButton onClick={handleShowModal}>
                <BlockchainIcon style={{ margin: "0px 8px 0px 0px" }} />
                <span>{trimAddress(wallet.address)}</span>
              </StyledConnectedButton>
              <Tippy
                content={<GasCard />}
                trigger="click"
                interactive
                inlinePositioning
                appendTo={() => document.body}
                className="sanyi"
              >
                <StyledConnectedButton>
                  <div
                    className="icon-wrapper"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <GasStationIcon style={{ margin: "0px 8px 0px 0px" }} />
                    {!!gas.average && (
                      <span className="gas-price"> {gas.average / 10}</span>
                    )}
                  </div>
                </StyledConnectedButton>
              </Tippy>
            </div>
          ) : (
            <ConnectWalletButton primary onClick={handleShowModal}>
              {t("addition.connectWallet")}
            </ConnectWalletButton>
          ))}
        <NavLink
          activeClassName="active"
          to="/dashboard"
          onClick={isMobile ? handleToggleSidebar : null}
        >
          <StyledListItem>
            <InnerWrapper>
              <DashboardIcon />
              {!sidebarMinimized && t("dashboard.menu.dashboard")}
            </InnerWrapper>
          </StyledListItem>
        </NavLink>

        <NavLink
          activeClassName="active"
          to="/rebalancer"
          onClick={isMobile ? handleToggleSidebar : null}
        >
          <StyledListItem>
            <InnerWrapper>
              <RebalancerIcon />
              {!sidebarMinimized && t("dashboard.menu.rebalancer")}
            </InnerWrapper>
          </StyledListItem>
        </NavLink>

        <NavLink
          className={readOnly ? "link_disabled" : ""}
          activeClassName="active"
          to="/pools"
          onClick={(e) => checkReadOnly(e)}
        >
          <StyledListItem>
            <InnerWrapper>
              <PoolsIcon />
              {!sidebarMinimized && t("dashboard.menu.pools")}
            </InnerWrapper>
          </StyledListItem>
          <Tooltip text={t("addition.toolTipText")} />
        </NavLink>

        <NavLink
          className={readOnly ? "link_disabled" : ""}
          activeClassName="active"
          to="/swap"
          onClick={(e) => checkReadOnly(e)}
        >
          <StyledListItem>
            <InnerWrapper>
              <SwapIcon />
              {!sidebarMinimized && t("dashboard.menu.swap")}
            </InnerWrapper>
          </StyledListItem>
          <Tooltip text={t("addition.toolTipText")} />
        </NavLink>

        <NavLink
          className={readOnly ? "link_disabled" : ""}
          activeClassName="active"
          to="/deposit"
          onClick={(e) => checkReadOnly(e)}
        >
          <StyledListItem>
            <InnerWrapper>
              <DepositIcon />
              {!sidebarMinimized && t("dashboard.menu.deposit")}
            </InnerWrapper>
          </StyledListItem>
          <Tooltip text={t("addition.toolTipText")} />
        </NavLink>
        <NavLink
          className={readOnly ? "link_disabled" : ""}
          activeClassName="active"
          to="/borrow"
          onClick={(e) => checkReadOnly(e)}
        >
          <StyledListItem>
            <InnerWrapper>
              <BorrowIcon />
              {!sidebarMinimized && t("dashboard.menu.borrow")}
            </InnerWrapper>
          </StyledListItem>
          <Tooltip text={t("addition.toolTipText")} />
        </NavLink>

        <NavLink
          className={readOnly ? "link_disabled" : ""}
          activeClassName="active"
          to="/earn"
          onClick={(e) => checkReadOnly(e)}
        >
          <StyledListItem>
            <InnerWrapper>
              <TokensIcon />
              {!sidebarMinimized && t("dashboard.menu.earn")}
            </InnerWrapper>
          </StyledListItem>
          <Tooltip text={t("addition.toolTipText")} />
        </NavLink>

        <NavLink
          activeClassName="active"
          to="/transactions"
          onClick={isMobile ? handleToggleSidebar : null}
        >
          <StyledListItem>
            <InnerWrapper>
              <TransactionsIcon className="sidebar_icon_nofill" />
              {!sidebarMinimized && t("dashboard.menu.transactions")}
            </InnerWrapper>
          </StyledListItem>
        </NavLink>

        <a href="https://vote.keyfi.com/" target="_blank" rel="noreferrer">
          <StyledListItem>
            <InnerWrapper>
              <VoteIcon />
              {!sidebarMinimized && t("dashboard.menu.vote")}
            </InnerWrapper>
          </StyledListItem>
        </a>

        <NavLink
          activeClassName="active"
          to="/info"
          onClick={isMobile ? handleToggleSidebar : null}
        >
          <StyledListItem>
            <InnerWrapper sidebarMinimized={sidebarMinimized}>
              <img src={GrayKeyFiIcon} alt="KeyFi info" />
              {!sidebarMinimized && t("dashboard.menu.keyFiInfo")}
            </InnerWrapper>
          </StyledListItem>
        </NavLink>

        {ethereum && (
          <AddKeyfitoButton onClick={handleAddKeyfiToMetamask}>
            {!sidebarMinimized && t("dashboard.menu.addKeyFito")}
            <MetaMaskIcon />
          </AddKeyfitoButton>
        )}

        <LanguageDropdown>
          {!sidebarMinimized && (
            <>
              <h6>{t("dashboard.menu.changeLanguages")}</h6>
              <div onClick={setShowLang} className="language_container">
                <div className="language_current">
                  <>
                    <img
                      src={languageFlag[i18n.language]}
                      alt="current language"
                    />
                    {t("language." + i18n.language)}
                  </>
                </div>
                <ArrowDown />
              </div>
            </>
          )}
        </LanguageDropdown>

        <HackenAudit>
          {!sidebarMinimized && <span>{t("dashboard.menu.auditedBy")}:</span>}
          <a
            href="https://keyfi.com/static/Keyfi_SC_Audit_Report-78d8894ee980f2a7036a7a0f7a593a4b.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <HackenIcon />
          </a>
        </HackenAudit>
      </Wrapper>
      {isMobile ? <SidebarBackground onClick={handleToggleSidebar} /> : null}
    </>
  ) : null;
}

const mapStateToProps = (state) => ({
  gas: state.gas.gasPrices,
  readOnly: state.user.readOnly,
});

const mapDispatchToProps = (dispatch) => ({
  getGasPrices: () => dispatch(getGasPrices()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
