import React, { useEffect, useMemo, useState } from "react";
import { integrations } from "@keyfi/keyfi-common";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import BigNumber from "bignumber.js";
import { beautifyTokensAmount, switchToETH } from "../../../helpers";
import { borrowOperations } from "../../../redux/borrowSlice";
import DesktopMyBorrows from "./DesktopMyBorrows";
import MobileMyBorrows from "./MobileMyBorrows";
import { breakPoints } from "../../../theme";
import MyCollateral from "./MyCollateral";
import { Skeleton, Spinner } from "../../../Shared";

const Wrapper = styled.div`
  margin-bottom: 2.5rem;
  display: flex;
  gap: 2.5rem;
  @media (max-width: ${breakPoints.lg}px) {
    flex-direction: column;
    gap: 1.5rem;
  }
  .my_borrows {
    flex: 1.5;
    display: flex;
    flex-direction: column;
    .my_borrows_header {
      margin-bottom: 0.5rem;
      .my_borrows_total {
        display: flex;
        h3 {
          margin: 0;
          align-items: center;
          font-size: 16px;
        }
        span {
          display: flex;
          align-items: center;
          font-weight: 600;
          margin-left: 0.5rem;
          ${Skeleton} {
            margin-left: 0.25rem;
          }
        }
      }
    }
    .my_borrows_body {
      background: ${(props) => props.theme.superLightBlue};
      padding: 1rem 1.5rem;
      border-radius: 6px;
      border: 1px solid ${(props) => props.theme.border3};
      @media (max-width: ${breakPoints.md}px) {
        padding: 0.5rem 0.75rem;
      }
    }
    .my_borrows_no_assets {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .my_collateral {
    flex: 1;
    .my_collateral_header {
      margin-bottom: 0.5rem;
      .my_collateral_total {
        display: flex;
        h3 {
          margin: 0;
          align-items: center;
          font-size: 16px;
        }
        span {
          display: flex;
          align-items: center;
          font-weight: 600;
          margin-left: 0.5rem;
          ${Skeleton} {
            margin-left: 0.25rem;
          }
        }
      }
    }
    .my_collateral_body {
      background: ${(props) => props.theme.superLightBlue};
      width: 100%;
      padding: 1rem;
      border-radius: 6px;
      border: 1px solid ${(props) => props.theme.border3};
      @media (max-width: ${breakPoints.md}px) {
        padding: 1rem 0.75rem;
      }
    }
  }
`;

const MyBorrows = () => {
  const { t } = useTranslation();
  const [borrowData, setBorrowData] = useState([]);
  const [collateralData, setCollateralData] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const borrow = useSelector((state) => state.borrow);
  const { isFetching } = borrow;

  useEffect(() => {
    const getData = async () => {
      try {
        if (Object.keys(borrow.mainnet.aave.v2.balance).length !== 0) {
          setLoading(true);
          const data = dispatch(borrowOperations.getBorrowedAmounts());

          const ETHPrice = await integrations.compound.getETHPrice();

          const mappedData = data.map((item) => {
            const itemData = borrow[item.network][item.platform][
              item.version
            ].data.find((tokenData) => tokenData.symbol === item.symbol);

            const debtAmountInUSD =
              item.debtAmount * itemData.priceETH * parseFloat(ETHPrice);

            return {
              ...item,
              debtAmountInUSD,
              assetData: {
                platform: item.platform,
                version: item.version,
                asset: item.symbol,
                debtAmountInUSD,
              },
            };
          });

          const collateralData = dispatch(borrowOperations.getCollateralData());

          const collateralMappedData = collateralData.map((item) => ({
            ...item,
            totalCollateralUSD: BigNumber(item.totalCollateralETH)
              .times(ETHPrice)
              .toFixed(),
          }));

          setBorrowData(mappedData);
          setCollateralData(collateralMappedData);
          setLoading(false);
        }
      } catch (err) {}
    };
    if (!borrow.isFetching) {
      getData();
    }
  }, [borrow.isFetching]);

  const collateralTotal = useMemo(
    () =>
      collateralData.reduce(
        (acc, item) => BigNumber(item.totalCollateralUSD).plus(acc),
        0
      ),
    [collateralData]
  );

  const borrowTotal = useMemo(
    () => borrowData.reduce((acc, item) => acc + item.debtAmountInUSD, 0),
    [borrowData]
  );
  return (
    <Wrapper>
      <div className="my_borrows">
        <div className="my_borrows_header">
          <div className="my_borrows_total">
            <h3>{t("borrow.borrowedBalance")} -</h3>{" "}
            <span>
              {isFetching || loading ? (
                <Skeleton white width="50" height="18" />
              ) : (
                beautifyTokensAmount(borrowTotal, { prefix: "$" })
              )}
            </span>
          </div>
        </div>
        {borrowData && borrowData.length !== 0 ? (
          <div className="my_borrows_body">
            <DesktopMyBorrows borrowData={borrowData} t={t} />
            <MobileMyBorrows borrowData={borrowData} t={t} />
          </div>
        ) : (
          <div className="my_borrows_body my_borrows_no_assets">
            {isFetching || loading ? (
              <Spinner background="superLightBlue" />
            ) : (
              t("borrow.noBorrowedAsset")
            )}
          </div>
        )}
      </div>
      <div className="my_collateral">
        <div className="my_collateral_header">
          <div className="my_collateral_total">
            <h3>{t("borrow.collateralBalance")} -</h3>{" "}
            <span>
              {isFetching || loading ? (
                <Skeleton white width="50" height="18" />
              ) : (
                beautifyTokensAmount(collateralTotal, { prefix: "$" })
              )}
            </span>
          </div>
        </div>
        <div className="my_collateral_body">
          <MyCollateral data={collateralData} />
        </div>
      </div>
    </Wrapper>
  );
};

export default MyBorrows;
