import React, { useCallback } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import InputGroup from "./InputGroup";
import Button from "./Button";
import { beautifyTokensAmount, calculateTotal, tokensLogos } from "../helpers";

import {
  BlueTextCard,
  PoolsCardWrapper,
  PlatformSelect,
  SecondTitle,
  PoolsInnerWrapper,
  PoolTokenCard,
} from "../Shared";
import PlatformsDropdown from "./PlatformsDropdown";

const Divider = styled.hr`
  background: ${(props) => props.theme.lightBlue3};
  border: 0;
  height: 1px;
  margin: 1em 0 1.5em;
  width: 100%;
`;

const Wrapper = styled.div`
  .pool__apy {
    margin-bottom: 1.2rem;
  }
  .pool__apy,
  .pool__supply {
    font-weight: 500;
    line-height: 20px;

    .title {
      font-size: 12px;
      margin-right: 0.7rem;
    }

    .value {
      font-size: 1rem;
      color: ${(props) => props.theme.brandBlue};
    }
  }

  .pool__tokenList {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
  }
`;

const VersionButtons = styled.div`
  margin-top: 1rem;
  button {
    cursor: pointer;
    width: 80px;
    padding: 9px 0;
    background: none;
    border: 1px solid ${(props) => props.theme.brandBlue};
    :first-of-type {
      border-radius: 6px 0 0 6px;
    }
    :last-of-type {
      border-radius: 0 6px 6px 0;
    }
  }
  .version-selected {
    background: ${(props) => props.theme.brandBlue};
    color: white;
  }
  .version-disabled {
    cursor: default;
    color: ${(props) => props.theme.disabled};
    border-color: ${(props) => props.theme.disabled};
  }
`;

const PoolsCard = (props) => {
  const {
    APY,
    balance = {},
    updateParentInput,
    input1Name,
    input2Name,
    onDeposit,
    onWithdraw,
    user,
    platform,
    version,
    versions,
    handleVersionChange,
    selectdeposit,
    selectwithdraw,
    defaultSelected1,
    defaultSelected2,
    value1,
    value2,
    handleMaxInput,
    filterZeroBalance,
    filterZeroPlatformBalance,
    setOption,
    selectedOption,
    options,
    hasInSufficientWithdrawBalance,
    hasInSufficientDepositBalance,
    hasInvalidWithdrawInput,
    hasInvalidDepositInput,
    staking,
  } = props;
  const { t } = useTranslation();

  const handleCoinInput = (symbol) => {
    updateParentInput("selectdeposit", symbol);
    updateParentInput("selectwithdraw", symbol);
  };

  const renderPoolTokensList = useCallback(
    (balance) =>
      Object.entries(balance)
        .filter(([_, { amount }]) => amount > 0)
        .slice(0, 6)
        .map(([symbol, { amount }]) => (
          <PoolTokenCard key={symbol} onClick={() => handleCoinInput(symbol)}>
            <span className="token__name">
              {tokensLogos[symbol]} {symbol}
            </span>
            <span className="token__value">{beautifyTokensAmount(amount)}</span>
          </PoolTokenCard>
        )),
    [balance]
  );

  const adjustedBalanceStructure =
    Object.keys(balance).length !== 0
      ? Object.entries(balance).reduce(
          (acc, [symbol, { amount }]) => ({
            ...acc,
            [symbol]: amount,
          }),
          {}
        )
      : {};

  return (
    <Wrapper>
      <PoolsCardWrapper>
        <BlueTextCard>{t("deposit.description")}</BlueTextCard>
        <PlatformSelect>
          <SecondTitle>{t("deposit.choosePlatform")}</SecondTitle>
          <PlatformsDropdown
            setOption={setOption}
            selectedOption={selectedOption}
            options={options}
          />
          {versions && versions.length !== 0 && (
            <VersionButtons>
              {versions.map((item) => (
                <button
                  key={item}
                  className={version === item ? "version-selected" : ""}
                  onClick={() => handleVersionChange(item)}
                >
                  {item}
                </button>
              ))}
            </VersionButtons>
          )}
        </PlatformSelect>

        <Divider />
        <div className="pool__apy">
          <span className="title">{t("deposit.netAPY")}:</span>
          <span className="value">
            {beautifyTokensAmount(APY, {
              suffix: " %",
            })}
          </span>
        </div>

        <div className="pool__supply">
          <span className="title"> {t("deposit.supplyBalance")}:</span>
          <span className="value">
            {beautifyTokensAmount(
              calculateTotal(adjustedBalanceStructure, user.usdPrices),
              {
                prefix: "$",
              }
            )}
          </span>
        </div>
        <div className="pool__tokenList"> {renderPoolTokensList(balance)}</div>
        <Divider />

        <PoolsInnerWrapper>
          <div className="InnerWrapper">
            <div className="InnerWrapper__inputsgroup">
              <span className="inputsgroup__floating-left">
                {t("deposit.supply")}
              </span>

              <span className="inputsgroup__floating-right">
                {t("deposit.wallet")}:{" "}
                {beautifyTokensAmount(user.tokens[selectdeposit] ?? 0, {
                  suffix: ` ${selectdeposit}`,
                })}
              </span>

              <InputGroup
                height="48px"
                name={input1Name}
                updateParent={updateParentInput}
                platform={platform}
                version={version ?? ""}
                value={value1}
                invokeMaxInput={handleMaxInput}
                filterZeroBalance={filterZeroBalance}
                error={hasInSufficientDepositBalance}
                defaultSelected={defaultSelected1}
              />
            </div>

            {hasInSufficientDepositBalance && (
              <span className="error float top">
                {t("deposit.insufficient")} {selectwithdraw}{" "}
                {t("deposit.balance")}
              </span>
            )}
            <Button
              primary
              onClick={onDeposit}
              disabled={hasInvalidDepositInput || hasInSufficientDepositBalance}
            >
              {t("deposit.deposit")}
            </Button>
          </div>
          <div className="InnerWrapper">
            <div className="InnerWrapper__inputsgroup">
              <span className="inputsgroup__floating-left">
                {t("deposit.withdraw")}
              </span>

              <span className="inputsgroup__floating-right">
                {platform}:{" "}
                {beautifyTokensAmount(
                  staking.mainnet[platform][version ?? "v1"][selectwithdraw]
                    ? staking.mainnet[platform][version ?? "v1"][selectwithdraw]
                        .amount
                    : 0,
                  {
                    suffix: ` ${selectwithdraw}`,
                  }
                )}
              </span>
              <InputGroup
                height="48px"
                name={input2Name}
                updateParent={updateParentInput}
                platform={platform}
                version={version ?? ""}
                value={value2}
                invokeMaxInput={handleMaxInput}
                filterZeroPlatformBalance={filterZeroPlatformBalance}
                defaultSelected={defaultSelected2}
                error={hasInSufficientWithdrawBalance}
              />
            </div>

            {hasInSufficientWithdrawBalance && (
              <span className="error float bottom">
                {t("deposit.insufficient")} {selectwithdraw}{" "}
                {t("deposit.balance")}
              </span>
            )}

            <Button
              primary
              onClick={onWithdraw}
              disabled={
                hasInvalidWithdrawInput || hasInSufficientWithdrawBalance
              }
            >
              {t("deposit.withdraw")}
            </Button>
          </div>
        </PoolsInnerWrapper>
      </PoolsCardWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  staking: state.staking,
});

export default connect(mapStateToProps)(PoolsCard);
