import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en/translation.json";
import translationAR from "./locales/ar/translation.json";
import translationTR from "./locales/tr/translation.json";
import translationZH from "./locales/zh/translation.json";
import translationKR from "./locales/kr/translation.json";
import translationJP from "./locales/jp/translation.json";
import translationRU from "./locales/ru/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  zh: {
    translation: translationZH,
  },
  jp: {
    translation: translationJP,
  },
  kr: {
    translation: translationKR,
  },
  ru: {
    translation: translationRU,
  },
  tr: {
    translation: translationTR,
  },
  ar: {
    translation: translationAR,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    debug: true,
    detection: {
      order: ["localStorage"],
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
