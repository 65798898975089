import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { poolsLogos, SwitchToBsc, switchToETH } from "../helpers";
import AddLiquidity from "../components/LiquidityPools/AddLiquidity";
import Tabs from "../components/Tabs";
import UserLiquidity from "../components/LiquidityPools/UserLiquidity";

const platforms = [
  {
    value: "uniswap",
    icon: poolsLogos["Uniswap"],
    text: "Uniswap",
    network: "mainnet",
  },
  {
    value: "curve",
    icon: poolsLogos["Curve"],
    text: "Curve",
    network: "mainnet",
  },
  {
    value: "apeswap",
    icon: poolsLogos["ApeSwap"],
    text: "ApeSwap",
    network: "bsc-mainnet",
  },
  {
    value: "pancakeswap",
    icon: poolsLogos["Pancakeswap"],
    text: "PancakeSwap",
    network: "bsc-mainnet",
  },
];

const Wrapper = styled.div`
  max-width: 560px;
  width: 100%;
  margin: 1rem auto;
  overflow: hidden;
  h1 {
    font-size: 24px;
    font-weight: 500;
    color: ${(props) => props.theme.mainDarkText};
    margin: 0 0 1.5rem;
  }
`;

function Pools() {
  const { t } = useTranslation();

  const wallet = useSelector((state) => state.wallet);
  const { network } = wallet;

  const [selectedPlatform, setPlatform] = useState();
  const [activeTab, setActiveTab] = useState();

  const tabs = useMemo(() => {
    return [
      {
        id: "add-liquidity",
        Name: t("pool.addLiquidity.title"),
        Component: <AddLiquidity />,
      },
      {
        id: "my-liquidity",
        Name: t("pool.yourLiquidity.title"),
        Component: <UserLiquidity />,
      },
    ];
  }, [t]);

  let { platformCode, tab } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (tab) {
      const tabData = tabs.find((item) => item.id === tab);
      setActiveTab(tabData);
    }

    if (platformCode) {
      const platformData = platforms.find(
        (platform) => platform.value === platformCode
      );
      switch (platformData.network) {
        case "bsc-mainnet":
          SwitchToBsc();
          break;
        case "mainnet":
          switchToETH();
          break;
        default:
          break;
      }
      setPlatform(platformData);
    } else {
      history.push(
        `/pools/${tabs[0].id}/${
          network && network.name === "bsc-mainnet" ? "apeswap" : "uniswap"
        }`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, tabs, platformCode]);

  const handleTabSwitch = (newTab) => {
    history.push(`/pools/${newTab.id}/${selectedPlatform.value}`);
  };

  return (
    <>
      <Wrapper>
        <h1>{t("pool.title")}</h1>
        {activeTab && selectedPlatform && (
          <Tabs
            tabs={tabs}
            activeTabID={activeTab}
            handleChange={handleTabSwitch}
          />
        )}
      </Wrapper>
    </>
  );
}

export default Pools;
