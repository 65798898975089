import React, { useState } from "react";
import { ReactComponent as TokensIcon } from "../../../../assets/sidebar/tokens.svg";
import { ReactComponent as ArrowDown } from "../../../../assets/arrowDown.svg";
import { AccordionList, AccordionWrapper } from "../../shared";

// Components
import KeyFiStaking from "./KeyFiStaking";
import KeyFiBUSDStaking from "./KeyFiBUSDStaking";
import BUSDStaking from "./BUSDStaking";
import WBNBStaking from "./WBNBStaking";
import USDCStaking from "./USDCStaking";
import KeyFiBUSDv2Staking from "./KeyFiBUSDv2Staking";

const Staking = () => {
  const [open, setOpen] = useState(true);

  return (
    // <AccordionWrapper open={open}>
    //   <div className="accordion_header" onClick={() => setOpen(!open)}>
    //     <div className="accordion_header_title">
    //       <TokensIcon />
    //       <span>Staking</span>
    //     </div>
    //     <ArrowDown className="accordion_arrow" />
    //   </div>
    <>
      {open && (
        <AccordionList>
          <KeyFiStaking />
          <KeyFiBUSDStaking />
          <KeyFiBUSDv2Staking />
          <BUSDStaking />
          <WBNBStaking />
          <USDCStaking />
        </AccordionList>
      )}
    </>
    // </AccordionWrapper>
  );
};

export default Staking;
