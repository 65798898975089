import React from "react";
import styled from "styled-components";

const KeyFiTooltipWrapper = styled.div`
  background: ${(props) => props.theme.lightBlue3};
  border-radius: 6px;
  max-width: 200px;
  .keyfitooltip_inside {
    font-size: 12px;
    padding: 0.5rem;
    font-weight: 500;
    color: ${(props) => props.theme.brandBlue};
  }

  .keyfitooltip_arrow {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${(props) => props.theme.lightBlue3};
    z-index: 13;
  }
`;

const KeyFiTooltip = ({ title, ...attrs }) => (
  <KeyFiTooltipWrapper {...attrs}>
    <div className="keyfitooltip_inside">{title}</div>
    <div className="keyfitooltip_arrow" data-popper-arrow="" />
  </KeyFiTooltipWrapper>
);

export default KeyFiTooltip;
