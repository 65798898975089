import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ReactComponent as CredentialsIcon } from "../../assets/credentials2.svg";
import Button from "../Button";
import { breakPoints } from "../../theme";
import { BlueIconTextTitle, CardWrapper, Divider } from "../../Shared";
const MainWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 2rem;
  @media (max-width: ${breakPoints.sm}px) {
    margin: 32px auto 0;
    width: 100%;
    padding: 0.5rem;
  }

  button {
    margin-top: 2rem;
    width: 100%;
  }

  .description {
    display: inline-block;
    margin-top: 1rem;
    color: ${(props) => props.theme.brandBlue};
    font-weight: 500;

    li {
      font-weight: 400;
    }
  }
`;

function Credential() {
  const { t } = useTranslation();

  return (
    <MainWrapper>
      <BlueIconTextTitle>
        <CredentialsIcon />
        {t("earn.v1Staking.credentialRequired")}
      </BlueIconTextTitle>
      <Divider />

      <span className="description">
        {t("earn.v1Staking.description")}
        <ul>
          <li> {t("earn.v1Staking.subDescription")}</li>
        </ul>
      </span>

      <a href="https://credentials.keyfi.com/" target="_blank" rel="noreferrer">
        <Button primary>{t("earn.v1Staking.applyBtn")}</Button>
      </a>
    </MainWrapper>
  );
}

export default Credential;
