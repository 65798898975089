import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import PlatformsDropdown from "../../PlatformsDropdown";
import {
  platforms,
  UserLiquidityListWrapper,
  VersionButtons,
} from "../SharedLiquidity";

import Warning from "../../../assets/Warning.svg";
import UserLiquidityListItem from "../SharedLiquidity/UserLiquidityListItem";
import NoAssetContainer from "../SharedLiquidity/NoAssetContainer";
import { useTranslation } from "react-i18next";

const WarningMessage = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.warningBackground};
  padding: 0.5rem 0.75rem;
  margin-top: 1rem;
  border-radius: 6px;
  font-size: 12px;
  img {
    margin-right: 0.5rem;
  }
`;

const PancakeSwapUserLiquidity = ({
  handlePlatformSwitch,
  selectedPlatform,
}) => {
  const { t } = useTranslation();

  const [version, setVersion] = useState("v2");
  const liquidity = useSelector((state) => state.liquidity);

  return (
    <>
      <div className="user-liquidity-platform">
        <div className="user-liquidity-platform-label">
          {t("pool.yourLiquidity.choosePlatform")}:
        </div>
        <PlatformsDropdown
          setOption={handlePlatformSwitch}
          selectedOption={selectedPlatform}
          options={platforms}
        />
        <VersionButtons>
          <button
            className={version === "v2" ? "liquidity-version-selected" : ""}
            onClick={() => setVersion("v2")}
          >
            V2
          </button>
          <button
            className={version === "v1" ? "liquidity-version-selected" : ""}
            onClick={() => setVersion("v1")}
          >
            V1 (old)
          </button>
        </VersionButtons>
        {version === "v1" && (
          <WarningMessage>
            <img src={Warning} alt="Warning" />
            {t("pool.addLiquidity.pancakeSwapWarningMsg")}
          </WarningMessage>
        )}
      </div>
      <UserLiquidityListWrapper>
        {selectedPlatform.value &&
        liquidity["bsc-mainnet"][selectedPlatform.value][version].length !==
          0 ? (
          liquidity["bsc-mainnet"][selectedPlatform.value][version].map(
            (item, i) => (
              <UserLiquidityListItem
                key={i}
                data={item}
                platform={selectedPlatform.value}
                version={version}
                disabledAddLiquidity={version === "v1"}
              />
            )
          )
        ) : (
          <NoAssetContainer
            platform="pancakeswap"
            disabled={version === "v1"}
          />
        )}
      </UserLiquidityListWrapper>
    </>
  );
};

export default PancakeSwapUserLiquidity;
