import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { SwitchToBsc, switchToETH } from "../helpers";
import { swapPlatforms } from "../components/SwapPlatforms/shared";

// All Platforms
import OneInchSwap from "../components/SwapPlatforms/1Inch";
import ApeSwapSwap from "../components/SwapPlatforms/ApeSwap";
import PancakeSwapSwap from "../components/SwapPlatforms/PancakeSwap";
import UniswapSwap from "../components/SwapPlatforms/Uniswap";

const Wrapper = styled.div`
  max-width: 560px;
  width: 100%;
  margin: 1rem auto 2rem;
  h1 {
    font-size: 24px;
    font-weight: 500;
    color: ${(props) => props.theme.mainDarkText};
    margin: 0 0 1.5rem;
  }
`;

const SwapPlatformsWrapper = styled.div`
  color: ${(props) => props.theme.mainDarkText};
  width: 100%;
  background: ${(props) => props.theme.superLightBlue};
  padding: 1.5rem;
  border: 1px solid ${(props) => props.theme.border3};
  border-radius: 6px;
  .swap-description {
    padding: 1rem 1rem;
    margin-bottom: 1.5rem;
    background: ${(props) => props.theme.lightBlue3};
    border-radius: 6px;
    color: ${(props) => props.theme.brandBlue};
    font-size: 12px;
  }
  .swap-platform-container {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid ${(props) => props.theme.border3};
    .swap-platform-label {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 0.4rem;
    }
  }
`;

const SwapPlatforms = ({ history }) => {
  const { t } = useTranslation();
  const { platformCode } = useParams();

  const wallet = useSelector((state) => state.wallet);
  const { network } = wallet;

  const [selectedPlatform, setSelectedPlatform] = useState({
    value: null,
  });

  const handlePlatformSwitch = (value) => {
    history.push(`/swap/${value}`);
  };

  useEffect(() => {
    if (platformCode) {
      const platform = swapPlatforms.find(
        (item) => item.value === platformCode
      );
      switch (platform.network) {
        case "bsc-mainnet":
          SwitchToBsc();
          break;
        case "mainnet":
          switchToETH();
          break;
        default:
          break;
      }
      setSelectedPlatform(platform);
    } else {
      history.push(
        `/swap/${
          network && network.name === "bsc-mainnet" ? "apeswap" : "uniswap"
        }`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformCode]);

  const handleSwapPlatform = (value) => {
    switch (value) {
      case "uniswap":
        return (
          <UniswapSwap
            handlePlatformSwitch={handlePlatformSwitch}
            selectedPlatform={selectedPlatform}
          />
        );
      case "1inch":
        return (
          <OneInchSwap
            handlePlatformSwitch={handlePlatformSwitch}
            selectedPlatform={selectedPlatform}
          />
        );
      case "apeswap":
        return (
          <ApeSwapSwap
            handlePlatformSwitch={handlePlatformSwitch}
            selectedPlatform={selectedPlatform}
          />
        );
      case "pancakeswap":
        return (
          <PancakeSwapSwap
            handlePlatformSwitch={handlePlatformSwitch}
            selectedPlatform={selectedPlatform}
          />
        );
      default:
        return;
    }
  };

  return (
    <Wrapper>
      <h1>{t("swap.title")}</h1>
      <SwapPlatformsWrapper>
        <div className="swap-description">{t("swap.description")}</div>
        {selectedPlatform.value && handleSwapPlatform(selectedPlatform.value)}
      </SwapPlatformsWrapper>
    </Wrapper>
  );
};

export default SwapPlatforms;
