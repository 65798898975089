import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Card from "./Card";
import {
  renderPlatformLink,
  RebalancerScreenWrapper,
  TotalInvestRate,
} from "./StableCoins";
import Table from "./Table";
import { getRates } from "../../redux/rates/actions";
import { Divider } from "../../Shared";
import { stakingOperations } from "../../redux/stakingSlice";

const platforms = ["Compound", "Aave", "dydx"];

function MaxYield({ rates, user }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [localRates, setLocalRates] = useState([]);

  const tableHeadItems = [
    { id: "rank", label: t("rebalancer.rank"), sortable: true },
    { id: "asset", label: t("dashboard.platforms.asset"), sortable: true },
    { id: "rate", label: t("rebalancer.supplyRate"), sortable: true },
    { id: "assetPrice", label: t("rebalancer.price"), sortable: true },
    { id: "borrowRate", label: t("rebalancer.borrowRate"), sortable: true },
    { id: "platform", label: t("rebalancer.platform"), sortable: true },
    { id: "action", label: t("rebalancer.action"), sortable: false },
  ];

  const filteredStableCoinsTokensList = (platform, dispatch) => {
    const balance = dispatch(stakingOperations.getPlatformAllTokens(platform));
    return Object.entries(balance)
      .filter(([symbol, value]) => value.amount > 0)
      .slice(0, 6);
  };

  useEffect(() => {
    if (rates.length) {
      let filterStableCoins = rates.filter((entry) => entry.assetPrice != null);

      filterStableCoins.sort((a, b) => b.rate - a.rate);
      filterStableCoins.forEach((rate, index) => {
        filterStableCoins[index].rank = index + 1;
        filterStableCoins[index].action = user.readOnly
          ? ""
          : renderPlatformLink(
              filterStableCoins[index].platform,
              t("rebalancer.manageAssets"),
              filterStableCoins[index].asset
            );
      });
      filterStableCoins = filterStableCoins.map((entry) => ({
        rank: entry.rank,
        asset: entry.asset,
        rate: `${String(entry.rate).slice(0, 6)} %`,
        assetPrice: `$${String(entry.assetPrice).slice(0, 7)} USD`,
        borrowRate: `${String(entry.borrowRate).slice(0, 4)} %`,
        platform:
          entry.platform === "C.R.E.A.M. Finance"
            ? "C.R.E.A.M."
            : entry.platform === "dYdX"
            ? "dydx"
            : entry.platform.charAt(0).toUpperCase() + entry.platform.slice(1),
        action: entry.action,
      }));
      setLocalRates(filterStableCoins);
    }
  }, [rates]);

  return (
    <RebalancerScreenWrapper>
      <div
        style={{
          padding: "1.5em",
          background: "#F6FAFF",
          borderRadius: "0 6px 6px 6px",
          border: "1px solid #D4E7FF",
        }}
      >
        <TotalInvestRate localRates={localRates} t={t} />
        {!user.readOnly && (
          <>
            <div className="PlatformsCards">
              {platforms.map((p) => (
                <Card
                  key={p}
                  title={p}
                  platform={p.toLowerCase()}
                  tokenList={filteredStableCoinsTokensList(
                    p.toLowerCase(),
                    dispatch
                  )}
                />
              ))}
            </div>
            <Divider />
          </>
        )}

        {localRates.length ? (
          <Table
            rows={localRates}
            tableHeadItems={
              user.readOnly ? tableHeadItems.slice(0, -1) : tableHeadItems
            }
            title={t("addition.maxYieldCoins")}
          />
        ) : null}
      </div>
    </RebalancerScreenWrapper>
  );
}

const mapStateToProps = (state) => ({
  rates: state.rates.rates,
  user: state.user,
});

const mapDispatchtoProps = (dispatch) => ({
  getRates: () => dispatch(getRates()),
});
export default connect(mapStateToProps, mapDispatchtoProps)(MaxYield);
