import styled, { ThemeProvider } from "styled-components";
import { Link } from "react-router-dom";
import { useDarkMode } from "../hooks/useDarkMode";

export const lightTheme = {
  bg: "#FFFFFF",
  bg2: "#F8FCFD",
  text_grey: "#99A7C2",
  text_greyDark: "#555C68",
  text_primary: "#0046a2",
  text_primary2: "#0046a2",
  text_primary_dark: "#066F7E",
  text_secondary: "#1F347E",
  navBg: "#FFFFFF",
  navHover: "#EFF8F9",
  navActive: "#EEFDFF",
  btnPrimaryBg: "#00CAEC",
  btnPrimaryColor: "white",
  borderBg: "#CCE8EB",
  btnSecondary: "#AEF3FF",
  tabsBg: "#EEFDFF",
  grey1: "#F8F9FC",
  grey2: "#E6ECF5",
  grey3: "#F9FEFF",
  grey4: "#DEF0F2",
  grey5: "#D3DEEE",
  greySokadik: "#F9F9F9",
  navy: "#1B3784",
  lightBlack: "#474D58",
  black1: "#555C67",
  white1: "#F3F7FF",
  orange: "#FF9F1C",
  red1: "#F65555",
  red2: "#FE7062",
  blue: "#192B66",
  lightBlue: "#5171d4",
  darkBlue: "#002072",
  brandBlue: "#005AD3",
  brandBlueDark: "#004CB3",
  primaryButtonHover: "#1C6DDA",
  white: "#FFFFFF",
  superLightBlue: "#F6FAFF",
  lightBlue2: "#DDEAFB",
  lightBlue3: "#D4E7FF",
  lightBlue4: "#EAF3FF",
  blue2: "#74A7EB",
  mainDarkText: "#3B474E",
  brandBlack: "#1B232E",
  border: "#B3D5FF",
  border2: "#D4E7FF",
  border3: "rgba(178, 212, 255, 0.5)",
  placeholder: "#C4C8CA",
  placeholder2: "#BEC4CA",
  placeholderText: "#DCDEDF",
  error: "#EB5757",
  primaryHover: "#1A6BD7",
  secondaryHover: "#EAF3FF",
  green: "#27AE60",
  green2: "#2EBA77",
  green3: "#31AC65",
  notificationBlue: "#3D7FE3",
  notificationFailed: "#F44747",
  tableRow: "#ECF4FF",
  lightGray: "#8A9FB2",
  walletModalBorder: "#D4E7FF",
  checkboxBorder: "#4984D3",
  warningBackground: "rgba(255, 220, 112, 0.7)",
  disabled: "#BEC4CA",
  disabledBorder: "#EBECEE",
  removeLiquidityPlatformBG: "#DBDEE8",
  removeLiquidityPlatformNetwork: "#E6E8EF",
  grayTitleBorrow: "#647783",
  uniswapPink: "#FD017A",
  polygonPurple: "#8247E5",
  borrowSelectedInterest: "#E5F1FF",
  borrowSelectedInterestShadow: "0px 0px 6px rgba(0, 90, 211, 0.25);",
  borrowGreen: "#4A9C6D",
  borrowYellow: "#EBA757",
  defaultWidgetBackground: "#F6FAFF",
  warning: "#FCE59B",
  txGreen: "#4A9C6D",
  txError: "#C64949",
};
export const darkTheme = {
  bg: "#EFF8F9",
  bg2: "#F8FCFD",
  text_grey: "#99A7C2",
  text_greyDark: "#555C68",
  text_primary: "#00CAED",
  text_primary2: "#00B2D1",
  text_primary_dark: "#066F7E",
  text_secondary: "#1F347E",
  navBg: "#FFFFFF",
  navHover: "#EFF8F9",
  navActive: "#EEFDFF",
  btnPrimaryBg: "#00CAEC",
  btnPrimaryColor: "white",
  borderBg: "#CCE8EB",
  btnSecondary: "#AEF3FF",
  tabsBg: "#EEFDFF",
  grey2: "#E6ECF5",
  grey3: "#F9FEFF",
  navy: "#1B3784",
  lightBlack: "#474D58",
  black1: "#555C67",
  white1: "#F3F7FF",
  red1: "#F65555",
};

export const breakPoints = {
  sm: 576, // 576
  md: 768, // TODO: Implement tablet layout
  lg: 992,
  xl: 1200,
  xll: 1060,
  laptop: 1350,
};

export const StyledThemeProvider = ({ children }) => {
  const [theme] = useDarkMode();
  const themeMode = theme === "light" ? lightTheme : darkTheme;
  return <ThemeProvider theme={themeMode}>{children}</ThemeProvider>;
};

const StyledContainer = styled.div`
  width: calc(100% - 240px);
  display: flex;
  flex-direction: column;
  margin: 0 20px 0 auto;

  @media (max-width: ${breakPoints.md}px) {
    margin: 0 auto;
    width: calc(100% - 40px);
  }

  @media (max-width: ${breakPoints.sm}px) {
    width: calc(100% - 30px);
  }
`;

export const Container = ({ children, isMinimized }) => (
  <StyledContainer isMinimized={isMinimized}>{children}</StyledContainer>
);

export const StyledLink = styled(Link)`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-decoration-line: underline;
  color: ${(props) => props.theme.text_primary};
`;

export const BodyText = styled.p`
  color: ${(props) => (props.color ? props.color : props.theme.text_greyDark)};
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => (props.weight ? props.weight : 300)};
  line-height: 24px;
  letter-spacing: 0px;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  text-align: ${(props) => (props.align === "center" ? "center" : "left")};
`;
