import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { integrations } from "@keyfi/keyfi-common";
import timestamp from "time-stamp";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import PoolsCard from "../PoolsCard";
import { getAPYRatesOnPlatform, calculateAPY } from "../../helpers";
import { getTxHistory } from "../TransactionManager";
import { walletOperations } from "../../redux/walletSlice";

import {
  showTransactionManager,
  closeTransactionManager,
  addTx,
  removeTx,
} from "../../redux/transactionManager/actions";
import { Storage } from "../../helpers/Storage";
import { BoldTitle } from "../../Shared";

const Aave = ({
  user,
  rates,
  showTransactionManager,
  addTx,
  removeTx,
  setOption,
  selectedOption,
  selectedSupply,
  options,
  staking,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const version = new URLSearchParams(location.search).get("v") ?? "v1";

  const [inputsData, setInputsData] = useState({
    deposit: "",
    selectdeposit: "",
    withdraw: "",
    selectwithdraw: version === "v1" ? "ETH" : "WETH",
  });
  const { deposit, selectdeposit, withdraw, selectwithdraw } = inputsData;

  useEffect(() => {
    const selected = () => {
      if (selectedSupply !== "ETH") {
        return selectedSupply;
      }
      if (version === "v1") {
        return "ETH";
      }
      return "WETH";
    };
    setInputsData((prev) => ({
      ...prev,
      selectdeposit: selected(),
      selectwithdraw: version === "v1" ? "ETH" : "WETH",
    }));
  }, [version]);

  const [hasInSufficientDepositBalance, setHasInSufficientDepositBalance] =
    useState(null);
  const [hasInSufficientWithdrawBalance, setHasInSufficientWithdrawBalance] =
    useState(null);

  const [hasInvalidDepositInput, setHasInvalidDepositInput] = useState(true);
  const [hasInvalidWithdrawInput, setHasInvalidWithdrawInput] = useState(true);

  const handleValidation = () => {
    // check max deposit balance
    const MaxDepositBalance = user.tokens[selectdeposit] ?? 0;

    setHasInSufficientDepositBalance(+deposit > +MaxDepositBalance);

    // check max withdraw balance
    const MaxWithdrawBalance = staking.mainnet.aave[version][selectwithdraw]
      ? staking.mainnet.aave[version][selectwithdraw].amount
      : 0;
    setHasInSufficientWithdrawBalance(+withdraw > +MaxWithdrawBalance);

    setHasInvalidDepositInput(!+deposit);
    setHasInvalidWithdrawInput(!+withdraw);
  };

  const getInputValues = (inputName, inputValue) => {
    setInputsData((prevState) => ({
      ...prevState,
      [inputName]: inputValue,
    }));
  };

  const HandleMaxInput = (name) => {
    if (name === "deposit" && user.tokens) {
      setInputsData((prevState) => ({
        ...prevState,
        deposit: user.tokens[inputsData.selectdeposit]
          ? user.tokens[inputsData.selectdeposit]
          : "0",
      }));
    }

    if (name === "withdraw" && staking.mainnet.aave[version]) {
      setInputsData((prevState) => ({
        ...prevState,
        withdraw: staking.mainnet.aave[version][selectwithdraw]
          ? staking.mainnet.aave[version][selectwithdraw].amount
          : "0",
      }));
    }
  };

  const handleVersionChange = (id) => {
    history.push(`/deposit/aave?v=${id}`);
  };

  useEffect(() => {
    handleValidation();
  }, [inputsData]);

  const onDeposit = async () => {
    // check max balance
    const MaxBalance = user.tokens[inputsData.selectdeposit] ?? 0;
    const { deposit, selectdeposit } = inputsData;

    if (
      Number(deposit) &&
      selectdeposit &&
      Number(MaxBalance) >= Number(deposit)
    ) {
      const txHash = [];
      let isFirstTrxFired = false;

      try {
        const response = await integrations.aave[version].deposit(
          selectdeposit,
          deposit,
          {
            pendingCallback: (trx) => {
              showTransactionManager();
              if (!isFirstTrxFired) {
                addTx({
                  platform: `Aave ${version}`,
                  txType: "Deposit",
                  txAmount: Number(deposit),
                  txToken: selectdeposit,
                  txHash: trx.hash,
                  timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
                });
                isFirstTrxFired = true;
              }
              txHash.push(trx.hash);
            },
          }
        );
        if (response) {
          dispatch(walletOperations.loadUserData());

          const HistoryArray = getTxHistory();
          HistoryArray.unshift({
            platform: `Aave ${version}`,
            txType: "Deposit",
            txAmount: Number(deposit),
            txToken: selectdeposit,
            txHash: response.transactionHash,
            timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
          });
          Storage.setItem("transactions", JSON.stringify(HistoryArray));
          removeTx(txHash);
        }
      } catch (err) {
        showTransactionManager();
        const HistoryArray = getTxHistory();
        HistoryArray.unshift({
          platform: `Aave ${version}`,
          txType: "Deposit",
          txAmount: Number(deposit),
          txToken: selectdeposit,
          txHash: txHash[txHash.length - 1],
          timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
          status: "failed",
        });
        Storage.setItem("transactions", JSON.stringify(HistoryArray));
        removeTx(txHash);
      }
    }

    // reset inputs
    setInputsData((prevState) => ({
      ...prevState,
      deposit: "",
      withdraw: "",
    }));
  };

  const onWithdraw = async () => {
    const { withdraw, selectwithdraw } = inputsData;
    // check max balance
    const MaxBalance = staking.mainnet.aave[version][selectwithdraw]
      ? staking.mainnet.aave[version][selectwithdraw].amount
      : 0;

    if (
      Number(withdraw) &&
      selectwithdraw &&
      Number(MaxBalance) >= Number(withdraw)
    ) {
      const txHash = [];
      let isFirstTrxFired = false;

      try {
        const response = await integrations.aave[version].withdraw(
          selectwithdraw,
          withdraw,
          {
            pendingCallback: (trx) => {
              showTransactionManager();

              if (!isFirstTrxFired) {
                addTx({
                  platform: `Aave ${version}`,
                  txType: "Withdraw",
                  txAmount: Number(withdraw),
                  txToken: selectwithdraw,
                  txHash: trx.hash,
                  timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
                });
                isFirstTrxFired = true;
              }

              txHash.push(trx.hash);
            },
          }
        );
        if (response) {
          dispatch(walletOperations.loadUserData());

          const HistoryArray = getTxHistory();
          HistoryArray.unshift({
            platform: `Aave ${version}`,
            txType: "Withdraw",
            txAmount: Number(withdraw),
            txToken: selectwithdraw,
            txHash: response.transactionHash,
            timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
          });
          Storage.setItem("transactions", JSON.stringify(HistoryArray));
          removeTx(txHash);
        }
      } catch (err) {
        showTransactionManager();

        const HistoryArray = getTxHistory();
        HistoryArray.unshift({
          platform: `Aave ${version}`,
          txType: "Withdraw",
          txAmount: Number(withdraw),
          txToken: selectwithdraw,
          txHash: txHash[txHash.length - 1],
          timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
          status: "failed",
        });
        Storage.setItem("transactions", JSON.stringify(HistoryArray));
        removeTx(txHash);
      }
    }

    // reset inputs
    setInputsData((prevState) => ({
      ...prevState,
      deposit: "",
      withdraw: "",
    }));
  };

  return (
    <>
      <BoldTitle>{t("deposit.title")}</BoldTitle>
      {version === "v1" ? (
        <PoolsCard
          balance={staking.mainnet.aave.v1}
          APY={calculateAPY(
            user.aave,
            user.usdPrices,
            getAPYRatesOnPlatform(rates, "aave")
          )}
          updateParentInput={getInputValues}
          input1Name="deposit"
          input2Name="withdraw"
          defaultSelected1={inputsData.selectdeposit}
          defaultSelected2={inputsData.selectwithdraw}
          onDeposit={onDeposit}
          onWithdraw={onWithdraw}
          platform="aave"
          version="v1"
          versions={["v1", "v2"]}
          handleVersionChange={handleVersionChange}
          selectdeposit={inputsData.selectdeposit}
          selectwithdraw={inputsData.selectwithdraw}
          value1={inputsData.deposit}
          value2={inputsData.withdraw}
          handleMaxInput={HandleMaxInput}
          filterZeroBalance
          filterZeroPlatformBalance
          setOption={setOption}
          selectedOption={selectedOption}
          options={options}
          hasInSufficientDepositBalance={hasInSufficientDepositBalance}
          hasInSufficientWithdrawBalance={hasInSufficientWithdrawBalance}
          hasInvalidDepositInput={hasInvalidDepositInput}
          hasInvalidWithdrawInput={hasInvalidWithdrawInput}
        />
      ) : (
        <PoolsCard
          balance={staking.mainnet.aave.v2}
          APY={calculateAPY(
            user.aave,
            user.usdPrices,
            getAPYRatesOnPlatform(rates, "aave")
          )}
          updateParentInput={getInputValues}
          input1Name="deposit"
          input2Name="withdraw"
          defaultSelected1={inputsData.selectdeposit}
          defaultSelected2={inputsData.selectwithdraw}
          onDeposit={onDeposit}
          onWithdraw={onWithdraw}
          platform="aave"
          version="v2"
          versions={["v1", "v2"]}
          handleVersionChange={handleVersionChange}
          selectdeposit={inputsData.selectdeposit}
          selectwithdraw={inputsData.selectwithdraw}
          value1={inputsData.deposit}
          value2={inputsData.withdraw}
          handleMaxInput={HandleMaxInput}
          filterZeroBalance
          filterZeroPlatformBalance
          setOption={setOption}
          selectedOption={selectedOption}
          options={options}
          hasInSufficientDepositBalance={hasInSufficientDepositBalance}
          hasInSufficientWithdrawBalance={hasInSufficientWithdrawBalance}
          hasInvalidDepositInput={hasInvalidDepositInput}
          hasInvalidWithdrawInput={hasInvalidWithdrawInput}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  rates: state.rates.rates,
  staking: state.staking,
  isTransactionManagerOpen: state.transactions.isTransactionManagerOpen,
});

const mapDispatchToProps = (dispatch) => ({
  showTransactionManager: () => dispatch(showTransactionManager()),
  closeTransactionManager: () => dispatch(closeTransactionManager()),
  addTx: (tx) => dispatch(addTx(tx)),
  removeTx: (txHash) => dispatch(removeTx(txHash)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Aave);
