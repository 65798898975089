import React from "react";
import styled from "styled-components";
import { ReactComponent as CopyIcon } from "../assets/copy.svg";
import Button from "./Button";
import { trimAddress, walletsLogos } from "../helpers";
import { breakPoints } from "../theme";
import { useTranslation } from "react-i18next";

const StyledButton = styled(Button)`
  padding: 10px 26px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  background: ${(props) => props.theme.lightBlue3};
  color: ${(props) => props.theme.brandBlue};
  border-radius: 6px;
  border: 0;
  text-transform: capitalize;
  &:hover {
    background: ${(props) => props.theme.brandBlue};
    color: ${(props) => props.theme.white};
  }

  @media (max-width: ${breakPoints.sm}px) {
    padding: 6px 10px;
    font-size: 14px;
  }
`;
const StyledListItem = styled.div`
  font-family: Montserrat;
  color: ${(props) => props.theme.mainDarkText};
  margin-top: 2rem;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .wallet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;

    .wallet__info {
      display: flex;
      align-items: center;
      font-size: 20px;
      line-height: 24px;
      svg,
      img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }

      @media (max-width: ${breakPoints.sm}px) {
        font-size: 14px;
      }
    }

    @media (max-width: ${300}px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .address {
    margin-top: 1.5rem;
    margin-left: 2rem;
    display: flex;
    justify-content: space-between;

    .address__info {
      font-size: 16px;
      line-height: 20px;
    }

    .address__copy {
      cursor: pointer;
      display: flex;
      align-items: center;

      svg {
        margin-right: 7px;
      }
      span {
        color: rgba(59, 71, 78, 0.6);
        font-size: 12px;
        line-height: 15px;
      }

      &:hover {
        span {
          color: ${(props) => props.theme.mainDarkText};
        }
      }
    }

    @media (max-width: ${breakPoints.sm}px) {
      margin-left: 0;
    }
  }
`;

export default function ConnectedWallet(props) {
  const { handleChangeWallet, onDisconnect, wallet, userAddress } = props;
  const { t } = useTranslation();
  return (
    <StyledListItem>
      <div className="wallet">
        <span className="wallet__info">
          {walletsLogos[wallet]}
          {wallet}
        </span>
        <StyledButton secondary onClick={handleChangeWallet}>
          {t("addition.change")}
        </StyledButton>
        {(wallet === "SelfKey" ||
          wallet === "WalletConnect" ||
          wallet === "Metamask") && (
          <StyledButton onClick={onDisconnect}>
            {t("addition.disconnectBtn")}
          </StyledButton>
        )}
      </div>

      <div className="address">
        <span className="address__info">
          {!!userAddress && trimAddress(userAddress)}
        </span>
        <span
          className="address__copy"
          onClick={() => navigator.clipboard.writeText(userAddress)}
        >
          <CopyIcon />
          <span> {t("addition.copyAddress")}</span>
        </span>
      </div>
    </StyledListItem>
  );
}
