import React, { useEffect } from "react";
import { integrations } from "@keyfi/keyfi-common";
import { SwitchToBsc } from "../../helpers";
import Staking from "./BSC/Staking";
import { TabWrapper } from "./shared";

const BSCStaking = () => {
  useEffect(() => {
    SwitchToBsc();
    integrations.resetNetwork();
  }, []);

  return (
    <TabWrapper>
      <Staking />
    </TabWrapper>
  );
};

export default BSCStaking;
