import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import Tippy from "@tippyjs/react/headless";
import { useTranslation } from "react-i18next";

import {
  getAddressSum,
  getContractPlatform,
} from "@keyfi/keyfi-common/src/integrations/common";

// Styles
import { MobileTXCardWrapper } from "./transaction.styles";

// Helpers
import { beautifyTokensAmount, trimAddress } from "../../helpers";
import { breakPoints } from "../../theme";
import { Spinner } from "../../Shared";
import AssetImage from "../Borrow/AssetImage";
import { directionSignCheck, TXTooltip } from "./DesktopTransactionTable";

// Icons
import { ReactComponent as ArrowIcon } from "../../assets/arrow-out.svg";
import { ReactComponent as TXSuccess } from "../../assets/transactions/txSuccess.svg";
import { ReactComponent as TXFailed } from "../../assets/transactions/txFailed.svg";
import { ReactComponent as InfoIcon } from "../../assets/infoIcon.svg";

const Wrapper = styled.div`
  display: none;
  @media (max-width: ${breakPoints.lg}px) {
    display: block;
  }
`;

const MessageWrapper = styled.div`
  padding: 1rem;
  background: ${(props) => props.theme.superLightBlue};
  border: 1px solid ${(props) => props.theme.border2};
  border-radius: 6px;
`;

const MobileTXCard = ({
  data,
  id,
  ETHPrice,
  t,
  network,
  tokenSymbol,
  apiURL,
}) => (
  <MobileTXCardWrapper>
    <div className="mobile_tx_section">
      <div className="mobile_tx_title">{t("transactions.transactionHash")}</div>
      <div className="mobile_tx_value">
        <a
          href={`https://${apiURL}/tx/${data.hash}`}
          target="_blank"
          rel="noreferrer"
          className="mobile_tx_link tx_blue"
        >
          {trimAddress(data.hash)}
          <ArrowIcon />
        </a>
      </div>
    </div>
    <div className="mobile_tx_section">
      <div className="mobile_tx_title">{t("transcation.fromTo")}</div>
      <div className="mobile_tx_value">
        {directionSignCheck(data, id, t)}
        <div className="mobile_tx_direction">
          <a
            href={`https://${apiURL}/tx/${data.from}`}
            target="_blank"
            rel="noreferrer"
            className="mobile_tx_link"
          >
            {trimAddress(data.from)}
            <ArrowIcon />
          </a>
          <a
            href={`https://${apiURL}/tx/${data.to}`}
            target="_blank"
            rel="noreferrer"
            className={`mobile_tx_link ${
              getContractPlatform(data.to) ? "tx_blue" : ""
            }`}
          >
            {getContractPlatform(data.to) ?? trimAddress(data.to)}
            <ArrowIcon />
          </a>
        </div>
      </div>
    </div>
    <div className="mobile_tx_section">
      <div className="mobile_tx_title">{t("addition.token")}</div>
      <div className="mobile_tx_token mobile_tx_value">
        {data.tokenName ? (
          <>
            <AssetImage
              className="mobile_tx_token_image"
              src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
                network.name === "bsc-mainnet" ? "smartchain" : "ethereum"
              }/assets/${getAddressSum(data.contractAddress)}/logo.png`}
              alt={data.tokenSymbol}
            />
            <div>
              <div>{data.tokenName ?? "-"}</div>
              <div className="mobile_tx_token_symbol">{data.tokenSymbol}</div>
            </div>
          </>
        ) : (
          "-"
        )}
      </div>
    </div>
    <div className="mobile_tx_section">
      <div className="mobile_tx_title">{t("transactions.status")}</div>
      <div className="mobile_tx_value">
        {data.isError === "0" || !data.isError ? <TXSuccess /> : <TXFailed />}
      </div>
    </div>
    <div className="mobile_tx_section">
      <div className="mobile_tx_title">{t("transactions.transactionFee")}</div>
      <div className="mobile_tx_value" style={{ display: "block" }}>
        <div>
          {BigNumber(data.gasPrice)
            .times(data.gasUsed)
            .shiftedBy(-18)
            .toFixed(9)}{" "}
          {tokenSymbol}
        </div>
        <div className="tx_blue tx_feevalue">
          {beautifyTokensAmount(
            BigNumber(data.gasPrice)
              .times(data.gasUsed)
              .shiftedBy(-18)
              .times(ETHPrice)
              .toFixed(),
            { prefix: "$", suffix: " USD" }
          )}
          <Tippy
            offset={[100, 5]}
            render={(attrs) => <TXTooltip attrs={attrs} network={network} />}
          >
            <InfoIcon className="tx_info" />
          </Tippy>
        </div>
      </div>
    </div>
    <div className="mobile_tx_section">
      <div className="mobile_tx_title">{t("transactions.gas")}</div>
      <div className="mobile_tx_value" style={{ display: "block" }}>
        <div>
          {BigNumber(data.gasPrice).shiftedBy(-18).toFixed(9)} {tokenSymbol}
        </div>
        <div className="tx_blue">
          {beautifyTokensAmount(
            BigNumber(data.gasPrice).shiftedBy(-9).toFixed(),
            { suffix: " Gwei" }
          )}
        </div>
      </div>
    </div>
    <div className="mobile_tx_section">
      <div className="mobile_tx_title">{t("transactions.value")}</div>
      <div className="mobile_tx_value">
        <div>
          {beautifyTokensAmount(
            BigNumber(data.value)
              .shiftedBy(data.tokenDecimal ? -data.tokenDecimal : -18)
              .toFixed(),
            { suffix: ` ${data.tokenSymbol ?? tokenSymbol}` }
          )}
        </div>
      </div>
    </div>
    <div className="mobile_tx_section">
      <div className="mobile_tx_title">{t("transactions.date")}</div>
      <div className="mobile_tx_value">
        <div>
          {dayjs(data.timeStamp * 1000).format("YYYY/MM/DD")}{" "}
          {dayjs(data.timeStamp * 1000).format("HH:mm:ss")}
        </div>
      </div>
    </div>
  </MobileTXCardWrapper>
);

const MobileTransactionTable = ({
  loading,
  tokenPrice,
  tokenSymbol,
  network,
  apiURL,
  filter,
  tx,
}) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const { id } = user;

  return (
    <Wrapper>
      {loading && (
        <MessageWrapper>
          <Spinner background="superLightBlue" />
        </MessageWrapper>
      )}
      {/* If filters selected AND tx array length === 0 */}
      {tx.length === 0 && !loading && (filter.status || filter.direction) && (
        <MessageWrapper>{t("addition.doNotMeetCriteria")}</MessageWrapper>
      )}
      {/* If tx array length === 0 AND no filters selected */}
      {tx.length === 0 && !loading && !filter.status && !filter.direction && (
        <MessageWrapper>{t("addition.noTranscation")}</MessageWrapper>
      )}
      {!loading &&
        tx.length !== 0 &&
        tx.map((item) => (
          <MobileTXCard
            data={item}
            id={id}
            ETHPrice={tokenPrice}
            tokenSymbol={tokenSymbol}
            apiURL={apiURL}
            network={network}
            t={t}
          />
        ))}
    </Wrapper>
  );
};

export default MobileTransactionTable;
