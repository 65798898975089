import React, { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import styled from "styled-components";
import { integrations } from "@keyfi/keyfi-common";
import { useDispatch } from "react-redux";
import timestamp from "time-stamp";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { beautifyTokensAmount } from "../../helpers";
import Button from "../Button";
import InputGroupv2 from "../InputGroupv2";
import { walletOperations } from "../../redux/walletSlice";
import { getTxHistory } from "../TransactionManager";
import { borrowOperations } from "../../redux/borrowSlice";
import HealthFactorBar from "./HealthFactorBar";
import {
  addTx,
  removeTx,
  showTransactionManager,
} from "../../redux/transactionManager/actions";
import { Storage } from "../../helpers/Storage";

const Wrapper = styled.div`
  padding: 1rem;
  background: ${(props) => props.theme.superLightBlue};
  border-radius: 0 6px 6px 6px;
  border: 1px solid ${(props) => props.theme.border3};
  h3 {
    color: ${(props) => props.theme.brandBlue};
    margin: 0 0 1rem;
    font-size: 14px;
  }
  .borrow_input_container {
    padding-bottom: 2.5rem;
    border-bottom: 1px solid ${(props) => props.theme.border2};
    .borrow_input_labels {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      span {
        font-size: 12px;
      }
    }
    .borrow_input_error {
      margin-top: 0.5rem;
      font-size: 12px;
      color: ${(props) => props.theme.error};
    }
    .borrow_no_deposits {
      background: ${(props) => props.theme.lightBlue2};
      margin: 1rem 0 -1.25rem;
      padding: 1rem;
      border-radius: 6px;
      h3 {
        font-size: 16px;
        margin-bottom: 0.5rem;
      }
      p {
        margin: 0 0 1.5rem;
        font-size: 14px;
      }
      a {
        width: 100%;
        ${Button} {
          padding: 0.75rem 0;
          width: 100%;
          max-width: 225px;
          font-size: 14px;
          margin-top: 0;
        }
      }
    }
  }
  .borrow_rate_options_container {
    margin-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid ${(props) => props.theme.border2};
    .borrow_rate_options {
      display: flex;
      gap: 1.5rem;
      > button {
        flex: 1;
        cursor: pointer;
        padding: 0.5rem;
        background: white;
        border-radius: 6px;
        border: 1px solid ${(props) => props.theme.border2};
        text-align: center;
        * {
          margin: 0;
        }
        h5 {
          font-size: 14px;
        }
        .borrow_rate_option_stable {
          color: ${(props) => props.theme.brandBlue};
        }
        .borrow_rate_option_var {
          color: ${(props) => props.theme.uniswapPink};
        }
        :disabled {
          cursor: default;
          h4 {
            color: darkgray;
          }
        }
      }
      .borrow_rate_selected {
        background: ${(props) => props.theme.borrowSelectedInterest};
        border: 1px solid ${(props) => props.theme.brandBlue};
        box-shadow: ${(props) => props.theme.borrowSelectedInterestShadow};
      }
    }
  }
  ${Button} {
    width: 100%;
    margin-top: 1.5rem;
    padding: 10px 0;
    line-height: normal;
    font-size: 16px;
    height: max-content;
    text-transform: capitalize;
  }
`;

function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}

const BorrowTab = ({
  userBorrowPlatformData,
  params,
  borrowAssetData,
  borrow,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { platform, version, asset } = params;

  const [inputData, setInputData] = useState({
    borrow: "",
    interestRateMode: "2",
    estimateHealthFactor: null,
  });

  const availableToBorrow = BigNumber(
    borrow.mainnet[platform][version].user.availableBorrowsETH
  ).dividedBy(borrowAssetData.priceETH);

  useEffect(() => {
    if (inputData.borrow) {
      const estimatedHealthFactor = integrations[platform][
        version
      ].estimateHealthFactor(
        borrowAssetData,
        inputData.borrow,
        userBorrowPlatformData
      );
      setInputData((prev) => ({
        ...prev,
        estimateHealthFactor: estimatedHealthFactor,
      }));
    } else {
      setInputData((prev) => ({ ...prev, estimateHealthFactor: 0 }));
    }
  }, [inputData.borrow]);

  const handleBorrow = async () => {
    const txHash = [];
    let isFirstTrxFired = false;

    try {
      const response = await integrations[platform][version].borrow(
        asset,
        inputData.borrow,
        {
          interestRateMode: inputData.interestRateMode,
          pendingCallback: (trx) => {
            dispatch(showTransactionManager());
            if (!isFirstTrxFired) {
              dispatch(
                addTx({
                  platform: capitalizeFirstLetter(platform),
                  txType: "Borrow",
                  txAmount: inputData.borrow,
                  txToken: asset,
                  txHash: trx.hash,
                  timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
                })
              );
              isFirstTrxFired = true;
            }
            txHash.push(trx.hash);
          },
        }
      );
      if (response) {
        dispatch(walletOperations.loadUserData());
        dispatch(borrowOperations.getBorrowBalance());
        const HistoryArray = getTxHistory();
        const txObject = {
          platform: capitalizeFirstLetter(platform),
          txType: "Borrow",
          txAmount: inputData.borrow,
          txToken: asset,
          txHash: response.transactionHash,
          timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
        };
        HistoryArray.unshift(txObject);
        Storage.setItem("transactions", JSON.stringify(HistoryArray));
        dispatch(removeTx(txHash));
      }
    } catch (err) {
      dispatch(showTransactionManager());
      const HistoryArray = getTxHistory();
      const txObject = {
        platform: capitalizeFirstLetter(platform),
        txType: "Borrow",
        txAmount: inputData.borrow,
        txToken: asset,
        txHash: txHash[txHash.length - 1],
        timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
        status: "failed",
      };
      HistoryArray.unshift(txObject);
      Storage.setItem("transactions", JSON.stringify(HistoryArray));
      dispatch(removeTx(txHash));
    }
  };

  const getInputValues = (name, value) => {
    setInputData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleMaxInput = () => {
    if (availableToBorrow) {
      setInputData((prevState) => ({
        ...prevState,
        borrow: availableToBorrow,
      }));
    }
  };
  return (
    <Wrapper>
      <h3>{t("borrow.borrowTab.title")}</h3>
      <div className="borrow_input_container">
        <div className="borrow_input_labels">
          <span>{t("borrow.borrowTab.input")}</span>
          <span>
            {t("borrow.borrowTab.availableToBorrow")}:{" "}
            {beautifyTokensAmount(availableToBorrow ?? 0, {
              suffix: ` ${asset}`,
            })}{" "}
          </span>
        </div>

        <InputGroupv2
          name="borrow"
          updateParent={getInputValues}
          value={inputData.borrow}
          error={+inputData.borrow > +availableToBorrow}
          invokeMaxInput={handleMaxInput}
          readOnly={userBorrowPlatformData.availableBorrowsETH === "0"}
          disableOptions
        />
        {+inputData.borrow > +availableToBorrow && (
          <div className="borrow_input_error">{t("addition.overTheLimit")}</div>
        )}

        {userBorrowPlatformData.availableBorrowsETH !== "0" ? (
          <HealthFactorBar
            currentHealthFactor={userBorrowPlatformData.healthFactor}
            estimateHealthFactor={inputData.estimateHealthFactor}
          />
        ) : (
          <div className="borrow_no_deposits">
            <h3>{t("borrow.borrowTab.noDeposits.title")}</h3>
            <p>{t("borrow.borrowTab.noDeposits.description")}</p>
            <Link to={`/deposit/${platform}?asset=${asset}&v=${version}`}>
              <Button primary>
                {t("borrow.borrowTab.noDeposits.depositBtn")}
              </Button>
            </Link>
          </div>
        )}
      </div>
      {userBorrowPlatformData.availableBorrowsETH !== "0" && (
        <div className="borrow_rate_options_container">
          <h3>{t("borrow.asset.select interest rate")}</h3>
          <div className="borrow_rate_options">
            <button
              className={
                inputData.interestRateMode === "1"
                  ? " borrow_rate_selected"
                  : ""
              }
              disabled={!borrowAssetData.stableBorrowRateEnabled}
              onClick={() =>
                setInputData((prev) => ({ ...prev, interestRateMode: "1" }))
              }
            >
              <h5>{t("borrow.stableAPY")}</h5>
              <h4 className="borrow_rate_option_stable">
                {borrowAssetData.stableBorrowRateEnabled
                  ? beautifyTokensAmount(borrowAssetData.stableAPY * 100, {
                      suffix: "%",
                      percent: true,
                    })
                  : "-"}
              </h4>
            </button>
            <button
              className={
                inputData.interestRateMode === "2"
                  ? " borrow_rate_selected"
                  : ""
              }
              onClick={() =>
                setInputData((prev) => ({ ...prev, interestRateMode: "2" }))
              }
            >
              <h5>{t("borrow.variableAPY")}</h5>
              <h4 className="borrow_rate_option_var">
                {beautifyTokensAmount(borrowAssetData.variableAPY * 100, {
                  suffix: "%",
                  percent: true,
                })}
              </h4>
            </button>
          </div>
        </div>
      )}

      <Button
        primary
        onClick={handleBorrow}
        disabled={!inputData.borrow || +inputData.borrow > +availableToBorrow}
      >
        {t("borrow.title")}
      </Button>
    </Wrapper>
  );
};

export default BorrowTab;
