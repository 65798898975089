import React, { useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import TxContentCard from "./TxContentCard";
import {
  showTransactionManager,
  closeTransactionManager,
} from "../redux/transactionManager/actions";
import Modal from "./Modal";
import { breakPoints } from "../theme";
import { ReactComponent as Arrow } from "../assets/arrow-blue.svg";
import { ReactComponent as LastArrow } from "../assets/last-arrow.svg";

const Wrapper = styled.div`
  table {
    width: 100%;
  }
  .wallet-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: ${breakPoints.lg}px) {
    padding: 0;
  }
`;

const StyledTitle = styled.div`
  background: ${(props) => props.theme.lightBlue3};
  color: ${(props) => props.theme.brandBlue};
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  padding: 0.5em 1em;
  width: fit-content;
  margin: 1.5em 0 1em;
`;

const THeader = styled.thead`
  border: 1px solid ${(props) => props.theme.brandBlue};
  color: ${(props) => props.theme.brandBlue};
  font-size: 12px;
  th {
    border-bottom: 1px solid ${(props) => props.theme.brandBlue};
    font-weight: 500;
    padding: 8px 12px 8px 8px;
  }

  @media (max-width: ${breakPoints.lg}px) {
    display: none;
  }
`;

const TBody = styled.tbody`
  color: ${(props) => props.theme.mainDarkText};
  font-size: 12px;

  .pending-row {
    padding: 16px;
  }

  tr {
    :nth-of-type(even) {
      background: ${(props) => props.theme.lightBlue3};
    }
  }

  tr td {
    border-bottom: 1px solid ${(props) => props.theme.lightBlue3};
    padding: 12px;

    @media (max-width: ${breakPoints.lg}px) {
      padding: 0;

      tr td:not(.last) {
        border-bottom: 1px solid ${(props) => props.theme.brandBlue};
      }
    }
  }

  td {
    font-size: 12px;
    font-weight: 500;

    @media (max-width: ${breakPoints.lg}px) {
      font-size: 16px;
    }

    img {
      height: 24px;
      width: 24px;
    }
  }

  .status-failed,
  .status-success {
    font-size: 12px;
    font-weight: 500;
  }

  .status-failed {
    color: ${(props) => props.theme.red1};
  }

  .status-success {
    color: ${(props) => props.theme.green};
  }
`;

export const Pagination = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button,
  div {
    display: flex;
    align-items: center;
    width: 30px;
    height: 30px;
    margin-left: 5px;
    border-radius: 5px;
  }
  button {
    padding: 0;
    cursor: pointer;
    background: none;
    border: 1px solid ${(props) => props.theme.border};
    :hover {
      background: ${(props) => props.theme.lightBlue2};
      border-color: ${(props) => props.theme.brandBlue};
    }
    svg {
      width: 100%;
      height: 100%;
      padding: 7px;
    }
  }
  div {
    background: ${(props) => props.theme.lightBlue2};
    color: ${(props) => props.theme.brandBlue};
    font-weight: 500;
    justify-content: center;
    border: 1px solid ${(props) => props.theme.brandBlue};
  }
`;

export const getTxHistory = () => {
  return JSON.parse(localStorage.getItem("transactions")) || [];
};

const PAGESIZE = 5;

function TransactionManager({
  open,
  txData,
  txHistory,
  closeTransactionManager,
}) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const tableHeadItems = [
    t("addition.Tx Hash"),
    t("addition.Platform"),
    t("addition.Status"),
    t("addition.Type"),
    t("addition.Token"),
    t("addition.Amount"),
    t("transactions.value"),
    t("addition.Date"),
  ];

  const TableHeader = () => {
    return (
      <THeader>
        <tr>
          {tableHeadItems.map((data, i) => (
            <th
              key={i}
              style={
                i === tableHeadItems.length - 1 ? { textAlign: "right" } : null
              }
            >
              {data}
            </th>
          ))}
        </tr>
      </THeader>
    );
  };

  return (
    <Modal
      open={open}
      width="100%"
      title={t("transactions.title")}
      maxwidth="942px"
      handleCloseModal={closeTransactionManager}
    >
      <Wrapper>
        <StyledTitle>{t("addition.pendingTransactions")}</StyledTitle>
        <table>
          <TableHeader />
          <TBody>
            {txData && txData.length ? (
              txData.map((tx, i) => (
                <TxContentCard
                  className="info-card"
                  key={i}
                  {...tx}
                  pending
                  tableHeadItems={tableHeadItems}
                />
              ))
            ) : (
              <tr>
                <td colSpan="8" className="pending-row">
                  {t("addition.noCurrentPendingTransaction")}
                </td>
              </tr>
            )}
          </TBody>
        </table>

        <StyledTitle>{t("addition.transactionsHistory")}</StyledTitle>
        <table>
          <TableHeader />
          <TBody>
            {txHistory && txHistory.length ? (
              txHistory.map(
                (tx, i) =>
                  i < PAGESIZE + page * PAGESIZE &&
                  i >= page * PAGESIZE && (
                    <TxContentCard
                      key={i}
                      {...tx}
                      tableHeadItems={tableHeadItems}
                    />
                  )
              )
            ) : (
              <tr>
                <td colSpan="8" className="pending-row">
                  {t("addition.noRecentTransactionsCompleted")}
                </td>
              </tr>
            )}
          </TBody>
        </table>
      </Wrapper>
      <Pagination>
        {page !== 0 && (
          <>
            <button onClick={() => setPage(0)}>
              <LastArrow style={{ transform: "rotate(180deg)" }} />
            </button>
            <button onClick={() => setPage(page - 1)}>
              <Arrow style={{ transform: "rotate(180deg)" }} />
            </button>
          </>
        )}
        {txHistory.length !== 0 && <div>{page + 1}</div>}
        {txHistory.length > (page + 1) * PAGESIZE && (
          <>
            <button onClick={() => setPage(page + 1)}>
              <Arrow />
            </button>
            <button
              onClick={() => setPage(Math.floor(txHistory.length / PAGESIZE))}
            >
              <LastArrow className="pagination_last" />
            </button>
          </>
        )}
      </Pagination>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  open: state.transactions.isTransactionManagerOpen,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  showTransactionManager: () => dispatch(showTransactionManager()),
  closeTransactionManager: () => dispatch(closeTransactionManager()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionManager);
