import React from "react";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  beautifyTokensAmount,
  poolsLogos,
  tokensLogos,
} from "../../../../helpers";
import {
  PlatformRemoveLiquidityWrapper,
  RemoveLiquidityData,
} from "../../SharedLiquidity";

// Assets
import { ReactComponent as ArrowBack } from "../../../../assets/arrow-back-dark.svg";

// Versions
import V1 from "./v1";

const CurveRemoveLiquidity = () => {
  const { t } = useTranslation();

  const liquidity = useSelector((state) => state.liquidity);
  const { mainnet } = liquidity;

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const [input1, input2] = [query.get("input1"), query.get("input2")];
  const liquidityData = mainnet.curve.v1.find(
    (item) => item.assetA === input1 && item.assetB === input2
  );

  return (
    <>
      <PlatformRemoveLiquidityWrapper>
        <Link to="/pools/my-liquidity/curve" className="remove-liquidity-back">
          <button>
            <ArrowBack /> {t("common.back")}
          </button>
        </Link>
        <h2>{t("common.removeLiquidity")}</h2>
        <div className="remove-liquidity-description">
          {t("addition.removeLiquidityDescription")}{" "}
        </div>
        <div className="remove-liquidity-platform-container">
          <div className="remove-liquidity-platform-label">
            {t("dashboard.platform.platform")}
          </div>
          <div className="remove-liquidity-platform">
            {poolsLogos["Curve"]}
            <h4>Curve</h4>
            <div className="remove-liquidity-platform-network">ETH</div>
          </div>
        </div>
        <V1 liquidityData={liquidityData} />
      </PlatformRemoveLiquidityWrapper>
      <RemoveLiquidityData>
        <div className="remove-liquidity-data-line">
          <div>
            {tokensLogos[input1]}
            {t("addition.pooledToken", { token: input1 })}
          </div>
          <div>{beautifyTokensAmount(liquidityData[input1])}</div>
        </div>
        <div className="remove-liquidity-data-line">
          <div>
            {tokensLogos[input2]}
            {t("addition.pooledtoken", { token: input2 })}
          </div>
          <div>{beautifyTokensAmount(liquidityData[input2])}</div>
        </div>
        <div className="remove-liquidity-data-line">
          <div>{t("addition.yourPoolShare")}</div>
          <div>
            {parseFloat(liquidityData.liquidityPercent) * 100 > 0.01
              ? beautifyTokensAmount(
                  parseFloat(liquidityData.liquidityPercent) * 100
                )
              : "<0.01"}
            %
          </div>
        </div>
      </RemoveLiquidityData>
    </>
  );
};

export default CurveRemoveLiquidity;
