import React, { useState } from "react";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { beautifyTokensAmount, tokensLogos } from "../../../../helpers";
import { RemoveLiquidityBody } from "../../SharedLiquidity";
import InputRange from "../../SharedLiquidity/InputRange";
import { useTranslation } from "react-i18next";

const V3 = ({ liquidityData }) => {
  const { search } = useLocation();
  const { t } = useTranslation();
  const query = new URLSearchParams(search);

  const [input, setInput] = useState(0);

  const [input1, input2] = [query.get("input1"), query.get("input2")];

  const input1Return = useMemo(
    () => beautifyTokensAmount(liquidityData[input1] * (input / 100)),
    [input1, liquidityData, input]
  );

  const input2Return = useMemo(
    () => beautifyTokensAmount(liquidityData[input2] * (input / 100)),
    [input2, liquidityData, input]
  );

  const handleInputChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    if (value > 100) {
      return setInput(100);
    }
    return setInput(value);
  };

  return (
    <>
      <div className="remove-liquidity-tokens">
        <div className="remove-liquidity-tokens-logos">
          {tokensLogos[input1]}
          {tokensLogos[input2]}
          {input1}:{input2}
        </div>
        <span className="remove-liquidity-token-liquidity">
          {beautifyTokensAmount(liquidityData.liquidity)}
        </span>
      </div>
      <RemoveLiquidityBody>
        <div className="remove-liquidity-input-container">
          <div className="remove-liquidity-input-label">
            {t("addition.amountToRemove")}
          </div>
          <div className="remove-liquidity-input">
            <input
              value={input}
              onChange={handleInputChange}
              max="100"
              min="0"
            />{" "}
            %
          </div>
        </div>
        <div className="remove-liquidity-slider">
          <InputRange
            type="range"
            min="0"
            max="100"
            value={input}
            onChange={(e) => setInput(parseFloat(e.target.value))}
            step="1"
          />
        </div>
        <div className="remove-liquidity-options">
          <button
            className={input === 25 && "remove-liquidity-option-selected"}
            onClick={() => setInput(25)}
          >
            25%
          </button>
          <button
            className={input === 50 && "remove-liquidity-option-selected"}
            onClick={() => setInput(50)}
          >
            50%
          </button>
          <button
            className={input === 75 && "remove-liquidity-option-selected"}
            onClick={() => setInput(75)}
          >
            75%
          </button>
          <button
            className={input === 100 && "remove-liquidity-option-selected"}
            onClick={() => setInput(100)}
          >
            {t("swap.max")}
          </button>
        </div>
        <div className="remove-liquidity-data">
          <div className="remove-liquidity-data-item">
            <div>
              {tokensLogos[input1]}{" "}
              {t("addition.tokenYouWillReceive", { token: input1 })}
            </div>
            <div>{input1Return}</div>
          </div>
          <div className="remove-liquidity-data-item">
            <div>
              {tokensLogos[input2]}{" "}
              {t("addition.tokenYouWillReceive", { token: input2 })}
            </div>
            <div>{input2Return}</div>
          </div>
        </div>
        <button className="remove-liquidity-button">
          {t("addition.removeLiquidity")}
        </button>
      </RemoveLiquidityBody>
    </>
  );
};

export default V3;
