import React, { useState } from "react";
import styled, { css } from "styled-components";
import DrwopDown from "./DropDown";
import { useTranslation } from "react-i18next";
import { breakPoints } from "../theme";

const Wrapper = styled.div`
  font-family: "Montserrat";
  font-weight: 500;
  background: ${(props) => props.theme.white};
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "79px")};
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid
    ${(props) =>
      props.error ? `${props.theme.error} !important` : props.theme.lightBlue3};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  padding: 0.7rem 0 0.7rem 0.7rem;
  @media (max-width: ${breakPoints.lg}px) {
    width: 100%;
  }

  @media (max-width: ${breakPoints.md}px) {
    height: ${(props) => (props.height ? props.height : "63px")};
    padding: 8px;
  }

  ${(props) =>
    props.error &&
    css`
      ${StyledInput} {
        color: ${(props) => props.theme.error};
      }
    `}
  :focus-within {
    border-color: ${(props) => props.theme.brandBlue};
    box-shadow: ${(props) =>
      !props.error && "0px 0px 6px rgba(0, 90, 211, 0.25)"};
  }
`;

const StyledInput = styled.input`
  font-family: "Montserrat";
  width: calc(100% - 200px);
  outline: 0;
  border: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  height: 34px;
  color: ${(props) => props.theme.mainDarkText};
  margin: ${(props) => (props.margin ? props.margin : 0)};

  @media (max-width: ${breakPoints.md}px) {
    height: 20px;
    line-height: 0;
    width: calc(100% - 150px);
  }

  ::placeholder {
    color: ${(props) => props.theme.placeholder};
  }
`;

const StyledMax = styled.span`
  background: ${(props) => props.theme.mainDarkText};
  color: ${(props) => props.theme.bg};
  border-radius: 6px;
  padding: 6px 10px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  :hover {
    background: ${(props) => props.theme.brandBlack};
  }

  @media (max-width: ${breakPoints.md}px) {
    font-size: 12px;
  }
`;

const SelectionWrap = styled.div`
  position: absolute;
  right: 1rem;
  display: flex;
  align-items: center;
`;

export default function InputGroup(props) {
  const { t } = useTranslation();
  const [showMax, setShowMax] = useState(false);
  const {
    placeholder,
    updateParent,
    name,
    value,
    defaultSelected,
    platform,
    version,
    noOptions,
    invokeMaxInput,
    filterZeroBalance,
    filterZeroPlatformBalance,
    readOnly,
    showAll,
  } = props;

  const onChange = (e) => {
    // filter non-numric input values
    let str = "";
    if (e.target.value.match(/\d|\./g)) {
      str = e.target.value.match(/\d|\./g).join("");
    }

    updateParent(e.target.name, str);
  };

  return (
    <Wrapper {...props}>
      <StyledInput
        onChange={onChange}
        autoComplete="off"
        placeholder={placeholder || "0.0"}
        name={name}
        value={value}
        readOnly={readOnly}
      />
      <SelectionWrap>
        {invokeMaxInput && (showMax || defaultSelected) && (
          <StyledMax onClick={() => invokeMaxInput(name)}>
            {t("pool.addLiquidity.max")}
          </StyledMax>
        )}
        <DrwopDown
          updateParent={(name, value) => {
            updateParent(name, value);
            value && setShowMax(true);
          }}
          name={`select${name}`}
          defaultSelected={defaultSelected}
          platform={platform}
          version={version}
          noOptions={noOptions}
          filterZeroBalance={filterZeroBalance}
          filterZeroPlatformBalance={filterZeroPlatformBalance}
        />
      </SelectionWrap>
    </Wrapper>
  );
}
