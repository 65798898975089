import styled, { css } from "styled-components";
import { breakPoints } from "../../theme";

export const TransactionTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  position: relative;
  overflow: auto;
  @media (max-width: ${breakPoints.lg}px) {
    display: none;
  }
  thead {
    border-bottom: 1px solid ${(props) => props.theme.brandBlue};
    th {
      color: ${(props) => props.theme.brandBlue};
    }
  }
  th,
  td {
    text-align: left;
    padding: 0.75rem 0.4rem;
    font-size: 12px;
    a {
      text-decoration: underline;
      color: ${(props) => props.theme.mainDarkText};
      .hash {
        svg {
          margin-left: 4px;
        }
      }
    }
    .success {
      color: ${(props) => props.theme.green};
    }
    .failed {
      color: ${(props) => props.theme.error};
    }
    :first-of-type {
      padding-left: 1rem;
    }
    :last-of-type {
      text-align: right;
      padding-right: 1rem;
      .transaction_th {
        justify-content: flex-end;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid ${(props) => props.theme.lightBlue2};
      font-weight: 500;
      :nth-child(2n) {
        background: ${(props) => props.theme.superLightBlue};
      }
    }
  }
`;

export const StyledTableCell = styled.td`
  > div {
    display: flex;
    align-items: center;
    .transaction_txhash {
      display: flex;
      align-items: center;
      font-size: 12px;
      svg {
        margin-left: 2px;
        height: 8.5px;
        width: 8.5px;
      }
    }
    .tx_token {
      display: flex;
      align-items: center;
      font-size: 12x;
      .tx_token_image {
        height: 24px;
        width: 24px;
        font-size: 18px;
        margin-right: 6px;
      }
      .tx_token_symbol {
        color: ${(props) => props.theme.mainDarkText};
        opacity: 0.7;
      }
    }
    .tx_success {
      color: ${(props) => props.theme.txGreen};
      margin-left: 0.25rem;
    }
    .tx_failed {
      color: ${(props) => props.theme.txError};
      margin-left: 0.25rem;
    }
    ${(props) =>
      props.txHash &&
      css`
        justify-content: space-between;
      `}
    .tx_info {
      height: 11px;
      cursor: pointer;
    }
  }
  .tx_blue {
    color: ${(props) => props.theme.brandBlue};
  }
`;

export const DirectionSign = styled.div`
  min-width: 30px;
  font-size: 10px;
  font-weight: 600;
  line-height: 19px;
  text-align: center;
  border-radius: 3px;
  ${(props) =>
    props.in &&
    css`
      background: ${(props) => props.theme.txGreen};
      color: white;
    `}
  ${(props) =>
    props.out &&
    css`
      background: ${(props) => props.theme.warning};
    `}
    ${(props) =>
    props.self &&
    css`
      background: #ebecee;
    `}
`;

export const MobileTXCardWrapper = styled.div`
  padding: 1rem 0.75rem;
  margin-bottom: 1.5rem;
  border-radius: 6px;
  background: ${(props) => props.theme.superLightBlue};
  border: 1px solid ${(props) => props.theme.border2};
  .mobile_tx_section {
    display: flex;
    align-items: center;
    max-height: 46px;
    :not(:first-of-type) {
      padding-top: 0.75rem;
    }
    :not(:last-of-type) {
      border-bottom: 1px solid ${(props) => props.theme.border2};
      padding-bottom: 0.75rem;
    }
    > div {
      flex: 1;
    }
    .mobile_tx_title {
      color: ${(props) => props.theme.brandBlue};
      font-size: 14px;
    }
    .mobile_tx_value {
      display: flex;
      font-size: 12px;
      align-items: center;
      .mobile_tx_link {
        text-decoration: underline;
        color: ${(props) => props.theme.mainDarkText};
        svg {
          margin-left: 2px;
          height: 9px;
          width: 9px;
        }
      }
      .mobile_tx_direction {
        margin-left: 0.5rem;
        a {
          display: block;
        }
      }
      .tx_success {
        color: ${(props) => props.theme.txGreen};
        margin-left: 0.25rem;
      }
      .tx_failed {
        color: ${(props) => props.theme.txError};
        margin-left: 0.25rem;
      }
      .tx_blue {
        color: ${(props) => props.theme.brandBlue};
      }
      .tx_feevalue {
        display: flex;
        align-items: center;
      }
    }
    .mobile_tx_token {
      .mobile_tx_token_image {
        height: 24px;
        width: 24px;
        font-size: 18px;
        margin-right: 6px;
      }
      .mobile_tx_token_symbol {
        opacity: 0.7;
      }
    }
    .tx_info {
      height: 11px;
      width: 11px;
      margin-left: 0.25rem;
    }
  }
`;

export const TXTooltipWrapper = styled.div`
  font-size: 12px;
  padding: 0.25rem 0.5rem;
  background: ${(props) => props.theme.lightBlue2};
  color: ${(props) => props.theme.brandBlue};
  border: 1px solid ${(props) => props.theme.brandBlue};
  border-radius: 4px;
`;
