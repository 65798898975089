import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  border-radius: 6px;
  padding: 1rem;
  background: ${(props) => props.theme.lightBlue3};
  font-size: 16px;
  line-height: 19.5px;
  color: ${(props) => props.theme.mainDarkText};
  a {
    display: inline-block;
    background: ${(props) => props.theme.brandBlue};
    color: white;
    width: 100%;
    max-width: 225px;
    margin-top: 1rem;
    padding: 10px;
    border-radius: 6px;
    text-align: center;
    :hover {
      background: ${(props) => props.theme.primaryButtonHover};
    }
  }
  .no-asset-link-disabled {
    pointer-events: none;
    background: ${(props) => props.theme.disabled};
  }
`;

const NoAssetContainer = ({ platform, version, disabled = false }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div>{t("pool.yourLiquidity.warningMsg")}</div>
      <Link
        to={`/pools/add-liquidity/${platform}${version ? `?v=${version}` : ""}`}
        className={disabled ? "no-asset-link-disabled" : ""}
      >
        {t("pool.yourLiquidity.addLiquiditybtn")}
      </Link>
    </Wrapper>
  );
};

export default NoAssetContainer;
