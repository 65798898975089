import { createGlobalStyle } from "styled-components";
import { breakPoints } from "./index";

export const GlobalStyles = createGlobalStyle`
  body {
    position: relative;
    margin:0;
    padding:0;
    width:100%;
    min-height: 100vh;
    background: ${({ theme }) => theme.bg};
    color: ${({ theme }) => theme.mainDarkText};
    font-family: 'Montserrat', sans-serif;
  }
  
  button {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
  }

  a {
      text-decoration: none;
    }

  * {
    
      box-sizing: border-box;
    }

  svg {
      overflow: visible
    }

 .tippy-box{
    outline:none !important;
    max-width:400px !important;
  }


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


input[type=number] {
  -moz-appearance: textfield;
  }

[data-reach-dialog-overlay]{
  overflow:hidden;
}
[data-reach-dialog-content]{
    @media (max-width: ${breakPoints.sm}px) {
      padding:1rem;
    }
}
  
  `;
