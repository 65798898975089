import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as ChevronDownIcon } from "../assets/header/chevron-down.svg";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Modal from "./Modal";
import { useHistory } from "react-router";

const DropDownWrapper = styled.div``;
const StyledSelect = styled.div`
  width: 200px;
  height: 42px;
  padding: 10px;
  color: ${(props) => props.theme.mainDarkText};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.border2};
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;

  .selectedOption {
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: normal;

    svg,
    img {
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }
  }

  .selected__arrow {
    margin-left: auto;
    svg {
      fill: ${(props) => props.theme.brandBlue};
      width: 17px;
      height: 10px;
    }
  }
`;

const StyledOptions = styled.div`
  margin-top: 32px;
  color: ${(props) => props.theme.mainDarkText};
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  justify-content: flex-start;
  flex-direction: column;
  max-height: 350px;
  min-height: 350px;
  overflow-y: auto;
  span {
    margin-left: 5px;
  }
  svg,
  img {
    width: 32px !important;
    height: 32px !important;
  }
`;

const StyledOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  height: 56px;
  cursor: pointer;

  color: ${(props) => props.theme.mainDarkText};
  font-size: 16px;
  line-height: 20px;
  .token-info {
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: normal;
  }

  .token-info span {
    margin-left: 0.5em;
  }
  :hover {
    background: rgba(212, 231, 255, 0.7);
    border-radius: 6px;
  }

  border-top: 1px solid ${(props) => props.theme.border2};
`;

function DropDown({ options, selectedOption, setOption }) {
  const history = useHistory();
  const { t } = useTranslation();
  const [showOptions, setShowOptions] = useState(false);

  const handleShowOptions = () => {
    setShowOptions(!showOptions);
  };

  // handle select an option from the dropdown
  const handleSelect = (value) => {
    setOption(value);
    setShowOptions(false);
  };

  return (
    <DropDownWrapper>
      <StyledSelect onClick={handleShowOptions} selectedOption={selectedOption}>
        <span className="selectedOption">
          {selectedOption.icon}
          {selectedOption.text}
        </span>
        <span className="selected__arrow">
          <ChevronDownIcon />
        </span>
      </StyledSelect>
      {showOptions && (
        <Modal
          handleCloseModal={() => setShowOptions(false)}
          width="400px"
          title={t("borrow.selectPlatform")}
        >
          <StyledOptions>
            {options.map((option) => (
              <StyledOption
                key={option.text}
                onClick={() => handleSelect(option.value)}
              >
                <div className="token-info">
                  {option.icon}
                  <span>{option.text}</span>
                </div>
              </StyledOption>
            ))}
          </StyledOptions>
        </Modal>
      )}
    </DropDownWrapper>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(DropDown);
