import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { beautifyTokensAmount, calculateTotal } from "../../helpers";
import { breakPoints } from "../../theme";
import { Skeleton } from "../../Shared";

const Wrapper = styled.div`
  width: 376px;
  height: 120px;
  background: linear-gradient(277.85deg, #0a1727 -0.21%, #002454 91.73%);
  color: ${(props) => props.theme.white};
  margin: 32px 0px 54px;
  padding: 24px 17px 28px 23px;
  border-radius: 6px;

  @media (max-width: ${breakPoints.md}px) {
    width: 100%;
  }

  @media (max-width: ${breakPoints.xl}px) {
    margin: 1rem 0px 16px;
  }

  .head {
    display: block;
    color: ${(props) => props.theme.white};
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 12px;
  }

  .value {
    display: block;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 27px;
    line-height: 33px;
  }
`;

function TotalPortfolioCard({ user }) {
  const { t } = useTranslation();
  const isFetching = useSelector((state) => state.app.isLoading);

  return (
    <Wrapper>
      <span className="head">{t("dashboard.total")}</span>
      <span className="value">
        {!isFetching ? (
          beautifyTokensAmount(
            calculateTotal(user.totalTokens, user.usdPrices),
            { suffix: " USD", prefix: "$" }
          )
        ) : (
          <Skeleton />
        )}
      </span>
    </Wrapper>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(TotalPortfolioCard);
