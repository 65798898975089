import styled, { css } from "styled-components";
import { breakPoints } from "../theme";

const Button = styled.button`
  font-size: 20px;
  line-height: 24px;
  height: 48px;
  text-align: center;
  border-radius: 6px;
  outline: 0;
  border: 0;
  padding: 10px 0;
  cursor: pointer;
  text-transform: uppercase;

  ${(props) =>
    props.primary &&
    css`
      color: ${(props) => props.theme.white};
      background: ${(props) => props.theme.brandBlue};

      :hover,
      :focus {
        background: ${(props) => props.theme.primaryHover};
        color: ${(props) => props.theme.white};
      }
    `}

  ${(props) =>
    props.secondary &&
    css`
      color: ${(props) => props.theme.brandBlue};
      background: ${(props) => props.theme.lightBlue3};
      border: 1px solid ${(props) => props.theme.brandBlue};

      :hover,
      :focus {
        background: ${(props) => props.theme.lightBlue4};
      }
    `}

    ${(props) =>
    props.disabled &&
    css`
      color: ${(props) => props.theme.white};
      background: ${(props) => props.theme.placeholder2};
      border: 0;
      cursor: unset;
      :hover,
      :focus {
        color: ${(props) => props.theme.white};
        background: ${(props) => props.theme.placeholder2};
      }
    `}


  @media (max-width: ${breakPoints.sm}px) {
    height: 35px;
    font-size: 1rem;
    padding: 6px 0;
  }
`;

export default Button;
