import React from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import ApeSwapRemoveLiquidity from "../components/LiquidityPools/RemoveLiquidity/ApeSwap";
import CurveRemoveLiquidity from "../components/LiquidityPools/RemoveLiquidity/Curve";
import PancakeSwapRemoveLiquidity from "../components/LiquidityPools/RemoveLiquidity/PancakeSwap";
import UniswapRemoveLiquidity from "../components/LiquidityPools/RemoveLiquidity/Uniswap";

const Wrapper = styled.div`
  width: 100%;
  max-width: 570px;
  margin: 0 auto 2rem;
`;

const handleRemoveLiquidityScreen = (platform) => {
  switch (platform) {
    case "apeswap":
      return <ApeSwapRemoveLiquidity />;
    case "pancakeswap":
      return <PancakeSwapRemoveLiquidity />;
    case "curve":
      return <CurveRemoveLiquidity />;
    case "uniswap":
      return <UniswapRemoveLiquidity />;
    default:
      break;
  }
};

const RemoveLiquidity = () => {
  const { platformCode } = useParams();

  return <Wrapper>{handleRemoveLiquidityScreen(platformCode)}</Wrapper>;
};

export default RemoveLiquidity;
