import React, { useEffect, useState } from "react";
import axios from "axios";

const AssetImage = ({ alt, className, src, fallbackLogo }) => {
  const [error, setError] = useState(true);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        await axios.get(src);
        setError(false);
      } catch (err) {
        setError(true);
      }
    };
    if (src) {
      fetchImage();
    }
  }, [src]);

  if (!error) {
    return <img alt={alt} className={className} src={src} />;
  }
  if (fallbackLogo) {
    return fallbackLogo;
  }
  return (
    <span
      className={className}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      ?
    </span>
  );
};

export default AssetImage;
