import React from "react";
import styled from "styled-components";
import BigNumber from "bignumber.js";
import { connect } from "react-redux";
import { beautifyTokensAmount } from "../helpers";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  height: 125px;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.border2};

  background: ${(props) => props.theme.superLightBlue};
  display: flex;
  .item {
    font-family: Montserrat;
    position: relative;
    padding: 24px;
    width: 127px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid ${(props) => props.theme.border2};

    :last-child {
      border-right: 0;
    }
    .title {
      color: ${(props) => props.theme.brandBlue};
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 4px;
      font-size: 14px;
      line-height: 17px;
    }

    .units {
      font-size: 14px;
      line-height: 17px;
      color: ${(props) => props.theme.blue2};
      margin-bottom: 4px;
    }

    .value {
      font-size: 14px;
      line-height: 17px;
      color: ${(props) => props.theme.brandBlue};
      margin-bottom: 4px;
    }
    .color {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

    .green {
      background: #22df90;
    }
    .orange {
      background: #ffd700;
    }

    .red {
      background: #ff6400;
    }
  }
`;

function GasCard({ gasPrices, user }) {
  const requiredGas = 15000;

  const getGasUSDPrice = (type) => {
    if (user.usdPrices) {
      const priceOf1GasInETH = BigNumber(user.usdPrices.ETH)
        .shiftedBy(-9)
        .toFixed();

      const priceOfGasForETHInUsd = BigNumber(priceOf1GasInETH)
        .multipliedBy(requiredGas)
        .multipliedBy(gasPrices[type] / 10)
        .toFixed();

      if (isNaN(priceOfGasForETHInUsd)) return null;

      return priceOfGasForETHInUsd;
    }
    return 0;
  };

  const USDPriceFastest = beautifyTokensAmount(getGasUSDPrice("fastest"), {
    suffix: " USD",
    percent: true,
  });
  const USDPriceAverage = beautifyTokensAmount(getGasUSDPrice("average"), {
    suffix: " USD",
    percent: true,
  });
  const USDPriceSafeLow = beautifyTokensAmount(getGasUSDPrice("safeLow"), {
    suffix: " USD",
    percent: true,
  });

  const { t } = useTranslation();
  return (
    <Wrapper>
      <div className="item">
        <span className="color green" />
        <span className="title">{t("addition.fastest")}</span>
        <span className="units">
          {!!gasPrices && `${gasPrices.fastest / 10} gwei`}
        </span>
        <span className="value">
          {!!USDPriceFastest && `${USDPriceFastest}`}
        </span>

        <span className="units">
          {!!gasPrices.fastWait &&
            `${gasPrices.fastWait} ${t("addition.minute")}`}
        </span>
      </div>
      <div className="item">
        <span className="color orange" />
        <span className="title">{t("addition.average")}</span>
        <span className="units">
          {!!gasPrices && `${gasPrices.average / 10} gwei`}
        </span>
        <span className="value">
          {!!USDPriceAverage && `${USDPriceAverage}`}
        </span>
        <span className="units">
          {!!gasPrices.avgWait &&
            `${gasPrices.avgWait} ${t("addition.minute")}`}
        </span>
      </div>

      <div className="item">
        <span className="color red" />
        <span className="title">{t("addition.safeLow")}</span>
        <span className="units">
          {!!gasPrices && `${gasPrices.safeLow / 10} gwei`}
        </span>

        <span className="value">
          {!!USDPriceSafeLow && `${USDPriceSafeLow}`}
        </span>

        <span className="units">
          {!!gasPrices.safeLowWait &&
            `${gasPrices.safeLowWait} ${t("addition.minute")}`}
        </span>
      </div>
    </Wrapper>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  gasPrices: state.gas.gasPrices,
});

export default connect(mapStateToProps)(GasCard);
