import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Tippy from "@tippyjs/react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setCurrentUser } from "../redux/user/actions";
import { appOperations } from "../redux/appSlice";
import { getRates } from "../redux/rates/actions";
import { getGasPrices } from "../redux/gasPrices/actions";
import {
  showTransactionManager,
  closeTransactionManager,
} from "../redux/transactionManager/actions";
import { walletOperations } from "../redux/walletSlice";

// Assets
import { ReactComponent as TransactionsIcon } from "../assets/transactions.svg";
import { ReactComponent as GasStationIcon } from "../assets/header/gasStation.svg";
import { ReactComponent as NewHamburgerMenuLogo } from "../assets/header/hamburgerNew.svg";
import { ReactComponent as BlockchainIcon } from "../assets/header/blockchain.svg";
import { ReactComponent as ChevronDownIcon } from "../assets/header/chevron-down.svg";
import KeyfiLogo from "../assets/Blue-only-logo.svg";
import KeyfiLogoText from "../assets/Blue-only-text.svg";

// Components and helpers
import Modal from "./Modal";
import WalletModalContent from "./WalletModalContent";
import { tokensLogos, trimAddress } from "../helpers";
import ConnectedWallet from "./ConnectedWallet";
import { useWallet } from "../helpers/useWallet";
import GasCard from "./GasCard";
import Tooltip from "./Tooltip";
import Button from "./Button";

import { useTranslation } from "react-i18next";

// import Web3 from "web3";
// import ENS, { getEnsAddress } from "@ensdomains/ensjs";

export const StyledConnectedButton = styled.button`
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.btnPrimaryColor};
  padding: 8px 5px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  cursor: pointer;
  span {
    font-family: Montserrat;
    color: ${(props) => props.theme.brandBlue};
    font-size: 1rem;
    font-weight: 400;
    line-height: 20px;
  }
  svg {
    margin-right: 8px;
  }
  .chev-down {
    margin-left: 9px;
  }
`;

const DesktopHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 1.25rem 3.5rem 0.5rem 2.5rem;
  width: calc(100% - 200px);
  margin-left: auto;
  .desktopheader_readonly {
    position: relative;
    background: black;
    color: white;
    padding: 0.5rem;
    border-radius: 6px;
    display: flex;
    align-items: center;
    font-size: 12px;
    .readonly_circle {
      height: 13px;
      width: 13px;
      background: #f2c94c;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.25rem;
      .readonly_insidecircle {
        height: 7px;
        width: 7px;
        border-radius: 50%;
        background: #ffe600;
      }
    }
    &:hover {
      .tooltip {
        display: flex;
      }
    }
  }
  .desktopheader_end {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    .icon-wrapper {
      display: flex;
      align-items: center;
      margin-right: 48px;
      cursor: pointer;
      svg {
        margin-right: 9px;
      }

      .gas-price {
        margin-right: 8px;
        color: ${(props) => props.theme.brandBlue};
        font-family: Montserrat;
        font-size: 1rem;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .header__connectButton {
      width: 220px;
      height: 45px;
      text-transform: none;
    }
  }
`;

const StyledMobileMenu = styled.div`
  align-items: center;
  background: ${(props) => props.theme.superLightBlue};
  border-bottom: 1px solid ${(props) => props.theme.lightBlue2};
  display: flex;
  height: 64px;
  justify-content: center;
  position: relative;
  width: 100%;
`;

const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 4px;
  }
`;

const NetworkStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background: ${(props) => props.bg};
  color: ${(props) => props.color};
  border-radius: 6px;
  font-size: 12px;
  width: fit-content;
  ${(props) =>
    props.mobile &&
    css`
      margin: 1rem 0 0 1rem;
    `}
  img {
    margin-left: 0.5rem;
    height: 15px;
    width: 15px;
  }
`;

const supportedNetworks = {
  mainnet: {
    icon: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
    bg: "#393939",
    color: "white",
  },
  "bsc-mainnet": {
    icon: tokensLogos.BNB,
    bg: "#FFC700",
    color: "#3B474E",
  },
  polygon: {
    icon: tokensLogos.MATIC,
    bg: "#8247E5",
    color: "white",
  },
};

const NetworkStatus = ({ networkName, mobile }) => {
  const { t } = useTranslation();
  if (supportedNetworks[networkName]) {
    return (
      <NetworkStatusWrapper
        mobile={mobile}
        bg={supportedNetworks[networkName].bg}
        color={supportedNetworks[networkName].color}
      >
        {t("keyfiInfo.network")}:{" "}
        {typeof supportedNetworks[networkName].icon === "string" ? (
          <img src={supportedNetworks[networkName].icon} alt={networkName} />
        ) : (
          supportedNetworks[networkName].icon
        )}
      </NetworkStatusWrapper>
    );
  }
  return <></>;
};

const Header = ({
  handleToggleSidebar,
  isMobile,
  handleShowModal,
  handleCloseModal,
  showModal,
  getRates,
  getGasPrices,
  handleShowTxm,
  gas,
  user,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const wallet = useWallet();

  const walletRedux = useSelector((state) => state.wallet);
  const { network } = walletRedux;

  const [requestChangeWallet, setRequestChangeWallet] = useState(false);
  const [appOpen, setAppOpen] = useState(true);

  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        setAppOpen(true);
      } else {
        setAppOpen(false);
      }
    });
  }, []);

  useEffect(() => {
    try {
      dispatch(appOperations.initApplication());
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (appOpen) {
        getRates();
        getGasPrices();
        dispatch(walletOperations.loadUserData());
      }
    }, 1000 * 60 * 5);

    return () => clearInterval(interval);
  });

  const handleRequestChangeWallet = () => {
    setRequestChangeWallet(true);
  };

  const onConnectWallet = async (providerId) => {
    try {
      setRequestChangeWallet(false);
      handleCloseModal();
      await dispatch(walletOperations.connect(providerId));
    } catch (err) {
      console.log(err);
    }
  };

  const onReadOnlyWallet = async (address) => {
    try {
      setRequestChangeWallet(false);
      handleCloseModal();
      dispatch(walletOperations.connectReadOnly(address));
    } catch (err) {
      console.log(err);
    }
  };

  const handleDisconnect = () => {
    setRequestChangeWallet(false);
    dispatch(walletOperations.disconnect());
    setRequestChangeWallet(true);
  };

  const { t } = useTranslation();

  return (
    <>
      {isMobile ? (
        <>
          <StyledMobileMenu>
            <StyledLogoWrapper onClick={() => history.push("/dashboard")}>
              <img src={KeyfiLogo} alt="logo" height="45px" width="45px" />
              <img
                src={KeyfiLogoText}
                alt="log text"
                height="32px"
                width="100px"
              />
            </StyledLogoWrapper>
            <NewHamburgerMenuLogo
              style={{ position: "absolute", right: "19px" }}
              onClick={handleToggleSidebar}
            />
          </StyledMobileMenu>
          {network && network.name && (
            <NetworkStatus mobile networkName={network.name} />
          )}
        </>
      ) : (
        <DesktopHeader>
          {user.readOnly && (
            <div className="desktopheader_readonly">
              <div className="readonly_circle">
                <div className="readonly_insidecircle" />
              </div>
              {t("addition.readOnlyPortfolioChecking")}
              <Tooltip arrowTop text={t("addition.toolTipText")} />
            </div>
          )}
          {network && network.name && (
            <NetworkStatus networkName={network.name} />
          )}

          <div className="desktopheader_end">
            <Tippy
              content={<GasCard />}
              trigger="click"
              interactive
              appendTo={() => document.body}
            >
              <span className="icon-wrapper">
                <GasStationIcon />
                {!!gas.average && (
                  <span className="gas-price"> {gas.average / 10}</span>
                )}
                <ChevronDownIcon className="chev-down" />
              </span>
            </Tippy>

            <span className="icon-wrapper" onClick={handleShowTxm}>
              <TransactionsIcon />
              <ChevronDownIcon className="chev-down" />
            </span>

            {wallet.address ? (
              <StyledConnectedButton onClick={handleShowModal}>
                <BlockchainIcon style={{ margin: "0px 8px 0px" }} />
                <span>{trimAddress(wallet.address)}</span>
                <ChevronDownIcon className="chev-down" />
              </StyledConnectedButton>
            ) : (
              <Button
                primary
                onClick={handleShowModal}
                className="header__connectButton"
              >
                {t("addition.connectToKeyfi")}
              </Button>
            )}
          </div>
        </DesktopHeader>
      )}

      <Modal
        title={
          wallet.address
            ? `${
                user.readOnly
                  ? t("addition.youAreCurrentlyIn")
                  : t("addition.youAreConnectedIn")
              }`
            : t("addition.connectToKeyfi")
        }
        open={showModal}
        HandleShowModal={handleShowModal}
        handleCloseModal={handleCloseModal}
        maxwidth="740px"
        width="100%"
      >
        {wallet.address && !requestChangeWallet ? (
          <ConnectedWallet
            readOnly={user.readOnly}
            wallet={wallet.providerId}
            userAddress={wallet.address}
            handleChangeWallet={handleRequestChangeWallet}
            onDisconnect={handleDisconnect}
          />
        ) : (
          <WalletModalContent
            handleWalletSelect={onConnectWallet}
            handleReadOnly={onReadOnlyWallet}
          />
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  gas: state.gas.gasPrices,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  showTransactionManager: () => dispatch(showTransactionManager()),
  closeTransactionManager: () => dispatch(closeTransactionManager()),
  getRates: () => dispatch(getRates()),
  getGasPrices: () => dispatch(getGasPrices()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
