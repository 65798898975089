import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PlatformsDropdown from "../../PlatformsDropdown";
import { platforms, UserLiquidityListWrapper } from "../SharedLiquidity";
import NoAssetContainer from "../SharedLiquidity/NoAssetContainer";
import UserLiquidityListItem from "../SharedLiquidity/UserLiquidityListItem";

const CurveUserLiquidity = ({ handlePlatformSwitch, selectedPlatform }) => {
  const { t } = useTranslation();

  const liquidity = useSelector((state) => state.liquidity);
  const { mainnet } = liquidity;

  return (
    <>
      <div className="user-liquidity-platform">
        <div className="user-liquidity-platform-label">
          {t("pool.yourLiquidity.choosePlatform")}:
        </div>
        <PlatformsDropdown
          setOption={handlePlatformSwitch}
          selectedOption={selectedPlatform}
          options={platforms}
        />
      </div>
      <UserLiquidityListWrapper>
        {selectedPlatform.value &&
        mainnet[selectedPlatform.value].v1.length !== 0 ? (
          mainnet[selectedPlatform.value].v1.map((item, i) => (
            <UserLiquidityListItem
              key={i}
              data={item}
              platform={selectedPlatform.value}
            />
          ))
        ) : (
          <NoAssetContainer platform="curve" />
        )}
      </UserLiquidityListWrapper>
    </>
  );
};

export default CurveUserLiquidity;
