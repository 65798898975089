import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import PlatformsDropdown from "../../../PlatformsDropdown";
import { platforms } from "../../SharedLiquidity";

// Uniswap Versions
import V3 from "./v3";
import V2 from "./v2";

const handleVersions = (version) => {
  switch (version) {
    case "v3":
      return <V3 />;
    case "v2":
      return <V2 />;
    default:
      break;
  }
};

const UniswapAddLiquidity = ({ handlePlatformSwitch, selectedPlatform }) => {
  const { t } = useTranslation();

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const [version] = useState(query.get("v") || "v2");

  return (
    <>
      <div className="add-liquidity-platform">
        <div className="add-liquidity-platform-label">
          {t("pool.addLiquidity.choosePlatform")}:
        </div>
        <PlatformsDropdown
          setOption={handlePlatformSwitch}
          selectedOption={selectedPlatform}
          options={platforms}
        />
        {/* <VersionButtons>
          <button className={version === "v3" && "liquidity-version-selected"} onClick={() => setVersion("v3")}>
            V3
          </button>
          <button className={version === "v2" && "liquidity-version-selected"} onClick={() => setVersion("v2")}>
            V2 (old)
          </button>
        </VersionButtons> */}
      </div>
      {handleVersions(version)}
    </>
  );
};

export default UniswapAddLiquidity;
