import React from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import TotalPortfolioCard from "../components/Dashboard/TotalPortfolioCard";
import { calculateTotal } from "../helpers";
import { breakPoints } from "../theme";
import KeyFiCard from "../components/Dashboard/KeyFiCard";
import WalletCard from "../components/Dashboard/WalletCard";
import StakingCard from "../components/Dashboard/StakingCard";
import LiquidityPoolsCard from "../components/Dashboard/LiquidityPoolsCard";
import PlatformCard from "../components/Dashboard/PlatformCard";
import { stakingOperations } from "../redux/stakingSlice";
import { getDashboardPlatforms } from "../constants";
import { liquidityOperations } from "../redux/liquiditySlice";

const Wrapper = styled.div`
  .total {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 2fr;

    @media (max-width: ${breakPoints.xl}px) {
      grid-gap: 0;
      grid-template-columns: 1fr;
    }
  }

  .platforms,
  .account {
    margin-bottom: 40px;
  }
  .title {
    font-size: 24px;
    line-height: 29px;
    font-weight: 500;
    color: ${(props) => props.theme.mainDarkText};
    margin-bottom: 13px;
  }

  .list {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: ${breakPoints.lg}px) {
    .list {
      grid-template-columns: repeat(auto-fit, minmax(100%, 100%));
    }
  }

  @media (min-width: ${breakPoints.lg}px) and (max-width: ${breakPoints.laptop}px) {
    .list {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (min-width: ${breakPoints.lg}px) {
    margin: 0px 20px;
  }
`;

function Dashboard({ user, rates }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const wallet = useSelector((state) => state.wallet);
  const { network } = wallet;

  // total Account Overview
  const totalWallet = calculateTotal(user.tokens, user.usdPrices);
  const totalStaking = dispatch(stakingOperations.getTotalStakeValue());
  const totalLiquidityPools = dispatch(liquidityOperations.getTotalLPValue());

  const platformsList = getDashboardPlatforms(dispatch, user.usdPrices);

  const sortPlatformList = () =>
    platformsList
      .filter((p) => p.network === "all" || p.network === network.name)
      .sort((a, b) => b.total - a.total)
      .map((p) => (
        <PlatformCard
          platform={p}
          user={user}
          rates={rates}
          key={p.name}
          tokens={p.tokens}
        />
      ));

  return (
    <Wrapper>
      <section className="total">
        <TotalPortfolioCard />
        <KeyFiCard />
      </section>
      <section className="account">
        <div className="title">{t("dashboard.accountOverview.title")}</div>
        <div className="list">
          <WalletCard total={totalWallet} />
          <StakingCard total={totalStaking} />
          <LiquidityPoolsCard total={totalLiquidityPools} />
        </div>
      </section>

      <section className="platforms">
        <div className="title">{t("dashboard.platforms.title")}</div>
        <div className="list">{network && sortPlatformList()}</div>
      </section>
    </Wrapper>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  rates: state.rates.rates,
});

export default connect(mapStateToProps)(Dashboard);
