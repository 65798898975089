import React, { useEffect, useState, useMemo } from "react";
import styled, { css } from "styled-components";
import { connect } from "react-redux";
import { integrations, ERC20Tokens, BEP20Tokens } from "@keyfi/keyfi-common";
import { ReactComponent as ChevronDownIcon } from "../assets/header/chevron-down.svg";
import { tokensLogos, beautifyTokensAmount, poolsLogos } from "../helpers";
import { breakPoints } from "../theme";
import Modal from "./Modal";
import { useTranslation } from "react-i18next";
import AssetImage from "./Borrow/AssetImage";
import { getAddressSum } from "@keyfi/keyfi-common/src/integrations";

const Wrapper = styled.div``;
const StyledSelect = styled.div`
  background: ${(props) =>
    props.selectedOption
      ? props.theme.white
      : "linear-gradient(256.02deg, #0ca7eb 2.19%, #005ad3 188.15%)"};
  color: ${(props) =>
    props.selectedOption ? props.theme.mainDarkText : props.theme.white};
  outline: 0;
  border: 0;
  border-radius: ${(props) => (props.selectedOption ? 0 : "6px")};
  height: 2em;
  padding: ${(props) => (props.selectedOption ? 0 : "6px 10px")};
  font-weight: 500;
  font-size: 1em;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  margin-left: 12px;

  .selected__icon {
    height: 24px;
    width: 24px;
    margin-right: 0.5rem;

    img,
    svg {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }

    h2 {
      line-height: 24px;
    }

    @media (max-width: ${breakPoints.md}px) {
      img,
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
  .selected__choose {
    margin-right: 0.5rem;
    font-size: 0.8rem;
  }
  .selected__text {
    margin-right: 0.5rem;
    font-size: 1rem;

    @media (max-width: ${breakPoints.md}px) {
      font-size: 12px;
    }
  }

  .selected__arrow {
    margin-bottom: 2px;
    path {
      fill: ${(props) =>
        props.selectedOption ? props.theme.mainDarkText : props.theme.white};
    }
  }

  @media (max-width: ${breakPoints.md}px) {
    font-size: 12px;
    margin-left: 0.5rem;
  }
`;

const StyledOptions = styled.div`
  color: ${(props) => props.theme.mainDarkText};
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  justify-content: flex-start;
  flex-direction: column;
  max-height: 350px;
  min-height: 350px;
  overflow-y: auto;
  z-index: 9999;
  span {
    margin-left: 5px;
  }
  svg,
  img {
    width: 32px !important;
    height: 32px !important;
  }
`;

const StyledOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  height: 56px;
  cursor: pointer;
  color: ${(props) => props.theme.mainDarkText};
  font-size: 16px;
  .token-info {
    display: flex;
    align-items: center;
    .swap_token_image {
      height: 32px;
      width: 32px;
      font-size: 32px;
      line-height: 32px;
      margin: 0;
    }
  }
  :hover {
    background: rgba(212, 231, 255, 0.7);
  }
  border-top: 1px solid ${(props) => props.theme.border2};
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      color: ${(props) => props.theme.disabled};
      img {
        opacity: 0.7;
      }
    `}
`;

const OptionsWrapper = styled.div`
  min-height: 400px;

  .input-wrapper {
    margin-top: 32px;
    margin-bottom: 24px;

    .search {
      outline: none;
      height: 48px;
      width: 100%;
      border-radius: 6px;
      border: 1px solid ${(props) => props.theme.border2};
      padding-left: 1rem;
      color: ${(props) => props.theme.mainDarkText};
      font-family: "Montserrat";
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;

      ::placeholder {
        color: ${(props) => props.theme.placeholderText};
      }
    }
  }
`;
const defaultOptions = [
  { value: "ETH", icon: tokensLogos.ETH, text: "ETH" },
  { value: "USDT", icon: tokensLogos.USDT, text: "USDT" },
  { value: "DAI", icon: tokensLogos.DAI, text: "DAI" },
  { value: "BUSD", icon: tokensLogos.BUSD, text: "BUSD" },
  { value: "USDC", icon: tokensLogos.USDC, text: "USDC" },
];

const defaultOptionsPlatforms = {
  CurveLiquedity: [
    { value: "cDAI", icon: tokensLogos.cDAI, text: "cDAI" },
    { value: "cUSDC", icon: tokensLogos.cUSDC, text: "cUSDC" },
  ],
  keyfi: [{ value: "KEY", icon: tokensLogos.KEY, text: "KEY" }],
  keyfiBUSD: [{ value: "BUSD", icon: tokensLogos.BUSD, text: "BUSD" }],
  keyfiBSC: [
    { value: "KEYFI", icon: tokensLogos.KEYFI, text: "KEYFI" },
    { value: "USDC", icon: tokensLogos.USDC, text: "USDC" },
  ],
  keyfiWBNB: [{ value: "wBNB", icon: tokensLogos.wBNB, text: "wBNB" }],
  keyfiWETH: [{ value: "wETH", icon: tokensLogos.WETH, text: "wETH" }],
  keyfiWBTC: [{ value: "wBTC", icon: tokensLogos.WBTC, text: "wBTC" }],
  "UNI-V2": [
    { value: "KEYFIUSDCLP", icon: tokensLogos.Uniswap, text: "UNI-V2" },
  ],
  KEYFIETH_LP: [
    { value: "KEYFIETH_LP", icon: tokensLogos.Uniswap, text: "UNI-V2" },
  ],
  KEYFIBUSD_LP: [
    { value: "KEYFIBUSD_LP", icon: poolsLogos.Pancakeswap, text: "CAKE-LP" },
  ],
  liquidityPools: [
    { value: "uniswap", icon: poolsLogos.Uniswap, text: "Uniswap" },
    { value: "curve", icon: poolsLogos.Curve, text: "Curve" },
  ],
};

function DropDown({
  name,
  updateParent,
  user,
  noOptions,
  platform,
  version,
  removeToken = "",
  defaultSelected,
  value,
}) {
  const [showOptions, setShowOptions] = useState(false);

  const theDefaultOptions = defaultOptionsPlatforms[platform] ?? defaultOptions;

  const [options, setOptions] = useState(theDefaultOptions);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (defaultSelected && !value) {
      const defSelected = options.find(
        (option) => option.value === defaultSelected
      );
      setSelectedOption(defSelected);
    }
  }, [defaultSelected, options, value]);

  useEffect(() => {
    const getSupportedTokens = async () => {
      // filter and update the supported tokens for each platform
      try {
        if (!noOptions && platform === "aave") {
          const aaveTokens = await integrations.aave[
            version
          ].getSupportedAssets();
          const newOptions = aaveTokens.map((option) => ({
            value: option,
            icon: tokensLogos[option],
            text: option,
          }));
          setOptions([...newOptions]);
        } else if (!noOptions && platform === "compound") {
          const compoundTokens =
            await integrations.compound.getSupportedAssets();
          const newOptions = compoundTokens.map((option) => ({
            value: option,
            icon: tokensLogos[option],
            text: option,
          }));
          setOptions([...newOptions]);
        } else if (!noOptions && platform === "uniswap") {
          const tokens = ERC20Tokens.map((item) => {
            return {
              value: item.symbol,
              text: item.symbol,
              icon: (
                <AssetImage
                  className="swap_token_image"
                  src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${
                    item.symbol === "ETH"
                      ? "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
                      : getAddressSum(item.id)
                  }/logo.png`}
                  fallbackLogo={tokensLogos[item.symbol]}
                  alt={item.symbol}
                />
              ),
            };
          });
          setOptions([...tokens]);
        } else if (!noOptions && platform === "inche") {
          const tokens = ERC20Tokens.map((item) => {
            return {
              value: item.symbol,
              text: item.symbol,
              icon: (
                <AssetImage
                  className="swap_token_image"
                  src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${getAddressSum(
                    item.id
                  )}/logo.png`}
                  fallbackLogo={tokensLogos[item.symbol]}
                  alt={item.symbol}
                />
              ),
            };
          });
          setOptions([...tokens]);
        } else if (!noOptions && platform === "dydx") {
          const dydxTokens = integrations.dydx.getSupportedAssets();
          const newOptions = dydxTokens.map((option) => ({
            value: option,
            icon: tokensLogos[option],
            text: option,
          }));
          setOptions([...newOptions]);
        } else if (
          !noOptions &&
          (platform === "pancakeswap" || platform === "apeswap")
        ) {
          const tokens = BEP20Tokens.map((option) => ({
            value: option.symbol,
            icon: (
              <AssetImage
                className="swap_token_image"
                src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${
                  option.symbol === "BNB"
                    ? "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                    : getAddressSum(option.id)
                }/logo.png`}
                alt={option.symbol}
              />
            ),
            text: option.symbol,
            address: option.id,
          }));

          setOptions([...tokens]);
        }
      } catch (err) {}
    };
    if (user.id) {
      try {
        getSupportedTokens();
      } catch (err) {}
    }
  }, [user.id, platform, version]);

  const handleShowOptions = () => {
    if (!noOptions) {
      setShowOptions(!showOptions);
    }
  };

  // handle select an option from the dropdown
  const handleSelect = (token) => {
    const selectedToken = options.find((option) => option.value === token);
    setSearchQuery("");
    setSelectedOption(selectedToken);
    setShowOptions(false);
    updateParent(name, selectedToken.value);
  };

  const filteredOptions = useMemo(() => {
    if (options.length > 0) {
      return options
        .filter((item) =>
          item.text.toLowerCase().includes(searchQuery.toLowerCase())
        )
        .slice(0, 100);
    }
    return [];
  }, [searchQuery, options]);

  const { t } = useTranslation();

  return (
    <Wrapper>
      <StyledSelect onClick={handleShowOptions} selectedOption={selectedOption}>
        {selectedOption ? (
          <>
            <span className="selected__icon"> {selectedOption.icon} </span>
            <span className="selected__text"> {selectedOption.text}</span>
          </>
        ) : (
          <span className="selected__choose">
            {t("pool.addLiquidity.chooseToken")}
          </span>
        )}
        {noOptions ? null : (
          <span className="selected__arrow">
            <ChevronDownIcon />
          </span>
        )}
      </StyledSelect>

      {showOptions && (
        <Modal
          handleCloseModal={() => setShowOptions(false)}
          width="480px"
          title={t("pool.addLiquidity.chooseToken")}
        >
          <OptionsWrapper>
            <div className="input-wrapper">
              <input
                className="search"
                type="text"
                placeholder={t("pool.addLiquidity.searchTokenName")}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            {filteredOptions.length ? (
              <StyledOptions>
                {filteredOptions.map((option) => (
                  <StyledOption
                    key={option.text}
                    disabled={
                      option.value.toLowerCase() === removeToken.toLowerCase()
                    }
                    onClick={() => handleSelect(option.value)}
                  >
                    <div className="token-info">
                      {option.icon}
                      <span>{option.value}</span>
                    </div>

                    <span className="token-value">
                      {name === "selectwithdraw"
                        ? beautifyTokensAmount(user[platform][option.value])
                        : beautifyTokensAmount(user.tokens[option.value])}
                    </span>
                  </StyledOption>
                ))}
              </StyledOptions>
            ) : (
              t("addition.noResultFound")
            )}
          </OptionsWrapper>
        </Modal>
      )}
    </Wrapper>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(DropDown);
