import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { PoolsCardWrapper } from "../../Shared";
import { tokensLogos, compareFunction, poolsLogos } from "../../helpers";
import { ReactComponent as SortArrowIcon } from "../../assets/sortArrow.svg";
import { breakPoints } from "../../theme";

const StyledTable = styled.table`
  border-collapse: collapse;
  min-width: 400px;
  width: 100%;

  th {
    border-bottom: 1px solid ${(props) => props.theme.brandBlue};
    padding-left: 17px;
    color: ${(props) => props.theme.brandBlue};
    font-size: 12px;
    text-align: left;
    font-weight: 500;
  }

  thead tr {
    height: 32px;
    th > * {
      color: ${(props) => props.theme.brandBlue};
      font-size: 12px;
      line-height: 16px;
      text-align: left;
      display: flex;
    }
  }

  tbody {
    border-spacing: 0px;
    svg,
    img {
      width: 24px;
      height: 24px;
    }
    tr {
      border-spacing: 0px;
      height: 56px;
      :nth-of-type(even) {
        background: ${(props) => props.theme.lightBlue3};
      }
    }

    td {
      color: ${(props) => props.theme.mainDarkText};
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      position: relative;
      text-align: left;
    }

    tr:hover {
      // background: rgb(0, 70, 162, 0.2);
    }
  }
`;

const TableWrapper = styled(PoolsCardWrapper)`
  border: 0;
  overflow-x: auto;
  padding: 0;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 0;
  display: table;

  @media (max-width: ${breakPoints.lg}px) {
    display: none;
  }
`;

export const StyledLogoWrapper = styled.div`
  color: ${(props) => props.theme.text_greyDark};
  font-size: 12px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: none;
  white-space: nowrap;
  img,
  svg {
    margin-left: 8px;
  }

  .sort-arrow {
    margin-left: 8px;
    transform: ${(props) => (props.state === "asc" ? "scale(-1)" : "none")};

    :hover {
      cursor: pointer;
    }
  }
`;
const RowsIconsWrapper = styled.div`
  position: absolute;
  align-items: center;
  color: ${(props) => props.theme.text_greyDark};
  display: flex;
  left: -15px;
  overflow: hidden;
  text-overflow: ellipsis;
  top: 15px;
  white-space: nowrap;

  img,
  svg {
    margin-right: 8px;
  }
`;

const StyledTd = styled.td`
  padding-left: 17px;
`;

const RebalancerMobileTableWrap = styled.div`
  display: none;

  .table {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  tbody {
    width: 100% !important;
  }

  @media (max-width: ${breakPoints.lg}px) {
    display: flex;
    margin-top: 2rem;

    .mobileTable {
      font-size: 14px;
      width: 100%;
    }
  }
`;

const MobileTransactionWidget = styled.tr`
  background: ${(props) => props.theme.white};
  border: 1px solid ${(props) => props.theme.lightBlue3};
  color: ${(props) => props.theme.mainDarkText};
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  margin-bottom: 1.5em;
  padding: 6px 16px;
  height: initial !important;
  width: 100%;

  .tableTitle {
    color: ${(props) => props.theme.brandBlue};
    margin-right: 5px;
    min-width: 100px;
  }

  .tableLink {
    color: ${(props) => props.theme.mainDarkText};
    text-decoration: underline;

    a,
    a:hover,
    a:focus,
    a:visited {
      color: ${(props) => props.theme.mainDarkText};
    }
  }

  div {
    font-weight: 500;
  }
`;

const RebalancerMobileCell = styled.td`
  align-items: center;
  display: flex;
  height: 40px;
  margin: 0;
  max-width: 100%;

  img,
  svg {
    height: 20px;
    width: 20px;
    margin-right: 8px;
  }

  :not(.last) {
    border-bottom: 1px solid ${(props) => props.theme.lightBlue3};
  }
`;

export default function Table(props) {
  const { defaultSorted } = props;
  const [sort, setSort] = useState({
    field: props.tableHeadItems[defaultSorted || 2].id,
    direction: "desc",
  });

  const [rowsData, setRowsData] = useState([...props.rows]);

  useEffect(() => {
    rowsData.sort(compareFunction(sort));
    setRowsData([...rowsData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, props.rows]);

  const changeSort = (field) => {
    setSort((sort) => {
      if (sort.field === field) {
        return {
          ...sort,
          direction: sort.direction === "asc" ? "desc" : "asc",
        };
      }
      return {
        field,
        direction: "desc",
      };
    });
  };

  const createRows = (rows) =>
    rows.map((row, i) => (
      <tr key={i}>
        {Object.keys(row).map((cell) => (
          <StyledTd key={cell}>
            {cell === "asset" ? (
              <RowsIconsWrapper>
                {tokensLogos[row[cell]]}
                {row[cell]}
              </RowsIconsWrapper>
            ) : cell === "platform" ? (
              <RowsIconsWrapper>
                {
                  poolsLogos[
                    row[cell] === "Aave V1" || row[cell] === "Aave V2"
                      ? "Aave"
                      : row[cell]
                  ]
                }
                {row[cell] === "Aave" ? "AAVE" : row[cell]}
              </RowsIconsWrapper>
            ) : (
              row[cell]
            )}
          </StyledTd>
        ))}
      </tr>
    ));

  const RebalancerMobileTable = ({ txHistory, tableHeadItems }) => (
    <RebalancerMobileTableWrap>
      <table className="mobileTable">
        <tbody>
          {txHistory.map((row, indx) => (
            <MobileTransactionWidget key={indx}>
              <RebalancerMobileCell>
                <div className="tableTitle">{tableHeadItems[0].label}</div>
                <div>{row.rank}</div>
              </RebalancerMobileCell>
              <RebalancerMobileCell>
                <div className="tableTitle">{tableHeadItems[1].label}</div>
                <div>
                  {tokensLogos[row.asset]} {row.asset}
                </div>
              </RebalancerMobileCell>
              <RebalancerMobileCell>
                <div className="tableTitle">{tableHeadItems[2].label}</div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {row.rate}
                </div>
              </RebalancerMobileCell>
              <RebalancerMobileCell>
                <div className="tableTitle">{tableHeadItems[3].label}</div>
                <div>${row.assetPrice}</div>
              </RebalancerMobileCell>
              <RebalancerMobileCell>
                <div className="tableTitle">{tableHeadItems[4].label}</div>
                <div>{row.borrowRate}</div>
              </RebalancerMobileCell>
              <RebalancerMobileCell>
                <div className="tableTitle">{tableHeadItems[5].label}</div>
                <div>
                  {poolsLogos[row.platform]}
                  {row.platform === "Aave" ? "AAVE" : row.platform}
                </div>
              </RebalancerMobileCell>
              {tableHeadItems[6] && (
                <RebalancerMobileCell className="last">
                  <div className="tableTitle">{tableHeadItems[6].label}</div>
                  <div className="tableLink">
                    <a href={row.action.props.to}>
                      {row.action.props.children}
                    </a>
                  </div>
                </RebalancerMobileCell>
              )}
            </MobileTransactionWidget>
          ))}
        </tbody>
      </table>
    </RebalancerMobileTableWrap>
  );

  return (
    <>
      <TableWrapper>
        <StyledTable {...props}>
          <thead>
            <tr>
              {props.tableHeadItems.map(({ label, sortable, id }) => (
                <th key={label}>
                  {sortable ? (
                    <StyledLogoWrapper
                      state={sort.field === id ? sort.direction : "asc"}
                    >
                      {label}
                      <SortArrowIcon
                        className="sort-arrow"
                        onClick={() => changeSort(id)}
                      />
                    </StyledLogoWrapper>
                  ) : (
                    label
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{createRows(rowsData)}</tbody>
        </StyledTable>
      </TableWrapper>
      <RebalancerMobileTable
        txHistory={rowsData}
        tableHeadItems={props.tableHeadItems}
      />
    </>
  );
}
