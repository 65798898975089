import React from "react";
import styled from "styled-components";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Link } from "react-router-dom";
import { beautifyTokensAmount, poolsLogos } from "../../../helpers";
import { breakPoints } from "../../../theme";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  @media (max-width: ${breakPoints.md}px) {
    display: none;
  }
  .my_borrows_manage_link {
    color: ${(props) => props.theme.brandBlue};
    text-decoration: underline;
    font-size: 14px;
    font-weight: 600;
  }
`;

const CustomTooltipWrapper = styled.div`
  background: ${(props) => props.theme.lightBlue2};
  min-width: 200px;
  padding: 0.5rem;
  border: 0.5px solid ${(props) => props.theme.brandBlue};
  border-radius: 6px;
  > div {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    :not(:last-of-type) {
      margin-bottom: 6px;
    }
    span {
      font-weight: 600;
    }
    .custom_tooltip_stable {
      color: ${(props) => props.theme.brandBlue};
    }
    .custom_tooltip_variable {
      color: ${(props) => props.theme.uniswapPink};
    }
  }
`;

const CustomLabel = (props) => {
  const { x, y, width, height, value, fill } = props;
  const { t } = useTranslation();
  const valueStringLength = beautifyTokensAmount(value.debtAmountInUSD, {
    prefix: "$",
  }).length;

  return (
    <g>
      <text
        x={width + x + 10}
        y={y + height / 2}
        fill={fill}
        fontSize="14"
        textAnchor="center"
        dominantBaseline="middle"
      >
        {beautifyTokensAmount(value.debtAmountInUSD, { prefix: "$" })}
      </text>
      <foreignObject
        x={width + x + 10 + valueStringLength * 8.2}
        y={y - 4}
        height="20"
        width="70"
      >
        <Link
          className="my_borrows_manage_link"
          to={`/borrow/${value.platform}/${value.version}/${value.asset}`}
        >
          {t("addition.manage")}
        </Link>
      </foreignObject>
    </g>
  );
};

const CustomYTick = (props) => {
  const { y, payload, data } = props;

  const platform = data.find((item) => item.symbol === payload.value);

  function capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1);
  }
  return (
    <>
      <g transform={`translate(${0},${y})`}>
        <image
          style={{ border: "1px solid black" }}
          xlinkHref={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${platform.address}/logo.png`}
          x={-10}
          y={-8.5}
          height="16"
          width="16"
        />

        <foreignObject x="-2" y="-18" width="15" height="15">
          <style>
            {`.desktop_my_borrows_image img {
        height: 10px;
      }`}
          </style>
          <div className="desktop_my_borrows_image">
            {poolsLogos[capitalizeFirstLetter(platform.platform)]}
          </div>
        </foreignObject>
        <text x={10} y={5} fontSize="14" textAnchor="start" fill="#666">
          {payload.value}
        </text>
      </g>
    </>
  );
};

const CustomXTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} fontSize="10" textAnchor="middle" fill="#666">
        ${payload.value}
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload, label, t }) => {
  if (active && payload && payload.length) {
    return (
      <CustomTooltipWrapper>
        <div>
          <div>{t("addition.amount")}</div>
          <span>
            {beautifyTokensAmount(payload[0].payload.debtAmount, {
              suffix: ` ${label}`,
            })}
          </span>
        </div>
        <div>
          <div>{t("dashboard.accountOverview.value")}</div>
          <span>
            {beautifyTokensAmount(payload[0].payload.debtAmountInUSD, {
              prefix: "$",
            })}
          </span>
        </div>
        <div>
          <div>{t("addition.APYtype")}</div>

          {payload[0].payload.borrowMode === "1" ? (
            <span className="custom_tooltip_stable">
              {t("addition.stable")}
            </span>
          ) : (
            <span className="custom_tooltip_variable">
              {t("addition.varible")}
            </span>
          )}
        </div>
      </CustomTooltipWrapper>
    );
  }
  return null;
};

const platformColors = {
  compound: "#00D395",
  aave: "#CA5CBE",
};

const DesktopMyBorrows = ({ borrowData, t }) => (
  <Wrapper>
    <ResponsiveContainer
      width="100%"
      height={10 + borrowData.length * 40}
      debounce={1}
    >
      <BarChart
        barSize={16}
        layout="vertical"
        data={borrowData}
        margin={{ left: 10, right: 140 }}
      >
        <CartesianGrid horizontal={false} opacity={0.6} />
        <XAxis
          type="number"
          orientation="top"
          axisLine={false}
          tick={<CustomXTick />}
        />
        <YAxis
          type="category"
          dataKey="symbol"
          tick={<CustomYTick data={borrowData} />}
        />
        <Tooltip
          allowEscapeViewBox={{ y: true }}
          content={<CustomTooltip t={t} />}
          cursor={{ fill: "transparent" }}
        />

        <Bar dataKey="debtAmountInUSD" radius={[0, 6, 6, 0]}>
          {borrowData.map((entry) => {
            const color = platformColors[entry.platform];
            return <Cell fill={color} />;
          })}
          <LabelList
            dataKey="assetData"
            position="right"
            content={<CustomLabel />}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  </Wrapper>
);

export default DesktopMyBorrows;
