import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Redux
import {
  addTx,
  closeTransactionManager,
  removeTx,
  showTransactionManager,
} from "../../../../redux/transactionManager/actions";
import { connect, useDispatch, useSelector } from "react-redux";

// Assets and Helpers
import { ReactComponent as WBTC } from "../../../../assets/tokens/WBTC.svg";
import { ReactComponent as LinkIcon } from "../../../../assets/arrow-out.svg";
import { ReactComponent as ArrowDown } from "../../../../assets/arrowDown.svg";
import { integrations } from "@keyfi/keyfi-common";
import {
  TokenCardWrapper,
  TokensCardMainSection,
  TokenCardInput,
} from "../../shared";
import { Storage } from "../../../../helpers/Storage";
import timestamp from "time-stamp";
import { walletOperations } from "../../../../redux/walletSlice";
import { getTxHistory } from "../../../TransactionManager";
import { Skeleton } from "../../../../Shared";
import { beautifyTokensAmount } from "../../../../helpers";
import { breakPoints } from "../../../../theme";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const REWARD_UPDATE_INTERVAL = 7000;

const WBTCStaking = ({
  user,
  showTransactionManager,
  addTx,
  removeTx,
  wallet,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isFetching = useSelector((state) => state.app.isLoading);

  const [open, setOpen] = useState(false);
  const [inputsData, setInputsData] = useState({
    input1: "",
    selectinput1: "WBTC",
    input2: "",
    selectinput2: "WBTC",
    rewards: null,
    balance: null,
  });

  const userAmount = user.tokens[inputsData.selectinput1] ?? 0;

  const [hasInSufficientDepositBalance, setHasInSufficientDepositBalance] =
    useState(null);
  const [hasInSufficientWithdrawBalance, setHasInSufficientWithdrawBalance] =
    useState(null);

  const [hasInvalidDepositInput, setHasInvalidDepositInput] = useState(true);
  const [hasInvalidWithdrawInput, setHasInvalidWithdrawInput] = useState(true);

  let isMounted = true;

  const updateRewards = async () => {
    try {
      if (wallet.selectedAddress) {
        const rewards = await integrations.keyfipool.getRewardsv2(
          wallet.selectedAddress
        );
        const balance = await integrations.keyfipool.getBalancev2(
          wallet.selectedAddress
        );

        isMounted &&
          setInputsData((prevState) => ({
            ...prevState,
            rewards: rewards["WBTC"],
            balance: balance["WBTC"],
          }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    updateRewards();
    const interval = setInterval(async () => {
      updateRewards();
    }, REWARD_UPDATE_INTERVAL);

    return () => {
      isMounted = false;
      clearInterval(interval);
    };
  }, [wallet.selectedAddress]);

  const handleValidation = () => {
    const { input1, input2, balance } = inputsData;
    // check max deposit balance
    const maxInput1Balance = userAmount;

    setHasInSufficientDepositBalance(+input1 > +maxInput1Balance);

    // check max withdraw balance
    const maxInput2Balance = balance || 0;
    setHasInSufficientWithdrawBalance(+input2 > +maxInput2Balance);

    setHasInvalidDepositInput(!+input1);
    setHasInvalidWithdrawInput(!+input2);
  };

  const onInputUpdate = (name, value) => {
    setInputsData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleMaxInput = (name) => {
    if (name && user.tokens) {
      setInputsData((prevState) => {
        return {
          ...prevState,
          [name]: name === "input1" ? userAmount : inputsData.balance,
        };
      });
    }
  };

  useEffect(() => {
    handleValidation();
  }, [inputsData]);

  const handleWithdrawRewards = async () => {
    let txHash = [];
    let isFirstTrxFired = false;

    try {
      const response = await integrations.keyfipool.withdrawRewardv2(
        "WBTC",
        inputsData.rewards,
        {
          pendingCallback: (trx) => {
            if (!isFirstTrxFired) {
              showTransactionManager();
              addTx({
                platform: "KeyFi",
                txType: "Withdraw",
                txAmount: 0,
                txToken: "KEYFI",
                txHash: trx.hash,
                timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
              });

              isFirstTrxFired = true;
            }
            txHash.push(trx.hash);
          },
        }
      );
      if (response) {
        dispatch(walletOperations.loadUserData());
        updateRewards();

        const HistoryArray = getTxHistory();
        HistoryArray.unshift({
          platform: "KeyFi",
          txType: "Withdraw",
          txAmount: 0,
          txToken: "KEYFI",
          txHash: response.transactionHash,
          timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
        });
        Storage.setItem("transactions", JSON.stringify(HistoryArray));
        removeTx(txHash);
      }
    } catch (err) {
      console.log(err);
      const HistoryArray = getTxHistory();
      showTransactionManager();

      HistoryArray.unshift({
        platform: "KeyFi",
        txType: "Withdraw",
        txAmount: 0,
        txToken: "KEYFI",
        txHash: txHash[txHash.length - 1],
        timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
        status: "failed",
      });
      Storage.setItem("transactions", JSON.stringify(HistoryArray));
      removeTx(txHash);
    }
  };

  const onDeposit = async () => {
    const { input1, selectinput1 } = inputsData;

    if (Number(input1) && selectinput1) {
      let txHash = [];
      let isFirstTrxFired = false;

      try {
        const response = await integrations.keyfipool.depositv2(
          selectinput1,
          input1,
          {
            pendingCallback: (trx) => {
              if (!isFirstTrxFired) {
                showTransactionManager();
                addTx({
                  platform: "KeyFi",
                  txType: "Deposit",
                  txAmount: Number(input1),
                  txToken: "wBTC",
                  txHash: trx.hash,
                  timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
                });

                isFirstTrxFired = true;
              }
              txHash.push(trx.hash);
            },
          }
        );
        if (response) {
          dispatch(walletOperations.loadUserData());
          updateRewards();

          const HistoryArray = getTxHistory();
          HistoryArray.unshift({
            platform: "KeyFi",
            txType: "Deposit",
            txAmount: Number(input1),
            txToken: "wBTC",
            txHash: response.transactionHash,
            timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
          });
          Storage.setItem("transactions", JSON.stringify(HistoryArray));
          removeTx(txHash);
        }
      } catch (err) {
        console.log(err);
        const HistoryArray = getTxHistory();
        showTransactionManager();

        HistoryArray.unshift({
          platform: "KeyFi",
          txType: "Deposit",
          txAmount: Number(input1),
          txToken: "wBTC",
          txHash: txHash[txHash.length - 1],
          timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
          status: "failed",
        });
        Storage.setItem("transactions", JSON.stringify(HistoryArray));
        removeTx(txHash);
      }
    }
    // reset input
    setInputsData((prevState) => {
      return {
        ...prevState,
        input1: "",
      };
    });
  };

  const onWithdraw = async () => {
    const { input2, selectinput2 } = inputsData;
    if (Number(input2) && selectinput2) {
      let txHash = [];
      let isFirstTrxFired = false;

      try {
        const response = await integrations.keyfipool.withdrawv2(
          selectinput2,
          input2,
          {
            pendingCallback: (trx) => {
              if (!isFirstTrxFired) {
                showTransactionManager();
                addTx({
                  platform: "KeyFi",
                  txType: "Withdraw",
                  txAmount: Number(input2),
                  txToken: "wBTC",
                  txHash: trx.hash,
                  timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
                });
                isFirstTrxFired = true;
              }
              txHash.push(trx.hash);
            },
          }
        );
        if (response) {
          dispatch(walletOperations.loadUserData());
          updateRewards();

          const HistoryArray = getTxHistory();
          HistoryArray.unshift({
            platform: "KeyFi",
            txType: "Withdraw",
            txAmount: Number(input2),
            txToken: "wBTC",
            txHash: response.transactionHash,
            timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
          });
          Storage.setItem("transactions", JSON.stringify(HistoryArray));
          removeTx(txHash);
        }
      } catch (err) {
        console.log(err);

        const HistoryArray = getTxHistory();
        showTransactionManager();

        HistoryArray.unshift({
          platform: "KeyFi",
          txType: "Withdraw",
          txAmount: Number(input2),
          txToken: "wBTC",
          txHash: txHash[txHash.length - 1],
          timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
          status: "failed",
        });
        Storage.setItem("transactions", JSON.stringify(HistoryArray));
        removeTx(txHash);
      }
    }

    // reset input
    setInputsData((prevState) => {
      return {
        ...prevState,
        input2: "",
      };
    });
  };

  return (
    <TokenCardWrapper open={open}>
      <div className="tokens_card_header" onClick={() => setOpen(!open)}>
        <div className="tokens_card_title">
          <WBTC />
          wBTC {t("earn.staking")}
        </div>

        <div className="tokens_card_staked">
          {t("earn.v2Staking.staked")}:{" "}
          {!isFetching ? (
            <span className="tokens_value">
              {beautifyTokensAmount(inputsData.balance, {
                suffix: " wBTC",
              })}
            </span>
          ) : (
            <Skeleton className="value" white="true" height="20" width="80" />
          )}
        </div>
        <div className="tokens_card_earned">
          {t("earn.v2Staking.earned")}:{" "}
          {!isFetching ? (
            <TransitionGroup>
              <CSSTransition
                key={inputsData.rewards}
                classNames="flash"
                timeout={300}
              >
                <span className={`tokens_value`}>
                  {beautifyTokensAmount(inputsData.rewards, {
                    suffix: " KEYFI",
                  })}
                </span>
              </CSSTransition>
            </TransitionGroup>
          ) : (
            <Skeleton className="value" white="true" height="20" width="80" />
          )}
        </div>
        <div className="tokens_card_learn">
          {window.innerWidth < breakPoints.lg
            ? ""
            : t("earn.v2Staking.moreDetails")}{" "}
          <ArrowDown className="tokens_card_arrow" />
        </div>
      </div>
      {open && (
        <TokensCardMainSection>
          <div className="tokens_card_values_mobile">
            <div>
              {t("earn.v2Staking.staked")}:{" "}
              <span className="tokens_value">
                {beautifyTokensAmount(inputsData.balance, {
                  suffix: " wBTC",
                })}
              </span>
            </div>
            <div>
              {t("earn.v2Staking.earned")}:{" "}
              <TransitionGroup>
                <CSSTransition
                  key={inputsData.rewards}
                  classNames="flash"
                  timeout={300}
                >
                  <span className={`tokens_value`}>
                    {beautifyTokensAmount(inputsData.rewards, {
                      suffix: " KEYFI",
                    })}
                  </span>
                </CSSTransition>
              </TransitionGroup>
            </div>
          </div>
          <TokenCardInput
            name="input1"
            label={t("earn.v2Staking.deposit")}
            inputValue={inputsData.input1}
            updateValue={onInputUpdate}
            disabled={hasInvalidDepositInput || hasInSufficientDepositBalance}
            showTokens={userAmount}
            showTokensSuffix=" wBTC"
            onClick={onDeposit}
            buttonTitle={t("earn.v2Staking.deposit")}
            handleMaxInput={() => handleMaxInput("input1")}
            error={hasInSufficientDepositBalance}
          />
          <TokenCardInput
            name="input2"
            label={t("earn.v2Staking.withdraw")}
            inputValue={inputsData.input2}
            updateValue={onInputUpdate}
            disabled={hasInvalidWithdrawInput || hasInSufficientWithdrawBalance}
            showTokens={inputsData.balance}
            showTokensSuffix=" wBTC"
            onClick={onWithdraw}
            buttonTitle={t("earn.v2Staking.withdraw")}
            handleMaxInput={() => handleMaxInput("input2")}
            error={hasInSufficientWithdrawBalance}
          />
          <TokenCardInput
            name="rewards"
            label={t("earn.v2Staking.earned")}
            inputValue={inputsData.rewards}
            disabled={!+inputsData.rewards}
            showTokens={inputsData.rewards}
            showTokensSuffix=" KEYFI"
            onClick={handleWithdrawRewards}
            buttonTitle={t("earn.v2Staking.claimRewardBtn")}
            animation
          />
          <div className="tokens_card_poolinfo">
            <div>{t("earn.v2Staking.poolInfo")}</div>
            <div>
              <div>
                <span>{t("earn.v2Staking.allocation")}:</span>
                <span className="tokens_poolinfo_value">10%</span>
              </div>
              <div>
                <span>{t("earn.v2Staking.depositFee")}:</span>
                <span className="tokens_poolinfo_value">3%</span>
              </div>
            </div>
            <a
              href="https://etherscan.io/address/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599"
              target="_blank"
              rel="noreferrer"
            >
              {t("earn.v2Staking.tokenAddress")} <LinkIcon />
            </a>
          </div>
        </TokensCardMainSection>
      )}
    </TokenCardWrapper>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  wallet: state.wallet,
});

const mapDispatchToProps = (dispatch) => ({
  showTransactionManager: () => dispatch(showTransactionManager()),
  closeTransactionManager: () => dispatch(closeTransactionManager()),
  addTx: (tx) => dispatch(addTx(tx)),
  removeTx: (txHash) => dispatch(removeTx(txHash)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WBTCStaking);
