import React from "react";
import { useTranslation } from "react-i18next";
import PlatformsDropdown from "../../../PlatformsDropdown";
import { platforms } from "../../SharedLiquidity";
import V1 from "./v1";

const ApeSwapAddLiquidity = ({ handlePlatformSwitch, selectedPlatform }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="add-liquidity-platform">
        <div className="add-liquidity-platform-label">
          {t("pool.addLiquidity.choosePlatform")}:
        </div>
        <PlatformsDropdown
          setOption={handlePlatformSwitch}
          selectedOption={selectedPlatform}
          options={platforms}
        />
      </div>
      <V1 />
    </>
  );
};

export default ApeSwapAddLiquidity;
