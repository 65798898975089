import React, { useState, useEffect, useMemo } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { integrations } from "@keyfi/keyfi-common";
import { GlobalStyles } from "./theme/globalStyles";
import { Container } from "./theme";
import { useWindowSize } from "./hooks/useWindowSize";

// Components
import TransactionManager, {
  getTxHistory,
} from "./components/TransactionManager";
import {
  showTransactionManager,
  closeTransactionManager,
} from "./redux/transactionManager/actions";
import LanguageSelector from "./components/LanguageSelector";
import SideBar from "./components/SideBar";
import Header from "./components/Header";

// Pages
import Dashboard from "./pages/Dashboard";
import Rebalancer from "./pages/Rebalancer";
import Tokens from "./pages/Tokens";
import Deposit from "./pages/Deposit";
import Pools from "./pages/Pools";
import Info from "./pages/Info";
import Transactions from "./pages/Transactions";
import RemoveLiquidity from "./pages/RemoveLiquidity";
import SwapPlatforms from "./pages/SwapPlatforms";
import Borrow from "./pages/Borrow";
import BorrowAsset from "./pages/BorrowAsset";

window.integrations = integrations;
const { ethereum } = window;

function App({
  isTransactionManagerOpen,
  transactions,
  showTransactionManager,
}) {
  const { i18n } = useTranslation();
  const [showSidebar, setShowSidebar] = useState(true);
  const [isMinimized, setIsMinimized] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [, setLang] = useState(i18n.language);
  const [width] = useWindowSize();
  const isMobile = useMemo(() => width <= 768, [width]);
  const isTablet = useMemo(() => width <= 920, [width]);

  const [showLang, setShowLang] = useState(false);

  useEffect(() => {
    if (isTablet && isTransactionManagerOpen && !isMinimized) {
      setShowSidebar(true);
      setIsMinimized(true);
    } else if (isMobile) {
      setShowSidebar(false);
      setIsMinimized(false);
    } else {
      setShowSidebar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, isTablet, isTransactionManagerOpen]);

  const handleChangeLang = (lang) => {
    i18n.changeLanguage(lang);
    setLang(lang);
    setShowLang(false);
  };

  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };
  const handleToggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleShowModalAndSidebar = () => {
    setShowModal(true);
    setShowSidebar(!showSidebar);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowTxm = () => {
    showTransactionManager();
  };

  const handleOpenLangSelector = () => {
    setShowLang(true);
    if (isMobile) {
      setShowSidebar(false);
    }
  };

  function handleChainChanged() {
    window.location.reload();
  }

  if (ethereum) {
    try {
      ethereum.on("chainChanged", handleChainChanged);
    } catch (error) {
      console.log(error);
    }
  }

  const navigation = () => (
    <>
      <SideBar
        showSidebar={showSidebar}
        handleToggleSidebar={handleToggleSidebar}
        isMobile={isMobile}
        sidebarMinimized={isMinimized}
        handleMinimize={handleMinimize}
        handleShowModal={isMobile ? handleShowModalAndSidebar : handleShowModal}
        setLang={handleChangeLang}
        setShowLang={handleOpenLangSelector}
      />
      <Header
        handleToggleSidebar={handleToggleSidebar}
        isMobile={isMobile}
        showModal={showModal}
        handleShowModal={handleShowModal}
        handleCloseModal={handleCloseModal}
        handleShowTxm={handleShowTxm}
      />
      {isTransactionManagerOpen ? (
        <TransactionManager
          open={isTransactionManagerOpen}
          txData={transactions.txData}
          txHistory={getTxHistory()}
        />
      ) : null}
      {showLang && (
        <LanguageSelector
          close={() => setShowLang(false)}
          changeLanguage={handleChangeLang}
        />
      )}
      {/* <Notification /> */}
      <Container isMinimized={isMinimized}>
        <Route exact path="/">
          <Redirect to="/dashboard" />
        </Route>

        <Switch>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route path="/rebalancer" component={Rebalancer} />
          <Route exact path="/earn/:platformCode?" component={Tokens} />
          <Route path="/swap/:platformCode?" component={SwapPlatforms} />
          <Route path="/deposit/:platformCode?" component={Deposit} />
          <Route
            path="/pools/remove-liquidity/:platformCode?"
            component={RemoveLiquidity}
          />
          <Route path="/pools/:tab?/:platformCode?" component={Pools} />
          <Route
            exact
            path="/borrow/:platform/:version/:asset"
            component={BorrowAsset}
          />
          <Route exact path="/borrow" component={Borrow} />
          <Route path="/info" component={Info} />
          <Route path="/transactions" component={Transactions} />
        </Switch>
      </Container>
    </>
  );
  return (
    <>
      <GlobalStyles />
      {navigation()}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  rates: state.rates.rates,
  isTransactionManagerOpen: state.transactions.isTransactionManagerOpen,
  transactions: state.transactions,
});

const mapDispatchToProps = (dispatch) => ({
  showTransactionManager: () => dispatch(showTransactionManager()),
  closeTransactionManager: () => dispatch(closeTransactionManager()),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
