import React from "react";

import Staking from "./Polygon/Staking";
import { TabWrapper } from "./shared";

const PolygonStaking = () => {
  return (
    <TabWrapper>
      <Staking />
    </TabWrapper>
  );
};

export default PolygonStaking;
