import React, { useState, useEffect } from "react";
import Tippy from "@tippyjs/react/headless";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  .tabs {
    margin-bottom: -1px;
  }
`;

const Tab = styled.span`
  display: inline-block;
  border-radius: 6px 6px 0px 0px;
  border: 1px solid ${(props) => props.theme.lightBlue3};
  background: ${(props) =>
    props.active ? props.theme.lightBlue3 : props.theme.white};
  color: ${(props) => props.theme.brandBlue};
  cursor: pointer;
  height: 30px;
  padding: 8px;
  font-size: 12px;
  font-weight: 500;

  :focus,
  :hover,
  :visited {
    background: ${(props) => props.theme.lightBlue3};
  }
  ${(props) =>
    props.disable &&
    css`
      background: white;
      color: ${(props) => props.theme.disabled};
      border: 1px solid ${(props) => props.theme.disabledBorder};
      cursor: default;
      :focus,
      :hover,
      :visited {
        background: 0;
      }
    `}
`;

const DisabledTabWrapper = styled.div`
  font-size: 10px;
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  background: ${(props) => props.theme.lightBlue2};
  .keyfitooltip_inside {
    color: ${(props) => props.theme.brandBlue};
  }
  .keyfitooltip_arrow {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 9px solid ${(props) => props.theme.lightBlue3};
    z-index: 13;
  }
`;

const DisabledTabTooltip = ({ title, ...attrs }) => (
  <DisabledTabWrapper {...attrs}>
    <div className="keyfitooltip_inside">{title}</div>
    <div className="keyfitooltip_arrow" data-popper-arrow="" />
  </DisabledTabWrapper>
);

// example on how to use Tabs
// const tabs = [
//     { Name: "tab1", Component: comp },
//     { Name: "tab2", Component: comp2 },
//   ];

function Tabs({ tabs, activeTabID, handleChange }) {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    if (activeTabID) {
      setActiveTab(activeTabID);
    }
  }, [tabs, activeTabID]);

  return (
    <Wrapper>
      {activeTab && (
        <>
          <div className="tabs">
            {tabs.map((t, index) =>
              t.disable ? (
                <Tippy
                  render={(attrs) => (
                    <DisabledTabTooltip title={t.disableTitle} {...attrs} />
                  )}
                  offset={[30, 8]}
                >
                  <Tab key={index} className="tabs__tab" disable>
                    {t.MobileName && window.innerWidth < 767
                      ? t.MobileName
                      : t.Name}
                  </Tab>
                </Tippy>
              ) : (
                <Tab
                  key={index}
                  className="tabs__tab"
                  active={t.Name === activeTab.Name}
                  onClick={() => {
                    if (handleChange) {
                      handleChange(tabs[index]);
                    } else {
                      setActiveTab(tabs[index]);
                    }
                  }}
                >
                  {t.MobileName && window.innerWidth < 767
                    ? t.MobileName
                    : t.Name}
                </Tab>
              )
            )}
          </div>

          <div className="tabs__component">{activeTab.Component}</div>
        </>
      )}
    </Wrapper>
  );
}

export default Tabs;
