import React, { useState, useEffect } from "react";
import timestamp from "time-stamp";
import { connect, useDispatch } from "react-redux";
import { integrations } from "@keyfi/keyfi-common";
import { useTranslation } from "react-i18next";
import PoolsCard from "../PoolsCard";
import { getAPYRatesOnPlatform, calculateAPY } from "../../helpers";
import { getTxHistory } from "../TransactionManager";
import { walletOperations } from "../../redux/walletSlice";
import {
  showTransactionManager,
  closeTransactionManager,
  addTx,
  removeTx,
} from "../../redux/transactionManager/actions";
import { Storage } from "../../helpers/Storage";
import { BoldTitle } from "../../Shared";

const Compound = ({
  user,
  rates,
  showTransactionManager,
  addTx,
  removeTx,
  setOption,
  selectedOption,
  selectedSupply,
  options,
  staking,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [hasInSufficientDepositBalance, setHasInSufficientDepositBalance] =
    useState(null);
  const [hasInSufficientWithdrawBalance, setHasInSufficientWithdrawBalance] =
    useState(null);

  const [hasInvalidDepositInput, setHasInvalidDepositInput] = useState(true);
  const [hasInvalidWithdrawInput, setHasInvalidWithdrawInput] = useState(true);

  const [inputsData, setInputsData] = useState({
    deposit: "",
    selectdeposit: selectedSupply,
    withdraw: "",
    selectwithdraw: "ETH",
  });
  const { deposit, selectdeposit, withdraw, selectwithdraw } = inputsData;

  const handleValidation = () => {
    // check max deposit balance
    const MaxDepositBalance = user.tokens[selectdeposit] ?? 0;

    setHasInSufficientDepositBalance(+deposit > +MaxDepositBalance);

    // check max withdraw balance
    const MaxWithdrawBalance = staking.mainnet.compound.v1[selectwithdraw]
      ? staking.mainnet.compound.v1[selectwithdraw].amount
      : 0;
    setHasInSufficientWithdrawBalance(+withdraw > +MaxWithdrawBalance);

    setHasInvalidDepositInput(!+deposit);
    setHasInvalidWithdrawInput(!+withdraw);
  };

  const getInputValues = (inputName, inputValue) => {
    setInputsData((prevState) => ({
      ...prevState,
      [inputName]: inputValue,
    }));
  };

  const HandleMaxInput = (name) => {
    if (name === "deposit" && user.tokens) {
      setInputsData((prevState) => ({
        ...prevState,
        deposit: user.tokens[inputsData.selectdeposit]
          ? user.tokens[inputsData.selectdeposit]
          : "0",
      }));
    }

    if (name === "withdraw" && staking.mainnet.compound.v1) {
      setInputsData((prevState) => ({
        ...prevState,
        withdraw: staking.mainnet.compound.v1[selectwithdraw]
          ? staking.mainnet.compound.v1[selectwithdraw].amount
          : "0",
      }));
    }
  };

  useEffect(() => {
    handleValidation();
  }, [inputsData]);

  const onDeposit = async () => {
    const { deposit, selectdeposit } = inputsData;

    // check max balance
    const MaxBalance = user.tokens[inputsData.selectdeposit] ?? 0;

    if (
      Number(deposit) &&
      selectdeposit &&
      Number(MaxBalance) >= Number(deposit)
    ) {
      const txHash = [];
      let isFirstTrxFired = false;
      try {
        const response = await integrations.compound.deposit(
          selectdeposit,
          deposit,
          {
            pendingCallback: (trx) => {
              if (!isFirstTrxFired) {
                showTransactionManager();
                addTx({
                  platform: "Compound",
                  txType: "Deposit",
                  txAmount: Number(deposit),
                  txToken: selectdeposit,
                  txHash: trx.hash,
                  timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
                  status: "pending",
                });
                isFirstTrxFired = true;
              }

              txHash.push(trx.hash);
            },
          }
        );
        if (response) {
          dispatch(walletOperations.loadUserData());
          const HistoryArray = getTxHistory();
          HistoryArray.unshift({
            platform: "Compound",
            txType: "Deposit",
            txAmount: Number(deposit),
            txToken: selectdeposit,
            txHash: response.transactionHash,
            timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
            status: "success",
          });
          Storage.setItem("transactions", JSON.stringify(HistoryArray));
          removeTx(txHash);
        }
      } catch (err) {
        const HistoryArray = getTxHistory();
        showTransactionManager();

        HistoryArray.unshift({
          platform: "Compound",
          txType: "Deposit",
          txAmount: Number(deposit),
          txToken: selectdeposit,
          txHash: txHash[txHash.length - 1],
          timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
          status: "failed",
        });
        Storage.setItem("transactions", JSON.stringify(HistoryArray));
        removeTx(txHash);
      }
    }

    // reset inputs
    setInputsData((prevState) => ({
      ...prevState,
      deposit: "",
      withdraw: "",
    }));
  };

  const onWithdraw = async () => {
    const { withdraw, selectwithdraw } = inputsData;

    // check max balance
    const MaxBalance = staking.mainnet.compound.v1[selectwithdraw]
      ? staking.mainnet.compound.v1[selectwithdraw].amount
      : 0;

    if (
      Number(withdraw) &&
      selectwithdraw &&
      Number(MaxBalance) >= Number(withdraw)
    ) {
      const txHash = [];
      let isFirstTrxFired = false;

      try {
        const response = await integrations.compound.withdraw(
          selectwithdraw,
          withdraw,
          {
            pendingCallback: (trx) => {
              if (!isFirstTrxFired) {
                showTransactionManager();
                addTx({
                  platform: "Compound",
                  txType: "Withdraw",
                  txAmount: Number(withdraw),
                  txToken: selectwithdraw,
                  txHash: trx.hash,
                  timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
                });
                isFirstTrxFired = true;
              }
              txHash.push(trx.hash);
            },
          }
        );
        if (response) {
          dispatch(walletOperations.loadUserData());
          const HistoryArray = getTxHistory();
          HistoryArray.unshift({
            platform: "Compound",
            txType: "Withdraw",
            txAmount: Number(withdraw),
            txToken: selectwithdraw,
            txHash: response.transactionHash,
            timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
          });
          Storage.setItem("transactions", JSON.stringify(HistoryArray));
          removeTx(txHash);
        }
      } catch (err) {
        const HistoryArray = getTxHistory();
        showTransactionManager();

        HistoryArray.unshift({
          platform: "Compound",
          txType: "Withdraw",
          txAmount: Number(withdraw),
          txToken: selectwithdraw,
          txHash: txHash[txHash.length - 1],
          timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
          status: "failed",
        });
        Storage.setItem("transactions", JSON.stringify(HistoryArray));
        removeTx(txHash);
      }
    }
    // reset inputs
    setInputsData((prevState) => ({
      ...prevState,
      deposit: "",
      withdraw: "",
    }));
  };

  return (
    <>
      <BoldTitle>{t("deposit.title")}</BoldTitle>
      <PoolsCard
        balance={staking.mainnet.compound.v1}
        APY={calculateAPY(
          user.compound,
          user.usdPrices,
          getAPYRatesOnPlatform(rates, "compound")
        )}
        updateParentInput={getInputValues}
        input1Name="deposit"
        input2Name="withdraw"
        defaultSelected1={inputsData.selectdeposit}
        defaultSelected2={inputsData.selectwithdraw}
        onDeposit={onDeposit}
        onWithdraw={onWithdraw}
        platform="compound"
        selectdeposit={inputsData.selectdeposit}
        selectwithdraw={inputsData.selectwithdraw}
        value1={inputsData.deposit}
        value2={inputsData.withdraw}
        handleMaxInput={HandleMaxInput}
        filterZeroBalance
        filterZeroPlatformBalance
        setOption={setOption}
        selectedOption={selectedOption}
        options={options}
        hasInSufficientDepositBalance={hasInSufficientDepositBalance}
        hasInSufficientWithdrawBalance={hasInSufficientWithdrawBalance}
        hasInvalidDepositInput={hasInvalidDepositInput}
        hasInvalidWithdrawInput={hasInvalidWithdrawInput}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  rates: state.rates.rates,
  staking: state.staking,
  isTransactionManagerOpen: state.transactions.isTransactionManagerOpen,
});
const mapDispatchToProps = (dispatch) => ({
  showTransactionManager: () => dispatch(showTransactionManager()),
  closeTransactionManager: () => dispatch(closeTransactionManager()),
  addTx: (tx) => dispatch(addTx(tx)),
  removeTx: (txHash) => dispatch(removeTx(txHash)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Compound);
