import React from "react";
import styled from "styled-components";
import Tippy from "@tippyjs/react/headless";
import PlatformsDropdown from "../../../PlatformsDropdown";
import { platforms, VersionButtons } from "../../SharedLiquidity";

// PancakeSwap Versions
import V2 from "./v2";
import { useTranslation } from "react-i18next";

const DisabledTippyWrapper = styled.div`
  background: ${(props) => props.theme.lightBlue3};
  border-radius: 6px;
  .keyfitooltip_inside {
    font-size: 12px;
    padding: 0.5rem;
    font-weight: 500;
    color: ${(props) => props.theme.brandBlue};
  }

  .keyfitooltip_arrow {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${(props) => props.theme.lightBlue3};
    z-index: 13;
  }
`;

const DisabledVersionTippy = ({ title, ...attrs }) => {
  return (
    <DisabledTippyWrapper {...attrs}>
      <div className="keyfitooltip_inside">{title}</div>
      <div className="keyfitooltip_arrow" data-popper-arrow="" />
    </DisabledTippyWrapper>
  );
};

const PancakeSwapAddLiquidity = ({
  handlePlatformSwitch,
  selectedPlatform,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="add-liquidity-platform">
        <div className="add-liquidity-platform-label">
          {t("pool.addLiquidity.choosePlatform")}:
        </div>
        <PlatformsDropdown
          setOption={handlePlatformSwitch}
          selectedOption={selectedPlatform}
          options={platforms}
        />
        <VersionButtons>
          <button className="liquidity-version-selected">V2</button>
          <Tippy
            render={(attrs) => (
              <DisabledVersionTippy
                title={t("pool.addLiquidity.pancakeSwapWarningMsg")}
                {...attrs}
              />
            )}
            offset={[100, 10]}
          >
            <button className="liquidity-version-disabled">V1 (old)</button>
          </Tippy>
        </VersionButtons>
      </div>
      <V2 />
    </>
  );
};

export default PancakeSwapAddLiquidity;
