import { calculateTotal, getTokensTotalFromPairs, poolsLogos } from "./helpers";
import { liquidityOperations } from "./redux/liquiditySlice";
import { stakingOperations } from "./redux/stakingSlice";

export const dashboardSupportedTokens = {
  mainnet: [
    "DAI",
    "USDT",
    "USDC",
    "TUSD",
    "WBTC",
    "LINK",
    "BAT",
    "KEY",
    "1INCH",
    "AMPL",
    "AXS",
    "BAL",
    "BAND",
    "BUSD",
    "CEL",
    "CREAM",
    "DIA",
    "DYT",
    "ENJ",
    "FYZ",
    "HEGIC",
    "HEX",
    "KNC",
    "KP3R",
    "LEND",
    "MANA",
    "MKR",
    "OCEAN",
    "OMG",
    "REN",
    "REP",
    "RLC",
    "RSR",
    "SLP",
    "SNX",
    "STAKE",
    "sUSD",
    "SUSHI",
    "SXP",
    "UBT",
    "WETH",
    "XOR",
    "YFI",
    "ZRX",
    "DPI",
  ],
  "bsc-mainnet": [
    "BUSD",
    "DAI",
    "USDT",
    "wBNB",
    "ADA",
    "ALICE",
    "ALPHA",
    "ATOM",
    "BANANA",
    "BAKE",
    "BAND",
    "BCH",
    "BDO",
    "BEL",
    "BELT",
    "BIFI",
    "BSCX",
    "BTCB",
    "BTCST",
    "BURGER",
    "BUSD",
    "CAKE",
    "COMP",
    "DODO",
    "DOT",
    "EGLD",
    "EOS",
    "ETH",
    "FIL",
    "FOR",
    "FRONT",
    "INJ",
    "LINA",
    "LINK",
    "LIT",
    "LTC",
    "LTO",
    "OG",
    "RAMP",
    "REEF",
    "SFP",
    "STAX",
    "SUSHI",
    "SXP",
    "TPT",
    "TWT",
    "UNFI",
    "UNI",
    "UST",
    "USDC",
    "VAI",
    "WATCH",
    "XRP",
    "XTZ",
    "XVS",
    "YFI",
    "YFII",
    "ZEE",
    "ZIL",
    "BAT",
    "DOGE",
    "AUTO",
    "SNX",
    "NEAR",
    "COTI",
    "AVAX",
    "MATIC",
    "AAVE",
    "ETC",
    "FTM",
    "NRV",
    "ZEC",
    "CELR",
  ],
  "bsc-testnet": [],
};

export const mainAssets = {
  mainnet: "ETH",
  "bsc-mainnet": "BNB",
  "bsc-testnet": "BNB",
};
export const governanceTokens = {
  mainnet: ["KEYFI", "UNI", "COMP", "CRV", "AAVE"],
  "bsc-mainnet": ["KEYFI"],
  "bsc-testnet": ["KEYFI"],
  polygon: ["KEYFI", "CRV", "AAVE"],
};

export const stableCoins = {
  mainnet: ["DAI", "USDC", "USDT", "TUSD", "BUSD", "sUSD"],
  "bsc-mainnet": ["BUSD", "DAI", "USDT", "USDC"],
  "bsc-testnet": ["BUSD"],
  polygon: ["DAI", "USDC", "USDT"],
};

const utilityTokens = {
  mainnet: ["KEYFIUSDCLP", "KEYFIETH_LP"],
  "bsc-mainnet": ["KEYFIBUSD_LP", "KEYFIBUSDLPv2"],
  "bsc-testnet": [],
};

export const erc20BalanceTokens = Object.keys(dashboardSupportedTokens).reduce(
  (tokens, networkName) => {
    tokens[networkName] = dashboardSupportedTokens[networkName]
      .concat(governanceTokens[networkName])
      .concat(utilityTokens[networkName]);
    return tokens;
  },
  {}
);

export const getDashboardPlatforms = (dispatch, prices) => {
  const data = [
    { name: "Aave", icon: poolsLogos.Aave, network: "mainnet" },
    {
      name: "Compound",
      icon: poolsLogos.Compound,
      network: "mainnet",
    },
    { name: "dYdX", network: "mainnet", icon: poolsLogos.dydx },
    { name: "KeyFi", network: "all", icon: poolsLogos.KeyFi },
    { name: "Alkemi", network: "mainnet", icon: poolsLogos.Alkemi },
    {
      name: "Curve",
      network: "mainnet",
      icon: poolsLogos.Curve,
      category: "lp",
    },
    {
      name: "Uniswap",
      icon: poolsLogos.Uniswap,
      network: "mainnet",
      category: "lp",
    },
    {
      name: "PancakeSwap",
      network: "bsc-mainnet",
      icon: poolsLogos.PancakeSwap,
      category: "lp",
    },
    {
      name: "ApeSwap",
      network: "bsc-mainnet",
      icon: poolsLogos.ApeSwap,
      category: "lp",
    },
    {
      name: "ApeSwap",
      network: "polygon",
      icon: poolsLogos.ApeSwap,
      category: "lp",
    },
    {
      name: "QuickSwap",
      network: "polygon",
      icon: poolsLogos.QuickSwap,
      category: "lp",
    },
  ].map((item) => {
    if (item.category && item.category === "lp") {
      item.total = calculateTotal(
        getTokensTotalFromPairs(
          dispatch(
            liquidityOperations.getAllPlatformLiquidityTokens(
              item.name.toLowerCase()
            )
          )
        ),
        prices
      );
      return item;
    }
    item.total = dispatch(
      stakingOperations.getTotalStakePlatformValue(item.name.toLowerCase())
    );
    return item;
  });

  return data;
};
