import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { Divider } from "../../Shared";
import Table from "./Table";
import { breakPoints, StyledLink } from "../../theme";
import { poolsLogos, tokensLogos } from "../../helpers";
import { getRates } from "../../redux/rates/actions";
import Card from "./Card";
import { stakingOperations } from "../../redux/stakingSlice";

const platforms = ["Compound", "Aave", "dydx"];

const ExtendedLink = styled(StyledLink)`
  color: ${(props) => props.theme.text_secondary};
`;

export const Container = styled.div`
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${(props) => props.theme.mainDarkText};

  svg,
  img {
    margin-right: 10px;
    margin-left: 5px;
  }
  span {
    font-size: 16px;
    line-height: 16px;
    color: ${(props) => props.theme.brandBlue};
  }

  @media (max-width: ${breakPoints.lg}px) {
    font-size: 12px;
  }
`;
const Title = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.mainDarkText};
  background: ${(props) => props.theme.lightBlue3};
  padding: 16px 24px;
  margin-bottom: 1.5em;

  .topInterestRate {
    color: ${(props) => props.theme.brandBlue};
    font-family: "Telex", sans-serif;
    font-size: 14px;
  }

  @media (max-width: ${breakPoints.laptop}px) {
    display: flex;
    align-items: baseline;

    span {
      font-size: 14px;
    }
  }

  @media (max-width: ${breakPoints.md}px) {
    flex-direction: column;
    padding: 16px;
  }

  svg,
  img {
    width: 24px;
    height: 24px;
  }
`;

export const RebalancerScreenWrapper = styled.div`
  margin-bottom: 2em;
  .PlatformsCards {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));

    @media (max-width: ${breakPoints.sm}px) {
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    }
  }
`;

export const renderPlatformLink = (title, text, asset) => {
  let str = title.toLowerCase();

  if (title.includes("C.")) str = "cream";
  if (title.includes("Aave")) str = "aave";

  const version = title.includes(" V")
    ? title.split(" ")[1].toLowerCase()
    : "v1";

  return (
    <ExtendedLink to={`/deposit/${str}?asset=${asset}&v=${version}`}>
      {text}
    </ExtendedLink>
  );
};

const stableCoins = ["DAI", "USDC", "USDT", "TUSD", "BUSD", "sUSD"];

export const TotalInvestRate = ({ t, localRates }) => (
  <Title>
    <span className="topInterestRate">{t("rebalancer.topInterestRate")}: </span>
    {localRates.length ? (
      <Container>
        <span>{localRates[0].rate}</span>
        &nbsp;
        <span> {localRates[0].asset}</span>
        {tokensLogos[localRates[0].asset]}
        <span
          style={{ marginRight: "10px", fontWeight: 600, color: "#3B474E" }}
        >
          {" "}
          @{" "}
        </span>
        {localRates[0].platform}
        {poolsLogos[localRates[0].platform]}
      </Container>
    ) : null}
  </Title>
);

function StableCoins({ rates, user }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [localRates, setLocalRates] = useState([]);

  const tableHeadItems = [
    { id: "rank", label: t("rebalancer.rank"), sortable: true },
    { id: "asset", label: t("dashboard.platforms.asset"), sortable: true },
    { id: "rate", label: t("rebalancer.supplyRate"), sortable: true },
    { id: "assetPrice", label: t("rebalancer.price"), sortable: true },
    { id: "borrowRate", label: t("rebalancer.borrowRate"), sortable: true },
    { id: "platform", label: t("rebalancer.platform"), sortable: true },
    { id: "action", label: t("rebalancer.action"), sortable: false },
  ];

  const filteredStableCoinsTokensList = (platform, dispatch) => {
    const balance = dispatch(stakingOperations.getPlatformAllTokens(platform));
    return Object.entries(balance)
      .filter(
        ([symbol, value]) => value.amount > 0 && stableCoins.includes(symbol)
      )
      .slice(0, 6);
  };

  useEffect(() => {
    if (rates.length) {
      // TODO: remove that excessive filtering
      let filterStableCoins = rates.filter(
        (entry, index) =>
          stableCoins.includes(entry.asset) &&
          rates.findIndex(
            (obj) =>
              obj.asset === entry.asset && obj.platform === entry.platform
          ) === index
      );

      filterStableCoins.sort((a, b) => b.rate - a.rate);
      filterStableCoins.forEach((rate, index) => {
        filterStableCoins[index].rank = index + 1;
        filterStableCoins[index].action = user.readOnly
          ? ""
          : renderPlatformLink(
              filterStableCoins[index].platform,
              t("rebalancer.manageAssets"),
              filterStableCoins[index].asset
            );
      });
      filterStableCoins = filterStableCoins.map((entry) => ({
        rank: entry.rank,
        asset: entry.asset,
        rate: `${String(entry.rate).slice(0, 6)} %`,
        assetPrice: `$${String(
          entry.asset === "sUSD" ? 1 : entry.assetPrice
        ).slice(0, 7)} USD`,
        borrowRate: `${String(entry.borrowRate).slice(0, 4)} %`,
        platform:
          entry.platform === "C.R.E.A.M. Finance"
            ? "C.R.E.A.M."
            : entry.platform === "dYdX"
            ? "dydx"
            : entry.platform.charAt(0).toUpperCase() + entry.platform.slice(1),
        action: entry.action,
      }));
      setLocalRates(filterStableCoins);
    }
  }, [rates]);
  return (
    <RebalancerScreenWrapper>
      <div
        style={{
          padding: "1.5em",
          background: "#F6FAFF",
          borderRadius: "0 6px 6px 6px",
          border: "1px solid #D4E7FF",
        }}
      >
        <TotalInvestRate localRates={localRates} t={t} />
        {!user.readOnly && (
          <>
            <div className="PlatformsCards">
              {platforms.map((p) => (
                <Card
                  key={p}
                  title={p}
                  platform={p.toLowerCase()}
                  tokenList={filteredStableCoinsTokensList(p, dispatch)}
                />
              ))}
            </div>
            <Divider />
          </>
        )}

        {localRates.length ? (
          <Table
            rows={localRates}
            tableHeadItems={
              user.readOnly ? tableHeadItems.slice(0, -1) : tableHeadItems
            }
            title={t("rebalancer.stableCoins")}
          />
        ) : null}
      </div>
    </RebalancerScreenWrapper>
  );
}

const mapStateToProps = (state) => ({
  rates: state.rates.rates,
  user: state.user,
});

const mapDispatchtoProps = (dispatch) => ({
  getRates: () => dispatch(getRates()),
});
export default connect(mapStateToProps, mapDispatchtoProps)(StableCoins);
