import { combineReducers } from "redux";
import { appReducer } from "./appSlice";
import ratesReducer from "./rates/reducers";
import gasReducer from "./gasPrices/reducers";
import userReducer from "./user/reducers";
import transactionReducer from "./transactionManager/reducers";

import { walletReducer } from "./walletSlice";
import { liquidityReducer } from "./liquiditySlice";
import { borrowReducer } from "./borrowSlice";
import { stakingReducer } from "./stakingSlice";
import { keyfiDataReducer } from "./keyfiDataSlice";

export default combineReducers({
  user: userReducer,
  liquidity: liquidityReducer,
  staking: stakingReducer,
  rates: ratesReducer,
  wallet: walletReducer,
  borrow: borrowReducer,
  app: appReducer,
  transactions: transactionReducer,
  gas: gasReducer,
  keyfiData: keyfiDataReducer,
});
