import { useSelector } from "react-redux";
import styled from "styled-components";
import { beautifyTokensAmount } from "../../helpers";
import { Skeleton } from "../../Shared";
import { breakPoints } from "../../theme";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Tippy from "@tippyjs/react/headless";
import KeyFiTooltip from "../KeyFiTooltip";
import { useTranslation } from "react-i18next";

export const TabWrapper = styled.div`
  width: 100%;
  padding: 1.5rem;
  background: white;
  border: 1px solid ${(props) => props.theme.border2};
  border-radius: 0 6px 6px 6px;

  /* .flash {
    animation: fade 300ms linear;
  }
  @keyframes fade {
    0% {
      opacity: 1;
    }
    20% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  } */
  @media (max-width: ${breakPoints.lg}px) {
    padding: 0.75rem;
  }
`;

export const AccordionWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.border2};
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: ${(props) => (props.open ? `2.5rem` : `1.5rem`)};
  @media (max-width: ${breakPoints.lg}px) {
    margin-bottom: 1.5rem;
  }
  :last-of-type {
    margin: 0;
  }
  .accordion_header {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${(props) => props.theme.brandBlue};
    padding: 1rem;
    background: linear-gradient(
      101.25deg,
      #d2e6ff 0%,
      rgba(246, 250, 255, 0.5) 100%
    );
    border-bottom: ${(props) =>
      props.open && `1px solid ${props.theme.border2}`};
    .accordion_header_title {
      display: flex;
      align-items: center;
      font-weight: 500;
      svg {
        margin-right: 0.4rem;
        path {
          fill: ${(props) => props.theme.brandBlue};
        }
      }
      .accordion_subtitle {
        font-size: 14px;
        color: #8a9fb2;
        margin-left: 0.5rem;
        @media (max-width: ${breakPoints.lg}px) {
          font-size: 12px;
        }
      }
    }

    .accordion_arrow {
      transform: ${(props) => props.open && `rotate(180deg)`};
      path {
        fill: #3b474e;
      }
    }
  }
`;

export const AccordionList = styled.div`
  background: white;
  padding: ${(props) => props.padding && "1rem"};
  > div {
    padding: 0.75rem 1rem;
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.border2};
    background: ${(props) => props.theme.superLightBlue};
  }
`;

export const TokenCardWrapper = styled.div`
  margin-bottom: 1rem;
  font-weight: 500;
  :hover {
    background: #e6f1ff;
    border-color: ${(props) => props.theme.brandBlue};
    box-shadow: 0px 0px 6px rgba(0, 90, 211, 0.25);
  }
  .tokens_card_header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;

    > div {
      flex: 1;
    }
    .tokens_card_title {
      flex: 1.25;
      display: flex;
      align-items: center;
      color: ${(props) => props.theme.brandBlue};
      font-size: 16px;
      svg,
      img {
        height: 32px;
        width: 32px;
        margin-right: 0.5rem;
        min-width: ${(props) => props.bigLogo && "55px"};
      }
      @media (max-width: ${breakPoints.lg}px) {
        flex: 2;
        svg,
        img {
          width: ${(props) => props.bigLogo && "55px"};
          object-fit: contain;
        }
      }
    }
    .tokens_card_staked {
      flex: 1.25;
    }
    .tokens_card_earned,
    .tokens_card_staked {
      display: flex;
      > span,
      > div {
        margin-left: 0.25rem;
      }
      @media (max-width: ${breakPoints.lg}px) {
        display: none;
      }
    }
    .tokens_card_learn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: right;
      font-weight: 500;
      text-decoration: underline;
      @media (max-width: ${breakPoints.lg}px) {
        font-size: 0;
      }
      svg {
        transform: ${(props) => props.open && `rotate(180deg)`};
        margin-left: 0.5rem;
        max-width: 18px;
        path {
          fill: ${(props) => props.theme.mainDarkText};
        }
      }
    }
    .tokens_value {
      margin: 0;
      color: ${(props) => props.theme.brandBlue};
    }
  }
  :last-of-type {
    margin: 0;
  }
`;

export const TokensCardMainSection = styled.div`
  display: flex;
  gap: 1.25rem;
  margin: 0.75rem 0 1.25rem;
  @media (min-width: 1400px) {
    gap: 2.2rem;
  }

  .tokens_card_values_mobile {
    display: none;
  }

  .tokens_card_poolinfo {
    flex: 1.7;
    font-size: 12px;
    * {
      color: ${(props) => props.theme.mainDarkText};
    }
    > div {
      display: flex;
      flex-direction: column;
      margin-bottom: 5px;
      @media (max-width: ${breakPoints.lg}px) {
        flex-direction: row;
        justify-content: space-between;
      }
      div {
        margin-bottom: 5px;
        :last-of-type {
          margin: 0;
        }
      }
      span {
        text-align: left;
        flex: 1.2;
      }
      .tokens_poolinfo_value {
        margin-left: 0.5rem;
        flex: 0.9;
        color: ${(props) => props.theme.brandBlue};
      }
    }
    a {
      text-decoration: underline;
      svg {
        margin-bottom: -0.1rem;
        margin-left: 0.1rem;
      }
    }
  }
  @media (max-width: ${breakPoints.lg}px) {
    flex-direction: column;
    .tokens_card_values_mobile {
      display: block;
      font-size: 12px;
      > div {
        margin-bottom: 4px;
      }
      .tokens_value {
        color: ${(props) => props.theme.brandBlue};
      }
    }
  }
`;

export const TokensCardButton = styled.button`
  cursor: ${(props) => (props.isDisabled ? `normal` : `pointer`)};
  display: flex;
  justify-content: center;
  margin: 10px 0 0 auto;
  padding: 6px 10px;
  min-width: 70%;
  font-size: 16px;
  color: white;
  background: ${(props) =>
    props.isDisabled ? props.theme.placeholder2 : props.theme.brandBlue};
  outline: none;
  border: none;
  border-radius: 6px;
  @media (max-width: ${breakPoints.lg}px) {
    width: 100%;
  }
  :disabled {
    pointer-events: all;
  }
`;

const TokenCardInputWrapper = styled.div`
  flex: 1.9;
  .tokens_inputsection_title {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: ${(props) => props.theme.mainDarkText};
    margin-bottom: 0.5rem;
  }
  .tokens_card_input {
    display: flex;
    padding: 0.75rem;
    background: white;
    align-items: center;
    border-radius: 6px;
    border: 1px solid
      ${(props) => (props.error ? `#EB5757 !important` : props.theme.border2)};
    box-shadow: ${(props) =>
      props.error && `0px 0px 6px rgba(235, 87, 87, 0.25) !important`};
    gap: 0.5rem;
    :focus-within {
      border-color: ${(props) => props.theme.brandBlue};
      box-shadow: 0px 0px 6px rgba(0, 90, 211, 0.25);
    }
    input {
      text-align: right;
      min-width: 0;
      width: 100%;
      flex: 8;
      background: none;
      outline: none;
      border: none;
      font-family: Montserrat;
      font-size: 16px;
      color: ${(props) => props.error && `#EB5757`};
      font-weight: 500;
      ::placeholder {
        color: #698095;
      }
    }

    .tokens_input_max {
      cursor: pointer;
      padding: 3px 8px;
      font-size: 12px;
      border-radius: 6px;
      background: ${(props) => props.theme.mainDarkText};
      color: white;
      height: max-content;
    }
  }
  .card_input_rewards {
    input {
      width: 100%;
    }
  }
`;

export const TokenCardInput = ({
  name,
  inputValue,
  updateValue,
  disabled,
  disabledTooltip,
  showTokens,
  showTokensSuffix,
  onClick,
  buttonTitle,
  label,
  handleMaxInput,
  error,
  animation,
}) => {
  const isFetching = useSelector((state) => state.app.isLoading);

  const onChange = (e) => {
    // filter non-numric input values
    if (updateValue) {
      let str = "";
      if (e.target.value.match(/\d|\./g)) {
        str = e.target.value.match(/\d|\./g).join("");
      }

      updateValue(e.target.name, str);
    }
  };

  if (disabledTooltip) {
    console.log(disabledTooltip);
  }

  const { t } = useTranslation();

  return (
    <TokenCardInputWrapper handleMax={handleMaxInput} error={error}>
      <div className="tokens_inputsection_title">
        <div>{label}</div>
        <div>
          {!isFetching ? (
            <span className="tokens_value">
              {beautifyTokensAmount(showTokens, {
                suffix: showTokensSuffix,
              })}
            </span>
          ) : (
            <Skeleton className="value" white="true" height="20" width="80" />
          )}
        </div>
      </div>
      <div className="tokens_card_input">
        <TransitionGroup>
          <CSSTransition
            key={animation ? inputValue : ""}
            classNames="flash"
            timeout={300}
          >
            <input
              className="flash"
              placeholder="0.00"
              name={name}
              onChange={onChange}
              value={inputValue}
              disabled={!updateValue && "disabled"}
              autoComplete="off"
            />
          </CSSTransition>
        </TransitionGroup>
        {handleMaxInput && (
          <div className="tokens_input_max" onClick={handleMaxInput}>
            {t("pool.addLiquidity.max")}
          </div>
        )}
      </div>
      {disabledTooltip ? (
        <Tippy
          render={(attrs) => (
            <KeyFiTooltip title={disabledTooltip} {...attrs} />
          )}
        >
          <TokensCardButton
            onClick={!disabled && onClick}
            isDisabled={disabled}
          >
            {error ? t("addition.insufficentBalance") : buttonTitle}
          </TokensCardButton>
        </Tippy>
      ) : (
        <TokensCardButton onClick={!disabled && onClick} isDisabled={disabled}>
          {error ? t("addition.insufficentBalance") : buttonTitle}
        </TokensCardButton>
      )}
    </TokenCardInputWrapper>
  );
};
