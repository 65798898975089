import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { platforms } from "../SharedLiquidity";
import ApeSwapAddLiquidity from "./ApeSwap";
import CurveAddLiquidity from "./Curve";
import PancakeSwapAddLiquidity from "./PancakeSwap";
import UniswapAddLiquidity from "./Uniswap";

const AddLiquidityWrapper = styled.div`
  background: ${(props) => props.theme.superLightBlue};
  border: 1px solid ${(props) => props.theme.border3};
  border-radius: 0 6px 6px 6px;
  padding: 1.5rem;
  .add-liquidity-info {
    background: ${(props) => props.theme.lightBlue3};
    padding: 1rem;
    border-radius: 6px;
    color: ${(props) => props.theme.brandBlue};
    font-size: 12px;
  }
  .add-liquidity-platform {
    margin-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid ${(props) => props.theme.border2};

    .add-liquidity-platform-label {
      font-size: 12px;
      color: ${(props) => props.theme.mainDarkText};
      margin-bottom: 0.5rem;
      font-weight: 500;
    }
  }
`;

const AddLiquidity = () => {
  const { t } = useTranslation();

  const { platformCode } = useParams();
  const history = useHistory();

  const [selectedPlatform, setSelectedPlatform] = useState({
    value: null,
  });

  const handlePlatformSwitch = (value) => {
    history.push(`/pools/add-liquidity/${value}`);
  };

  useEffect(() => {
    if (platformCode) {
      const platform = platforms.find((item) => item.value === platformCode);
      setSelectedPlatform(platform);
    }
  }, [platformCode]);

  const handleAddLiquidityPlatform = (value) => {
    switch (value) {
      case "pancakeswap":
        return (
          <PancakeSwapAddLiquidity
            handlePlatformSwitch={handlePlatformSwitch}
            selectedPlatform={selectedPlatform}
          />
        );
      case "apeswap":
        return (
          <ApeSwapAddLiquidity
            handlePlatformSwitch={handlePlatformSwitch}
            selectedPlatform={selectedPlatform}
          />
        );
      case "uniswap":
        return (
          <UniswapAddLiquidity
            handlePlatformSwitch={handlePlatformSwitch}
            selectedPlatform={selectedPlatform}
          />
        );
      case "curve":
        return (
          <CurveAddLiquidity
            handlePlatformSwitch={handlePlatformSwitch}
            selectedPlatform={selectedPlatform}
          />
        );
      default:
        return;
    }
  };

  return (
    <AddLiquidityWrapper>
      <div className="add-liquidity-info">
        {t("pool.addLiquidity.description")}{" "}
      </div>
      {selectedPlatform.value &&
        handleAddLiquidityPlatform(selectedPlatform.value)}
    </AddLiquidityWrapper>
  );
};

export default AddLiquidity;
