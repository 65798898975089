import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  calculateTotal,
  getTokensFromStakingNetwork,
  mergeAmount,
} from "../helpers";

const FETCH_URL = process.env.REACT_APP_BALANCE_API_URL;

const initialState = {
  isFetching: true,
  mainnet: {
    aave: {
      v1: {},
      v2: {},
    },
    compound: {
      v1: {},
    },
    dydx: {
      v1: {},
    },
    keyfi: {
      v1: {},
      v2: {},
    },
  },
  "bsc-mainnet": {
    keyfi: {
      v1: {},
    },
  },
  polygon: {
    keyfi: {
      v1: {},
    },
  },
};

const staking = createSlice({
  name: "staking",
  initialState,
  reducers: {
    setMainnet(state, action) {
      state.mainnet = action.payload;
    },
    setBSCMainnet(state, action) {
      state["bsc-mainnet"] = action.payload;
    },
    setPolygon(state, action) {
      state.polygon = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
  },
});

export const stakingActions = staking.actions;

const getRoot = (state) => state.staking;

export const stakingSelectors = {
  getMainnet: (state) => getRoot(state).mainnet,
  getBSCMainnet: (state) => getRoot(state)["bsc-mainnet"],
};

export const stakingOperations = {
  loadStaking: (address, network) => async (dispatch, getState) => {
    try {
      if (!address) {
        return [];
      }

      if (network.name === "bsc-mainnet") {
        const { data } = await axios.get(
          `${FETCH_URL}/staking?address=${address}&network=bsc-mainnet&ignoreCache=true`
        );
        dispatch(stakingActions.setBSCMainnet(data));
      }

      if (network.name === "mainnet") {
        const { data: data2 } = await axios.get(
          `${FETCH_URL}/staking?address=${address}&network=mainnet&ignoreCache=true`
        );
        dispatch(stakingActions.setMainnet(data2));
      }

      if (network.name === "polygon") {
        const { data: data3 } = await axios.get(
          `${FETCH_URL}/staking?address=${address}&network=polygon&ignoreCache=true`
        );
        dispatch(stakingActions.setPolygon(data3));
      }

      const { staking } = getState();

      const allMainnetStaking = getTokensFromStakingNetwork(staking.mainnet);
      const allBSCMainnetStaking = getTokensFromStakingNetwork(
        staking["bsc-mainnet"]
      );
      const allPolygonStaking = getTokensFromStakingNetwork(staking.polygon);

      dispatch(stakingActions.setIsFetching(false));
      return {
        ...allMainnetStaking,
        ...allBSCMainnetStaking,
        ...allPolygonStaking,
      };
    } catch (err) {
      dispatch(stakingActions.setIsFetching(false));
      throw new Error(err.message);
    }
  },
  getTotalStakePlatformValue: (platform) => (dispatch, getState) => {
    const { user } = getState();

    const platformTokens = dispatch(
      stakingOperations.getPlatformAllTokens(platform)
    );
    const totalValue = Object.entries(platformTokens).reduce(
      (acc, [key, value]) => {
        if (user.usdPrices) {
          if (value.type === "lp") {
            const [assetA, assetB] = [
              key.split(":")[0],
              key.split(":")[1].split(" ")[0],
            ];

            const assetAValue = user.usdPrices[assetA] * value[assetA];
            const assetBValue = user.usdPrices[assetB] * value[assetB];

            return acc + assetAValue + assetBValue;
          }
          return acc + value.amount * user.usdPrices[key] ?? 0;
        }
        return 0;
      },
      0
    );

    return totalValue || 0;
  },
  getTotalStakeNetworkValue: (network) => (_, getState) => {
    const { staking, user } = getState();
    const totalValue = calculateTotal(
      getTokensFromStakingNetwork(staking[network]),
      user.usdPrices
    );

    return totalValue;
  },
  getTotalStakeValue: () => (_, getState) => {
    const { staking, user } = getState();

    return Object.keys(staking).reduce(
      (acc, network) =>
        acc +
        calculateTotal(
          getTokensFromStakingNetwork(staking[network]),
          user.usdPrices
        ),
      0
    );
  },
  getPlatformAllTokens: (platformName) => (_, getState) => {
    const { staking } = getState();

    return Object.values(staking).reduce((acc, network) => {
      if (network[platformName]) {
        const data2 = Object.entries(network[platformName]).reduce(
          (acc2, [versionKey, versionValue]) =>
            mergeAmount(versionKey, versionValue, acc2),
          {}
        );
        return { ...acc, ...data2 };
      }
      return acc;
    }, {});
  },
};

export const stakingReducer = staking.reducer;
