import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import styled, { css } from "styled-components";

// Assets and Components
import { ReactComponent as ArrowDown } from "../../../assets/arrowDown.svg";
import { platforms } from "../SharedLiquidity";

import ApeSwapUserLiquidity from "./ApeSwapUserLiquidity";
import CurveUserLiquidity from "./CurveUserLiquidity";
import PancakeSwapUserLiquidity from "./PancakeSwapUserLiquidity";
import UniswapUserLiquidity from "./UniswapUserLiquidity";

const Wrapper = styled.div`
  padding: 1.5rem;
  background: ${(props) => props.theme.superLightBlue};
  border: 1px solid ${(props) => props.theme.border2};
  border-radius: 0 6px 6px 6px;
  .user-liquidity-platform {
    margin-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid ${(props) => props.theme.border2};
    .user-liquidity-platform-label {
      font-size: 12px;
      margin-bottom: 0.5rem;
    }
  }
`;

const UserLiquidityAccordion = styled.div`
  cursor: pointer;
  background: ${(props) => props.theme.border2};
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  font-size: 12px;

  color: ${(props) => props.theme.brandBlue};
  svg {
    margin-left: 0.5rem;
    height: 17px;
    width: 17px;
    ${(props) =>
      props.open &&
      css`
        transform: rotate(180deg);
      `}
    path {
      fill: ${(props) => props.theme.brandBlue};
    }
  }
`;

const UserLiquidity = () => {
  const { t } = useTranslation();

  const { platformCode } = useParams();
  const history = useHistory();

  const [openAcc, setOpenAcc] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState({
    value: null,
  });

  const handlePlatformSwitch = (value) => {
    history.push(`/pools/my-liquidity/${value}`);
  };

  useEffect(() => {
    if (platformCode) {
      const platform = platforms.find((item) => item.value === platformCode);
      setSelectedPlatform(platform);
    }
  }, [platformCode]);

  const handleShowUserLiquidityPlatform = (value) => {
    switch (value) {
      case "pancakeswap":
        return (
          <PancakeSwapUserLiquidity
            handlePlatformSwitch={handlePlatformSwitch}
            selectedPlatform={selectedPlatform}
          />
        );
      case "apeswap":
        return (
          <ApeSwapUserLiquidity
            handlePlatformSwitch={handlePlatformSwitch}
            selectedPlatform={selectedPlatform}
          />
        );
      case "uniswap":
        return (
          <UniswapUserLiquidity
            handlePlatformSwitch={handlePlatformSwitch}
            selectedPlatform={selectedPlatform}
          />
        );
      case "curve":
        return (
          <CurveUserLiquidity
            handlePlatformSwitch={handlePlatformSwitch}
            selectedPlatform={selectedPlatform}
          />
        );
      default:
        return;
    }
  };

  const accText = t("pool.yourLiquidity.description");

  return (
    <Wrapper>
      <UserLiquidityAccordion
        onClick={() => setOpenAcc(!openAcc)}
        open={openAcc}
      >
        {openAcc ? accText : `${accText.substring(0, 71)}...`} <ArrowDown />
      </UserLiquidityAccordion>
      {selectedPlatform.value &&
        handleShowUserLiquidityPlatform(selectedPlatform.value)}
    </Wrapper>
  );
};

export default UserLiquidity;
