import React from "react";
import { integrations } from "@keyfi/keyfi-common";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import BorrowTable from "../components/Borrow/BorrowTable";
import MyBorrows from "../components/Borrow/MyBorrows";
import Button from "../components/Button";
import { switchToETH } from "../helpers";

const Wrapper = styled.div`
  width: 100%;
  max-width: 870px;
  margin: 0 auto 3rem;

  h1 {
    font-size: 24px;
    margin-bottom: 2rem;
  }
  h2 {
    font-size: 16px;
  }
`;

const ChangeNetworkMessage = styled.div`
  width: 100%;
  max-width: 505px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.theme.superLightBlue};
  border: 1px solid ${(props) => props.theme.border3};
  border-radius: 6px;
  padding: 2rem 1.5rem;
  text-align: center;
  ${Button} {
    margin-top: 1.5rem;
    padding: 10px 1.6rem;
    font-size: 16px;
    text-transform: none;
    width: max-content;
    height: max-content;
    line-height: unset;
  }
`;

const Borrow = () => {
  const { t } = useTranslation();

  const wallet = useSelector((state) => state.wallet);
  const { network } = wallet;

  const handleNetworkChange = async () => {
    switchToETH();
    integrations.resetNetwork();
  };

  return (
    <Wrapper>
      <h1>{t("borrow.title")}</h1>
      {network && network.chainId === 1 ? (
        <>
          <MyBorrows />
          <BorrowTable />
        </>
      ) : (
        <ChangeNetworkMessage>
          {t("borrow.description")}
          <Button primary onClick={handleNetworkChange}>
            {t("borrow.switchbtn")}
          </Button>
        </ChangeNetworkMessage>
      )}
    </Wrapper>
  );
};

export default Borrow;
