import React, { useCallback, useEffect, useState } from "react";

import {
  getRewards,
  getRewardsv2,
} from "@keyfi/keyfi-common/src/integrations/keyfipool";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { beautifyTokensAmount } from "../../../helpers";
import { StakingChart, listOfColors } from "./shared";

const REWARD_UPDATE_INTERVAL = 7000;

const doughnutOptions = {
  cutout: "84%",
  // borderWidth: 2,
  borderColor: "transparent",
  aspectRatio: 1,
  animation: {
    duration: 0,
  },
  layout: {
    padding: 0,
  },
};

const EarnChart = ({ wallet, user, showV2 }) => {
  const [rewardsKeyFi, setRewardsKeyFi] = useState({});
  const { t } = useTranslation();
  const totalValue = Object.keys(rewardsKeyFi).reduce((acc, key) => {
    return acc + parseFloat(rewardsKeyFi[key]);
  }, 0);

  const fetchRewards = useCallback(async () => {
    try {
      let rewards = {};

      if (showV2) {
        const [v1, v2] = await Promise.all([
          getRewards(wallet.selectedAddress),
          getRewardsv2(wallet.selectedAddress),
        ]);
        rewards = { ...v2, ...v1 };
      } else {
        const v1 = await getRewards(wallet.selectedAddress);
        rewards = v1;
      }

      // Removes all the values, which are 0
      Object.keys(rewards).forEach((x) => {
        if (rewards[x] === "0") {
          delete rewards[x];
        }
      });

      setRewardsKeyFi(rewards);
    } catch (e) {
      console.log(e);
    }
  }, [wallet.selectedAddress]);

  useEffect(() => {
    if (wallet.selectedAddress) {
      fetchRewards();
      const interval = setInterval(async () => {
        fetchRewards();
      }, REWARD_UPDATE_INTERVAL);
      return () => {
        clearInterval(interval);
      };
    }
  }, [wallet.selectedAddress, fetchRewards]);

  if (Object.keys(rewardsKeyFi).length !== 0) {
    return (
      <StakingChart>
        <>
          <div className="tokens_chart_wrapper">
            <Doughnut
              height={"20%"}
              data={{
                datasets: [
                  {
                    data: Object.keys(rewardsKeyFi).map(function (key) {
                      return rewardsKeyFi[key];
                    }),
                    backgroundColor: listOfColors,
                  },
                ],
              }}
              options={doughnutOptions}
            />
            <div className="tokens_chart_total">
              <div className="tokens_chart_total_title">
                {t("addition.totalEarned")}
              </div>
              <TransitionGroup>
                <CSSTransition
                  key={totalValue}
                  timeout={300}
                  classNames="flash"
                >
                  <div className={`tokens_chart_total_keyfi`}>
                    {beautifyTokensAmount(totalValue, { suffix: " KEYFI" })}
                  </div>
                </CSSTransition>

                <div className={`tokens_chart_total_usd`}>
                  {beautifyTokensAmount(totalValue * user.usdPrices["KEYFI"], {
                    prefix: "$",
                    suffix: " USD",
                  })}{" "}
                </div>
              </TransitionGroup>
            </div>
          </div>
          <div className="tokens_chart_details">
            <div className="tokens_chart_title">{t("addition.details")}</div>
            <div className="tokens_chart_labels">
              {rewardsKeyFi &&
                Object.keys(rewardsKeyFi).map(
                  (key, i) =>
                    rewardsKeyFi[key] !== "0" && (
                      <div key={key} className="tokens_chart_label">
                        <div
                          style={{ background: listOfColors[i] }}
                          className="tokens_chart_label_color"
                        ></div>
                        <div>
                          <div className="tokens_chart_label_title">{key}</div>
                          <TransitionGroup>
                            <CSSTransition
                              key={rewardsKeyFi[key]}
                              timeout={300}
                              classNames="flash"
                            >
                              <div className={`tokens_chart_label_value`}>
                                {beautifyTokensAmount(rewardsKeyFi[key], {
                                  suffix: ` KEYFI`,
                                })}
                              </div>
                            </CSSTransition>
                          </TransitionGroup>
                        </div>
                      </div>
                    )
                )}
            </div>
          </div>
        </>
      </StakingChart>
    );
  }
  return <></>;
};

const mapStateToProps = (state) => ({
  user: state.user,
  wallet: state.wallet,
});

export default connect(mapStateToProps)(EarnChart);
