import React, { useState, useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
import { integrations } from "@keyfi/keyfi-common";
import Tippy from "@tippyjs/react/headless";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// utils
import { breakPoints } from "../../theme";
import { beautifyTokensAmount, sumBigNumbers } from "../../helpers";
import { Skeleton } from "../../Shared";
import Tooltip from "../Tooltip";
import KeyFiTooltip from "../KeyFiTooltip";
import { keyfiDataOperations } from "../../redux/keyfiDataSlice";
import { walletActions } from "../../redux/walletSlice";

const Wrapper = styled.div`
  background: ${(props) => props.theme.superLightBlue};
  border: 1px solid ${(props) => props.theme.lightBlue3};
  height: 120px;
  color: ${(props) => props.theme.mainDarkText};
  margin: 32px 0px 54px;
  padding: 1.5rem 1rem;
  border-radius: 6px;
  max-width: 560px;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr 0.5fr;
  font-size: 12px;
  font-weight: 500;

  .keyfiTotal {
    display: flex;
    align-items: flex-end;
    margin-bottom: 6px;
    span {
      font-size: 14px;
      margin-right: 0.25rem;
    }
    > div {
      font-size: 18px;
      line-height: 19px;
    }
  }

  @media (max-width: ${breakPoints.md}px) {
    grid-template-columns: 1fr;
    height: initial;
    width: 100%;
  }

  @media (max-width: ${breakPoints.xl}px) {
    margin: 16px 0px 54px;
  }

  .head {
    display: block;
    color: ${(props) => props.theme.brandBlue};
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 6px;
  }

  .value {
    display: block;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 27px;
    line-height: 33px;
  }

  .keyfi-total-info,
  .keyfi-total-buttons {
    align-items: end;
    display: grid;
    a {
      &:hover {
        .tooltip {
          display: ${(props) => props.disabled && "flex"};
        }
      }
    }
  }

  .keyfi-total-buttons {
    @media (max-width: ${breakPoints.md}px) {
      grid-template-columns: 0.5fr 1fr;
    }
  }

  .keyfi-total-info__row {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    height: 14.4px;
  }
`;

const KeyFiButtonHover = css`
  background: ${(props) => props.theme.brandBlue};
  color: ${(props) => props.theme.white};
`;

const KeyFiButton = styled.button`
  position: relative;
  background: ${(props) => (props.disabled ? "none" : props.theme.lightBlue3)};
  color: ${(props) =>
    props.disabled ? props.theme.placeholder2 : props.theme.brandBlue};
  border: 1px solid
    ${(props) =>
      props.disabled ? props.theme.placeholder2 : props.theme.brandBlue};
  border-radius: 6px;
  padding: 6px 0;
  width: 86px;
  font-size: 16px;
  font-weight: 500;
  cursor: ${(props) => !props.disabled && "pointer"};
  outline: none;

  :visited {
    color: ${(props) => props.theme.brandBlue};
    outline: none;
  }

  a:visited {
    color: ${(props) => props.theme.brandBlue};
    outline: none;
  }

  &:hover {
    ${(props) => !props.disabled && KeyFiButtonHover}
  }
`;

const KeyFiCheckboxContainer = styled.div`
  .keyfi-checkbox-container {
    display: block;
    position: relative;
    padding-left: 1.5rem;
    cursor: pointer;
    font-size: 12px;
    font-style: italic;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      :checked {
        ~ .keyfi-checkbox {
          background-color: ${(props) => props.theme.brandBlueDark};
          :after {
            display: block;
          }
        }
      }
    }
    .keyfi-checkbox {
      position: absolute;
      top: -1px;
      left: 0;
      height: 16px;
      width: 16px;
      border-radius: 3px;
      border: 1px solid ${(props) => props.theme.checkboxBorder};
      background-color: ${(props) => props.theme.secondaryHover};
      :after {
        content: "";
        position: absolute;
        display: none;
        left: 4px;
        top: 1px;
        width: 3px;
        height: 7px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
`;

function KeyFiCard({ wallet, user }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isFetching = useSelector((state) => state.app.isLoading);

  const staking = useSelector((state) => state.staking);
  const { mainnet, isFetching: stakingIsFetching } = staking;

  const keyfiData = useSelector((state) => state.keyfiData);
  const {
    liquidityTotal,
    rewardsTotal,
    isFetching: keyfiDataIsFetching,
  } = keyfiData;

  const [showLiquidity, setShowLiquidity] = useState(true);

  const stakedTotal = useMemo(() => {
    if (wallet.network.name) {
      const balance = staking[wallet.network.name]?.keyfi.v1?.KEYFI ?? 0;
      const v2Balance = staking[wallet.network.name]?.keyfi.v2?.KEYFI ?? 0;

      const stakedData = [balance.amount ?? 0, v2Balance.amount ?? 0];
      return stakedData.reduce((acc, value) => acc + parseFloat(value), 0);
    }
  }, [staking, wallet.network.name]);

  useEffect(() => {
    if (
      !liquidityTotal &&
      !rewardsTotal &&
      wallet.selectedAddress &&
      wallet.network
    ) {
      dispatch(keyfiDataOperations.loadData());
    }
  }, [wallet, liquidityTotal, rewardsTotal, dispatch]);

  return (
    <Wrapper disabled={user.readOnly}>
      <div>
        <div className="head">{t("dashboard.KeyFiBalances.title")}</div>
        <div className="keyfiTotal">
          <span>{t("dashboard.KeyFiBalances.total")}:</span>{" "}
          <div>
            {isFetching || stakingIsFetching || keyfiDataIsFetching ? (
              <Skeleton width="100" height="19" white="true" />
            ) : (
              beautifyTokensAmount(
                sumBigNumbers([
                  stakedTotal,
                  rewardsTotal,
                  showLiquidity ? liquidityTotal : 0,
                  user.tokens.KEYFI ?? 0,
                ]),
                {
                  suffix: " KEYFI",
                }
              )
            )}
          </div>
        </div>
        <KeyFiCheckboxContainer>
          <label className="keyfi-checkbox-container">
            {t("dashboard.KeyFiBalances.showLiquidityPoolBalance")}
            <input
              type="checkbox"
              onChange={(e) => setShowLiquidity(!!e.target.checked)}
              checked={showLiquidity}
            />
            <span className="keyfi-checkbox" />
          </label>
        </KeyFiCheckboxContainer>
      </div>
      <div className="keyfi-total-info">
        <div className="keyfi-total-info__row">
          <Tippy
            render={(attrs) => (
              <KeyFiTooltip
                title={t("dashboard.KeyFiBalances.walletDesciption")}
                {...attrs}
              />
            )}
            offset={[40, 10]}
          >
            <span>{t("dashboard.KeyFiBalances.wallet")}: </span>
          </Tippy>
          <span>
            {isFetching || stakingIsFetching || keyfiDataIsFetching ? (
              <Skeleton white="true" width="50" height="12" />
            ) : (
              beautifyTokensAmount(user.tokens.KEYFI ?? 0, {
                suffix: " KEYFI",
              })
            )}
          </span>
        </div>
        <div className="keyfi-total-info__row">
          <Tippy
            render={(attrs) => (
              <KeyFiTooltip
                title={t("dashboard.KeyFiBalances.stakingDesciption")}
                {...attrs}
              />
            )}
            offset={[29, 10]}
          >
            <span>{t("dashboard.KeyFiBalances.staking")}: </span>
          </Tippy>
          <span>
            {isFetching || stakingIsFetching || keyfiDataIsFetching ? (
              <Skeleton white="true" width="50" height="12" />
            ) : (
              beautifyTokensAmount(stakedTotal, { suffix: " KEYFI" })
            )}
          </span>
        </div>

        <div className="keyfi-total-info__row">
          <Tippy
            render={(attrs) => (
              <KeyFiTooltip
                title={t("dashboard.KeyFiBalances.rewardsDesciption")}
                {...attrs}
              />
            )}
            offset={[60, 10]}
          >
            <span>{t("dashboard.KeyFiBalances.rewards")}: </span>
          </Tippy>
          <span>
            {isFetching || stakingIsFetching || keyfiDataIsFetching ? (
              <Skeleton white="true" width="50" height="12" />
            ) : (
              beautifyTokensAmount(rewardsTotal, { suffix: " KEYFI" })
            )}
          </span>
        </div>

        <div className="keyfi-total-info__row">
          {showLiquidity ? (
            <>
              <Tippy
                render={(attrs) => (
                  <KeyFiTooltip
                    title={t("dashboard.KeyFiBalances.liquidityDesciption")}
                    {...attrs}
                  />
                )}
                offset={[55, 10]}
              >
                <span>{t("dashboard.KeyFiBalances.liquidity")}: </span>
              </Tippy>
              <span>
                {isFetching || stakingIsFetching || keyfiDataIsFetching ? (
                  <Skeleton white="true" width="50" height="12" />
                ) : (
                  beautifyTokensAmount(liquidityTotal, {
                    suffix: " KEYFI",
                  })
                )}
              </span>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="keyfi-total-buttons">
        <Link to="/earn" disabled={user.readOnly}>
          <KeyFiButton disabled={user.readOnly}>
            {t("dashboard.KeyFiBalances.earnBtn")}
            <Tooltip text={t("addition.toolTipText")} />
          </KeyFiButton>
        </Link>
      </div>
    </Wrapper>
  );
}

const mapStateToProps = (state) => ({
  wallet: state.wallet,
  user: state.user,
});

export default connect(mapStateToProps)(KeyFiCard);
