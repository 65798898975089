import styled from "styled-components";
import { poolsLogos } from "../../../helpers";
import { breakPoints } from "../../../theme";

export const platforms = [
  { value: "uniswap", icon: poolsLogos["Uniswap"], text: "Uniswap", network: "mainnet" },
  { value: "curve", icon: poolsLogos["Curve"], text: "Curve", network: "mainnet" },
  {
    value: "apeswap",
    icon: poolsLogos["ApeSwap"],
    text: "ApeSwap",
    network: "bsc-mainnet",
  },
  {
    value: "pancakeswap",
    icon: poolsLogos["Pancakeswap"],
    text: "PancakeSwap",
    network: "bsc-mainnet",
    options: [
      { id: "v2", text: "V2" },
      { id: "v1", text: "V1 (Old)" },
    ],
  },
];

/* 
  ***
  Styled Components
  ***
*/
export const UserLiquidityListWrapper = styled.div`
  margin-top: 1.5rem;
  .user-liquidity-list-item {
    background: ${(props) => props.theme.lightBlue3};
    padding: 0.75rem 1rem;
    border-radius: 6px;
    .user-liquidity-item-header {
      cursor: pointer;
      display: flex;
      align-items: center;
      .user-liquidity-item-images {
        margin-right: 0.5rem;
        img {
          position: relative;
          height: 32px;
          width: 32px;
          :last-of-type {
            margin-left: -0.3rem;
          }
        }
      }
      .user-liquidity-item-title {
        flex: 1;
        font-size: 16px;
        font-weight: 500;
        color: ${(props) => props.theme.mainDarkText};
        div {
          :last-of-type {
            font-size: 14px;
            color: ${(props) => props.theme.brandBlue};
          }
        }
      }
      .user-liquidity-item-manage {
        display: flex;
        align-items: center;
        span {
          margin-right: 0.5rem;
          font-size: 14px;
          color: ${(props) => props.theme.brandBlue};
          font-weight: 500;
          line-height: 17px;
        }
        svg {
          height: 17px;
          width: 17px;
          path {
            fill: ${(props) => props.theme.brandBlue};
          }
        }
      }
    }
    .user-liquidity-item-body {
      margin-top: 0.75rem;
      padding-top: 0.75rem;
      border-top: 1px solid ${(props) => props.theme.brandBlue};
      .user-liquidity-item-body-data {
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        div {
          font-size: 14px;
          color: ${(props) => props.theme.mainDarkText};
          font-weight: 500;
          :first-of-type {
            display: flex;
            align-items: center;
            img {
              margin-right: 0.5rem;
              height: 24px;
              width: 24px;
            }
          }
        }
      }
      .user-liquidity-item-buttons {
        display: flex;
        margin-top: 1rem;
        a {
          flex: 1;
          text-align: center;
          padding: 10px 0;
          background: ${(props) => props.theme.brandBlue};
          color: white;
          border-radius: 6px;
          :first-of-type {
            margin-right: 2rem;
          }
          :hover {
            background: ${(props) => props.theme.primaryButtonHover};
          }
        }
        .user-liquidity-button-disabled {
          cursor: default;
          background: ${(props) => props.theme.disabled};
          pointer-events: none;
        }
        @media (max-width: ${breakPoints.md}px) {
          flex-direction: column;
          a {
            :first-of-type {
              margin: 0 0 1rem;
            }
          }
        }
      }
    }
  }
`;

export const AddLiquidityBody = styled.div`
  margin-top: 1.5rem;
  .add-liquidity-input-wrapper {
    margin: 1.5rem 0;

    .add-liquidity-input-labels {
      margin-bottom: 0.5rem;
      display: flex;
      justify-content: space-between;
      color: ${(props) => props.theme.mainDarkText};
      font-size: 12px;
      font-weight: 500;
    }
  }
  .add-liquidity-plus {
    display: flex;
    svg {
      margin: 0 auto;
    }
  }
  .add-liquidity-data {
    margin: 2rem 0 0;
    h2 {
      font-size: 12px;
      font-weight: 500;
      margin: 0 0 0.5rem;
    }
    .add-liquidity-data-box {
      display: flex;
      justify-content: space-between;
      padding: 0.75rem 1rem;
      border: 1px solid ${(props) => props.theme.border2};
      border-radius: 6px;
      color: ${(props) => props.theme.mainDarkText};
      .add-liquidity-data-snippet {
        * {
          margin: 0;
        }
        h4 {
          font-size: 14px;
          font-weight: 500;
        }
        h6 {
          font-size: 12px;
          font-weight: 400;
        }
      }
      @media (max-width: ${breakPoints.md}px) {
        flex-direction: column;
        .add-liquidity-data-snippet {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
  .add-liquidity-button {
    margin-top: 1.5rem;
    cursor: pointer;
    width: 100%;
    border: 0;
    border-radius: 6px;
    background: ${(props) => props.theme.brandBlue};
    color: white;
    padding: 0.75rem;
    font-size: 20px;
    :hover {
      background: ${(props) => props.theme.primaryButtonHover};
    }
    :disabled {
      pointer-events: none;
      background: ${(props) => props.theme.disabled};
    }
  }
`;

export const VersionButtons = styled.div`
  margin-top: 1rem;
  button {
    cursor: pointer;
    width: 80px;
    padding: 9px 0;
    background: none;
    border: 1px solid ${(props) => props.theme.brandBlue};
    :first-of-type {
      border-radius: 6px 0 0 6px;
    }
    :last-of-type {
      border-radius: 0 6px 6px 0;
    }
  }
  .liquidity-version-selected {
    background: ${(props) => props.theme.brandBlue};
    color: white;
  }
  .liquidity-version-disabled {
    cursor: default;
    color: ${(props) => props.theme.disabled};
    border-color: ${(props) => props.theme.disabled};
  }
`;

export const ErrorMessageLiquidity = styled.div`
  margin-top: 5px;
  margin-left: 0.75rem;
  color: ${(props) => props.theme.error};
  font-size: 12px;
`;

export const PlatformRemoveLiquidityWrapper = styled.div`
  margin-top: 2rem;
  padding: 1.5rem;
  background: ${(props) => props.theme.superLightBlue};
  border: 1px solid ${(props) => props.theme.border3};
  border-radius: 6px;
  .remove-liquidity-back {
    button {
      display: flex;
      align-items: center;
      cursor: pointer;
      border: 1px solid black;
      width: max-content;
      background: 0;
      padding: 0.5rem 1.5rem;
      border-radius: 6px;
      font-size: 14px;
      color: ${(props) => props.theme.mainDarkText};
      svg {
        margin-right: 0.5rem;
      }
    }
  }
  > h2 {
    font-size: 20px;
    font-weight: 500;
    margin: 1.5rem 0;
  }
  .remove-liquidity-description {
    margin-bottom: 1rem;
    padding: 1rem;
    background: ${(props) => props.theme.lightBlue3};
    border-radius: 6px;
    color: ${(props) => props.theme.brandBlue};
    font-size: 12px;
  }
  .remove-liquidity-platform-container {
    margin-bottom: 1.5rem;
    .remove-liquidity-platform-label {
      font-size: 12px;
      margin-bottom: 0.5rem;
    }
    .remove-liquidity-platform {
      overflow: hidden;
      display: flex;
      position: relative;
      align-items: center;
      background: ${(props) => props.theme.removeLiquidityPlatformBG};
      max-width: 238px;
      padding: 0.5rem 0.75rem;
      border-radius: 6px;
      img {
        height: 24px;
        width: 24px;
        margin-right: 0.25rem;
      }
      h4 {
        font-size: 14px;
        margin: 0;
        z-index: 2;
        font-weight: 500;
      }
      .remove-liquidity-platform-network {
        position: absolute;
        right: 0;
        font-size: 74px;
        font-weight: 600;
        color: ${(props) => props.theme.removeLiquidityPlatformNetwork};
      }
    }
  }
  .remove-liquidity-tokens {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    border-bottom: 1px solid ${(props) => props.theme.border2};
    padding-bottom: 1rem;
    .remove-liquidity-tokens-logos {
      display: flex;
      align-items: center;
      img,
      svg {
        height: 32px;
        width: 32px;
        position: relative;
        :last-of-type {
          margin-left: -0.4rem;
          margin-right: 6px;
        }
      }
    }
    .remove-liquidity-token-liquidity {
      color: ${(props) => props.theme.brandBlue};
    }
  }
`;

export const RemoveLiquidityBody = styled.div`
  margin-top: 1.5rem;
  .remove-liquidity-input-container {
    .remove-liquidity-input-label {
      font-size: 12px;
      margin-bottom: 0.5rem;
    }
    input {
      font-size: 16px;
      font-family: Montserrat;
      width: 120px;
      padding: 0.75rem;
      background: white;
      border: 1px solid ${(props) => props.theme.border2};
      border-radius: 6px;
      text-align: right;
      :focus {
        outline: 0;
      }
    }
  }
  .remove-liquidity-slider {
    margin: 1.5rem 0;
    input {
      width: 100%;
    }
  }
  .remove-liquidity-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    > button {
      cursor: pointer;
      font-size: 16px;
      line-height: 19.5px;
      width: 100px;
      padding: 10px 0;
      background: 0;
      border: 1px solid ${(props) => props.theme.brandBlue};
      border-radius: 6px;
      color: ${(props) => props.theme.brandBlue};
    }
    .remove-liquidity-option-selected {
      background: ${(props) => props.theme.lightBlue2};
    }
  }
  .remove-liquidity-data {
    margin-bottom: 2rem;
    .remove-liquidity-data-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        display: flex;
        align-items: center;
      }
      img {
        height: 24px;
        width: 24px;
        margin-right: 6px;
      }
      :first-of-type {
        margin-bottom: 0.5rem;
      }
    }
  }

  .remove-liquidity-button {
    cursor: pointer;
    background: ${(props) => props.theme.brandBlue};
    padding: 10px 0;
    border: 0;
    border-radius: 6px;
    width: 100%;
    max-width: 225px;
    font-size: 16px;
    color: white;
    :hover {
      background: ${(props) => props.theme.primaryButtonHover};
    }
    :disabled {
      cursor: default;
      background: ${(props) => props.theme.disabled};
    }
  }
`;

export const RemoveLiquidityData = styled.div`
  margin-top: 1.5rem;
  padding: 1.5rem;
  background: ${(props) => props.theme.superLightBlue};
  border: 1px solid ${(props) => props.theme.border3};
  border-radius: 6px;
  .remove-liquidity-data-line {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      display: flex;
      align-items: center;
      img {
        height: 24px;
        width: 24px;
        margin-right: 6px;
      }
    }
    :not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`;
