import React from "react";
import BigNumber from "bignumber.js";
// wallets icons
import MetaMaskLogo from "../assets/wallets/metaMask.svg";
import CoinbaseLogo from "../assets/wallets/coinbase.svg";
import WalletConnectLogo from "../assets/wallets/walletConnect.svg";
import SelfKeyLogo from "../assets/wallets/selfkey.svg";
import KeyfiLogo from "../assets/Blue-filled-logo.svg";
import SelfKEYLogo from "../assets/wallets/selfkey.svg";

// tokens
import BNBLogo from "../assets/tokens/BNB.svg";
import EthLogo from "../assets/tokens/ETH.svg";
import LinkLogo from "../assets/tokens/LINK.svg";
import BalLogo from "../assets/tokens/BAL.svg";
import BatLogo from "../assets/tokens/BAT.svg";
import TusdLogo from "../assets/tokens/TUSD.svg";
import BUSDLogo from "../assets/tokens/BUSD.svg";
import FtxLogo from "../assets/tokens/FTX.svg";
import KNCLogo from "../assets/tokens/USDT.svg";
import LendLogo from "../assets/tokens/LEND.svg";
import MtaLogo from "../assets/tokens/MTA.svg";
import RepLogo from "../assets/tokens/REP.svg";
import SrmLogo from "../assets/tokens/SRM.svg";
import UniLogo from "../assets/tokens/UNI.svg";
import UsdcLogo from "../assets/tokens/USDC.svg";
import WbtcLogo from "../assets/tokens/WBTC.svg";
import YfiLogo from "../assets/tokens/YFI.svg";
import ZrxLogo from "../assets/tokens/ZRX.svg";
import RenBTCLogo from "../assets/tokens/renBTC.svg";
import EnjLogo from "../assets/tokens/ENJ.svg";
import UsdtLogo from "../assets/tokens/USDT.svg";
import DaiLogo from "../assets/tokens/DAI.svg";
import SaiLogo from "../assets/tokens/SAI.svg";
import InchLogo from "../assets/platforms/1inch.svg";
import INCHE from "../assets/tokens/1INCHE.png";
import CusdcLogo from "../assets/tokens/cUSDC.png";
import CdaiLogo from "../assets/tokens/cDAI.png";
import AMPL from "../assets/tokens/AMPL.png";
import AXS from "../assets/tokens/AXS.png";
import BAND from "../assets/tokens/BAND.png";
import CEL from "../assets/tokens/CEL.png";
import DIA from "../assets/tokens/DIA.png";
import DYT from "../assets/tokens/DYT.png";
import FYZ from "../assets/tokens/FYZ.png";
import HEGIC from "../assets/tokens/HEGIC.png";
import HEX from "../assets/tokens/HEX.png";
import KP3R from "../assets/tokens/KP3R.png";
import OCEAN from "../assets/tokens/OCEAN.png";
import OMG from "../assets/tokens/OMG.png";
import RLC from "../assets/tokens/OCEAN.png";
import RSR from "../assets/tokens/RSR.png";
import SLP from "../assets/tokens/SLP.png";
import STAKE from "../assets/tokens/STAKE.jpg";
import XOR from "../assets/tokens/XOR.png";
import SUSHI from "../assets/tokens/SUSHI.svg";
import SXP from "../assets/tokens/SXP.png";
import UBT from "../assets/tokens/UBT.png";
import SnxLogo from "../assets/tokens/SNX.svg";
import ManaLogo from "../assets/tokens/MANA.svg";
import CREAM from "../assets/tokens/CREAM.png";
import WETH from "../assets/tokens/WETH.png";
import SUSD from "../assets/tokens/SUSD.png";
import DPI from "../assets/tokens/DPI.png";
import CHAI from "../assets/tokens/CHAI.png";
import PAX from "../assets/tokens/PAX.png";
import TBTC from "../assets/tokens/PAX.png";
import HBTC from "../assets/tokens/HBTC.png";
import sBTC from "../assets/tokens/SBTC.png";
import MATIC from "../assets/tokens/matic.svg";

// platforms
import UniswapLogo from "../assets/platforms/uniswap.svg";
import MakerLogo from "../assets/platforms/maker.svg";
import CreamLogo from "../assets/platforms/cream.svg";
import MkrLogo from "../assets/tokens/MKR.svg";
import RenLogo from "../assets/tokens/REN.svg";
import CurveLogo from "../assets/platforms/curve.svg";
import BalancerLogo from "../assets/platforms/balancer.svg";
import DydxLogo from "../assets/platforms/dydx.svg";
import FulcrumLogo from "../assets/platforms/fulcrum.svg";
import AaveLogo from "../assets/platforms/aave.svg";
import CompoundLogo from "../assets/platforms/compound.svg";
import DeFinerIcon from "../assets/platforms/DeFiner.png";
import Pancakeswap from "../assets/platforms/pancakeswap.png";
import Apeswap from "../assets/platforms/Apeswap.svg";
import QuickSwap from "../assets/platforms/quickswap.png";
import Alkemi from "../assets/platforms/alkemi.svg";

export const compareFunction =
  ({ field, direction }) =>
  (a, b) => {
    const parsedA = isNaN(parseFloat(a[field]))
      ? a[field]
      : parseFloat(a[field]);
    const parsedB = isNaN(parseFloat(b[field]))
      ? b[field]
      : parseFloat(b[field]);
    if (direction === "desc") {
      if (parsedA > parsedB) return -1;
      if (parsedA === parsedB) return 0;
      if (parsedA < parsedB) return 1;
    }
    if (direction === "asc") {
      if (parsedA > parsedB) return 1;
      if (parsedA === parsedB) return 0;
      if (parsedA < parsedB) return -1;
    }
  };

export const trimAddress = (str, number) => {
  if (str) {
    const firstPart = str.slice(0, number || 6);
    const secondPart = str.slice(-4);

    return `${firstPart}...${secondPart}`;
  }
};

const numberFormat = {
  decimalSeparator: ".",
  groupSeparator: ",",
  groupSize: 3,
  secondaryGroupSize: 3,
};

export const beautifyTokensAmount = (amount, format = {}) => {
  const n = new BigNumber(amount);
  if (n.isNaN()) {
    return "0.00";
  }
  if (amount < 1 && amount > 0 && !format.percent) {
    return n.toFormat(4, { ...numberFormat, ...format });
  }
  return n.toFormat(2, { ...numberFormat, ...format });
};

export const beautifyTokensAmountSmall = (amount, format = {}) => {
  const n = new BigNumber(amount);
  if (n.gte(0.01) || n.isNaN()) {
    return beautifyTokensAmount(amount);
  }
  return n.toFormat({ ...numberFormat, ...format });
};

export const walletsLogos = {
  Metamask: <img src={MetaMaskLogo} alt="MetaMaskLogo" />,
  Coinbase: <img src={CoinbaseLogo} alt="CoinbaseLogo" />,
  WalletConnect: <img src={WalletConnectLogo} alt="WalletConnectLogo" />,
  SelfKey: <img src={SelfKeyLogo} alt="SelfKeyLogo" />,
};

// map for pools logos
export const poolsLogos = {
  Compound: <img src={CompoundLogo} alt="compound" />,
  Aave: <img src={AaveLogo} alt="AaveLogo" />,
  Uniswap: <img src={UniswapLogo} alt="UniswapLogo" />,
  dydx: <img src={DydxLogo} alt="DYdX" />,
  Fulcrum: <img src={FulcrumLogo} alt="Fulcrum" />,
  Maker: <img src={MakerLogo} alt="MakerLogo" />,
  "C.R.E.A.M.": <img src={CreamLogo} alt="CreamLogo" />,
  "1Inch": <img src={InchLogo} alt="InchLogo" />,
  Curve: <img src={CurveLogo} alt="CurveLogo" />,
  Balancer: <img src={BalancerLogo} alt="BalancerLogo" />,
  KeyFi: <img src={KeyfiLogo} alt="keyfi" />,
  DeFiner: <img src={DeFinerIcon} alt="DeFiner Icon" />,
  PancakeSwap: <img src={Pancakeswap} alt="PancakeSwap Icon" />,
  Pancakeswap: <img src={Pancakeswap} alt="PancakeSwap Icon" />,
  ApeSwap: <img src={Apeswap} alt="Apeswap" />,
  QuickSwap: <img src={QuickSwap} alt="QuickSwap" />,
  Alkemi: (
    <img
      src={Alkemi}
      height="26"
      width="30"
      style={{ marginTop: "-2px" }}
      alt="Alkemi"
    />
  ),
};

// map for tokens logos
export const tokensLogos = {
  CAKE: <img src={Pancakeswap} alt="CAKELogo" />,
  Cake: <img src={Pancakeswap} alt="CAKELogo" />,
  wBNB: <img src={BNBLogo} alt="BNBLogo" />,
  BNB: <img src={BNBLogo} alt="BNBLogo" />,
  ETH: (
    <img
      src="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png"
      alt="EthLogo"
    />
  ),
  DAI: <img src={DaiLogo} alt="DaiLogo" />,
  USDT: <img src={UsdtLogo} alt="UsdtLogo" />,
  LINK: <img src={LinkLogo} alt="LinkLogo" />,
  BAL: <img src={BalLogo} alt="BalLogo" />,
  BAT: <img src={BatLogo} alt="BatLogo" />,
  BUSD: <img src={BUSDLogo} alt="BUSDLogo" />,
  FTX: <img src={FtxLogo} alt="FtxLogo" />,
  KNC: <img src={KNCLogo} alt="KNCLogo" />,
  LEND: <img src={LendLogo} alt="LendLogo" />,
  MTA: <img src={MtaLogo} alt="MtaLogo" />,
  RenBTC: <img src={RenBTCLogo} alt="RenBTCLogo" />,
  renBTC: <img src={RenBTCLogo} alt="RenBTCLogo" />,
  REP: <img src={RepLogo} alt="RepLogo" />,
  SRM: <img src={SrmLogo} alt="SrmLogo" />,
  UNI: <img src={UniLogo} alt="UniLogo" />,
  USDC: <img src={UsdcLogo} alt="UsdcLogo" />,
  WBTC: <img src={WbtcLogo} alt="WbtcLogo" />,
  YFI: <img src={YfiLogo} alt="YfiLogo" />,
  ZRX: <img src={ZrxLogo} alt="ZrxLogo" />,
  TUSD: <img src={TusdLogo} alt="TusdLogo" />,
  SAI: <img src={SaiLogo} alt="SaiLogo" />,
  ENJ: <img src={EnjLogo} alt="EnjLogo" />,
  MKR: <img src={MkrLogo} alt="MkrLogo" />,
  REN: <img src={RenLogo} alt="RenLogo" />,
  SNX: <img src={SnxLogo} alt="SnxLogo" />,
  MANA: <img src={ManaLogo} alt="ManaLogo" />,
  cUSDC: <img src={CusdcLogo} alt="CusdcLogo" />,
  cDAI: <img src={CdaiLogo} alt="CdaiLogo" />,
  KEY: <img src={SelfKEYLogo} alt="selfkey logo" />,
  KEYFIUSDCLP: <img src={KeyfiLogo} alt="keyfi" />,
  KEYFI: <img src={KeyfiLogo} alt="keyfi" />,
  Uniswap: <img src={UniswapLogo} alt="UniswapLogo" />,
  AAVE: <img src={AaveLogo} alt="AaveLogo" />,
  COMP: <img src={CompoundLogo} alt="compound" />,
  CRV: <img src={CurveLogo} alt="CurveLogo" />,
  "1INCH": <img src={INCHE} alt="InchLogo Token" />,
  AMPL: <img src={AMPL} alt="AMPL Token" />,
  AXS: <img src={AXS} alt="AXS Token" />,
  BAND: <img src={BAND} alt="BAND Token" />,
  CEL: <img src={CEL} alt="CEL Token" />,
  DIA: <img src={DIA} alt="DIA Token" />,
  DYT: <img src={DYT} alt="DYT Token" />,
  FYZ: <img src={FYZ} alt="FYZ Token" />,
  HEGIC: <img src={HEGIC} alt="HEGIC Token" />,
  HEX: <img src={HEX} alt="HEX Token" />,
  KP3R: <img src={KP3R} alt="KP3R Token" />,
  OCEAN: <img src={OCEAN} alt="OCEAN Token" />,
  OMG: <img src={OMG} alt="OMG Token" />,
  RLC: <img src={RLC} alt="RLC Token" />,
  RSR: <img src={RSR} alt="RSR Token" />,
  SLP: <img src={SLP} alt="SLP Token" />,
  STAKE: <img src={STAKE} alt="STAKE Token" />,
  XOR: <img src={XOR} alt="XOR Token" />,
  SUSHI: <img src={SUSHI} alt="SUSHI Token" />,
  UBT: <img src={UBT} alt="UBT Token" />,
  SXP: <img src={SXP} alt="SXP Token" />,
  CREAM: <img src={CREAM} alt="CREAM Token" />,
  WETH: (
    <img
      src="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png"
      alt="WETH Token"
    />
  ),
  sUSD: <img src={SUSD} alt="SUSD Token" />,
  DPI: <img src={DPI} alt="DPI Token" />,
  CHAI: <img src={CHAI} alt="CHAI Token" />,
  PAX: <img src={PAX} alt="PAX Token" />,
  TBTC: <img src={TBTC} alt="TBTC Token" />,
  HBTC: <img src={HBTC} alt="HBTC Token" />,
  sBTC: <img src={sBTC} alt="sBTC Token" />,
  MATIC: <img src={MATIC} alt="MATIC Token" />,
};

export const calculateTotal = (tokens, tokensPrices) => {
  let total = 0;

  if (tokensPrices) {
    Object.keys(tokensPrices).forEach((token) => {
      const tokenBalance =
        typeof tokens[token] === "object"
          ? tokens[token].amount
          : tokens[token] ?? 0;
      if (tokenBalance) {
        const tokenPrice = tokensPrices[token];
        total += Number(tokenBalance) * Number(tokenPrice);
      }
    });
  }

  return total;
};

/**
 * Convert paired balance structure like in Uniswap:
 * [{ USDC: 100, DAI: 101, assetA: "USDC", ... } ...]
 * To Compound and others-like flat structure with tokens summary:
 * { USDC: 100, DAI: 100, ... }
 */
export const getTokensTotalFromPairs = (pairs) => {
  const total = {};

  for (const pair of pairs) {
    for (const tokenSymbol of [pair.assetA, pair.assetB]) {
      if (!total[tokenSymbol]) {
        total[tokenSymbol] = new BigNumber(0);
      }
      total[tokenSymbol] = total[tokenSymbol].plus(pair[tokenSymbol]);
    }
  }

  return Object.entries(total).reduce((newTotal, [symbol, amount]) => {
    newTotal[symbol] = amount.toNumber();
    return newTotal;
  }, {});
};

/**
 * Convert network from liquidity state
 * { uniswap: { v2: [] }, curve: { v1: [] } }
 * Into one single array of network liquidities
 * [{ assetA: "USDC", assetB: "KEYFI"}, {...} ...]
 */
export const getLiquditiesFromNetwork = (networkObject) =>
  Object.values(networkObject).reduce(
    (acc, platform) => [
      ...acc,
      ...Object.values(platform).reduce(
        (acc2, version) => [...acc2, ...version],
        []
      ),
    ],
    []
  );

/**
 * Convert platform staking state
 * { v1: {}, v2: {}... }
 * Into one single array of network liquidities
 * { BUSD: 12, USDC: 123 ...}
 */
export const getTokensFromStakingPlatform = (platformObject) => {
  const versionArray = Object.values(platformObject);

  return versionArray.reduce((acc, tokenList) => {
    const tokens = Object.keys(tokenList).reduce((acc2, key) => {
      const token = tokenList[key];
      if (token.type === "lp") {
        const [assetA, assetB] = [
          key.split(":")[0],
          key.split(":")[1].split(" ")[0],
        ];

        return merge(acc2, {
          [assetA]: token[assetA],
          [assetB]: token[assetB],
        });
      }
      return merge(acc2, { [key]: token.amount });
    }, {});
    return merge(acc, tokens);
  }, {});
};

/**
 * Convert network staking state
 * { aave: { v1: {} }, compound: { v1: {} }... }
 * Into one single array of network liquidities
 * { BUSD: 12, USDC: 123 ...}
 */
export const getTokensFromStakingNetwork = (networkObject) =>
  Object.values(networkObject).reduce((acc, platform) => {
    const data = getTokensFromStakingPlatform(platform);

    return merge(acc, data);
  }, {});

export const calculateLPValue = (lpName, lpObject, prices) => {
  const [assetA, assetB] = [
    lpName.split(":")[0],
    lpName.split(":")[1].split(" ")[0],
  ];

  const assetAValue = prices[assetA] * lpObject[assetA];
  const assetBValue = prices[assetB] * lpObject[assetB];

  return assetAValue + assetBValue;
};

export const chunkArray = (arr, size) =>
  arr.reduce(
    (acc, e, i) => (
      // eslint-disable-next-line no-sequences
      i % size ? acc[acc.length - 1].push(e) : acc.push([e]), acc
    ),
    []
  );

export const getAPYRatesOnPlatform = (rates, platformName) =>
  rates
    .filter(
      (rate) => rate.platform.toLowerCase() === platformName.toLowerCase()
    )
    .reduce((acc, rate) => {
      acc[rate.asset] = rate.rate;
      return acc;
    }, {});

export const calculateAPY = (tokens, tokenPrices, platformRates) =>
  Object.entries(tokens)
    .filter(([_, amount]) => amount > 0)
    .reduce(
      ([sum, rate], [symbol, amount]) => {
        const tokenPrice = tokenPrices[symbol];
        const tokenRate = platformRates[symbol] || 0;

        if (typeof tokenPrice === "number") {
          const currencyEquivalent = Number(amount) * Number(tokenPrice);
          const newSum = sum + currencyEquivalent;
          return [
            newSum,
            (sum * rate + currencyEquivalent * tokenRate) / newSum,
          ];
        }

        return [sum, rate];
      },
      [0, 0]
    )[1];

export const sumBigNumbers = (amounts) =>
  amounts.reduce((s, curr) => s.plus(curr), new BigNumber(0));

export const filterZeroBalance = (userTokens, tokens) => {
  const filteredBalance = tokens.filter(
    (token) => Number(userTokens[token]) > 0
  );

  return filteredBalance;
};

export const SwitchToBsc = () => {
  const { ethereum } = window;
  ethereum
    .request({
      id: 1,
      jsonrpc: "2.0",
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: "0x38",
          chainName: "Binance Smart Chain Mainnet",
          rpcUrls: ["https://bsc-dataseed1.ninicoin.io"],
          iconUrls: [
            "https://s2.coinmarketcap.com/static/img/coins/200x200/1839.png",
          ],
          nativeCurrency: {
            name: "bnb",
            symbol: "bnb",
            decimals: 18,
          },
        },
      ],
    })
    .then((result) => {})
    .catch((error) => {
      if (error.code === 4001) {
        // EIP-1193 userRejectedRequest error
        console.log(error);
      } else {
        console.error(error);
      }
    });
};

export const switchToETH = async () => {
  const { ethereum } = window;
  try {
    await ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x1" }],
    });
  } catch (err) {
    console.log(err);
  }
};

export const merge = (...objects) => {
  const merged = objects.reduce((a, obj) => {
    Object.entries(obj).forEach(([key, val]) => {
      a[key] = ((parseFloat(a[key]) || 0) + parseFloat(val)).toString();
    });
    return a;
  }, {});
  return Object.fromEntries(Object.entries(merged).sort((a, b) => b[1] - a[1]));
};

export const mergeAmount = (version, ...objects) => {
  const merged = objects.reduce((a, obj) => {
    Object.entries(obj).forEach(([key, val]) => {
      a[key] = {
        ...val,
        version,
        amount: (
          parseFloat(a[key]?.amount || 0) + parseFloat(val.amount)
        ).toString(),
        type: val.type,
      };
    });
    return a;
  }, {});

  return Object.fromEntries(
    Object.entries(merged).sort((a, b) => b.amount - a.amount)
  );
};
