import React from "react";
import styled from "styled-components";

const Wrapper = styled.label`
  display: block;
  position: relative;
  padding-left: 1.25rem;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    :checked ~ .checkmark {
      :after {
        display: block;
      }
    }
    :checked ~ .checkmark:after {
      display: block;
    }
    :not(:checked):hover ~ .checkmark:after {
      display: block;
      opacity: 0.2;
    }
  }
  .checkmark {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 14px;
    width: 14px;
    background-color: ${(props) => props.theme.lightBlue3};
    border: 1px solid ${(props) => props.theme.brandBlue};
    border-radius: 3px;
    :after {
      content: "";
      position: absolute;
      display: none;
      left: 4px;
      top: 1px;
      width: 2px;
      height: 6px;
      border: solid ${(props) => props.theme.brandBlue};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
`;

const CustomCheckbox = ({ title, ...props }) => (
  <Wrapper>
    {title}
    <input type="checkbox" {...props} />
    <span className="checkmark" />
  </Wrapper>
);

export default CustomCheckbox;
