import { parseJson } from "@keyfi/keyfi-common";

export const StorageKey = {
  selectedProvider: "selectedProvider",
  selectedAddress: "selectedAddress",
};

export const Storage = {
  setObject(key, value) {
    Storage.setItem(key, value);
  },
  getObject(key, defaultValue = {}) {
    return parseJson(Storage.getItem(key), defaultValue);
  },
  setItem: (key, value) => localStorage.setItem(key, value),
  getItem: (key) => localStorage.getItem(key),
  removeItem: (key) => localStorage.removeItem(key),
};

export const checkIfUserConnected = () => {
  const selectedPovider = localStorage.getItem(StorageKey.selectedProvider);
  if (selectedPovider) {
    return true;
  }
  return false;
};
