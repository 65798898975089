const INITIAL_STATE = {
  apeswap: [],
  aave: {},
  compound: {},
  curve: [],
  darkMode: null,
  dydx: {},
  keyfi: {},
  alkemi: {},
  id: null,
  isWhitelisted: false,
  tokens: {},
  totalTokens: {},
  uniswap: [],
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_CURRENT_USER":
      return {
        ...state,
        ...action.payload,
      };
    case "RESET_USER":
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default userReducer;
