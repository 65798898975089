import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import { ReactComponent as KEYFI } from "../assets/Blue-only-logo.svg";
import { ReactComponent as ETH } from "../assets/tokens/ETH.svg";
import { ReactComponent as BSC } from "../assets/tokens/BSC.svg";
import { ReactComponent as POLYGON } from "../assets/header/Polygon.svg";

import ETHStaking from "../components/Tokens/ETHStaking";
import Tabs from "../components/Tabs";
import BSCStaking from "../components/Tokens/BSCStaking";
import { breakPoints } from "../theme";

import PolygonStaking from "../components/Tokens/PolygonStaking";
import EarnChart from "../components/Tokens/Charts/EarnChart";

const Wrapper = styled.div`
  margin: 2em auto 1.2em;
  width: 100%;
  max-width: 910px;
  @media (min-width: 1440px) {
    max-width: 1200px;
  }
  .flash-enter {
    opacity: 0;
  }

  .flash-enter.flash-enter-active {
    opacity: 1;
    transition: opacity 0.3s ease-in;
  }

  .flash-enter-done {
    opacity: 1;
  }

  .flash-exit {
    display: none;
    opacity: 0;
  }

  .flash-exit.flash-exit-active {
    opacity: 0;
  }

  .flash-exit-done {
    opacity: 0;
  }
`;

const TokensHeader = styled.div`
  display: flex;
  font-weight: 500;
  margin-bottom: 1.25rem;
  .tokens_header_title {
    display: flex;
    align-items: center;
    font-size: 24px;
    color: ${(props) => props.theme.brandBlue};
    margin-right: 5rem;
    svg {
      margin-right: 0.5rem;
    }
  }
  .tokens_header_nets {
    display: flex;
    align-items: center;
    gap: 1rem;
    @media (max-width: ${breakPoints.lg}px) {
      margin-bottom: 1rem;
    }
  }
  @media (max-width: ${breakPoints.lg}px) {
    flex-direction: column-reverse;
    gap: 1.25rem;
  }
`;

const TokenNetLabel = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: ${(props) =>
    props.active ? props.theme.superLightBlue : "none"};
  padding: 0.5rem;
  border-radius: 6px;
  border: ${(props) =>
    props.active ? `1px solid ${props.theme.border}` : "1px solid transparent"};
  color: ${(props) =>
    props.active ? props.theme.brandBlue : props.theme.mainDarkText};
  svg {
    max-height: 20px;
    max-width: 20px;
    margin-right: 0.4rem;
  }
`;

const networkCodes = {
  "bsc-mainnet": "bsc",
  mainnet: "eth",
  polygon: "polygon",
};

function Tokens({ history, user, wallet }) {
  const { t } = useTranslation();

  const { platformCode } = useParams();

  const [activeNet, setActiveNet] = useState("");

  useEffect(() => {
    if (wallet.network && wallet.network.name) {
      if (platformCode) {
        setActiveNet(platformCode.toUpperCase());
      } else if (wallet && wallet.network.name) {
        history.push(`/earn/${networkCodes[wallet.network.name] ?? "eth"}`);
      }
    }
  }, [platformCode, history, wallet.network, wallet]);

  const tabs = {
    ETH: [{ Name: t("earn.staking"), Component: <ETHStaking /> }],
    BSC: [{ Name: t("earn.staking"), Component: <BSCStaking /> }],
    Polygon: [{ Name: t("earn.staking"), Component: <PolygonStaking /> }],
  };

  return (
    <Wrapper>
      <TokensHeader>
        <div className="tokens_header_title">
          <KEYFI />
          {t("earn.title")}
        </div>
        <div className="tokens_header_nets">
          <TokenNetLabel
            active={activeNet === "ETH"}
            onClick={() => history.push("/earn/eth")}
          >
            <ETH />
            {window.innerWidth < 992 ? "ETH" : t("earn.ethereum")}
          </TokenNetLabel>
          <TokenNetLabel
            active={activeNet === "BSC"}
            onClick={() => history.push("/earn/bsc")}
          >
            <BSC />
            {window.innerWidth < 992 ? "BSC" : t("earn.binanceSmartChain")}
          </TokenNetLabel>
          <TokenNetLabel
            active={activeNet === "POLYGON"}
            onClick={() => history.push(`/earn/polygon`)}
          >
            <POLYGON />
            {window.innerWidth < 992 ? "Polygon" : `Polygon`}
          </TokenNetLabel>
        </div>
      </TokensHeader>
      {activeNet && (
        <>
          {user.usdPrices && <EarnChart showV2={platformCode === "eth"} />}

          {platformCode === "eth" && <Tabs tabs={tabs.ETH} />}
          {platformCode === "bsc" && <Tabs tabs={tabs.BSC} />}
          {platformCode === "polygon" && <Tabs tabs={tabs.Polygon} />}
        </>
      )}
    </Wrapper>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  wallet: state.wallet,
});

export default connect(mapStateToProps)(Tokens);
