import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import TotalCard from "./TotalCard";
import Modal from "../Modal";
import PlatformTable from "./PlatformTable";
import {
  beautifyTokensAmount,
  calculateAPY,
  getAPYRatesOnPlatform,
} from "../../helpers";
import { breakPoints } from "../../theme";
import { stakingOperations } from "../../redux/stakingSlice";
import LPPlatformTable from "./LPPlatformTable";
import { useTranslation } from "react-i18next";

const PlatformModalTitle = styled.div`
  display: flex;
  align-items: center;
  .title_name {
    display: flex;
    align-items: center;
    img {
      margin-right: 0.5rem;
      height: 32px;
      width: 32px;
    }
    span {
      margin-right: 1.5rem;
      font-size: 24px;
    }
  }

  .title_values {
    display: flex;
    .value_box {
      background: ${(props) => props.theme.lightBlue2};
      font-size: 16px;
      line-height: 19.5px;
      border-radius: 4px;
      margin-right: 1.5rem;
      padding: 0.5rem 1rem;
    }
  }

  @media (max-width: ${breakPoints.lg}px) {
    flex-direction: column;
    align-items: flex-start;
    .title_name {
      margin: 1rem 0;
    }
    .title_values {
      .value_box {
        font-size: 12px;
        line-height: 14.5px;
      }
    }
  }
`;

const PlatformCard = ({ platform, user, rates }) => {
  const dispatch = useDispatch();

  const liquidity = useSelector((state) => state.liquidity);
  const staking = useSelector((state) => state.staking);

  const wallet = useSelector((state) => state.wallet);
  const { network } = wallet;
  const [showModal, setShowModal] = useState(false);

  const platformTokens = () => {
    if (user.usdPrices) {
      if (platform.category === "lp") {
        return liquidity[network.name][platform.name.toLowerCase()];
      }
      return dispatch(
        stakingOperations.getPlatformAllTokens(platform.name.toLowerCase())
      );
    }
    return {};
  };

  const platformAPY = calculateAPY(
    platformTokens,
    user.usdPrices,
    getAPYRatesOnPlatform(rates, platform.name)
  );
  const { t } = useTranslation();
  return (
    <div onClick={() => setShowModal(true)}>
      <TotalCard
        name={platform.name}
        icon={platform.icon}
        value={platform.total}
        netAPYtab
        netAPY={platformAPY}
      />
      <Modal
        width="690px"
        maxwidth="920px"
        title={
          <PlatformModalTitle>
            <div className="title_name">
              {platform.icon}
              <span>{platform.name}</span>
            </div>
            <div className="title_values">
              <div className="value_box">
                {beautifyTokensAmount(platform.total, {
                  suffix: " USD",
                  prefix: "$",
                })}
              </div>
              <div className="value_box">
                {t("rebalancer.netAPY")}:{" "}
                {beautifyTokensAmount(platformAPY, { suffix: "%" })}
              </div>
            </div>
          </PlatformModalTitle>
        }
        open={showModal}
        handleCloseModal={() => setShowModal(false)}
        padding="2.5rem"
        removeHeaderLine
      >
        {platform && platform.category === "lp" ? (
          <LPPlatformTable
            user={user}
            tokens={platformTokens()}
            title={platform.name.toLowerCase()}
          />
        ) : (
          <PlatformTable user={user} tokens={platformTokens()} />
        )}
      </Modal>
    </div>
  );
};

export default PlatformCard;
