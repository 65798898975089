import styled from "styled-components";
import { breakPoints } from "../theme";

export const BlueTextCard = styled.div`
  padding: 1.5em 1em;
  border-radius: 6px;
  color: ${(props) => props.theme.brandBlue};
  background: ${(props) => props.theme.lightBlue3};
  font-family: "Telex", sans-serif;
  font-size: 14px;
  line-height: 1.3;
  margin: 0px auto 2rem auto;
  max-width: 512px;
  @media (max-width: ${breakPoints.lg}px) {
    max-width: 100%;
  }
`;

export const PoolsCardWrapper = styled.div`
  background: ${(props) => props.theme.superLightBlue};
  color: ${(props) => props.theme.mainDarkText};
  padding: 24px;
  border: 1px solid ${(props) => props.theme.lightBlue3};
  border-radius: 6px;
  margin: 0 auto 4em;
  width: 560px;
  @media (max-width: ${breakPoints.lg}px) {
    margin: 0 auto 2em;
    padding: 16px;
    width: 100%;
  }
`;

export const CardWrapper = styled.div`
  background: ${(props) => props.theme.superLightBlue};
  color: ${(props) => props.theme.mainDarkText};
  border: 1px solid ${(props) => props.theme.lightBlue3};

  padding: 24px;
  border-radius: 6px;
`;
export const CardTitle = styled.div`
  color: ${(props) => props.theme.mainDarkText};
  font-weight: 500;
  font-size: 1.5em;
  margin-left: -1em;
  margin-top: -3em;
  position: absolute;
`;

export const SecondTitle = styled.div`
  color: ${(props) => props.theme.mainDarkText};
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 7px;
`;

export const PlatformSelect = styled.div`
  margin-bottom: 24px;
`;

export const BoldTitle = styled.div`
  color: ${(props) => props.theme.mainDarkText};
  font-weight: 500;
  font-size: 24px;
  margin: 2em auto 1.2em;
  width: 560px;

  @media (max-width: ${breakPoints.lg}px) {
    margin: 2em auto 1.2em;
    width: 100%;
  }
`;

export const InsideBoldTitle = styled.div`
  color: ${(props) => props.theme.mainDarkText};
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 1.2em;
  width: 560px;

  @media (max-width: ${breakPoints.lg}px) {
    width: 100%;
  }
`;

export const PoolsInnerWrapper = styled.div`
  font-family: "Montserrat";
  color: ${(props) => props.theme.mainDarkText};
  position: relative;
  display: flex;
  flex-direction: column;

  .InnerWrapper__inputsgroup {
    position: relative;

    .inputsgroup__floating-left,
    .inputsgroup__floating-right {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      position: absolute;
      top: -1.3rem;
      .value {
        max-width: 62px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: clip;
        display: inline-flex;
        margin-right: 3px;
      }
    }

    .inputsgroup__floating-left {
      left: 0;
    }

    .inputsgroup__floating-right {
      right: 0;
    }
  }

  .InnerWrapper__infogroup {
    margin-top: 1.5rem;
  }

  .infogroup__title {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 22px;
  }

  .error {
    margin: 5px 0 0 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: ${(props) => props.theme.error};
  }

  button {
    font-family: "Montserrat";
    text-transform: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    height: 46px;
    @media (max-width: ${breakPoints.md}px) {
      height: 35px;
      margin-top: 0.5rem;
    }
  }

  .float {
    position: absolute;
    left: 0;

    @media (max-width: ${breakPoints.md}px) {
      position: unset;
    }
  }

  .float.top {
    top: 62px;
  }

  .float.bottom {
    bottom: 15px;
  }

  @media (max-width: ${breakPoints.md}px) {
    .float {
      right: 40%;
    }

    .float.bottom {
      bottom: 85px;
    }
  }

  .InnerWrapper {
    display: grid;
    grid-template-columns: 3fr 156px;
    grid-column-gap: 1rem;
    margin-bottom: 2.5em;
    margin-top: 1rem;

    @media (max-width: ${breakPoints.md}px) {
      grid-template-columns: 3fr;
      margin-bottom: 2em;
    }

    button {
      align-self: end;
    }
  }

  .InnerWrapper__button {
    margin-bottom: 1rem;
  }

  .InnerWrapper__details {
    margin-bottom: 0;
    padding: 0;
  }
`;

export const PoolDataWrapper = styled.div`
  width: 100%;
  margin: 5px 0 0;
  height: 60px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid ${(props) => props.theme.lightBlue3};
  border-radius: 6px;

  span:nth-of-type(odd) {
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
    color: ${(props) => props.theme.mainDarkText};
  }
`;

export const DataInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span:nth-last-of-type(odd) {
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
  }
`;

export const Divider = styled.hr`
  background: ${(props) => props.theme.lightBlue3};
  border: 0;
  height: 1px;
  margin: 2rem 0 0;
  width: 100%;
`;

export const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.mainDarkText};
  svg,
  img {
    margin-right: 8px;
    max-width: 32px;
    max-height: 32px;
  }
`;

export const HeadInfo = styled.div`
  background: ${(props) => props.rewards && "red"};
  span {
    display: block;
    font-weight: 500;
  }

  .title {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
  }

  .value {
    font-size: 1rem;
    line-height: 20px;
    color: ${(props) => props.theme.brandBlue};
    margin-bottom: ${(props) => (props.rewardsCard ? "0" : "1.5rem")};
    @media (max-width: ${breakPoints.sm}px) {
      margin-bottom: ${(props) => (props.rewardsCard ? "0.75rem" : "1.5rem")};
    }
  }

  .rewards_value {
    margin-bottom: 0rem;
    @media (max-width: ${breakPoints.sm}px) {
      margin-bottom: 0.75rem;
    }
  }
`;

export const BlueIconTextTitle = styled(IconTextWrapper)`
  color: ${(props) => props.theme.brandBlue};
  margin-bottom: 2rem;
  font-size: 20px;
  line-height: 24px;

  img,
  svg {
    width: 32px;
    height: 32px;
  }
`;

export const PoolTokenCard = styled.div`
  cursor: pointer;
  height: 32px;
  padding: 8px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  border: 1px solid ${(props) => props.theme.lightBlue3};
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin: 0px 0.5rem 0.5rem 0px;

  .token__name {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
    img,
    svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }

  .token__value {
    color: ${(props) => props.theme.brandBlue};
  }
  :hover {
    background: ${(props) => props.theme.lightBlue3};
  }
`;

export const Skeleton = styled.div`
  border-radius: 2px;
  height: ${(props) => (props.height ? `${props.height}px` : "30px")};
  width: ${(props) => (props.width ? `${props.width}px` : "70%")};
  position: relative;
  background-color: ${(props) => (props.white ? "#DFEDFF" : "#07316a")};
  overflow: hidden;

  ::before {
    content: "";
    display: block;
    position: absolute;
    left: ${(props) => (props.width ? `-${props.width}px` : "-175px")};
    top: 0;
    height: 100%;
    width: ${(props) => (props.width ? `${props.width}px` : "75px")};
    background: ${(props) =>
      props.white
        ? `linear-gradient(
      to right,
      rgba(223, 237, 255, 0.4) 0%,
      #CBE2FF 100%
    )`
        : `linear-gradient(
      to right,
      rgba(7, 49, 106, 0.4) 0%,
      #0e4da3 100%
    )`};
    animation: load 1.2s ease-in-out infinite;
  }

  @keyframes load {
    from {
      left: ${(props) =>
        props.width ? `-${Number(props.width) + 25}px` : "-175px"};
    }
    to {
      left: 100%;
    }
  }
`;

export const Spinner = styled.div`
  font-size: 12px;
  margin: 10px auto;
  width: 8em;
  height: 8em;
  border-radius: 50%;
  background: linear-gradient(
    to right,
    ${(props) => props.theme.brandBlue} 10%,
    rgba(255, 255, 255, 0) 42%
  );
  position: relative;
  animation: load3 1.4s infinite linear;
  transform: translateZ(0);

  :before {
    width: 50%;
    height: 50%;
    background: ${(props) =>
      props.background ? props.theme[props.background] : "#ffffff"};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }
  :after {
    background: ${(props) =>
      props.background ? props.theme[props.background] : "#ffffff"};
    width: 85%;
    height: 85%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
