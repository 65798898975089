import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Assets and helpers
import AssetImage from "../AssetImage";
import { ReactComponent as ArrowDown } from "../../../assets/arrowDown.svg";
import { Skeleton } from "../../../Shared";
import {
  beautifyTokensAmount,
  compareFunction,
  poolsLogos,
} from "../../../helpers";
import { tableHeadItems } from "../BorrowConstants";
import { breakPoints } from "../../../theme";
import Button from "../../Button";

const BorrowTableWrapper = styled.table`
  border-collapse: collapse;
  display: table;
  width: 100%;
  @media (max-width: ${breakPoints.md}px) {
    display: none;
  }
  thead {
    text-align: left;
    tr {
      border-bottom: 1px solid blue;
      th {
        padding-bottom: 0.75rem;
        font-size: 12px;
        color: ${(props) => props.theme.brandBlue};
        cursor: pointer;
        > div {
          display: flex;
          align-items: center;
          > div {
            display: flex;
            flex-direction: column;
            margin-left: 0.4rem;
            svg {
              width: 10px;
              height: 8px;
              path {
                fill: ${(props) => props.theme.lightBlue3};
              }
            }
          }
        }
        :first-of-type {
          padding-left: 1rem;
        }
      }
    }
  }
  tbody {
    width: 100%;
    div {
      display: flex;
      align-items: center;
      .borrow_table_desktop_image,
      img {
        height: 20px;
        width: 20px;
        margin-right: 6px;
        border-radius: 50%;
      }
    }
    tr {
      :nth-child(2n) {
        background: ${(props) => props.theme.lightBlue3};
      }
      td {
        :first-of-type {
          padding-left: 1rem;
        }
        :last-of-type {
          padding-right: 1rem;
        }
        height: 20px;
        padding: 0.5rem 0;
        ${Button} {
          text-transform: capitalize;
          height: max-content;
          width: 100%;
          line-height: normal;
          padding: 6px 2rem;
          font-size: 16px;
        }
      }
    }
  }
`;

const ArrowDownSVG = styled(ArrowDown)`
  transform: ${(props) => (props.rotate ? "rotate(180deg)" : undefined)};

  ${(props) =>
    props.selected &&
    css`
      path {
        fill: ${props.theme.brandBlue} !important;
      }
    `}
`;

const BorrowTableDesktop = ({ data, isFetching }) => {
  const { t } = useTranslation();

  const [sort, setSort] = useState({
    field: tableHeadItems(t)[3].id, // Sets Variable APY as default
    direction: "asc",
  });

  const changeSort = (field) => {
    setSort((prevSort) => {
      if (prevSort.field === field) {
        return {
          ...prevSort,
          direction: prevSort.direction === "asc" ? "desc" : "asc",
        };
      }
      return {
        field,
        direction: "desc",
      };
    });
  };

  return (
    <BorrowTableWrapper>
      <thead>
        <tr>
          {tableHeadItems(t).map((item) => (
            <th key={item.id}>
              <div onClick={() => changeSort(item.id)}>
                {item.label}
                <div>
                  <ArrowDownSVG
                    rotate="true"
                    selected={
                      item.id === sort.field && sort.direction === "asc"
                    }
                  />
                  <ArrowDownSVG
                    selected={
                      item.id === sort.field && sort.direction === "desc"
                    }
                  />
                </div>
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {!isFetching ? (
          data.sort(compareFunction(sort)).map((item) => (
            <tr key={item.address + item.platform}>
              <td>
                <div>
                  <AssetImage
                    className="borrow_table_desktop_image"
                    src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${item.address}/logo.png`}
                    alt={item.symbol}
                  />
                  {item.symbol}
                </div>
              </td>
              <td>
                <div>
                  {poolsLogos[item.platform.split(" ")[0]]} {item.platform}
                </div>
              </td>
              <td>
                {beautifyTokensAmount(item.availableToBorrow, {
                  suffix: ` ${item.symbol}`,
                })}
              </td>
              <td>
                {beautifyTokensAmount(item.variableAPY * 100, {
                  suffix: "%",
                  percent: true,
                })}
              </td>
              <td>
                {item.stableAPY !== "0"
                  ? beautifyTokensAmount(item.stableAPY * 100, {
                      suffix: "%",
                      percent: true,
                    })
                  : "-"}
              </td>
              <td>
                <Link
                  to={`/borrow/${item.platform.split(" ")[0].toLowerCase()}/${
                    item.version
                  }/${item.symbol}`}
                >
                  <Button primary>{t("borrow.borrowBtn")}</Button>
                </Link>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            {[...Array(6)].map((e, i) => (
              <td key={Math.random()}>
                <Skeleton white width={i === 5 ? "100" : undefined} />
              </td>
            ))}
          </tr>
        )}
      </tbody>
    </BorrowTableWrapper>
  );
};

export default BorrowTableDesktop;
