import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  beautifyTokensAmount,
  filterZeroBalance,
  tokensLogos,
} from "../../helpers";
import {
  dashboardSupportedTokens,
  governanceTokens,
  mainAssets,
  stableCoins,
} from "../../constants";
import { breakPoints } from "../../theme";
import AssetImage from "../Borrow/AssetImage";
import { getAddress } from "@keyfi/keyfi-common/src/integrations";

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;

  .title {
    display: inline-block;
    margin-top: 2rem;
    background: ${(props) => props.theme.lightBlue3};
    color: ${(props) => props.theme.brandBlue};
    font-weight: 500;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 20px;
  }

  .mobileVersion {
    display: none;
    @media (max-width: ${breakPoints.lg}px) {
      display: block;
      .title {
        display: inline-block;
      }
    }
  }

  .desktopVersion {
    @media (max-width: ${breakPoints.lg}px) {
      .title {
        display: none;
      }
    }
  }

  @media (min-width: ${breakPoints.lg}px) {
    td {
      padding: 5px;
      max-width: 120px;
      font-size: 16px;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${(props) => props.theme.mainDarkText};
      border-bottom: 1px solid ${(props) => props.theme.border2};

      a {
        color: ${(props) => props.theme.mainDarkText};
      }
    }
  }
`;

const DesktopTableWrap = styled.table`
  display: table;
  width: 100%;
  border-collapse: collapse;
  margin-top: 2rem;
  font-weight: 500;
  thead {
    th {
      padding: 0 1rem 0.5rem;
      text-align: left;
      border-bottom: 1px solid ${(props) => props.theme.brandBlue};
      font-size: 16px;
      font-weight: 500;
      color: ${(props) => props.theme.brandBlue};
      :last-of-type {
        text-align: right;
      }
    }
  }
  tbody {
    tr {
      height: 54px;
      :nth-child(even) {
        background: ${(props) => props.theme.tableRow};
      }
      td {
        padding: 0 1rem;
        :last-of-type {
          text-align: right;
        }
      }
    }
  }
  .td_token {
    display: flex;
    align-items: center;
    svg,
    img,
    .table_image {
      height: 32px;
      width: 32px;
      font-size: 20px;
      margin-right: 0.5rem;
    }
  }
  @media (max-width: ${breakPoints.lg}px) {
    display: none;
  }
`;

const MobileTableWrap = styled.div`
  display: none;
  .mobileTable {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 2rem;
    width: 100%;
    tbody {
      width: 100%;
      font-weight: 500;
    }
  }
  @media (max-width: ${breakPoints.lg}px) {
    display: block;
  }
`;

const MobileTransactionWidget = styled.tr`
  background: ${(props) => props.theme.superLightBlue};
  border: 1px solid ${(props) => props.theme.lightBlue3};
  color: ${(props) => props.theme.mainDarkText};
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  margin-bottom: 1.5em;
  padding: 10px 16px;
  height: initial !important;
  width: 100%;

  .tableTitle {
    color: ${(props) => props.theme.brandBlue};
    margin-right: 5px;
    min-width: 80px;
  }

  div {
    font-weight: 500;
  }
`;

const MobileCell = styled.td`
  align-items: center;
  display: flex;
  height: 50px;
  margin: 0;
  max-width: 100%;

  img,
  svg {
    height: 20px;
    width: 20px;
    margin-right: 8px;
  }

  :not(.last) {
    border-bottom: 1px solid ${(props) => props.theme.lightBlue3};
  }
`;

function WalletTable({ hideZeroBalances }) {
  const { t } = useTranslation();
  const tableHeadItems = [
    { id: "asset", label: t("dashboard.accountOverview.asset") },
    { id: "balance,", label: t("dashboard.accountOverview.balance") },
    { id: "price", label: t("dashboard.accountOverview.price") },
    { id: "value", label: t("dashboard.accountOverview.value") },
  ];
  const network = useSelector((state) => state.wallet.network);

  const user = useSelector((state) => state.user);

  // const allTokens = [mainAssets[network?.name]].concat(
  //   dashboardSupportedTokens[network.name]
  // );
  // const nonStableCoins = allTokens.filter(
  //   (token) => !stableCoins[network?.name].includes(token) && token !== "KEYFI"
  // );

  const makeTokensRows = (tokens = []) => {
    return tokens
      .filter((token) => (hideZeroBalances ? user.tokens[token] : true))
      .map((token) => ({
        asset: token,
        balance: beautifyTokensAmount(user.tokens[token]),
        price: beautifyTokensAmount(user.usdPrices?.[token], {
          suffix: "",
          prefix: "$",
        }),
        value: beautifyTokensAmount(
          user.usdPrices?.[token] * (user.tokens[token] ?? 0),
          {
            suffix: " USD",
            prefix: "$",
          }
        ),
      }));
  };

  const governanceTokensRows = makeTokensRows(governanceTokens[network?.name]);
  const stableCoinsRows = makeTokensRows(stableCoins[network?.name]);
  const nonStableCoinsRows = user.readOnly
    ? makeTokensRows(
        Object.keys(user.tokens).filter(
          (x) =>
            !stableCoins[network?.name].includes(x) &&
            !governanceTokens[network?.name].includes(x)
        )
      )
    : makeTokensRows(
        Object.keys(user.tokens).filter(
          (x) =>
            !stableCoins[network?.name].includes(x) &&
            !governanceTokens[network?.name].includes(x)
        )
      );

  const DesktopTable = ({ tableHead, tableRows }) => {
    return (
      <DesktopTableWrap>
        <thead>
          <tr>
            {tableHead.map(({ label }) => (
              <th key={label}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableRows.length !== 0 ? (
            tableRows.map((row, i) => (
              <tr key={i}>
                {Object.keys(row).map((cell, i) => (
                  <td key={i}>
                    {cell === "asset" ? (
                      <span className="td_token">
                        {
                          <AssetImage
                            className="table_image"
                            src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
                              network.name === "bsc-mainnet"
                                ? "smartchain"
                                : "ethereum"
                            }/assets/${getAddress(
                              row[cell],
                              network.name
                            )}/logo.png`}
                            // fallbackLogo={"?"}
                            alt={row[cell]}
                          />
                        }{" "}
                        {row[cell]}
                      </span>
                    ) : (
                      row[cell]
                    )}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" style={{ textAlign: "left" }}>
                {t("addition.noAssets")}
              </td>
            </tr>
          )}
        </tbody>
      </DesktopTableWrap>
    );
  };

  const MobileTable = ({ tableHead, tableRows }) => {
    return (
      <MobileTableWrap>
        <table className="mobileTable">
          <tbody>
            {tableRows.map((row, index) => (
              <MobileTransactionWidget key={index}>
                {Object.keys(row).map((Cell, i) => (
                  <MobileCell key={i}>
                    <div className="tableTitle">{tableHead[i].label}</div>
                    {Cell === "asset" ? (
                      <span className="td__wrapper">
                        {tokensLogos[row[Cell]]} {row[Cell]}
                      </span>
                    ) : (
                      row[Cell]
                    )}
                  </MobileCell>
                ))}
              </MobileTransactionWidget>
            ))}
          </tbody>
        </table>
      </MobileTableWrap>
    );
  };

  return (
    <Wrapper>
      <div className="desktopVersion">
        <span className="title">
          {t("dashboard.accountOverview.governance")}
        </span>
        <DesktopTable
          tableHead={tableHeadItems}
          tableRows={governanceTokensRows}
        />

        <span className="title">
          {t("dashboard.accountOverview.stablecoins")}
        </span>
        <DesktopTable tableHead={tableHeadItems} tableRows={stableCoinsRows} />
        {!!nonStableCoinsRows.length && (
          <>
            <span className="title">{t("rebalancer.token")}</span>
            <DesktopTable
              tableHead={tableHeadItems}
              tableRows={nonStableCoinsRows}
            />
          </>
        )}
      </div>
      <div className="mobileVersion">
        <span className="title">
          {t("dashboard.accountOverview.governance")}
        </span>
        <MobileTable
          tableHead={tableHeadItems}
          tableRows={governanceTokensRows}
        />
        <span className="title">
          {t("dashboard.accountOverview.stablecoins")}
        </span>
        <MobileTable tableHead={tableHeadItems} tableRows={stableCoinsRows} />

        {!!nonStableCoinsRows.length && (
          <>
            <span className="title">{t("rebalancer.tokens")}</span>
            <MobileTable
              tableHead={tableHeadItems}
              tableRows={nonStableCoinsRows}
            />
          </>
        )}
      </div>
    </Wrapper>
  );
}

export default WalletTable;
