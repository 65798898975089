import React, { useEffect, useState } from "react";
import timestamp from "time-stamp";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { integrations } from "@keyfi/keyfi-common";
import { useTranslation } from "react-i18next";
import BigNumber from "bignumber.js";
import { getTxHistory } from "../TransactionManager";
import { beautifyTokensAmount } from "../../helpers";
import Button from "../Button";
import InputGroupv2 from "../InputGroupv2";
import { walletOperations } from "../../redux/walletSlice";
import { borrowOperations } from "../../redux/borrowSlice";
import { Storage } from "../../helpers/Storage";
import HealthFactorBar from "./HealthFactorBar";
import {
  addTx,
  removeTx,
  showTransactionManager,
} from "../../redux/transactionManager/actions";

const Wrapper = styled.div`
  background: ${(props) => props.theme.superLightBlue};
  border: 1px solid ${(props) => props.theme.border3};
  border-radius: 0 6px 6px 6px;
  padding: 1rem;
  h3 {
    color: ${(props) => props.theme.brandBlue};
    margin: 0 0 0.5rem;
    font-size: 14px;
  }
  .repay_input_container {
    padding-bottom: 2rem;
    border-bottom: 1px solid ${(props) => props.theme.border2};
    .repay_input_labels {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 0.5rem;
      span {
        font-size: 12px;
      }
    }
    .repay_input_error {
      margin-top: 0.5rem;
      font-size: 12px;
      color: ${(props) => props.theme.error};
    }
  }
  ${Button} {
    width: 100%;
    margin-top: 1.5rem;
    padding: 10px 0;
    line-height: normal;
    font-size: 16px;
    height: max-content;
    text-transform: capitalize;
  }
`;

function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}

const RepayTab = ({
  params,
  borrow,
  userBorrowPlatformData,
  borrowAssetData,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { platform, version, asset } = params;

  const user = useSelector((state) => state.user);

  const [inputData, setInputData] = useState({
    repay: "",
    estimatedHealthFactor: 0,
  });

  const borrowedBalance = borrow.mainnet[platform][version].balance;

  const getInputValues = (name, value) => {
    setInputData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (inputData.repay) {
      const estimatedHealthFactor = integrations.aave.v2.estimateHealthFactor(
        borrowAssetData,
        inputData.repay,
        userBorrowPlatformData,
        true
      );
      setInputData((prev) => ({ ...prev, estimatedHealthFactor }));
    } else {
      setInputData((prev) => ({ ...prev, estimatedHealthFactor: 0 }));
    }
  }, [inputData.repay]);

  const handleRepay = async () => {
    const txHash = [];
    let isFirstTrxFired = false;

    try {
      const res = await integrations[platform][version].repay(
        asset,
        inputData.repay,
        {
          pendingCallback: (trx) => {
            dispatch(showTransactionManager());
            if (!isFirstTrxFired) {
              dispatch(
                addTx({
                  platform: capitalizeFirstLetter(platform),
                  txType: "Borrow",
                  txAmount: inputData.repay,
                  txToken: asset,
                  txHash: trx.hash,
                  timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
                })
              );
              isFirstTrxFired = true;
            }
            txHash.push(trx.hash);
          },
        }
      );
      if (res) {
        dispatch(walletOperations.loadUserData());
        dispatch(borrowOperations.getBorrowBalance());
        const HistoryArray = getTxHistory();
        const txObject = {
          platform: capitalizeFirstLetter(platform),
          txType: "Repay",
          txAmount: inputData.repay,
          txToken: asset,
          txHash: res.transactionHash,
          timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
        };
        HistoryArray.unshift(txObject);
        Storage.setItem("transactions", JSON.stringify(HistoryArray));
        dispatch(removeTx(txHash));
      }
    } catch (err) {
      dispatch(showTransactionManager());
      const HistoryArray = getTxHistory();
      const txObject = {
        platform: capitalizeFirstLetter(platform),
        txType: "Repay",
        txAmount: inputData.repay,
        txToken: asset,
        txHash: txHash[txHash.length - 1],
        timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
        status: "failed",
      };
      HistoryArray.unshift(txObject);
      Storage.setItem("transactions", JSON.stringify(HistoryArray));
      dispatch(removeTx(txHash));
    }
  };

  const handleMaxInput = () => {
    if (borrowedBalance[asset].totalDebt) {
      setInputData((prevState) => ({
        ...prevState,
        repay: borrowedBalance[asset].totalDebt,
      }));
    }
  };

  const handleError = () => {
    if (
      BigNumber(inputData.repay).gt(user.tokens[asset.toUpperCase()] ?? 0) ||
      BigNumber(inputData.repay).gt(borrowedBalance[asset].totalDebt)
    ) {
      return true;
    }

    return false;
  };

  return (
    <Wrapper>
      <h3>{t("borrow.repayTab.tab")}</h3>
      <div className="repay_input_container">
        <div className="repay_input_labels">
          <span>{t("borrow.borrowTab.input")}</span>
          <span style={{ textAlign: "right" }}>
            <div>
              {asset} {t("addition.inWallet")}:{" "}
              {beautifyTokensAmount(user.tokens[asset.toUpperCase()] ?? 0, {
                suffix: ` ${asset}`,
              })}
            </div>
            <div>
              {t("addition.borrowedAmount")}:{" "}
              {beautifyTokensAmount(borrowedBalance[asset].totalDebt, {
                suffix: ` ${asset}`,
              })}
            </div>
          </span>
        </div>

        <InputGroupv2
          name="repay"
          updateParent={getInputValues}
          value={inputData.repay}
          invokeMaxInput={handleMaxInput}
          disableOptions
          error={handleError()}
        />
        {BigNumber(inputData.repay).gt(
          user.tokens[asset.toUpperCase()] ?? 0
        ) && (
          <div className="repay_input_error">
            {t("addition.notEnoughInWallet", { asset: asset })}
          </div>
        )}
        <HealthFactorBar
          currentHealthFactor={userBorrowPlatformData.healthFactor}
          estimateHealthFactor={inputData.estimatedHealthFactor}
        />
      </div>
      <Button
        primary
        onClick={handleRepay}
        disabled={!inputData.repay || handleError()}
      >
        {t("borrow.repayTab.tab")}
      </Button>
    </Wrapper>
  );
};

export default RepayTab;
