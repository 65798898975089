import { useState } from "react";
import { Link } from "react-router-dom";
import { beautifyTokensAmount, tokensLogos } from "../../../helpers";
import { ReactComponent as ArrowDown } from "../../../assets/arrowDown.svg";
import { useTranslation } from "react-i18next";

const UserLiquidityListItem = ({
  data,
  platform,
  version = "v1",
  disabledAddLiquidity = false,
}) => {
  const { t } = useTranslation();

  const { assetA, assetB, liquidity, liquidityPercent } = data;
  const [open, setOpen] = useState(false);

  const liquidityPercentNorm =
    parseFloat(liquidityPercent) * 100 > 0.01
      ? beautifyTokensAmount(parseFloat(liquidityPercent) * 100)
      : "<0.01";

  return (
    <div className="user-liquidity-list-item">
      <div
        className="user-liquidity-item-header"
        onClick={() => setOpen(!open)}
      >
        <div className="user-liquidity-item-images">
          {tokensLogos[assetA]}
          {tokensLogos[assetB]}
        </div>
        <div className="user-liquidity-item-title">
          <div>
            {assetA}:{assetB}
          </div>
          <div>{beautifyTokensAmount(liquidity)}</div>
        </div>
        <div className="user-liquidity-item-manage">
          <span>{t("addition.manage")}</span>
          <ArrowDown style={{ transform: open && `rotate(180deg)` }} />
        </div>
      </div>
      {open && (
        <div className="user-liquidity-item-body">
          <div className="user-liquidity-item-body-data">
            <div>
              {tokensLogos[assetA]}
              {t("addition.pooledToken", { token: assetA })}
            </div>
            <div>{beautifyTokensAmount(data[assetA])}</div>
          </div>
          <div className="user-liquidity-item-body-data">
            <div>
              {tokensLogos[assetB]}{" "}
              {t("addition.pooledToken", { token: assetB })}
            </div>
            <div>{beautifyTokensAmount(data[assetB])}</div>
          </div>
          <div className="user-liquidity-item-body-data">
            <div>{t("addition.yourPoolShare")}:</div>
            <div>{liquidityPercentNorm}%</div>
          </div>
          <div className="user-liquidity-item-buttons">
            <Link
              to={`/pools/add-liquidity/${platform}?input1=${assetA}&input2=${assetB}&v=${version}`}
              className={
                disabledAddLiquidity ? "user-liquidity-button-disabled" : ""
              }
            >
              {t("pool.addLiquidity.title")}
            </Link>
            <Link
              to={`/pools/remove-liquidity/${platform}?input1=${assetA}&input2=${assetB}&v=${version}`}
            >
              {t("addition.removeLiquidity")}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserLiquidityListItem;
