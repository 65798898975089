import React from "react";
import styled from "styled-components";
import {
  beautifyTokensAmount,
  tokensLogos,
  calculateLPValue,
} from "../../helpers";
import { breakPoints } from "../../theme";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

const DesktopPlatformTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 2rem;
  font-weight: 500;
  thead {
    th {
      padding: 0 1rem 0.5rem;
      text-align: left;
      border-bottom: 1px solid ${(props) => props.theme.brandBlue};
      font-size: 16px;
      font-weight: 500;
      color: ${(props) => props.theme.brandBlue};
      :last-of-type {
        text-align: right;
      }
    }
  }
  tbody {
    tr {
      height: 54px;
      :nth-child(even) {
        background: ${(props) => props.theme.tableRow};
      }
      td {
        padding: 0 1rem;
        :last-of-type {
          text-align: right;
        }
      }
    }
    .table_noassets {
      background: ${(props) => props.theme.tableRow};
    }
  }

  .td_token {
    display: flex;
    align-items: center;
    svg,
    img {
      height: 32px;
      width: 32px;
      margin-right: 0.5rem;
      :first-of-type {
        z-index: 2;
      }
      :nth-child(2) {
        transform: translateX(-1rem);
        margin-right: -0.5rem;
      }
    }
  }
  @media (max-width: ${breakPoints.lg}px) {
    display: none;
  }
`;

const MobilePlatformTable = styled.div`
  display: none;
  .mobileTable {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 2rem;
    width: 100%;
    tbody {
      font-weight: 500;
      width: 100%;
    }
  }
  @media (max-width: ${breakPoints.lg}px) {
    display: block;
  }
`;

const MobileTransactionWidget = styled.tr`
  background: ${(props) => props.theme.superLightBlue};
  border: 1px solid ${(props) => props.theme.lightBlue3};
  color: ${(props) => props.theme.mainDarkText};
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  margin-bottom: 1.5em;
  padding: 10px 16px;
  height: initial !important;
  width: 100%;
  .tableTitle {
    color: ${(props) => props.theme.brandBlue};
    margin-right: 5px;
    min-width: 80px;
  }

  div {
    font-weight: 500;
  }
`;

const MobileCell = styled.td`
  align-items: center;
  display: flex;
  height: 50px;
  margin: 0;
  max-width: 100%;

  img,
  svg {
    height: 24px;
    width: 24px;
    margin-right: 8px;
  }
  .td_token {
    display: flex;
    align-items: center;
    img {
      :first-of-type {
        z-index: 2;
      }
      :nth-child(2) {
        transform: translateX(-1rem);
        margin-right: -0.5rem;
      }
    }
  }
  border-bottom: 1px solid ${(props) => props.theme.lightBlue3};
  :last-of-type {
    border-bottom: 0;
  }
`;

const PlatformTable = ({ tokens, user }) => {
  const { t } = useTranslation();
  const tableHead = [
    { id: "asset", label: t("dashboard.accountOverview.asset") },
    { id: "balance", label: t("dashboard.accountOverview.balance") },
    { id: "price", label: t("dashboard.accountOverview.price") },
    { id: "value", label: t("dashboard.accountOverview.value") },
  ];

  const tokenRows = () => {
    let array = [];

    array = Object.entries(tokens).map(([title, value]) => {
      if (value.type === "lp") {
        const lpValue = calculateLPValue(title, value, user.usdPrices);

        return {
          asset: title,
          token0: title.split(":")[0],
          token1: title.split(":")[1].split(" LP")[0],
          balance: beautifyTokensAmount(value.amount),
          price: beautifyTokensAmount(lpValue / value.amount, { prefix: "$" }),
          value: beautifyTokensAmount(lpValue, { prefix: "$", suffix: " USD" }),
        };
      }
      return {
        asset: title,
        balance: beautifyTokensAmount(value.amount),
        price: beautifyTokensAmount(user.usdPrices[title], {
          suffix: "",
          prefix: "$",
        }),
        value: beautifyTokensAmount(user.usdPrices[title] * value.amount, {
          suffix: " USD",
          prefix: "$",
        }),
      };
    });

    array = array.filter((asset) => asset.balance !== "0.00");
    return array;
  };

  const DesktopTable = ({ tableHead, tableBody }) => (
    <DesktopPlatformTable>
      <thead>
        <tr>
          {tableHead.map(({ label }) => (
            <th key={label}>{label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableBody.length !== 0 ? (
          tableBody.map(
            (token, i) =>
              token.balance !== "0.00" && (
                <tr key={i}>
                  <td>
                    <div className="td_token">
                      {tokensLogos[token.token0 ?? token.asset]}
                      {token.token1 && tokensLogos[token.token1]}
                      {token.asset}
                    </div>
                  </td>
                  <td>{token.balance}</td>
                  <td>{token.price}</td>
                  <td>{token.value}</td>
                </tr>
              )
          )
        ) : (
          <tr className="table_noassets">
            <td colSpan="4" style={{ textAlign: "left" }}>
              {t("addition.noAssets")}
            </td>
          </tr>
        )}
      </tbody>
    </DesktopPlatformTable>
  );

  const MobileTable = ({ tableHead, tableBody }) => (
    <MobilePlatformTable>
      <table className="mobileTable">
        <tbody>
          {tableBody.length !== 0 ? (
            tableBody.map(
              (token, i) =>
                token.balance !== "0.00" && (
                  <MobileTransactionWidget key={i}>
                    <MobileCell>
                      <div className="tableTitle">{tableHead[0].label}</div>
                      <span className="td_token">
                        {tokensLogos[token.token0 ?? token.asset]}
                        {token.token1 && tokensLogos[token.token1]}
                        {token.asset}
                      </span>
                    </MobileCell>
                    <MobileCell>
                      <div className="tableTitle">{tableHead[1].label}</div>
                      {token.balance}
                    </MobileCell>
                    <MobileCell>
                      <div className="tableTitle">{tableHead[2].label}</div>
                      {token.price}
                    </MobileCell>
                    <MobileCell>
                      <div className="tableTitle">{tableHead[3].label}</div>
                      {token.value}
                    </MobileCell>
                  </MobileTransactionWidget>
                )
            )
          ) : (
            <div>{t("addition.noAssets")}</div>
          )}
        </tbody>
      </table>
    </MobilePlatformTable>
  );

  return (
    <Wrapper>
      {user.usdPrices && (
        <>
          <DesktopTable tableHead={tableHead} tableBody={tokenRows()} />
          <MobileTable tableHead={tableHead} tableBody={tokenRows()} />
        </>
      )}
    </Wrapper>
  );
};

export default PlatformTable;
