import React, { useEffect, useState, useMemo } from "react";
import { integrations } from "@keyfi/keyfi-common";
import styled from "styled-components";
import { Redirect, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useTranslation } from "react-i18next";
import BorrowTab from "../components/Borrow/BorrowTab";
import RepayTab from "../components/Borrow/RepayTab";

// Assets and components
import BorrowAssetHeader from "../components/Borrow/BorrowAssetHeader";
import Tabs from "../components/Tabs";
import { beautifyTokensAmount } from "../helpers";
import { Skeleton, Spinner } from "../Shared";
import { ReactComponent as InfoIcon } from "../assets/infoIcon.svg";
import AssetImage from "../components/Borrow/AssetImage";
import { breakPoints } from "../theme";

const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 1.25rem auto;
  a {
    display: inline-block;
    .borrow_asset_back {
      cursor: pointer;
      padding: 0.5rem 1.5rem;
      background: 0;
      border-radius: 6px;
      border: 1px solid black;
      display: flex;
      align-items: center;
      svg {
        margin-right: 0.5rem;
      }
    }
  }

  h1 {
    font-size: 24px;
    margin: 1rem 0;
  }
`;

const BorrowAssetBody = styled.div`
  margin-top: 1rem;
  display: flex;
  width: 100%;
  gap: 2.5rem;
  @media (max-width: ${breakPoints.md}px) {
    flex-direction: column-reverse;
  }
  .borrow_asset_body_tabs {
    flex: 4;
    .borrow_asset_history_mobile {
      display: none;
      @media (max-width: ${breakPoints.md}px) {
        h3 {
          font-size: 14px;
          margin: 1.5rem 0 0.5rem;
        }
        display: block;
      }
    }
  }
  .borrow_asset_body_info {
    flex: 6;
    h3 {
      margin: 0 0 0.75rem;
      font-size: 14px;
    }
    .borrow_asset_info {
      max-width: 300px;
      padding: 0.75rem 1rem;
      margin-bottom: 2.5rem;
      background: ${(props) => props.theme.superLightBlue};
      border-radius: 6px;
      border: 1px solid ${(props) => props.theme.border3};
      font-size: 12px;
      @media (max-width: ${breakPoints.md}px) {
        margin-bottom: 0;
        max-width: 100%;
      }
      > div {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid ${(props) => props.theme.border2};
        padding: 0.25rem 0;
        :first-of-type {
          padding-top: 0;
        }
        :last-of-type {
          border-bottom: 0;
          padding-bottom: 0;
        }
        .borrow_total_borrowed {
          display: flex;
          align-items: center;
          img {
            height: 13px;
            width: 13px;
            margin-left: 4px;
            border-radius: 50%;
          }
        }
      }
    }
    .borrow_asset_history_desktop {
      max-width: 520px;
      @media (max-width: ${breakPoints.md}px) {
        display: none;
      }
    }
  }
`;

const CustomTooltipWrapper = styled.div`
  background: ${(props) => props.theme.superLightBlue};
  padding: 0.75rem 1rem;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.lightBlue3};
  font-size: 14px;
  span {
    color: ${(props) => props.theme.brandBlue};
  }
  .tooltip_pink {
    color: ${(props) => props.theme.uniswapPink};
  }
`;

const HealthFactorSpan = styled.span`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme[props.color]};
  font-weight: 600;
  svg {
    height: 13px;
    width: 13px;
    margin-left: 3px;
    cursor: pointer;
    path {
      fill: ${(props) => props.theme.mainDarkText};
    }
  }
`;

const CustomTooltip = ({ active, payload, label, t }) => {
  if (active && payload && payload.length) {
    return (
      <CustomTooltipWrapper>
        <div>{label}</div>
        <div>
          {t("borrow.stableAPY")}:{" "}
          <span>
            {beautifyTokensAmount(payload[0].value, {
              suffix: "%",
              percent: true,
            })}
          </span>
        </div>
        <div>
          {t("borrow.variableAPY")}:{" "}
          <span className="tooltip_pink">
            {beautifyTokensAmount(payload[1].value, {
              suffix: "%",
              percent: true,
            })}
          </span>
        </div>
      </CustomTooltipWrapper>
    );
  }
  return null;
};

const HistoricalDataChart = ({ data, chartData, t }) => (
  <>
    <h3>{t("borrow.borrowTab.historicalBorrowRates")}</h3>
    {data.length !== 0 ? (
      <ResponsiveContainer width="99%" height={220}>
        <AreaChart data={chartData} margin={{ top: 16 }}>
          <XAxis dataKey="timestamp" tick={{ fontSize: 10 }} />
          <YAxis
            allowDecimals
            axisLine={false}
            tickLine={false}
            tick={{ fontSize: "10px", dy: -10 }}
            mirror
            unit="%"
          />
          <CartesianGrid vertical={false} />
          <Tooltip content={<CustomTooltip t={t} />} />
          <Area
            type="monotone"
            dataKey="stableBorrowRate"
            stroke="#1C6DDA"
            fillOpacity={0}
            strokeWidth={2}
          />
          <Area
            type="monotone"
            dataKey="variableBorrowRate"
            stroke="#FD017A"
            fillOpacity={0}
            strokeWidth={2}
          />
        </AreaChart>
      </ResponsiveContainer>
    ) : (
      <Spinner style={{ marginTop: "50px" }} />
    )}
  </>
);

const BorrowAsset = () => {
  const { t } = useTranslation();
  const [historicalAPYData, setHistoricalAPYData] = useState([]);

  const user = useSelector((state) => state.user);
  const wallet = useSelector((state) => state.wallet);
  const { network } = wallet;
  const borrow = useSelector((state) => state.borrow);
  const { mainnet } = borrow;

  const params = useParams();
  const { platform, version, asset } = params;

  const borrowAssetData = mainnet[platform][version].data.find(
    (item) => item.symbol === asset
  );
  const borrowedBalance = mainnet[platform][version].balance;
  const userBorrowPlatformData = mainnet[platform][version].user;

  useEffect(() => {
    const fetchAPYData = async () => {
      const data = await integrations[platform][version].getAssetHistoricalAPY(
        asset
      );
      const adjustedData = data.map((item) => ({
        ...item,
        stableBorrowRate: borrowAssetData.stableBorrowRateEnabled
          ? parseFloat(item.stableBorrowRate)
          : 0,
        variableBorrowRate: parseFloat(item.variableBorrowRate),
        timestamp: dayjs(item.timestamp * 1000).format("DD MMM"),
      }));
      setHistoricalAPYData(adjustedData);
    };
    if (asset && platform && version && network && network.chainId) {
      fetchAPYData();
    }
  }, [asset, platform, version]);

  const chartData = useMemo(() => historicalAPYData, [historicalAPYData]);

  const tabs = [
    {
      Name: t("borrow.borrowTab.tab"),
      Component: (
        <BorrowTab
          params={params}
          borrowAssetData={borrowAssetData}
          userBorrowPlatformData={userBorrowPlatformData}
          borrow={borrow}
        />
      ),
    },
    {
      Name: t("borrow.borrowTab.repayTab.tab"),
      disable: borrowedBalance[asset]
        ? borrowedBalance[asset].totalDebt === "0"
        : true,
      disableTitle: t("borrow.borrowTab.repayTab.warning"),
      Component: (
        <RepayTab
          params={params}
          borrow={borrow}
          userBorrowPlatformData={userBorrowPlatformData}
          borrowAssetData={borrowAssetData}
        />
      ),
    },
  ];

  // Checks the percentage => outputs a color from styled-components colors list
  const healthFactorColor = () => {
    if (userBorrowPlatformData.healthFactor > 1.5) return "borrowGreen";
    if (
      userBorrowPlatformData.healthFactor > 1.2 &&
      userBorrowPlatformData.healthFactor <= 1.5
    )
      return "borrowYellow";
    return "error";
  };

  if (borrowAssetData) {
    return (
      <Wrapper>
        <BorrowAssetHeader
          user={user}
          params={params}
          borrowAssetData={borrowAssetData}
        />
        <BorrowAssetBody>
          <div className="borrow_asset_body_tabs">
            <Tabs tabs={tabs} />
            <div className="borrow_asset_history_mobile">
              <HistoricalDataChart
                t={t}
                data={historicalAPYData}
                chartData={chartData}
              />
            </div>
          </div>
          <div className="borrow_asset_body_info">
            <h3>{t("borrow.borrowTab.status.title")}</h3>
            <div className="borrow_asset_info">
              <div>
                <span>{t("borrow.borrowTab.status.totalBorrow")}</span>
                <span className="borrow_total_borrowed">
                  {beautifyTokensAmount(borrowedBalance[asset].totalDebt, {
                    suffix: ` ${asset}`,
                  })}{" "}
                  <AssetImage
                    src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${borrowAssetData.address}/logo.png`}
                    alt={borrowAssetData.symbol}
                  />
                </span>
              </div>
              <div>
                <span>{t("borrow.borrowTab.status.totalCollateral")}</span>
                <div>
                  {user.usdPrices ? (
                    beautifyTokensAmount(
                      userBorrowPlatformData.totalCollateralETH *
                        user.usdPrices.ETH,
                      {
                        prefix: "$",
                        suffix: " USD",
                      }
                    )
                  ) : (
                    <Skeleton width="40" height="14" white />
                  )}
                </div>
              </div>
              <div>
                <span>{t("borrow.borrowTab.status.loanToValue")}</span>
                <span>
                  {beautifyTokensAmount(userBorrowPlatformData.ltv, {
                    suffix: "%",
                    percent: true,
                  })}
                </span>
              </div>
              <div>
                <span>{t("borrow.borrowTab.status.healthFactor")}</span>
                <HealthFactorSpan color={healthFactorColor()}>
                  {userBorrowPlatformData.totalCollateralETH !== "0" &&
                  userBorrowPlatformData.totalDebtETH !== "0"
                    ? beautifyTokensAmount(userBorrowPlatformData.healthFactor)
                    : "-"}
                  <InfoIcon />
                </HealthFactorSpan>
              </div>
            </div>
            <div className="borrow_asset_history_desktop">
              <HistoricalDataChart
                t={t}
                data={historicalAPYData}
                chartData={chartData}
              />
            </div>
          </div>
        </BorrowAssetBody>
      </Wrapper>
    );
  }
  return <Redirect to="/borrow" />;
};

export default BorrowAsset;
