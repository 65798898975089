import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import StableCoins from "../components/Rebalancer/StableCoins";
import MaxYield from "../components/Rebalancer/MaxYield";
import Tabs from "../components/Tabs";
import { breakPoints } from "../theme";

const Wrapper = styled.div`
  width: 1000px;
  margin: 2.5rem auto;
  overflow: hidden;
  @media (max-width: ${breakPoints.laptop}px) {
    width: 100%;
  }
`;

function Rebalancer() {
  const { t } = useTranslation();

  const tabs = [
    { Name: t("rebalancer.stableCoins"), Component: <StableCoins /> },
    { Name: t("rebalancer.maxYield"), Component: <MaxYield /> },
  ];
  return (
    <Wrapper>
      <Tabs tabs={tabs} />
    </Wrapper>
  );
}

export default Rebalancer;
