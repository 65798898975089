import { tokensLogos } from "../../helpers";
import { useTranslation } from "react-i18next";

export const typeOptions = (t) => {
  return [
    {
      id: "",
      text: t("borrow.all"),
    },
    {
      id: "stablecoins",
      text: t("borrow.stablecoin"),
    },
  ];
};

export const platformOptions = (t) => {
  return [
    {
      id: "",
      text: t("borrow.all"),
    },
    {
      id: "Compound",
      text: "Compound",
    },
    {
      id: "Aave",
      text: `Aave (${t("borrow.all")})`,
    },
    {
      id: "Aave v2",
      text: "Aave v2",
    },
    {
      id: "Aave v1",
      text: "Aave v1",
    },
  ];
};

export const tokenOptions = (data, type, t) =>
  [
    { id: "", text: t("borrow.all") },
    ...data
      .filter((item, i, array) => {
        if (type === "stablecoins") {
          return stablecoins.find((coin) => coin === item.symbol);
        }
        return item;
      })
      .map((item) => ({
        id: item.symbol,
        text: item.symbol,
        img: tokensLogos[item.symbol],
      }))
      .reduce((acc, token) => {
        const isTokenInAcc = acc.findIndex((item) => item.id === token.id);
        if (isTokenInAcc > -1) {
          return acc;
        }
        return [...acc, token];
      }, []),
  ] || [];

export const tableHeadItems = (t) => [
  { id: "symbol", label: t("borrow.token"), sortable: true },
  { id: "platform", label: t("borrow.platform"), sortable: true },
  {
    id: "availableToBorrow",
    label: t("borrow.availableToBorrow"),
    sortable: true,
  },
  { id: "variableAPY", label: t("borrow.variableAPY"), sortable: true },
  { id: "stableAPY", label: t("borrow.stableAPY"), sortable: true },
];

export const stablecoins = [
  "BUSD",
  "DAI",
  "GUSD",
  "TUSD",
  "USDC",
  "USDT",
  "sUSD",
];
