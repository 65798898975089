import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

// Assets
import { breakPoints } from "../theme";
import ETH from "../assets/tokens/ETH.svg";
import BSC from "../assets/tokens/BSC.svg";
import Polygon from "../assets/Polygon-chain.svg";
import PolygonVector from "../assets/polygon-vector.svg";
import UNI from "../assets/platforms/uniswap.svg";
import CAKE from "../assets/platforms/pancakeswap.png";
import APE from "../assets/platforms/Apeswap.svg";
import WORLD from "../assets/world.svg";
import WHITEPAPER from "../assets/whitepaper.svg";
import HELP from "../assets/help_icon.svg";
import FAQ from "../assets/faq.svg";
import QuickSwap from "../assets/quick-swap.svg";
import GITHUB from "../assets/socialMedia/Github.svg";
import TELEGRAM from "../assets/socialMedia/Telegram.svg";
import TWITTER from "../assets/socialMedia/Twitter.svg";
import DISCORD from "../assets/socialMedia/Discord.svg";
import COINMARKETCAP from "../assets/socialMedia/CoinMarketCap.svg";
import COINGECKO from "../assets/socialMedia/CoinGecko.svg";
import ETHERSCAN from "../assets/socialMedia/EtherScan.svg";
import BSCSCAN from "../assets/socialMedia/BSCScan.svg";
import INSTAGRAM from "../assets/socialMedia/Instagram.svg";
import REDDIT from "../assets/socialMedia/Reddit.svg";
import LINKEDIN from "../assets/socialMedia/LinkedIn.svg";
import MEDIUM from "../assets/socialMedia/Medium.svg";
import YOUTUBE from "../assets/socialMedia/YouTube.svg";
import DEXTOOLS from "../assets/socialMedia/dextools.png";
import LUNAR from "../assets/socialMedia/lunarcrush_logo.png";
import COINRANKING from "../assets/socialMedia/coinranking.svg";
import CRYPTOPANIC from "../assets/socialMedia/cryptopanic.png";
import NOMICS from "../assets/platforms/Nomics.svg";
import NOMICSBSC from "../assets/platforms/NomicsBSC.svg";

const KeyFiTokens = [
  {
    network: "ETH",
    networkImage: (
      <img src={ETH} className="info_network_image" alt="Ethereum" />
    ),
    tutorialLink: "https://docs.keyfi.com/buy-keyfi#buy-on-uniswap",
    links: [
      {
        url: "https://v2.info.uniswap.org/token/0xb8647e90c0645152fccf4d9abb6b59eb4aa99052",
        title: "KEYFI",
        image: <img src={UNI} alt="KeyFi" />,
      },
      {
        url: "https://app.uniswap.org/#/add/v2/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/0xB8647e90C0645152Fccf4d9AbB6B59Eb4AA99052",
        title: "KEYFI-USDC LP",
        image: <img src={UNI} alt="KeyFiUSDC LP" />,
      },
      {
        url: "https://app.uniswap.org/#/add/v2/ETH/0xB8647e90C0645152Fccf4d9AbB6B59Eb4AA99052",
        title: "KEYFI-ETH LP",
        image: <img src={UNI} alt="KeyFiETH LP" />,
      },
    ],
  },
  {
    network: "BSC",
    networkImage: (
      <img src={BSC} className="info_network_image" alt="Binance Smart Chain" />
    ),
    tutorialLink: "https://docs.keyfi.com/buy-keyfi#buy-on-apeswap",
    links: [
      {
        url: "https://dex.apeswap.finance/#/swap?outputCurrency=0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d",
        title: "KEYFI",
        image: <img src={APE} alt="KeyFi on ApeSwap" />,
      },
      {
        url: "https://pancakeswap.finance/swap?outputCurrency=0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d",
        title: "KEYFI",
        image: <img src={CAKE} alt="KeyFi on PancakeSwap" />,
      },
      {
        url: "https://pancakeswap.info/pool/0xd10321489beb6d3a83e09fa059cf6c8be5a4c542",
        title: "KEYFI-BUSD LP",
        image: <img src={CAKE} alt="KeyFi-BUSD LP on PancakeSwap" />,
      },
    ],
  },
  {
    network: "Polygon",
    networkImage: (
      <img src={Polygon} className="info_network_image" alt="Polygon Chain" />
    ),
    tutorialLink: "https://docs.keyfi.com/buy-keyfi/buy-on-quickswap",
    links: [
      {
        url: "https://quickswap.exchange/#/swap?inputCurrency=0xd1a5f2a049343fc4d5f8d478f734eba51b22375e",
        title: "KEYFI",
        image: <img src={QuickSwap} alt="KeyFi on QuickSwap" />,
      },
      {
        url: "https://quickswap.exchange/#/quick/0xD1A5f2A049343fc4D5F8d478f734eBa51B22375E/0x831753DD7087CaC61aB5644b308642cc1c33Dc13/0x8b405c88cdACB1f54206183bC5866bdAdfa315c8",
        title: "KEYFI-QUICK LP",
        image: <img src={QuickSwap} alt="KeyFi on QuickSwap" />,
      },
    ],
  },
];

const Wrapper = styled.div`
  max-width: 750px;
  width: 100%;
  padding-bottom: 2rem;
  margin: 0 auto;
  .info_section {
    :first-of-type {
      margin-bottom: 3rem;
    }
  }
`;

const InfoBox = styled.div`
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.lightBlue3};
  padding: 1.5rem;
  background: ${(props) => props.theme.superLightBlue};
  display: flex;
  flex-direction: column;
  font-weight: 500;
  .section_title {
    margin-bottom: 0.5rem;
  }
  .infobox_section {
    margin-bottom: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .infobox_section_half {
      display: flex;
      flex-direction: column;
      width: 50%;
      .section_title {
        margin-bottom: 0.5rem;
      }
    }

    @media (max-width: ${breakPoints.md}px) {
      min-height: ${(props) => (props.minHeight ? "400px" : "none")};
      flex-direction: column;
      .infobox_section_half {
        width: 100%;
      }
    }
  }
  @media (max-width: ${breakPoints.md}px) {
    .infobox_main {
      > div {
        margin-bottom: 2rem;
      }
    }
  }
  .info_network {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    * {
      margin: 0;
    }
    .info_network_image {
      max-height: 64px;
      margin-right: 0.5rem;
    }

    h2 {
      font-size: 32px;
      font-weight: 600;
      line-height: 32px;
    }
    .info_network_icon {
      margin-left: 1.5rem;
      img {
        min-width: 32px;
        min-height: 32px;
        margin-right: 0.6rem;
      }
    }
  }
  .info_links {
    flex: 1;
    .info_link {
      margin-bottom: 0.75rem;
      display: flex;
      align-items: center;
      width: fit-content;
      font-weight: 500;
      font-size: 16px;
      a {
        color: ${(props) => props.theme.brandBlue};
        text-decoration: underline;
        margin: 0 0.5rem;
      }
      span {
        margin-right: 0.5rem;
      }
      img {
        height: 24px;
      }
    }
  }

  .infobox_link {
    margin: 0 auto;
  }

  .infobox_button {
    cursor: pointer;
    font-size: 16px;
    margin-top: 1.5rem;
    outline: 0;
    border: 0;
    border-radius: 6px;
    color: white;
    background: ${(props) => props.theme.brandBlue};
    padding: 0.6rem 1.25rem;
    :hover {
      background: ${(props) => props.theme.primaryHover};
    }
  }
`;

const LinkContainer = styled.div`
  margin: 0 0 1rem;
  padding-right: 2rem;
  > * {
    margin: 0;
  }
  a {
    margin-bottom: 0.25rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: ${(props) => props.theme.brandBlue};
    font-weight: 500;
    text-decoration: underline;
    width: max-content;
    img {
      margin-right: 6px;
      max-width: 16px;
    }
  }
`;

const SocialMediaContainer = styled.div`
  .socialmedia_title {
    margin-bottom: 0.5rem;
  }
  .socialmedia_links {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    a {
      display: flex;
      align-items: center;
      color: ${(props) => props.theme.mainDarkText};

      white-space: nowrap;
      :hover {
        color: ${(props) => props.theme.brandBlue};
      }
      img {
        margin-right: 6px;
        height: 21px;
      }
    }
    @media (max-width: ${breakPoints.md}px) {
      gap: 1.5rem;
    }
  }
`;

const InformationLink = ({ image, label, url, breakUrl }) => (
  <LinkContainer breakUrl={breakUrl}>
    <a href={url} target="_blank" rel="noreferrer">
      {image && <img src={image} alt={label} />}
      <span>{label}</span>
    </a>
  </LinkContainer>
);

const Info = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <div className="info_section">
        <h2>{t("keyfiInfo.title")}</h2>
        <InfoBox minHeight>
          <div className="infobox_section infobox_main">
            {KeyFiTokens.map((item) => (
              <div key={item.network} className="infobox_section_half">
                <div className="info_network">
                  {item.networkImage}
                  <div>
                    <h2>{item.network}</h2>
                    <p>{t("keyfiInfo.network")}</p>
                  </div>
                </div>
                <div className="info_links">
                  {item.links.map((link) => (
                    <div className="info_link">
                      {link.image}
                      <a href={link.url} target="_blank" rel="noreferrer">
                        {link.title}
                      </a>
                    </div>
                  ))}
                </div>
                {/* <a href={item.tutorialLink} target="_blank" rel="noreferrer">
                  <button className="infobox_button">
                    {t(`keyfiInfo.buyOn${item.network}btn`)}
                  </button>
                </a> */}
              </div>
            ))}
          </div>
          <a
            href="https://docs.keyfi.com/buy-keyfi"
            target="_blank"
            rel="noreferrer"
            className="infobox_link"
          >
            <button className="infobox_button">How to Buy KEYFI</button>
          </a>
        </InfoBox>
      </div>
      <div>
        <h2>{t("keyfiInfo.information")}</h2>
        <InfoBox>
          <span className="section_title">{t("keyfiInfo.resources")}</span>
          <div className="infobox_section">
            <div className="infobox_section_half">
              <InformationLink
                image={WORLD}
                label={t("keyfiInfo.website")}
                url="https://keyfi.com"
              />
              <InformationLink
                image={WHITEPAPER}
                label={t("keyfiInfo.whitepaper")}
                url="https://keyfi.com/whitepaper"
              />
            </div>
            <div className="infobox_section_half">
              <InformationLink
                image={HELP}
                label={t("keyfiInfo.userGuide")}
                url="https://docs.keyfi.com"
              />
            </div>
            <div className="infobox_section_half">
              <InformationLink
                image={FAQ}
                label="FAQ"
                url="https://keyfi.com/#faq"
              />
            </div>
          </div>

          <span className="section_title">{t("keyfiInfo.liveCharts")}</span>
          <div className="infobox_section">
            <div className="infobox_section_half">
              <InformationLink
                image={UNI}
                label="Uniswap"
                url="https://v2.info.uniswap.org/token/0xb8647e90c0645152fccf4d9abb6b59eb4aa99052"
              />

              <InformationLink
                image={QuickSwap}
                label="QuickSwap"
                url="https://info.quickswap.exchange/#/token/0xd1a5f2a049343fc4d5f8d478f734eba51b22375e"
              />
            </div>
            <div className="infobox_section_half">
              <InformationLink
                image={CAKE}
                label="PancakeSwap"
                url="https://pancakeswap.finance/info/token/0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d"
              />
              <InformationLink
                image={NOMICS}
                label="Nomics ERC-20"
                url="https://nomics.com/assets/keyfi-keyfi-token-erc-20"
              />
            </div>
            <div className="infobox_section_half">
              <InformationLink
                image={APE}
                label="ApeSwap"
                url="https://info.apeswap.finance/token/0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d"
              />
              <InformationLink
                image={NOMICSBSC}
                label="Nomics BEP-20"
                url="https://nomics.com/assets/keyfibep20-keyfi-token-bep-20"
              />
            </div>
          </div>

          <span className="section_title">{t("keyfiInfo.tokenData")}</span>
          <div className="infobox_section">
            <div className="infobox_section_half">
              <InformationLink
                image={ETHERSCAN}
                label="Etherscan"
                url="https://etherscan.io/token/0xb8647e90c0645152fccf4d9abb6b59eb4aa99052"
              />
              <InformationLink
                image={COINMARKETCAP}
                label="CoinMarketCap"
                url="https://coinmarketcap.com/currencies/keyfi"
              />
              {/* <InformationLink
                image={COINRANKING}
                label="Coinranking"
                url="https://coinranking.com/coin/ZtmfWiAH0+keyfi-keyfi"
              /> */}
            </div>
            <div className="infobox_section_half">
              <InformationLink
                image={BSCSCAN}
                label="BscScan"
                url="https://bscscan.com/token/0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d"
              />
              <InformationLink
                image={COINGECKO}
                label="CoinGecko"
                url="https://www.coingecko.com/en/coins/keyfi"
              />
            </div>
            <div className="infobox_section_half">
              <InformationLink
                image={PolygonVector}
                label="PolygonScan"
                url="https://polygonscan.com/token/0xd1a5f2a049343fc4d5f8d478f734eba51b22375e"
              />
              <InformationLink
                image={QuickSwap}
                label="QuickSwap"
                url="https://info.quickswap.exchange/#/token/0xd1a5f2a049343fc4d5f8d478f734eba51b22375e"
              />
            </div>
          </div>
          <span className="section_title">News</span>

          <div className="infobox_section">
            <div className="infobox_section_half">
              <InformationLink
                image={MEDIUM}
                label="Medium"
                url="https://keyfi-app.medium.com/"
              />
            </div>
            <div className="infobox_section_half">
              <InformationLink
                image={CRYPTOPANIC}
                label="CryptoPanic"
                url="https://cryptopanic.com/news/keyfi/"
              />
            </div>
            <div className="infobox_section_half">
              {" "}
              <InformationLink
                image={TELEGRAM}
                label="News Channel"
                url="https://t.me/keyfi_official"
              />
            </div>
          </div>
          <span className="section_title">{t("keyfiInfo.community")}</span>

          <div className="infobox_section">
            <div className="infobox_section_half">
              <InformationLink
                image={TELEGRAM}
                label="Telegram"
                url="https://t.me/keyfi_ai"
              />
              <InformationLink
                image={INSTAGRAM}
                label="Instagram"
                url="https://www.instagram.com/keyfi.app/"
              />

              <InformationLink
                image={LINKEDIN}
                label="LinkedIn"
                url="https://www.linkedin.com/company/keyfi-app"
              />
            </div>
            <div className="infobox_section_half">
              <InformationLink
                image={TWITTER}
                label="Twitter"
                url="https://twitter.com/keyfi_app"
              />
              <InformationLink
                image={DISCORD}
                label="Discord"
                url="https://discord.com/invite/F8uruMRYYr"
              />
              <InformationLink
                image={YOUTUBE}
                label="YouTube"
                url="https://www.youtube.com/channel/UCt2BePcLH4aK5cOKbNDJjiA/videos"
              />
            </div>
            <div className="infobox_section_half">
              <InformationLink
                image={GITHUB}
                label="GitHub"
                url="https://github.com/KEYFIAI/"
              />
              <InformationLink
                image={REDDIT}
                label="Reddit"
                url="https://www.reddit.com/r/KeyFi_Official/"
              />
            </div>
          </div>
          <div className="infobox_section">
            {/* Community - Instagram, Medium, YouTube, Reddit, LinkedIn */}
            {/* <SocialMediaContainer>
              <div className="socialmedia_title">
                {t("keyfiInfo.community")}
              </div>
              <div className="socialmedia_links">
                <a
                  href="https://www.reddit.com/r/KeyFi_Official/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={REDDIT} alt="Reddit" />
                  Reddit
                </a>
              </div>
            </SocialMediaContainer> */}
          </div>
        </InfoBox>
      </div>
    </Wrapper>
  );
};

export default Info;
