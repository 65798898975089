import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { beautifyTokensAmount } from "../../helpers";

const Wrapper = styled.div`
  margin-top: 1rem;
  h4 {
    font-size: 12px;
  }
  .health_factor_body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const ProgressBarWrapper = styled.div`
  position: relative;
  width: 75%;
  height: 6px;
  border-radius: 6px;
  background: ${(props) => props.theme.disabled};
  .health_factor_bar {
    background: ${(props) => props.theme[props.color]};
    width: ${(props) => props.percent}%;
    height: 100%;
    border-radius: inherit;
    transition: 0.5s ease;
  }
  .health_factor_value {
    position: absolute;
    top: 250%;
    left: ${(props) => `${props.percent}%`};
    background: ${(props) => props.theme[props.color]};
    padding: 0.25rem 0.75rem;
    color: white;
    border-radius: 6px;
    transform: ${(props) =>
      `translateX(-${props.healthFactorValueWidth / 2}px)`};
    transition: left 0.5s ease;
    font-size: 12px;
    .health_factor_value_arrow {
      position: absolute;
      bottom: 100%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 6px solid ${(props) => props.theme[props.color]};
    }
  }
`;

const HealthFactorMessage = styled.div`
  padding: 0.25rem 0.75rem;
  border-radius: 6px;
  background: ${(props) => props.theme[props.color]};
  color: white;
  font-size: 12px;
`;

const HealthFactorBar = ({ currentHealthFactor, estimateHealthFactor }) => {
  const { t } = useTranslation();

  const observed = useRef(null);
  const [width, setWidth] = useState(0);

  const healthFactor = estimateHealthFactor || currentHealthFactor;
  const healthFactorPercent =
    ((10 - (healthFactor > 10 ? 10 : healthFactor)) / (10 - 1)) * 100;

  useEffect(() => {
    setWidth(observed.current ? observed.current.offsetWidth : 0);
  }, [estimateHealthFactor]);

  // Checks the percentage => outputs a color from styled-components colors list
  const healthFactorColor = () => {
    if (healthFactor > 1.5) return "borrowGreen";
    if (healthFactor > 1.2 && healthFactor <= 1.5) return "borrowYellow";
    return "error";
  };

  // Checks the percentage => outputs a message
  const healthFactorMessage = () => {
    if (healthFactorColor() === "borrowGreen") return t("addition.safe");
    if (healthFactorColor() === "borrowYellow") return t("addition.moderate");
    return t("addition.danger");
  };

  return (
    <Wrapper>
      <h4>{t("addition.healthFactor")}</h4>
      <div className="health_factor_body">
        <ProgressBarWrapper
          percent={
            healthFactor === "0"
              ? 0
              : healthFactorPercent < 100
              ? healthFactorPercent
              : 100
          }
          color={healthFactorColor()}
          healthFactorValueWidth={width}
        >
          <div className="health_factor_bar" />
          <div ref={observed} className="health_factor_value">
            {healthFactor > 20 || healthFactor === "0"
              ? "-"
              : beautifyTokensAmount(healthFactor)}
            <div className="health_factor_value_arrow" />
          </div>
        </ProgressBarWrapper>
        <HealthFactorMessage color={healthFactorColor()}>
          {healthFactorMessage()}
        </HealthFactorMessage>
      </div>
    </Wrapper>
  );
};

export default HealthFactorBar;
