import React from "react";
import { useTranslation } from "react-i18next";
import TotalCard from "./TotalCard";
import LiquidityPoolsIcon from "../../assets/pools-blue.svg";

function LiquidityPoolsCard({ total }) {
  const { t } = useTranslation();

  const cardProps = {
    name: t("dashboard.accountOverview.liquidityPool"),
    icon: <img src={LiquidityPoolsIcon} alt="Liquidity Pools Icon" />,
  };
  return (
    <div>
      <TotalCard value={total} {...cardProps} />
    </div>
  );
}

export default LiquidityPoolsCard;
