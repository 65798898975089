import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  calculateTotal,
  getLiquditiesFromNetwork,
  getTokensTotalFromPairs,
  merge,
} from "../helpers";

const FETCH_URL = process.env.REACT_APP_BALANCE_API_URL;

const initialState = {
  mainnet: {
    uniswap: {
      v2: [],
    },
    curve: {
      v1: [],
    },
  },
  "bsc-mainnet": {
    pancakeswap: {
      v1: [],
      v2: [],
    },
    apeswap: {
      v1: [],
    },
  },
  polygon: {
    quickswap: {
      v1: [],
    },
    apeswap: {
      v1: [],
    },
  },
};

// Example data
/* {
        assetA: "KEYFI",
        assetB: "BNB",
        KEYFI: "5.524212837932887896581585600768795146",
        BNB: "0.0096274166996377643432794619254182775",
        liquidity: "0.225921207272998058",
        totalLiquidity: "6115206441464250511403",
        liquidityPercent: "0.00003694416687900115",
      }, */

const liquidity = createSlice({
  name: "liquidity",
  initialState,
  reducers: {
    setMainnet(state, action) {
      state.mainnet = action.payload;
    },
    setPolygon(state, action) {
      state.polygon = action.payload;
    },
    setBSCMainnet(state, action) {
      state["bsc-mainnet"] = action.payload;
    },
  },
});

export const liquidityActions = liquidity.actions;

const getRoot = (state) => state.liquidity;

export const liquiditySelectors = {
  getMainnet: (state) => getRoot(state).mainnet,
  getBSCMainnet: (state) => getRoot(state)["bsc-mainnet"],
};

export const liquidityOperations = {
  getAccountLiquidity:
    (selectedAddress, network) => async (dispatch, getState) => {
      try {
        if (network.name === "bsc-mainnet") {
          const { data } = await axios.get(
            `${FETCH_URL}/liquidity?address=${selectedAddress}&network=bsc-mainnet&ignoreCache=true`
          );

          dispatch(liquidityActions.setBSCMainnet(data));
        }

        if (network.name === "mainnet") {
          const { data } = await axios.get(
            `${FETCH_URL}/liquidity?address=${selectedAddress}&network=mainnet&ignoreCache=true`
          );
          dispatch(liquidityActions.setMainnet(data));
        }

        if (network.name === "polygon") {
          const { data } = await axios.get(
            `${FETCH_URL}/liquidity?address=${selectedAddress}&network=polygon&ignoreCache=true`
          );
          dispatch(liquidityActions.setPolygon(data));
        }

        const state = getState();

        const allMainnet = getTokensTotalFromPairs(
          getLiquditiesFromNetwork(state.liquidity.mainnet)
        );

        const allBSCMainnet = getTokensTotalFromPairs(
          getLiquditiesFromNetwork(state.liquidity["bsc-mainnet"])
        );

        const allPolygon = getTokensTotalFromPairs(
          getLiquditiesFromNetwork(state.liquidity.polygon)
        );

        return merge(allMainnet, allBSCMainnet, allPolygon);
      } catch (err) {
        throw new Error(err.message);
      }
    },
  getAllPlatformLiquidityTokens: (platformName) => (_, getState) => {
    const { liquidity } = getState();
    return Object.values(liquidity).reduce((acc, platforms) => {
      if (platforms[platformName]) {
        const platformData = Object.values(platforms[platformName]).reduce(
          (acc2, version) => [...acc2, ...version]
        );
        return [...acc, ...platformData];
      }
      return acc;
    }, []);
  },
  getTotalLPNetworkValue: (network) => (_, getState) => {
    const { liquidity, user } = getState();
    return calculateTotal(
      getTokensTotalFromPairs(getLiquditiesFromNetwork(liquidity[network])),
      user.usdPrices
    );
  },
  getTotalLPValue: () => (_, getState) => {
    const { liquidity, user } = getState();

    return Object.keys(liquidity).reduce(
      (acc, network) =>
        acc +
        calculateTotal(
          getTokensTotalFromPairs(getLiquditiesFromNetwork(liquidity[network])),
          user.usdPrices
        ),
      0
    );
  },
};

export const liquidityReducer = liquidity.reducer;
