import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { beautifyTokensAmount, poolsLogos } from "../../../helpers";
import { breakPoints } from "../../../theme";
import AssetImage from "../AssetImage";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  display: none;
  @media (max-width: ${breakPoints.md}px) {
    display: block;
  }
`;

const MobileMyBorrowsTable = styled.table`
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  th {
    font-size: 12px;
    border-bottom: 1px solid ${(props) => props.theme.brandBlue};
    padding-bottom: 0.25rem;
    color: ${(props) => props.theme.brandBlue};
    text-align: left;
    :first-of-type {
      padding-left: 4px;
    }
  }

  tr {
    :not(:last-of-type) {
      border-bottom: 1px solid ${(props) => props.theme.border2};
    }
    td {
      font-size: 14px;
      padding: 0.5rem 0;
      :last-of-type {
        div {
          text-align: right;
        }
      }

      .mobile_myborrows_cell {
        display: flex;
        align-items: center;
        position: relative;
        .mobile_myborrows_image {
          height: 20px;
          width: 20px;
          margin-right: 6px;
        }
        .mobile_myborrows_platform_image {
          position: absolute;
          top: -35%;
          left: 12px;
          img {
            height: 10px;
          }
        }
      }
      .mobile_myborrows_amount {
        font-size: 12px;
      }
      a {
        font-weight: 600;
        text-decoration: underline;
        color: ${(props) => props.theme.brandBlue};
      }
    }
  }
`;

const platformColors = {
  compound: "#00D395",
  aave: "#CA5CBE",
};

function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}

const MobileMyBorrows = ({ borrowData }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <MobileMyBorrowsTable>
        <thead>
          <tr>
            <th>{t("dashboard.rebalancer.token")}</th>
            <th>
              {t("addition.value")} / {t("addition.amount")}
            </th>
            <th>{t("addition.borrowType")}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {borrowData.map((borrowAsset) => (
            <tr>
              <td>
                <div className="mobile_myborrows_cell">
                  <AssetImage
                    className="mobile_myborrows_image"
                    src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${borrowAsset.address}/logo.png`}
                  />
                  <div className="mobile_myborrows_platform_image">
                    {poolsLogos[capitalizeFirstLetter(borrowAsset.platform)]}
                  </div>
                  {borrowAsset.symbol}
                </div>
              </td>
              <td>
                <div>
                  <div
                    className="mobile_myborrows_price"
                    style={{ color: platformColors[borrowAsset.platform] }}
                  >
                    {beautifyTokensAmount(borrowAsset.debtAmountInUSD, {
                      prefix: "$",
                    })}
                  </div>
                  <div className="mobile_myborrows_amount">
                    {beautifyTokensAmount(borrowAsset.debtAmount)}
                  </div>
                </div>
              </td>
              <td>{borrowAsset.borrowMode === "1" ? "Stable" : "Variable"}</td>
              <td>
                <div>
                  <Link
                    to={`/borrow/${borrowAsset.platform}/${borrowAsset.version}/${borrowAsset.symbol}`}
                  >
                    {t("addition.manage")}
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </MobileMyBorrowsTable>
    </Wrapper>
  );
};

export default MobileMyBorrows;
