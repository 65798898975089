import React from "react";
import { useState } from "react";
import styled from "styled-components";

import { ReactComponent as ArrowDown } from "../assets/arrowDown.svg";

const Wrapper = styled.div`
  cursor: pointer;
  position: relative;
  background: ${(props) => (props.selected ? props.theme.lightBlue3 : props.theme.superLightBlue)};
  color: ${(props) => props.selected && props.theme.brandBlue};
  padding: 0.5rem;
  border: 1px solid ${(props) => (props.selected ? props.theme.brandBlue : props.theme.border2)};
  border-radius: ${(props) => (props.open ? `6px 6px 0 0` : `6px`)};
  font-size: 12px;
  min-width: 130px;
  .dropdown_selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      height: 100%;
      width: 14px;
      path {
        fill: ${(props) => props.theme.brandBlue};
      }
    }
  }
  .dropdown_options {
    background: ${(props) => props.theme.superLightBlue};
    color: ${(props) => props.theme.mainDarkText};
    overflow: auto;
    top: 100%;
    left: -1px;
    position: absolute;
    width: 130%;
    max-height: 180px;
    border: 1px solid ${(props) => (props.selected ? props.theme.brandBlue : props.theme.border2)};
    border-radius: 0 6px 6px 6px;
    z-index: 2;
    > div {
      cursor: pointer;
      padding: 0.5rem;
      :not(:last-of-type) {
        border-bottom: 1px solid ${(props) => props.theme.border2};
      }
      :hover {
        background: ${(props) => props.theme.lightBlue2};
      }
    }
  }
`;

/* 
*  options structure [
    {
        id: string,
        text: string,
        img: string?
    }
]
*
*/
const Dropdownv2 = ({ options, setOption, selectedOption, placeholder = "select" }) => {
  const [open, setOpen] = useState(false);

  const selectedOptionObject = selectedOption && options.find((item) => item.id === selectedOption);

  const handleSelectOption = (id) => {
    setOption(id);
    setOpen(false);
  };

  const onClickOutsideListener = () => {
    setOpen(false);
    document.removeEventListener("click", onClickOutsideListener);
  };

  return (
    <Wrapper
      open={open}
      selected={selectedOptionObject}
      onClick={() => {
        setOpen(!open);
      }}
      onMouseLeave={() => {
        if (open) {
          document.addEventListener("click", onClickOutsideListener);
        }
      }}
    >
      <div className="dropdown_selected">
        {selectedOption ? selectedOptionObject.text : placeholder} <ArrowDown />
      </div>
      {open && (
        <div className="dropdown_options">
          {options.map((item) => (
            <div onClick={() => handleSelectOption(item.id)}>{item.text}</div>
          ))}
        </div>
      )}
    </Wrapper>
  );
};

export default Dropdownv2;
