import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

// Assets and helpers
import { borrowOperations } from "../../../redux/borrowSlice";
import {
  platformOptions,
  stablecoins,
  tokenOptions,
  typeOptions,
} from "../BorrowConstants";
import { ReactComponent as Close } from "../../../assets/closeModal.svg";
import Dropdownv2 from "../../Dropdownv2";
import { breakPoints } from "../../../theme";
import BorrowTableDesktop from "./BorrowTableDesktop";
import BorrowTableMobile from "./BorrowTableMobile";

const Wrapper = styled.div`
  background: ${(props) => props.theme.superLightBlue};
  border: 1px solid ${(props) => props.theme.border3};
  border-radius: 6px;
  padding: 1.5rem;
  h2 {
    margin: 0 0 1rem;
    font-size: 20px;
  }
  .borrow_table_filter_container {
    display: flex;
    flex-wrap: wrap;
    .borrow_table_filter {
      margin-bottom: 1.5rem;
      margin-right: 1.5rem;
      .borrow_table_filter_label {
        font-size: 12px;
        color: ${(props) => props.theme.brandBlue};
        margin-bottom: 0.5rem;
      }
    }
    .borrow_table_filter_clear {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: fit-content;
      margin-top: 23px;
      font-size: 12px;
      padding: 0.5rem;
      border-radius: 6px;
      border: 1px solid ${(props) => props.theme.brandBlue};
      background: 0;
      color: ${(props) => props.theme.brandBlue};
      svg {
        height: 100%;
        width: 12px;
        margin-right: 0.75rem;
      }
      :hover {
        background: ${(props) => props.theme.lightBlue3};
      }
    }
  }
  @media (max-width: ${breakPoints.md}px) {
    padding: 1.25rem 0.75rem;
  }
`;

/**
 * Checks if the item matches borrow criteria
 * @param {object} item Borrow asset object
 * @param {object} filter Filter state
 * @return {boolean}
 */
const filterBorrowAssets = (item, filter) => {
  const { type, platform, token } = filter;

  const typeCheck = () =>
    !type ||
    (type === "stablecoins" &&
      stablecoins.find((coin) => coin === item.symbol));

  const platformCheck = () => !platform || item.platform.includes(platform);

  const tokenCheck = () =>
    !token || item.symbol.toLowerCase() === token.toLowerCase();

  return (
    typeCheck() && platformCheck() && tokenCheck() && item.variableAPY !== "0"
  );
};

const BorrowTable = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);
  const { network } = wallet;

  const borrow = useSelector((state) => state.borrow);
  const { mainnet, isFetching } = borrow;

  const [filter, setFilter] = useState({
    type: "",
    platform: "",
    token: "",
  });

  const aaveV1 = useMemo(
    () =>
      mainnet.aave.v1.data.map((item) => ({
        ...item,
        platform: "Aave v1",
        version: "v1",
        availableToBorrow:
          item.priceETH !== "0"
            ? mainnet.aave.v1.user.availableBorrowsETH / item.priceETH
            : 0,
        stableAPY: "0",
      })) || [],
    [mainnet.aave.v1.data, mainnet.aave.v1.user.availableBorrowsETH]
  );
  const aaveV2 = useMemo(
    () =>
      mainnet.aave.v2.data.map((item) => ({
        ...item,
        availableToBorrow:
          mainnet.aave.v2.user.availableBorrowsETH / item.priceETH,
        platform: "Aave v2",
        version: "v2",
      })) || [],
    [mainnet.aave.v2.data, mainnet.aave.v2.user.availableBorrowsETH]
  );

  const compoundV1 = useMemo(
    () =>
      mainnet.compound.v1.data.map((item) => ({
        ...item,
        availableToBorrow:
          mainnet.compound.v1.user.availableBorrowsETH / item.priceETH,
        platform: "Compound",
        version: "v1",
      })) || [],
    [mainnet.compound.v1.data, mainnet.compound.v1.user.availableBorrowsETH]
  );

  const data = [...aaveV1, ...aaveV2, ...compoundV1];

  useEffect(() => {
    if (aaveV1.length === 0 && network && network.name) {
      dispatch(borrowOperations.getBorrowBalance());
    }
  }, [aaveV1, network, dispatch]);

  return (
    <>
      <h2>{t("borrow.availableToBorrow")}</h2>
      <Wrapper>
        <div className="borrow_table_filter_container">
          <div className="borrow_table_filter">
            <div className="borrow_table_filter_label">{t("borrow.type")}</div>
            <Dropdownv2
              options={typeOptions(t)}
              setOption={(id) => setFilter((prev) => ({ ...prev, type: id }))}
              selectedOption={filter.type}
              placeholder={t("borrow.selectType")}
            />
          </div>
          <div className="borrow_table_filter">
            <div className="borrow_table_filter_label">
              {t("borrow.platform")}
            </div>
            <Dropdownv2
              options={platformOptions(t)}
              setOption={(id) =>
                setFilter((prev) => ({ ...prev, platform: id }))
              }
              selectedOption={filter.platform}
              placeholder={t("borrow.selectPlatform")}
            />
          </div>
          <div className="borrow_table_filter">
            <div className="borrow_table_filter_label">{t("borrow.token")}</div>
            <Dropdownv2
              options={tokenOptions(data, filter.type, t)}
              setOption={(id) => setFilter((prev) => ({ ...prev, token: id }))}
              selectedOption={filter.token}
              placeholder={t("borrow.selectToken")}
            />
          </div>
          {(filter.type || filter.platform || filter.token) && (
            <button
              className="borrow_table_filter_clear"
              onClick={() => setFilter({ type: "", platform: "", token: "" })}
            >
              <Close /> {t("borrow.clearFilter")}
            </button>
          )}
        </div>
        <BorrowTableDesktop
          data={data.filter((item) => filterBorrowAssets(item, filter))}
          filter={filter}
          isFetching={isFetching}
        />
        <BorrowTableMobile
          data={data.filter((item) => filterBorrowAssets(item, filter))}
          filter={filter}
          isFetching={isFetching}
        />
      </Wrapper>
    </>
  );
};

export default BorrowTable;
