import { integrations } from "@keyfi/keyfi-common";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFetching: true,
  rewardsTotal: "",
  liquidityTotal: "",
  rewards: {
    v1: {},
    v2: {},
  },
  balance: {
    v1: {},
    v2: {},
  },
};

const keyfiData = createSlice({
  name: "keyfiData",
  initialState,
  reducers: {
    setRewardsTotal(state, action) {
      return {
        ...state,
        rewardsTotal: action.payload,
      };
    },
    setLiquidityTotal(state, action) {
      return {
        ...state,
        liquidityTotal: action.payload,
      };
    },
    setIsFetching(state, action) {
      return {
        ...state,
        isFetching: action.payload,
      };
    },
    setRewards(state, action) {
      return {
        ...state,
        rewards: action.payload,
      };
    },
    setBalance(state, action) {
      return {
        ...state,
        balance: action.payload,
      };
    },
  },
});

export const keyfiDataActions = keyfiData.actions;

const getRoot = (state) => state.keyfiData;

export const keyfiDataSelectors = {
  getRewards: (state) => getRoot(state).rewardsTotal,
  getLiquidity: (state) => getRoot(state).liquidityTotal,
};

const supportedNetworks = ["mainnet", "bsc-mainnet", "polygon"];

export const keyfiDataOperations = {
  loadData: () => async (dispatch, getState) => {
    try {
      const { wallet, user } = getState();

      if (
        user.readOnly ||
        !supportedNetworks.find((name) => wallet.network.name === name)
      ) {
        dispatch(keyfiDataActions.setRewardsTotal(0));
        dispatch(keyfiDataActions.setLiquidityTotal(0));
        dispatch(keyfiDataActions.setIsFetching(false));
        return;
      }

      const [rewards, rewardsv2, liquidity, liquidityv2] = await Promise.all([
        integrations.keyfipool.getRewards(wallet.selectedAddress),
        wallet.network.chainId === 1 &&
          integrations.keyfipool.getRewardsv2(wallet.selectedAddress),
        integrations.keyfipool.getStaked(wallet.selectedAddress, true),
        wallet.network.chainId === 1 &&
          integrations.keyfipool.getStakedv2(wallet.selectedAddress, true),
      ]);

      const rewardsData = { ...rewards, ...rewardsv2 };
      const rewardsTotal = Object.keys(rewardsData).reduce(
        (acc, key) => acc + parseFloat(rewardsData[key]),
        0
      );

      const liquidityData = [liquidity.KEYFI ?? 0, liquidityv2.KEYFI ?? 0];
      const liquidityTotal = liquidityData.reduce(
        (acc, value) => acc + parseFloat(value),
        0
      );

      dispatch(
        keyfiDataActions.setRewards({
          v1: rewards,
          v2: rewardsv2,
        })
      );
      dispatch(
        keyfiDataActions.setBalance({
          v1: liquidity,
          v2: liquidityv2,
        })
      );
      dispatch(keyfiDataActions.setRewardsTotal(rewardsTotal));
      dispatch(keyfiDataActions.setLiquidityTotal(liquidityTotal));
      dispatch(keyfiDataActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      setTimeout(dispatch(keyfiDataOperations.loadData()), 3500);
    }
  },
};

export const keyfiDataReducer = keyfiData.reducer;
